const storeData = [
  {
    id: 'shopping',
    title: '쇼핑몰 APP',
    sub: '소비자의 지갑을 여는',
    detail: 'assets/imgs/storeDetail/shopping-app.png',
    options: [
      {
        optionTitle: '쇼핑몰 앱 - Android',
        model: 'Android',
        price: 6600000,
        salePrice: 3300000,
        storeRegistrationPrice: 330000,
        isSaled: true,
      },
      {
        optionTitle: '쇼핑몰 앱 - iOS',
        model: 'iOS',
        price: 6600000,
        salePrice: 3300000,
        storeRegistrationPrice: 330000,
        isSaled: true,
      },
      {
        optionTitle: '쇼핑몰 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 12000000,
        salePrice: 6600000,
        storeRegistrationPrice: 660000,
        isSaled: true,
      },
    ],
    images: [
      'assets/imgs/storeDetail/shopping1.png',
      'assets/imgs/storeDetail/shopping2.png',
      'assets/imgs/storeDetail/shopping3.png',
      'assets/imgs/storeDetail/shopping4.png',
      'assets/imgs/storeDetail/shopping5.png',
    ],
    style: ['#313131', '#FFF4F3', '#FF8B7F', '#FBFBFB'],
  },
  {
    id: 'community',
    title: '커뮤니티 APP',
    sub: '쉴새 없이 드나드는',
    detail: 'assets/imgs/storeDetail/community-app.png',
    options: [
      {
        optionTitle: '커뮤니티 앱 - Android',
        model: 'Android',
        price: 3300000,
        salePrice: 1100000,
        storeRegistrationPrice: 330000,
        isSaled: true,
      },
      {
        optionTitle: '커뮤니티 앱 - iOS',
        model: 'iOS',
        price: 3300000,
        salePrice: 1100000,
        storeRegistrationPrice: 330000,
        isSaled: true,
      },
      {
        optionTitle: '커뮤니티 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 6600000,
        salePrice: 2200000,
        storeRegistrationPrice: 660000,
        isSaled: true,
      },
    ],
    images: [
      'assets/imgs/storeDetail/community1.png',
      'assets/imgs/storeDetail/community2.png',
      'assets/imgs/storeDetail/community3.png',
      'assets/imgs/storeDetail/community4.png',
      'assets/imgs/storeDetail/community5.png',
    ],
    style: ['#313131', '#E9E8F3', '#F4A92B', '#FBFBFB'],
  },
  {
    id: 'matching',
    title: '매칭 APP',
    sub: '서로의 필요에 의한',
    detail: 'assets/imgs/storeDetail/matching-app.png',
    options: [
      {
        optionTitle: '매칭 앱 - Android',
        model: 'Android',
        price: 5500000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
        isSaled: false,
      },
      {
        optionTitle: '매칭 앱 - iOS',
        model: 'iOS',
        price: 5500000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
        isSaled: false,
      },
      {
        optionTitle: '매칭 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 11000000,
        storeRegistrationPrice: 660000,
        salePrice: 0,
        isSaled: false,
      },
    ],
    images: [
      'assets/imgs/storeDetail/matching1.png',
      'assets/imgs/storeDetail/matching2.png',
      'assets/imgs/storeDetail/matching3.png',
      'assets/imgs/storeDetail/matching4.png',
      'assets/imgs/storeDetail/matching5.png',
    ],
    style: ['#313131', '#C5E7D9', '#218F75', '#FBFBFB'],
  },
  {
    id: 'hospital',
    title: '병원 APP',
    sub: '소비자들이 원하는 병원정보',
    detail: 'assets/imgs/storeDetail/hospital-app.png',
    options: [
      {
        optionTitle: '병원 앱 - Android',
        model: 'Android',
        price: 5500000,
        storeRegistrationPrice: 330000,
        isSaled: false,
      },
      {
        optionTitle: '병원 앱 - iOS',
        model: 'iOS',
        price: 5500000,
        storeRegistrationPrice: 330000,
        isSaled: false,
      },
      {
        optionTitle: '병원 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 11000000,
        storeRegistrationPrice: 660000,
        isSaled: false,
      },
    ],
    images: [
      'assets/imgs/storeDetail/hospital1.png',
      'assets/imgs/storeDetail/hospital2.png',
      'assets/imgs/storeDetail/hospital3.png',
      'assets/imgs/storeDetail/hospital4.png',
      'assets/imgs/storeDetail/hospital5.png',
    ],
    style: ['#313131', '#E3F4FF', '#3DB7FF', '#FBFBFB'],
  },
  {
    id: 'delivery',
    title: '배달 APP',
    sub: '주문이 끊이질 않는',
    detail: 'assets/imgs/storeDetail/delivery-app.png',
    options: [
      {
        optionTitle: '배달 앱 - Android',
        model: 'Android',
        price: 11000000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
      },
      {
        optionTitle: '배달 앱 - iOS',
        model: 'iOS',
        price: 11000000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
      },
      {
        optionTitle: '배달 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 22000000,
        storeRegistrationPrice: 660000,
        salePrice: 0,
      },
    ],
    images: [
      'assets/imgs/storeDetail/delivery1.png',
      'assets/imgs/storeDetail/delivery2.png',
      'assets/imgs/storeDetail/delivery3.png',
      'assets/imgs/storeDetail/delivery4.png',
      'assets/imgs/storeDetail/delivery5.png',
    ],
    style: ['#313131', '#F3ECDF', '#DD3625', '#FBFBFB'],
  },
  {
    id: 'education',
    title: '교육 APP',
    sub: '학부모님들에 사랑받는',
    detail: 'assets/imgs/storeDetail/education-app.png',
    options: [
      {
        optionTitle: '교육 앱 - Android',
        model: 'Android',
        price: 5500000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
      },
      {
        optionTitle: '교육 앱 - iOS',
        model: 'iOS',
        price: 5500000,
        storeRegistrationPrice: 330000,
        salePrice: 0,
      },
      {
        optionTitle: '교육 앱 - Android + iOS',
        model: 'Android + iOS',
        price: 11000000,
        storeRegistrationPrice: 660000,
        salePrice: 0,
      },
    ],
    images: [
      'assets/imgs/storeDetail/education1.png',
      'assets/imgs/storeDetail/education2.png',
      'assets/imgs/storeDetail/education3.png',
      'assets/imgs/storeDetail/education4.png',
      'assets/imgs/storeDetail/education5.png',
    ],
    style: ['#313131', '#E3EBE1', '#3EA12F', '#FBFBFB'],
  },
];

export { storeData };
