<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h2 class="card-title">프로젝트 등록</h2>
            <p class="card-title-desc mb-3">프로젝트 정보를 입력해주세요.</p>
            <div class="row">
                <div class="mb-3">
                    <label for="project">프로젝트 명</label>
                    <input id="project"
                        name="project"
                        type="text"
                        disabled
                        class="form-control"
                        placeholder="미리보기 URL를 입력해주세요."
                        [(ngModel)]="contract.contractTitle">

                </div>
                <div class="mb-3">
                    <label class="control-label">카테고리</label>
                    <ng-select [items]="appCategories"
                        placeholder="카테고리를 선택해주세요.(다중선택 가능,없을 경우 텍스트 입력)"
                        [(ngModel)]="categories"
                        [addTag]="CreateNew"
                        [multiple]="true"></ng-select>

                </div>
                <div class="mb-3">

                    <label for="content">태그</label>
                    <mat-form-field class="example-chip-list">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let val of tagList"
                                [selectable]="true"
                                [removable]="true"
                                (removed)="remove(val)">
                                {{val}}
                                <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </mat-chip>
                            <input placeholder="새로운 태그를 추가해주세요."
                                [formControl]="tagCtrl"
                                [matChipInputFor]="chipList"
                                [matChipInputAddOnBlur]="true"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="add($event)">
                        </mat-chip-list>
                    </mat-form-field>

                </div>
                <div class="mb-3">
                    <label for="content">전체UX 파일</label>
                    <ngx-dropzone (change)="onSelect($event,'uxfiles')">
                        <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of uxfiles"
                            [removable]="true"
                            (removed)="onRemove(f,'uxfiles')">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                            </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>

                    <p *ngIf="isEdit&&project.uxfiles.length ==0 ">첨부파일 없음</p>
                    <div>
                        <div class="table-responsive"
                            *ngIf="isEdit && project.uxfiles.length > 0 ">

                            <table class="table table-centered table-nowrap"
                                *ngIf="project.uxfiles.length > 0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">파일명</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of  project.uxfiles">
                                        <td>
                                            {{getFileName(file) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="mb-3">
                    <label for="content">디자인 파일</label>
                    <ngx-dropzone (change)="onSelect($event,'uxfiles')">

                        <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of designFiles"
                            [removable]="true"
                            (removed)="onRemove(f,'designFiles')">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                            </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>

                    <p *ngIf="isEdit&& designFiles.length == 0 && project.designFiles.length ==0 ">첨부파일 없음</p>

                    <div>
                        <div class="table-responsive"
                            *ngIf="isEdit && project.designFiles.length > 0 ">


                            <table class="table table-centered mb-3 table-nowrap"
                                *ngIf="project.designFiles.length > 0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">파일명</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of  project.designFiles">
                                        <td>
                                            {{getFileName(file) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="content">퍼블리싱 APK파일</label>
                    <ngx-dropzone (change)="onSelect($event,'uxfiles')">

                        <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of publishingFiles"
                            [removable]="true"
                            (removed)="onRemove(f,'publishingFiles')">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                            </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>

                    <p *ngIf="isEdit&& publishingFiles.length == 0 && project.publishingFiles.length ==0 ">첨부파일
                        없음</p>

                    <div>
                        <div class="table-responsive"
                            *ngIf="isEdit && publishingFiles.length + project.publishingFiles.length > 0 ">


                            <table class="table table-centered mb-3 table-nowrap"
                                *ngIf="project.publishingFiles.length > 0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">파일명</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of  project.publishingFiles">
                                        <td>
                                            {{getFileName(file) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="content">개발 APK파일</label>
                    <ngx-dropzone (change)="onSelect($event,'uxfiles')">
                        <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                        <ngx-dropzone-preview *ngFor="let f of developedFiles"
                            [removable]="true"
                            (removed)="onRemove(f,'developedFiles')">
                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                            </ngx-dropzone-label>
                        </ngx-dropzone-preview>
                    </ngx-dropzone>

                    <p *ngIf="isEdit && project.developedFiles.length ==0 ">
                        첨부파일

                    <div>
                        <div class="table-responsive"
                            *ngIf="isEdit && project.developedFiles.length > 0 ">
                            <table class="table table-centered mb-0 table-nowrap"
                                *ngIf="developedFiles.length > 0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">업로드 파일명</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of developedFiles; let i = index">
                                        <td>
                                            {{file.name }}
                                        </td>
                                        <td>
                                            <button type="button"
                                                class="btn btn-sm btn-outline-danger"
                                                (click)="deleteUploadFile(i,'developedFiles')">삭제</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-centered mb-3 table-nowrap"
                                *ngIf="project.developedFiles.length > 0">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">파일명</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of  project.developedFiles">
                                        <td>
                                            {{getFileName(file) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="project">미리보기 URL</label>
                    <div class="row mb-2">
                        <div class="col-lg-12">
                            <input data-repeater-create
                                type="button"
                                class="btn btn-success inner"
                                value="항목추가"
                                (click)="addItem()" />
                        </div>
                    </div>

                    <div class="form-group row">
                        <!-- <label class="col-form-label col-lg-2">항목추가</label> -->
                        <div class="row col-lg-12 ml-md-auto"
                            *ngFor="let item of sampleUrls; let i = index;">
                            <div class="mb-3 row align-items-center">
                                <div class="col-md-4">
                                    <input type="text"
                                        class="form-control"
                                        [(ngModel)]="item.name"
                                        placeholder="제목을 입력해주세요." />
                                </div>
                                <div class="col-md-6">
                                    <div class="mt-4 mt-md-0">
                                        <input type="text"
                                            class="form-control"
                                            [(ngModel)]="item.url"
                                            placeholder="URL을 입력해주세요." />


                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="mt-2 mt-md-0">
                                        <input type="button"
                                            class="btn btn-danger btn-block inner"
                                            value="삭제"
                                            (click)="deleteItem(i)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!--
              <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
                  person, it will seem like simplified English, as a skeptical Cambridge.</p> -->

            </div>
            <div>
                <div class="d-flex flex-wrap gap-2 mt-2">
                    <input #fileInput
                        type="file"
                        multiple
                        style="display: none;"
                        (change)="onFileChange($event)" />
                    <button type="submit"
                        (click)="save()"
                        class="btn btn-primary">등록</button>
                    <button type="submit"
                        (click)="passBack()"
                        class="btn btn-secondary">취소</button>
                </div>
            </div>
        </div>
        <!-- <button type="submit"
        (click)="passBack()"
        class="btn btn-primary w-100">닫기</button> -->

    </div>
</div>