/** @format */

import { NgModule } from "@angular/core";
import { DateFormatPipe } from "./date-format.pipe";
import { CommonModule } from "@angular/common";
import { OrderByPipe } from "./order-by";
import { DocPipe } from "./doc.pipe";
import { ColPipe } from "./col.pipe";
import { SafeUrlPipe } from "./safeUrl.pipe";

@NgModule({
  declarations: [DateFormatPipe, OrderByPipe, DocPipe, ColPipe, SafeUrlPipe],
  imports: [CommonModule],
  exports: [DateFormatPipe, OrderByPipe, DocPipe, ColPipe, SafeUrlPipe],
})
export class PipesModule {}
