<div class="modal-header">
  <div class="modal-title mt-0">협약서 등록</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="file">첨부 견적서</label>
          <input id="file"
            type="file"
            class="form-control"
            (change)="onFileChange($event)">
        </div>
      </div>
    </div>
    <button class="btn btn-success"
      (click)="updatePartnershipDocument()"
      [disabled]="!myFile">등록</button>
  </div>

</div>