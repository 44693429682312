import { LocationStrategy } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Estimate } from "src/app/core/models/estimate.model";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
@Component({
  selector: "app-add-estimate",
  templateUrl: "./add-estimate.component.html",
  styleUrls: ["./add-estimate.component.scss"],
})
export class AddEstimateComponent implements OnInit {
  @Input() public inquiry;
  @HostListener("window:popstate", ["$event"])
  private _prevOnSameUrlNavigation;

  submitted = false;
  userList;
  inquiryList;
  inquiryList$;
  myFile;
  isEdit = false;
  isLoading = true;
  userList$;
  constructor(
    private _location: LocationStrategy,
    public modalService: NgbModal,
    public dataService: DataService,
    public fileUploaderService: FileUploaderSevice,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    public loader: LoaderService,
    private db: DbService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
  }

  async updateEstimate() {
    this.loader.setLoading(true);
    const file = await this.uploadFile(this.myFile);
    const estimate: Estimate = {
      dateCreated: new Date().toISOString(),
      file: file,
      createdBy: "",
      inquiryId: this.inquiry.id,
    };
    await this.dataService.addEstimate(estimate);
    this.loader.setLoading(false);
    await this.alertService.success("견적서가 등록되었습니다.");
    this.activeModal.close("update");
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFile = event.target.files[i];
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  customSearchFn(term: string, inquiry: Inquiry) {
    term = term ? term.toLowerCase() : "";
    return (
      inquiry.username?.toLowerCase().indexOf(term) > -1 ||
      inquiry.company?.toLowerCase().indexOf(term) > -1
    );
  }

  passBack() {
    this.activeModal.close();
  }

  getArrayToString(arr: User[]) {
    const stringArray = arr.map((item) => item.name);
    return stringArray.toString();
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }
}
