<div class="modal-header">
  <div class="modal-title mt-0">미팅 상세</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row m-2"
    *ngIf="meeting?.isCanceled">
    <button type="button"
      class="btn btn-danger font-size-16">취소된 미팅</button>
  </div>
  <div class="table-responsive table-nowrap">
    <table class="table table-borderless">
      <tbody>
        <tr>
          <th scope="row">제목 :</th>
          <td>{{meeting?.title}}</td>
        </tr>
        <tr>
          <th scope="row">설명 :</th>
          <td>{{meeting?.description}}</td>
        </tr>
        <tr>
          <th scope="row">미팅유형 :</th>
          <td>{{getMeetingTypeText(meeting?.color)}}</td>
        </tr>
        <tr>
          <th scope="row">화상미팅여부:</th>
          <td>{{meeting?.isVideoMeeting?'화상미팅':'해당없음'}}</td>
        </tr>
        <tr>
          <th scope="row">회의실 :</th>
          <td>{{getMeetingRoom(meeting?.meetingRoom)}}</td>
        </tr>
        <tr>
          <th scope="row">시간 :</th>
          <td>{{getDate(meeting?.start,meeting?.end)}}</td>
        </tr>
        <tr *ngIf="meeting?.refType === 'inquiry'"
          style="vertical-align: middle;">
          <th scope="row">해당문의 :</th>
          <td>{{meeting?.refId?.username}} <button type="button"
              (click)="goInquiryDetail(meeting.refId)"
              style="margin-left: 2px;"
              class="btn btn-outline-dark btn-sm btn-rounded m-0">상세
              보기</button></td>
          <td></td>
        </tr>
        <tr *ngIf="meeting?.refType === 'partnerShip' && meeting?.refId"
          style="vertical-align: middle;">
          <th scope="row">해당업체 :</th>
          <td>{{meeting?.refId?.companyName}} <button type="button"
              (click)="goContactCompanyDetail(meeting.refId)"
              style="margin-left: 2px;"
              class="btn btn-outline-dark btn-sm btn-rounded m-0">상세
              보기</button></td>
          <td></td>
        </tr>
        <tr style="vertical-align: middle;">
          <th scope="row">참여자 :</th>
          <td>
            <span *ngFor="let user of meeting?.joinUsers"
              style="display: inline-block;margin: 4px;"
              class="badge rounded-pill badge-soft-primary font-size-14"
              [ngClass]="{'badge-soft-secondary': user.type == 'user'}"> {{user.name}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer"
  *ngIf="meeting">
  <button class="btn btn-info"
    (click)="cancelMeeting()"
    *ngIf="!meeting.isCanceled">미팅취소</button>
  <button class="btn btn-info"
    (click)="resumeMeeting()"
    *ngIf="meeting.isCanceled">취소해제</button>
  <button class="btn btn-secondary"
    (click)="editMeeting()">수정</button>
  <button class="btn btn-danger"
    (click)="deletMeeting()">삭제</button>
  <button type="submit"
    (click)="passBack()"
    class="btn btn-primary">닫기</button>
</div>