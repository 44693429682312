/** @format */

import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentChangeAction,
  Action,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  QuerySnapshot,
} from "@angular/fire/compat/firestore";
import { Observable, from, combineLatest, defer, of, pipe } from "rxjs";
import {
  map,
  tap,
  take,
  switchMap,
  mergeMap,
  expand,
  takeWhile,
} from "rxjs/operators";
import firebase from "firebase/compat/app";

export const docJoin = (
  afs: AngularFirestore,
  paths: { [key: string]: string }
) => {
  return (source) =>
    defer(() => {
      let parent;
      const keys = Object.keys(paths);

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          const docs$ = keys.map((k) => {
            const fullPath = `${paths[k]}/${parent[k]}`;
            return afs.doc(fullPath).valueChanges();
          });

          // return combineLatest, it waits for all reads to finish
          return combineLatest(docs$);
        }),
        map((arr) => {
          // We now have all the associated douments
          // Reduce them to a single object based on the parent's keys
          const joins = keys.reduce((acc, cur, idx) => {
            return { ...acc, [cur]: arr[idx] };
          }, {});

          // Return the parent doc with the joined objects
          return { ...parent, ...joins };
        })
      );
    });
};
export const leftJoinOneDocument = (
  afs: AngularFirestore,
  field,
  collection
) => {
  return (source) =>
    defer(() => {
      let parent;

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;

          // Map each path to an Observable
          if (parent[field]) {
            const doc = afs
              .collection(collection)
              .doc(parent[field])
              .snapshotChanges()
              .pipe(
                map((doc) => {
                  const data: any = doc.payload.data();
                  const id = doc.payload.id;
                  return { id: doc.payload.id, ...data };
                })
              );

            return doc;
          }
          return of("");
        }),
        map((arr) => {
          return { ...parent, [field]: arr };
        })
      );
    });
};

export const getMeetingRef = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      let parent;
      let field;
      let collection;

      return source.pipe(
        switchMap((data) => {
          // Save the parent data state
          parent = data;
          collection =
            parent.refType == "inquiry"
              ? "inquiries"
              : parent.refType == "partnerShip"
              ? "contactCompany"
              : "";
          // Map each path to an Observable
          if (parent.refId) {
            const doc = afs
              .collection(collection)
              .doc(parent.refId)
              .snapshotChanges()
              .pipe(
                map((doc) => {
                  const data: any = doc.payload.data();
                  const id = doc.payload.id;
                  return { id: doc.payload.id, ...data };
                })
              );

            return doc;
          }
          return of("");
        }),
        map((arr) => {
          return { ...parent, refId: arr };
        })
      );
    });
};

export const leftArrayJoin = (afs: AngularFirestore, field, collection) => {
  return (source) =>
    defer(() => {
      let parent;

      return source.pipe(
        switchMap((data) => {
          parent = data;
          const array = parent[field] as any[];
          const reads$ = [];
          if (!array) {
            return of([]);
          }
          for (const item of array) {
            if (item) {
              const itemData = afs
                .collection(collection)
                .doc(parent[field])
                .snapshotChanges()
                .pipe(
                  map((doc) => {
                    const data: any = doc.payload.data();
                    return { id: doc.payload.id, ...data };
                  })
                );

              reads$.push(itemData);
            } else {
              reads$.push(of([]));
            }
          }
          return combineLatest(reads$);
        }),
        map((arr) => {
          return { ...parent, [field]: arr };
        })
      );
    });
};

export const leftUsersJoin = (afs: AngularFirestore, field) => {
  return (source) =>
    defer(() => {
      let parent;

      return source.pipe(
        switchMap((data) => {
          parent = data;
          const array = parent[field] as any[];
          console.log("array", array);

          const reads$ = [];
          if (array) {
            for (const userData of array) {
              if (userData) {
                if (userData.type != "department") {
                  const itemData = afs
                    .collection("users")
                    .doc(userData.id ? userData.id : userData)
                    .snapshotChanges()
                    .pipe(
                      map((doc) => {
                        const data: any = doc.payload.data();
                        return { id: doc.payload.id, ...data, type: "user" };
                      })
                    );

                  reads$.push(itemData);
                } else {
                  const itemData = afs
                    .collection("departments")
                    .doc(userData.id)
                    .snapshotChanges()
                    .pipe(
                      map((doc) => {
                        const data: any = doc.payload.data();
                        return {
                          id: doc.payload.id,
                          ...data,
                          type: "department",
                        };
                      })
                    );

                  reads$.push(itemData);
                }
              } else {
                reads$.push(of([]));
              }
            }
            return combineLatest(reads$);
          } else {
            return of([]);
          }
        }),
        map((arr: any) => {
          return { ...parent, [field]: arr };
        })
      );
    });
};

export const leftTrackDataTotal = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      let totalJoins = 0;
      return source.pipe(
        switchMap((data) => {
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            if (doc["ip"]) {
              const q = (ref) =>
                ref.where("ip", "==", doc["ip"]).orderBy("dateCreated", "desc");

              reads$.push(afs.collection("trackingDatas", q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return {
              ...v,
              ["ipTotal"]: joins[i] ? joins[i] : 0 || null,
            };
          });
        })
      );
    });
};

export const oneTrackDataTotal = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let totalJoins = 0;
      let docData;
      return source.pipe(
        switchMap((doc) => {
          docData = doc;
          if (doc["ip"]) {
            const q = (ref) =>
              ref.where("ip", "==", doc["ip"]).orderBy("dateCreated", "desc");
            return afs.collection("trackingDatas", q).valueChanges();
          } else {
            return of([]);
          }
        }),
        map((data) => {
          return {
            ...docData,
            ["ipTotal"]: data ? data : [] || null,
          };
        })
      );
    });
};

export const leftJoin = (
  afs: AngularFirestore,
  field,
  collection,
  limit = 100
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc[field]) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref.where(field, "==", doc[field]).limit(limit);

              reads$.push(afs.collection(collection, q).valueChanges());
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        })
      );
    });
};

export const getProject = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc["id"]) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref.where("contractId", "==", doc["id"]).limit(1);

              reads$.push(
                afs
                  .collection("projects", q)
                  .snapshotChanges()
                  .pipe(
                    map((actions: any) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, ["projects"]: joins[i] || null };
          });
        })
      );
    });
};

export const leftJoin2 = (afs: AngularFirestore, collection) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((data) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          collectionData = data as any[];

          const reads$ = [];
          for (const doc of collectionData) {
            // Push doc read to Array

            if (doc["id"]) {
              // Perform query on join key, with optional limit
              const q = (ref) => ref.where("divisionId", "==", doc["id"]);

              reads$.push(
                afs
                  .collection(collection, q)
                  .snapshotChanges()
                  .pipe(
                    map((actions) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return collectionData.map((v, i) => {
            totalJoins += joins[i].length;
            return { ...v, [collection]: joins[i] || null };
          });
        })
      );
    });
};

export const leftJoinConsult = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      return source.pipe(
        switchMap((data: any) => {
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            reads$.push(
              afs
                .collection(`consultings`, (ref) =>
                  ref
                    .where("inquiryId", "==", doc.id)
                    .orderBy("dateCreated", "desc")
                    .limit(1)
                )
                .valueChanges()
            );
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            return {
              ...v,
              consult: [...joins[i]] || null,
            };
          });
        })
      );
    });
};
export const getPhoneConsult = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data: any) => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            if (!doc["id"] || cache.get(doc["id"])) {
              continue;
            }
            const q = (ref) =>
              ref
                .where("consultingType", "==", "전화")
                .where("inquiryId", "==", doc["id"]);

            reads$.push(
              afs
                .collection("consultings", q)
                .snapshotChanges()
                .pipe(
                  map((actions) => {
                    return actions.map((a) => {
                      const data: any = a.payload.doc.data();
                      const id = a.payload.doc.id;
                      return { id, ...data };
                    });
                  }),
                  map((arr) => {
                    return arr.reduce((acc, curr) => {
                      const idx1 =
                        curr.description?.toLowerCase().indexOf("부재") > -1 ||
                        curr.description?.toLowerCase().indexOf("거절") > -1 ||
                        curr.description?.toLowerCase().indexOf("남김") > -1;
                      const idx2 = acc.findIndex(
                        (obj) => obj["inquiryId"] === curr["inquiryId"]
                      );
                      if (!idx1 && idx2 === -1) acc.push(curr);
                      return acc;
                    }, []);
                  })
                )
            );
            cache.set(doc["id"], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v["id"]);
            return {
              ...v,
              ["phoneConsult"]: [...joins[joinIdx]] || null,
            };
          });
        })
      );
    });
};
export const getConsultHistory = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data: any) => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            if (!doc["id"] || cache.get(doc["id"])) {
              continue;
            }
            const q = (ref) =>
              ref
                .where("consultingType", "==", "방문/화상")
                .where("inquiryId", "==", doc["id"]);

            reads$.push(
              afs
                .collection("consultings", q)
                .snapshotChanges()
                .pipe(
                  map((actions) => {
                    return actions.map((a) => {
                      const data: any = a.payload.doc.data();
                      const id = a.payload.doc.id;
                      return { id, ...data };
                    });
                  }),
                  map((arr) => {
                    return arr.reduce((acc, curr) => {
                      const idx2 = acc.findIndex(
                        (obj) => obj["inquiryId"] === curr["inquiryId"]
                      );
                      if (idx2 === -1) acc.push(curr);
                      return acc;
                    }, []);
                  })
                )
            );
            cache.set(doc["id"], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v["id"]);
            return {
              ...v,
              ["consult"]: [...joins[joinIdx]] || null,
            };
          });
        })
      );
    });
};
export const getContracts = (afs: AngularFirestore) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data: any) => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            if (!doc["id"] || cache.get(doc["id"])) {
              continue;
            }
            const q = (ref) => ref.where("inquiryId", "==", doc["id"]);

            reads$.push(
              afs
                .collection("contracts", q)
                .snapshotChanges()
                .pipe(
                  map((actions) => {
                    return actions.map((a) => {
                      const data: any = a.payload.doc.data();
                      const id = a.payload.doc.id;
                      return { id, ...data };
                    });
                  })
                )
            );
            cache.set(doc["id"], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v["id"]);
            return {
              ...v,
              ["contracts"]: [...joins[joinIdx]] || null,
            };
          });
        })
      );
    });
};
export const leftJoinDocument = (afs: AngularFirestore, field, collection) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data: any) => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            console.log("doc[field]", doc[field]);

            if (!doc[field] || cache.get(doc[field])) {
              continue;
            }
            reads$.push(
              afs
                .collection(collection)
                .doc(doc[field])
                .snapshotChanges()
                .pipe(
                  map((doc) => {
                    const data: any = doc.payload.data();
                    const id = doc.payload.id;
                    return { id: doc.payload.id, ...data };
                  })
                )
            );
            cache.set(doc[field], i);
            i++;
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            const joinIdx = cache.get(v[field]);
            return {
              ...v,
              [field]: { ...joins[joinIdx] } || null,
            };
          });
        })
      );
    });
};
export const leftJoinArryDocument = (
  afs: AngularFirestore,
  field,
  collection
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let collectionData;
      const cache = new Map();

      return source.pipe(
        switchMap((data: any) => {
          cache.clear();
          collectionData = data as any[];
          const reads$ = [];
          let i = 0;
          for (const doc of collectionData) {
            if (!doc[field]) {
              continue;
            }
            let j = 0;
            console.log(doc["id"]);
            console.log("doc", doc);

            console.log("doc[field]", doc[field]);

            for (const item of doc[field]) {
              let id;
              if (cache.get(item)) {
                continue;
              }
              if (typeof item !== "string") {
                id = item.id;
              } else {
                id = item;
              }

              reads$.push(
                afs
                  .collection(collection)
                  .doc(item)
                  .snapshotChanges()
                  .pipe(
                    map((doc) => {
                      const data: any = doc.payload.data();
                      const id = doc.payload.id;
                      return { id: doc.payload.id, ...data };
                    })
                  )
              );
              cache.set(id, i);
              i++;
            }
          }

          return reads$.length ? combineLatest(reads$) : of([]);
        }),
        map((joins: any) => {
          return collectionData.map((v, i) => {
            const array = v[field]
              ? v[field].map((item) => {
                  const joinIdx = cache.get(item);
                  return { ...joins[joinIdx] };
                })
              : {};

            return {
              ...v,
              [field]: array || [],
            };
          });
        })
      );
    });
};

export const colletionJoin = (
  afs: AngularFirestore,
  category,
  collection,
  field,
  limit
) => {
  return (source) =>
    defer(() => {
      // Operator state
      let array;

      // Track total num of joined doc reads
      let totalJoins = 0;

      return source.pipe(
        switchMap((select) => {
          // Clear mapping on each emitted val ;

          // Save the parent data state
          array = category as any[];

          const reads$ = [];
          for (const doc of array) {
            // Push doc read to Array

            if (doc) {
              // Perform query on join key, with optional limit
              const q = (ref) =>
                ref
                  .where("genre", "==", select)
                  .where(field, "array-contains", doc)
                  .orderBy("totalLike", "desc")
                  .limit(limit);
              const collectionMap = pipe(
                map((docs: QuerySnapshot<any>) => {
                  return docs.docs.map((e) => {
                    return {
                      id: e.id,
                      ...e.data(),
                    } as any;
                  });
                })
              );
              reads$.push(
                afs
                  .collection(collection, q)
                  .snapshotChanges()
                  .pipe(
                    map((actions) => {
                      return actions.map((a) => {
                        const data: any = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return { id, ...data };
                      });
                    })
                  )
              );
            } else {
              reads$.push(of([]));
            }
          }

          return combineLatest(reads$);
        }),
        map((joins) => {
          return array.map((v, i) => {
            totalJoins += joins[i].length;
            return { ["name"]: v, ["photos"]: joins[i] || null };
          });
        })
      );
    });
};

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: "root",
})
export class DbService {
  public masterRef;
  constructor(public afs: AngularFirestore) {
    this.masterRef = this.afs.doc(`master/${"1Xod9dBvZkUHJDPSTakjsoR6CCs2"}`);
  }

  createFsId() {
    return this.afs.createId();
  }

  createdAt() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  collection$(path, query?): Observable<any[]> {
    return this.afs
      .collection(path, query)
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          return actions.map((a: any) => {
            const data: any = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  collection2$(path, query?) {
    return this.afs.collection(path, query);
  }

  doc$(path): Observable<any> {
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          const data: any = doc.payload.data();
          const id = doc.payload.id;
          return { id: doc.payload.id, ...data };
        })
      );
  }

  doc2$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map(
          (
            doc: Action<
              DocumentSnapshotDoesNotExist | DocumentSnapshotExists<T>
            >
          ) => {
            return doc.payload.data() as T;
          }
        )
      );
  }

  col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((docs: DocumentChangeAction<T>[]) => {
          return docs.map((a: DocumentChangeAction<T>) =>
            a.payload.doc.data()
          ) as T[];
        })
      );
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === "string" ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === "string" ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/

  // ** 기본적인 DB처리 **//
  updateAt(path: string, data: Object): Promise<any> {
    const segments = path.split("/").filter((v) => v);
    if (segments.length % 2) {
      // Odd is always a collection
      return this.afs.collection(path).add(data);
    } else {
      // Even is always document
      return this.afs.doc(path).set(data, { merge: true });
    }
  }

  delete(path) {
    return this.afs.doc(path).delete();
  }

  /**
   * @param  {string} path path to document
   *
   * Deletes document from Firestore
   **/

  deleteDoc(path: string): Promise<any> {
    return this.afs.doc(path).delete();
  }

  acceptOrder(obj) {
    return new Promise((resolve, reject) => {
      this.connectOrder(obj)
        .then((success) => {
          const ref = this.afs.doc(`order/${obj.orderId}`);
          return ref
            .update({
              connectCompanys: firebase.firestore.FieldValue.arrayUnion(
                obj.companyId
              ),
            })
            .then((success) => {
              resolve(true);
            })
            .catch((error) => {
              reject(false);
            });
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  acceptagOrder(obj) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`arrangeorder/${obj.orderId}`);
      return ref
        .update({ connectCompany: obj.companyId })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  connectOrder(obj) {
    return new Promise((resolve, reject) => {
      return this.updateAt(`connect`, obj)
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  payVideoOrder(orderId, companyId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`order/${orderId}`);
      return ref
        .update({
          paidCompanys: firebase.firestore.FieldValue.arrayUnion(companyId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  refundOrder(orderId, companyId) {
    return new Promise((resolve, reject) => {
      firebase.firestore().runTransaction((transaction) => {
        return transaction
          .get(firebase.firestore().collection("order").doc(orderId))
          .then((docData) => {
            const refundCompanys = docData.data().refundCompanys || {};
            refundCompanys[companyId] = true;
            return transaction.set(
              firebase.firestore().collection("order").doc(orderId),
              { refundCompanys },
              { merge: true }
            );
          })
          .then((success) => {
            resolve(true);
          })
          .catch((error) => {
            reject(false);
          });
      });
    });
  }

  refundagOrder(orderId, companyId) {
    return new Promise((resolve, reject) => {
      firebase.firestore().runTransaction((transaction) => {
        return transaction
          .get(firebase.firestore().collection("arrangeorder").doc(orderId))
          .then((docData) => {
            const refundCompanys = docData.data().refundCompanys || {};
            refundCompanys[companyId] = true;
            return transaction.set(
              firebase.firestore().collection("arrangeorder").doc(orderId),
              { refundCompanys },
              { merge: true }
            );
          })
          .then((success) => {
            resolve(true);
          })
          .catch((error) => {
            reject(false);
          });
      });
    });
  }

  checkUsername(nickName: string) {
    nickName = nickName.toLowerCase();
    return this.doc$(`nickNames/${nickName}`);
  }

  appService() {
    const id = "bfHp2x2Xf9CvpqmZZFvD";
    return this.doc$(`appService/${id}`).pipe(take(1)).toPromise();
  }

  likeInfo(infoId, userId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({
          likes: firebase.firestore.FieldValue.arrayUnion(userId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  dislikeInfo(infoId, userId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({
          likes: firebase.firestore.FieldValue.arrayRemove(userId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  archiveInfo(infoId, userId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({
          archive: firebase.firestore.FieldValue.arrayUnion(userId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  viewInfo(infoId, userId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({
          view: firebase.firestore.FieldValue.arrayUnion(userId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  deleteArchive(infoId, userId) {
    return new Promise((resolve, reject) => {
      const ref = this.afs.doc(`wisdom/${infoId}`);
      return ref
        .update({
          archive: firebase.firestore.FieldValue.arrayRemove(userId),
        })
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }
}
