import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

import { AlertService } from "src/app/core/services/alert.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { TrackingData } from "src/app/core/models/trackingData.model";
import { InquiryDetailComponent } from "../inquiry-detail/inquiry-detail.component";
import { DataService } from "src/app/core/services/data.service";
import { GeolocationService } from "src/app/core/services/geoloacation.service";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
@Component({
  selector: "app-track-data-detail",
  templateUrl: "./track-data-detail.component.html",
  styleUrls: ["./track-data-detail.component.scss"],
})
export class TrackDataDetailComponent implements OnInit {
  @Input() public trackingDataId: string;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  private modalContent: TemplateRef<TrackDataDetailComponent>;
  private modalRef: NgbModalRef;
  isInquiry: string;
  firstVisit: string;
  trackingData: TrackingData;
  trackingDataList$: Observable<TrackingData[]>;
  inquiryIp: TrackingData;
  typeList = [
    {
      value: "visit",
      name: "방문",
    },
    {
      value: "inquiry",
      name: "문의-PC",
    },
    {
      value: "tryCall",
      name: "전화시도",
    },
    {
      value: "inquiry-mobile",
      name: "문의-모바일",
    },
  ];
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private db: DbService,
    public fileUploaderService: FileUploaderSevice,
    private loaderSerivce: LoaderService,
    private alertService: AlertService,
    private dataService: DataService,
    private geoService: GeolocationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getData();
    // this.getIPInfor(this.trackingData.ip);
  }

  async getData() {
    try {
      this.trackingData = await this.dataService.getTrackingDataByTrackDataId(
        this.trackingDataId
      );
      console.log("trackData", this.trackingData);

      this.trackingDataList$ = this.db
        .collection$("trackingDatas", (ref) =>
          ref
            .where("ip", "==", this.trackingData.ip)
            .orderBy("dateCreated", "desc")
        )
        .pipe(
          map((arr: TrackingData[]) => {
            return arr.map((item) => {
              const urlType = this.getUrlType(item.referrer);
              const searchKeyword = this.getSearchKeyword(item.referrer);
              return { ...item, urlType, searchKeyword, checked: false };
            });
          }),
          tap((arr) => this.checkFirstVisit(arr))
        );
    } catch (error) {}
  }

  checkFirstVisit(trackingDataList: TrackingData[]) {
    this.checkInquiry(trackingDataList);
    const last = trackingDataList[trackingDataList.length - 1];
    this.firstVisit = last ? last.dateCreated : undefined;
  }

  passBack() {
    this.activeModal.close();
  }

  checkInquiry(trackingDataList: TrackingData[]) {
    this.inquiryIp = trackingDataList?.find(
      (item) => item.type === "inquiry" || item.type === "inquiry-mobile"
    );
    this.isInquiry = this.inquiryIp ? "문의등록" : "문의안함";
  }

  async goInquiryDetail() {
    const inquiry = await this.dataService.getInquiryByTrackingId(
      this.inquiryIp.id
    );
    const modalRef = this.modalService.open(InquiryDetailComponent, {
      fullscreen: true,
      scrollable: true,
    });
    modalRef.componentInstance.inquiryId = inquiry.id;
  }

  getIPInfor(ip: string) {
    this.geoService.getGeoLocation(ip).subscribe(
      (data) => {
        console.log("data", data);
      },
      (error) => {
        console.error("Error retrieving location:", error);
      }
    );
  }

  getTypeName(type: string) {
    const find = this.typeList.find((item) => item.value === type);
    return find ? find.name : "기타";
  }

  getUrlType(url: string) {
    // 호스트 이름에 따라 유형 분류
    if (url.includes("search.daum.net")) {
      return "Daum Search";
    } else if (url.includes("google.com")) {
      return "Google";
    } else if (url.includes("https://googleads.g.doubleclick.net")) {
      return "Google-ads-doubleclick";
    } else if (url.includes("youtube.com")) {
      return "Youtube";
    } else if (url.includes("facebook.com")) {
      return "Facebook";
    } else if (url.includes("instagram.com")) {
      return "Instagram";
    } else if (url.includes("search.naver.com")) {
      if (url.includes("fbm=1")) {
        return "Naver Search - 메인검색";
      } else if (url.includes("op_hty")) {
        return "Naver Search - 직접검색";
      } else if (url.includes("tab_jum")) {
        return "Naver Search - 우측영역 클릭";
      } else if (url.includes("nexearch")) {
        return "Naver Search - 통합검색";
      } else if (url.includes("post")) {
        return "Naver Search - 블로그";
      } else if (url.includes("cafeblog")) {
        return "Naver Search - 카페";
      }

      return "Naver Search";
    } else {
      return "기타";
    }
  }

  getSearchKeyword(url: string) {
    console.log("url", url);

    const daumSearch = this.getParameterByName("q", url);
    const naverSerch = this.getParameterByName("query", url);
    return daumSearch || naverSerch;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    const result = results.map((item) => decodeURIComponent(item));
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
}
