<div class="load-wrap active"
    *ngIf="(loader.loading | async) == true ">
    <p class="loading-icon">
        <img src="assets/images/loading.gif"
            alt="" />
    </p>

    <p class="logo-icon">
        <img src="assets/images/logo.png"
            alt="" />
    </p>
</div>