import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-book-detail',
  templateUrl: './request-book-detail.component.html',
  styleUrls: ['./request-book-detail.component.scss']
})
export class RequestBookDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
