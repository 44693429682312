<div class="modal-header">
  <h2 class="modal-title mt-0">앱 카테고리 관리</h2>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body">

      <h4 class="card-title ">앱 카테고리</h4>
      <p class="card-title-desc mb-4">Drag&Drop을 통해 노출 순서를 변경할 수 있습니다.</p>
      <div id="categories"
        class="pb-1 task-list"
        dndDropzone
        dndEffectAllowed="move"
        (dndDrop)="onDrop($event,appCategories)">
        <div class="dndPlaceholder"
          dndPlaceholderRef></div>

        <ng-container *ngFor="let category of appCategories; let i= index">
          <div [dndDraggable]="category"
            dndEffectAllowed="move"
            (dndMoved)="onDragged(category,appCategories)">
            <ng-template [ngTemplateOutlet]="CategoryContent"
              [ngTemplateOutletContext]="{category:category,i:i}">
            </ng-template>
          </div>
        </ng-container>

        <div class="text-center">
          <a href="javascript: void(0);"
            (click)="addCategory()"
            class="btn btn-primary btn-block mt-1"><i class="mdi mdi-plus mr-1"></i>
            카테고리 추가</a>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-secondary"
    (click)="passBack()">닫기</button>
</div>

<ng-template #CategoryContent
  let-category='category'
  let-i='i'>
  <div class="card task-box">
    <div class="card-body">
      <div class="row m-0">
        <div class="col-8">
          <h2 class="font-size-15">
            {{i+1}} - {{category.title}}
          </h2>
        </div>
        <div class="col-4">
          <div class="row text-end">
            <div class="col-sm-8 text-end">
              <button class="btn btn-primary"
                (click)="editCategory(category)">수정</button>
            </div>

            <div class="col-sm-4">
              <button class="btn btn-danger"
                (click)="deleteCategory(category)">삭제</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>