/** @format */

import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../services/auth.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, public auth: AuthService) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const user = await this.auth.getUser();

    const isLoggedIn = !!user;
    if (!isLoggedIn) {
      this.router.navigate(["/account/login"], {
        queryParams: { returnUrl: state.url },
      });
    }

    return isLoggedIn;
  }
}
