<div class="modal-header">
  <div class="modal-title mt-0">자주묻는 질문 {{faqId?'수정':'등록'}}</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="close()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="file">제목</label>
          <textarea type="text"
            class="form-control"
            [(ngModel)]="faq.question">

          </textarea>
        </div>

        <div class="mb-3">
          <label for="file">내용</label>
          <textarea type="text"
            class="form-control"
            [(ngModel)]="faq.answer">
          </textarea>
        </div>
      </div>
    </div>
    <button class="btn btn-success"
      (click)="registerFaq()"
      [disabled]="!faq.question || !faq.answer">{{faqId?'저장':'등록'}}</button>
  </div>

</div>
