/** @format */

import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { first, take } from "rxjs/operators";
import { SolutionFaq } from "src/app/core/models/solutionFaq.models";

import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FcmService } from "src/app/core/services/fcm.service";

@Component({
  selector: "app-faq-modal",
  templateUrl: "./faq-modal.component.html",
  styleUrls: ["./faq-modal.component.scss"],
})
export class FaqModalComponent implements OnInit {
  @Input() faqId: any;
  @Input() type: string;
  faq: SolutionFaq = {
    answer: "",
    createdBy: "",
    dateCreated: "",
    displaySwitch: false,
    order: 0,
    question: "",
  };
  constructor(
    private modalService: NgbModal,
    private db: DbService,
    private alertService: AlertService,
    private push: FcmService,
    private dataService: DataService,
    private ngbActiveModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  async ngAfterContentInit() {
    this.dataService.inIt();
    if (this.faqId) {
      this.faq = await this.getFaqData();
    }
  }

  getFaqData() {
    return this.db.doc$(`solutionQna/${this.faqId}`).pipe(take(1)).toPromise();
  }

  getFaqLastData() {
    return this.db
      .collection$(`solutionQna`, (ref) =>
        ref.orderBy("order", "desc").limit(1)
      )
      .pipe(take(1))
      .toPromise();
  }

  close() {
    this.modalService.dismissAll();
  }

  async registerFaq() {
    if (this.faqId) {
      this.db
        .updateAt(`solutionQna/${this.faq.id}`, {
          question: this.faq.question,
          answer: this.faq.answer,
        })
        .then(() => {
          this.ngbActiveModal.close(this.faq);
          this.alertService.success("자주 묻는 질문이 수정되었습니다.");
        });
    } else {
      const lastFaq: SolutionFaq[] = await this.getFaqLastData();
      let lastOrder = lastFaq.length ? lastFaq[0].order : 1;
      this.faq.createdBy = this.dataService.userId;
      this.faq.order = lastOrder + 1;
      this.faq.id = await this.db.createFsId();
      this.faq.dateCreated = new Date().toISOString();
      await this.db
        .updateAt(`solutionQna/${this.faq.id}`, this.faq)
        .then(() => {
          this.ngbActiveModal.close(this.faq);
          this.alertService.success("자주 묻는 질문이 등록되었습니다.");
        });
    }
  }
}
