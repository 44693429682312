/** @format */

import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
const errorMessages = {
  // Firebase의 auth 에러 메세지 정의
  accountExistsWithDifferentCredential: {
    title: "계정 안내!",
    subTitle: "이미 있는 계정입니다.",
  },
  invalidCredential: {
    title: "인증 오류!",
    subTitle: "로그인 인증에 오류가 발생했습니다.",
  },
  operationNotAllowed: {
    title: "로그인 실패!",
    subTitle:
      "로그인 과정에서 오류가 발생했습니다. 관리자에게 문의하시길 바랍니다.",
  },
  userDisabled: {
    title: "정지 계정!",
    subTitle: "정지된 계정입니다. 관리자에게 연락하시길 바랍니다.",
  },
  userNotFound: { title: "계정 없음!", subTitle: "해당 계정 정보가 없습니다." },
  userEmailNotFound: {
    title: "가입된 이메일 주소가 아닙니다.",
    subTitle: "다시 확인해 주시기 바랍니다.",
  },
  wrongPassword: {
    title: "비밀번호 오류!",
    subTitle: "입력하신 비밀번호가 잘못되었습니다.다시 확인하시기 바랍니다.",
  },
  invalidEmail: {
    title: "이메일 오류!",
    subTitle: "입력하신 이메일 정보가 없습니다. 다시 확인하시기 바랍니다.",
  },
  emailAlreadyInUse: {
    title: "사용할 수 없는 이메일!",
    subTitle: "이미 사용중인 이메일입니다. 다시 확인하시기 바랍니다.",
  },
  weakPassword: {
    title: "비밀번호 경고!",
    subTitle: "입력하신 비밀번호가 보안에 취약합니다.",
  },
  requiresRecentLogin: {
    title: "인증 만료!",
    subTitle: "인증이 만료되었습니다. 다시 로그인 하시기 바랍니다.",
  },
  userMismatch: {
    title: "사용자 불일치!",
    subTitle: "다른 사용자의 인증정보입니다!",
  },
  providerAlreadyLinked: {
    title: "Already Linked!",
    subTitle: "Sorry, but your account is already linked to this credential.",
  },
  credentialAlreadyInUse: {
    title: "불가능 인증정보!",
    subTitle: "Sorry, but this credential is already used by another user.",
  },
  toManyrequests: {
    title: "확인 횟수 초과!",
    subTitle:
      "잘못된 비밀번호로 비밀번호 확인 횟수가 초과 되었습니다. 잠시 후 다시 시도하시기 바랍니다.",
  },
};
@Injectable({
  providedIn: "root",
})
export class AlertService {
  /**
   * icon
   * success,error,warning,info,question
   */
  constructor() {}
  getErrorMessage(code) {
    switch (code) {
      // Firebase Error Messages
      case "auth/account-exists-with-different-credential":
        return errorMessages.accountExistsWithDifferentCredential["subTitle"];

      case "auth/invalid-credential":
        return errorMessages.invalidCredential["subTitle"];

      case "auth/operation-not-allowed":
        return errorMessages.operationNotAllowed["subTitle"];

      case "auth/user-disabled":
        return errorMessages.userDisabled["subTitle"];

      case "auth/user-not-found":
        return errorMessages.userEmailNotFound["subTitle"];

      case "auth/wrong-password":
        return errorMessages.wrongPassword["subTitle"];

      case "auth/invalid-email":
        return errorMessages.invalidEmail["subTitle"];

      case "auth/email-already-in-use":
        return errorMessages.emailAlreadyInUse["subTitle"];

      case "auth/weak-password":
        return errorMessages.weakPassword["subTitle"];

      case "auth/requires-recent-login":
        return errorMessages.requiresRecentLogin["subTitle"];

      case "auth/user-mismatch":
        return errorMessages.userMismatch["subTitle"];

      case "auth/provider-already-linked":
        return errorMessages.providerAlreadyLinked["subTitle"];

      case "auth/credential-already-in-use":
        return errorMessages.credentialAlreadyInUse["subTitle"];

      case "auth/too-many-requests":
        return errorMessages.toManyrequests["subTitle"];
    }
  }

  showErrorMessage(code) {
    switch (code) {
      // Firebase Error Messages
      case "auth/account-exists-with-different-credential":
        this.errormsg(
          errorMessages.accountExistsWithDifferentCredential["title"],
          errorMessages.accountExistsWithDifferentCredential["subTitle"]
        );
        break;
      case "auth/invalid-credential":
        this.errormsg(
          errorMessages.invalidCredential["title"],
          errorMessages.invalidCredential["subTitle"]
        );
        break;
      case "auth/operation-not-allowed":
        this.errormsg(
          errorMessages.operationNotAllowed["title"],
          errorMessages.operationNotAllowed["subTitle"]
        );
        break;
      case "auth/user-disabled":
        this.errormsg(
          errorMessages.userDisabled["title"],
          errorMessages.userDisabled["subTitle"]
        );
        break;
      case "auth/user-not-found":
        this.errormsg(
          errorMessages.userEmailNotFound["title"],
          errorMessages.userEmailNotFound["subTitle"]
        );
        break;
      case "auth/wrong-password":
        this.errormsg(
          errorMessages.wrongPassword["title"],
          errorMessages.wrongPassword["subTitle"]
        );
        break;
      case "auth/invalid-email":
        this.errormsg(
          errorMessages.invalidEmail["title"],
          errorMessages.invalidEmail["subTitle"]
        );
        break;
      case "auth/email-already-in-use":
        this.errormsg(
          errorMessages.emailAlreadyInUse["title"],
          errorMessages.emailAlreadyInUse["subTitle"]
        );
        break;
      case "auth/weak-password":
        this.errormsg(
          errorMessages.weakPassword["title"],
          errorMessages.weakPassword["subTitle"]
        );
        break;
      case "auth/requires-recent-login":
        this.errormsg(
          errorMessages.requiresRecentLogin["title"],
          errorMessages.requiresRecentLogin["subTitle"]
        );
        break;
      case "auth/user-mismatch":
        this.errormsg(
          errorMessages.userMismatch["title"],
          errorMessages.userMismatch["subTitle"]
        );
        break;
      case "auth/provider-already-linked":
        this.errormsg(
          errorMessages.providerAlreadyLinked["title"],
          errorMessages.providerAlreadyLinked["subTitle"]
        );
        break;
      case "auth/credential-already-in-use":
        this.errormsg(
          errorMessages.credentialAlreadyInUse["title"],
          errorMessages.credentialAlreadyInUse["subTitle"]
        );
        break;
      case "auth/too-many-requests":
        this.errormsg(
          errorMessages.toManyrequests["title"],
          errorMessages.toManyrequests["subTitle"]
        );
        break;
    }
  }

  basicMessage(text) {
    Swal.fire({ text, confirmButtonText: "확인" });
  }

  titleText(title, text, icon) {
    Swal.fire({ title, text, icon, confirmButtonText: "확인" });
  }

  success(title) {
    return Swal.fire({
      position: "center",
      icon: "success",
      title,
      showConfirmButton: false,
      timer: 1000,
    });
  }

  errormsg(title, message) {
    Swal.fire(title, message, "error");
  }

  error(title) {
    return Swal.fire({
      position: "center",
      icon: "error",
      title,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  position() {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Your work has been saved",
      showConfirmButton: false,
      timer: 1500,
    });
  }

  confirm(title, text, confirmButtonText) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title,
        text,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText,
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.value) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  cancel() {
    return new Promise((resolve, reject) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            resolve("delete");
            swalWithBootstrapButtons.fire(
              "Deleted!",
              "Your file has been deleted.",
              "success"
            );
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            reject("cancel");
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your imaginary file is safe :)",
              "error"
            );
          }
        });
    });
  }

  confirmDelete(item: string) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "삭제확인",
        text: `${item}를/을 정말 삭제하시겠습니까?
        삭제하시면 되돌릴 수 없습니다.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제하겠습니다!",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.value) {
          Swal.fire("삭제완료!", `${item}이 삭제되었습니다.`, "success");
          resolve("delete");
        }
      });
    });
  }

  imageHeader() {
    Swal.fire({
      title: "Sweet!",
      text: "Modal with a custom image.",
      imageUrl: "assets/images/logo-dark.png",
      imageHeight: 20,
      confirmButtonColor: "#556ee6",
    });
  }

  timer() {
    let timerInterval;
    Swal.fire({
      title: "Auto close alert!",
      html: "I will close in <strong></strong> seconds.",
      timer: 2000,

      willOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          Swal.getContainer().querySelector("strong").textContent =
            Swal.getTimerLeft() + "";
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    });
  }

  custom() {
    Swal.fire({
      title: "<strong>HTML <u>example</u></strong>",
      icon: "info",
      html:
        "You can use <b>bold text</b>, " +
        '<a href="//sweetalert2.github.io">links</a> ' +
        "and other HTML tags",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
      confirmButtonAriaLabel: "Thumbs up, great!",
      cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
      cancelButtonAriaLabel: "Thumbs down",
    });
  }

  customBackground() {
    Swal.fire({
      title: "Custom width, padding, background.",
      width: 600,
      padding: 100,
      confirmButtonColor: "#556ee6",
      background:
        "#fff url(//subtlepatterns2015.subtlepatterns.netdna-cdn.com/patterns/geometry.png)",
    });
  }

  ajax() {
    Swal.fire({
      title: "Submit email to run ajax request",
      input: "email",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#556ee6",
      cancelButtonColor: "#f46a6a",
      preConfirm: (email) => {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (email === "taken@example.com") {
              Promise.reject(new Error("This email is already taken."));
            } else {
              resolve("");
            }
          }, 2000);
        });
      },
      allowOutsideClick: false,
    }).then((email) => {
      Swal.fire({
        title: "Ajax request finished!",
        html: "Submitted email: " + email,
      });
    });
  }
}
