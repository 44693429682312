import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  @Input() url: string;
  pdfUrl;
  constructor(
    public activeModal: NgbActiveModal,
    public loadService: LoaderService
  ) {}

  ngOnInit(): void {
    this.loadService.setLoading(true);
    const storage = getStorage();
    const starsRef = ref(storage, this.url);
    getDownloadURL(starsRef)
      .then((url) => {
        this.loadService.setLoading(false);
        this.pdfUrl = url;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  passBack() {
    this.activeModal.close();
  }
}
