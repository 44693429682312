import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { first, map, switchMap } from "rxjs/operators";
import { DbService } from "src/app/core/services/db.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { _ } from "Lodash";
import { DataService } from "src/app/core/services/data.service";
import { AlertService } from "src/app/core/services/alert.service";
import { BehaviorSubject, Observable } from "rxjs";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import firebase from "firebase/compat/app";
import moment from "moment";
import { ExcelService } from "src/app/core/services/excel.service";
import { ContractWithPartnership } from "src/app/core/models/contractWithPartnerShip.models";
import { ContractWithPartnershipDetailComponent } from "src/app/components/contract-with-partnership-detail/contract-with-partnership-detail.component";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
@Component({
  selector: "app-partnership-contract-list",
  templateUrl: "./partnership-contract-list.component.html",
  styleUrls: ["./partnership-contract-list.component.scss"],
})
export class PartnershipContractListComponent implements OnInit {
  @Input() contactCompany: ContactCompany;
  activeNav = "수주 계약";
  breadCrumbItems: Array<{}>;
  term: any;
  selectConsult;
  isModalOpen = false;
  transactions;
  contractWithPartnershipList;
  selectContractWithPartnership: Observable<ContractWithPartnership>;
  select = new BehaviorSubject("");
  memoText;
  contractWithPartnership: FormGroup;
  tabs = 4;
  selectMessage = "";
  allcheck = false;
  selectWeek: Date;
  selectMonth: Date;
  startDate: string = moment().startOf("month").format("YYYY-MM-DD");
  endDate: string = moment().endOf("month").format("YYYY-MM-DD");
  type = "수주 계약";
  constructor(
    private db: DbService,
    private modalService: NgbModal,
    private dataservice: DataService,
    private alertService: AlertService,
    public formBuilder: FormBuilder,
    private router: Router,
    private excelSrv: ExcelService,
    public activeModal: NgbActiveModal
  ) {}

  async ngOnInit() {
    await this.dataservice.inIt();
    this.getData();
    this.selectContractWithPartnership = this.select.pipe(
      switchMap((contractWithPartnershipId) => {
        return this.db.doc$(
          `contractWithPartnershipList/${contractWithPartnershipId}`
        );
      })
    );

    this.breadCrumbItems = [
      { label: "계약관리" },
      { label: "계약리스트", active: true },
    ];
  }

  navChanged(activeNav) {
    this.type = activeNav.nextId;
    this.tabs = 4;
    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().endOf("month").format("YYYY-MM-DD");
    this.getData();
  }

  async getData() {
    console.log("this.tabs", this.tabs);

    switch (this.tabs) {
      case 4:
        this.contractWithPartnershipList =
          await this.dataservice.getContractWithPartnershipByContactCompanybyId(
            this.type,
            this.contactCompany.id
          );
        break;
      case 1:
        this.contractWithPartnershipList = await this.getPeriodDatas();
        break;
      case 2:
        this.contractWithPartnershipList = await this.getMonthDatas();
        break;
      case 3:
        this.contractWithPartnershipList = await this.getWeekDatas();
        break;
      default:
        break;
    }
  }

  //** 주별 데이터 가져오기 **//
  async getPeriodDatas() {
    const date1 = new Date(this.startDate);
    date1.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const date2 = new Date(this.endDate);
    date2.setDate(date2.getDate() + 1);
    date2.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const start = firebase.firestore.Timestamp.fromDate(date1);
    const end = firebase.firestore.Timestamp.fromDate(date2);
    return this.dataservice.getContractWithPartnershipPeriodDataByContactCompanybyId(
      this.type,
      this.contactCompany.id,
      start,
      end
    );
  }

  //** 월별데이터 가져오기 **//
  async getMonthDatas() {
    const startOfMonth = moment(this.selectMonth, "YYYY-MM")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(this.selectMonth, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");
    const date1 = new Date(startOfMonth);
    date1.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const date2 = new Date(endOfMonth);
    date2.setDate(date2.getDate() + 1);
    date2.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const start = firebase.firestore.Timestamp.fromDate(date1);
    const end = firebase.firestore.Timestamp.fromDate(date2);
    return this.dataservice.getContractWithPartnershipPeriodDataByContactCompanybyId(
      this.type,
      this.contactCompany.id,
      start,
      end
    );
  }

  //** 주별 데이터 가져오기 **//
  async getWeekDatas() {
    let startDate = this.getStartDateFromISOWeek(this.selectWeek);
    const startOfWeek = moment(startDate)
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const endOfWeek = moment(startDate).endOf("isoWeek").format("YYYY-MM-DD");

    const date1 = new Date(startOfWeek);
    date1.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const date2 = new Date(endOfWeek);
    date2.setDate(date2.getDate() + 1);
    date2.setHours(9, 0, 0, 0); // +9 hours for Korean Time
    const start = firebase.firestore.Timestamp.fromDate(date1);
    const end = firebase.firestore.Timestamp.fromDate(date2);
    return this.dataservice.getContractWithPartnershipPeriodDataByContactCompanybyId(
      this.type,
      this.contactCompany.id,
      start,
      end
    );
  }

  getWeekFromISODate(dt) {
    dt.setHours(0, 0, 0, 0);
    dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
    const week1 = new Date(dt.getFullYear(), 0, 4);
    const weekNumber =
      1 +
      Math.round(
        ((dt.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      );

    return `${dt.getFullYear()}-W${weekNumber}`;
  }

  getTotaldatas(datas) {
    let total = datas.reduce((a, b) => a + b.amount, 0);
    return total;
  }

  getTotalSettlement(datas) {
    let total = datas.reduce((a, b) => a + this.caculate(b), 0);
    return total;
  }

  // YYYY-W00 형태의 ISO 8601 week number를 받아 해당 주의 월요일 Date 객체를 반환
  getStartDateFromISOWeek(ISOweek) {
    const y = parseInt(ISOweek.substring(0, 4));
    const w = parseInt(ISOweek.substring(6, 8));

    const simpleDate = new Date(y, 0, 1 + (w - 1) * 7);
    const dayOfWeek = simpleDate.getDay();
    const ISOweekStart = simpleDate;
    if (dayOfWeek <= 4) {
      ISOweekStart.setDate(simpleDate.getDate() - simpleDate.getDay() + 1);
    } else {
      ISOweekStart.setDate(simpleDate.getDate() + 8 - simpleDate.getDay());
    }

    return ISOweekStart;
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  downloadFile(url) {
    window.open(url);
  }

  goAddContract() {
    this.router.navigate(["/partnership/add-partnership-contract"]);
  }

  exportExcel(datas) {
    const fileName = `${this.contactCompany.companyName}-제휴연결 계약리스트`;
    const excelData = this.setExcelData(datas);
    this.excelSrv.arrayExportToFile(excelData, fileName);
  }

  setExcelData(datas) {
    const excelData = datas.map((item: ContractWithPartnership) => {
      const newObject = {
        ["계약날짜"]: item.dateContract,
        ["계약금액"]: item.amount,
        ["계약명"]: item.title,
        ["계약자명"]: item.title,
        ["계약수수료"]: item.title,
        ["제휴정산금"]: this.caculate(item),
        ["정산여부"]: "미정산",
        ["단계"]: item.status,
      };
      return newObject;
    });

    return excelData;
  }

  openInquiryDetail(contractWithPartnership: ContractWithPartnership) {
    // const modalRef = this.modalService.open(InquiryDetailComponent, {
    //   fullscreen: true,
    //   scrollable: true,
    // });
    // modalRef.componentInstance.contractWithPartnershipId = contractWithPartnership.id;
    // modalRef.result.then(async (result) => {
    //   this.getData();
    // });
  }

  termChange() {
    this.contractWithPartnershipList.map((item) => (item.checked = false));
    this.allcheck = false;
  }

  checkAll(data) {
    if (this.allcheck) {
      data.map((item) => (item.checked = true));
    } else {
      data.map((item) => (item.checked = false));
    }
  }
  async deleteInquiry() {
    const confirm = await this.alertService.confirm(
      "계약 삭제",
      "계약를 삭제하면 복원 할 수 없습니다. 해당 계약를 정말로 삭제하시겠습니까?",
      "계약 삭제"
    );
    if (confirm) {
      const filter = this.contractWithPartnershipList.filter(
        (item) => item.checked
      );
      const promises = filter.map((contractWithPartnership) =>
        this.deleteInquiryProcess(contractWithPartnership)
      );
      await Promise.all(promises);
      filter.forEach((element) => {
        let i = this.contractWithPartnershipList.findIndex(
          (item) => item.id == element.id
        );
        this.contractWithPartnershipList.splice(i, 1);
      });
      this.alertService.basicMessage(
        `${promises.length}개의 계약가 삭제되었습니다.`
      );
    }
  }

  async deleteInquiryProcess(contractWithPartnership) {
    return new Promise<any>(async (resolve) => {
      await this.db.updateAt(
        `deletedInquiries/${contractWithPartnership.id}`,
        contractWithPartnership
      );
      await this.db.delete(
        `contractWithPartnershipList/${contractWithPartnership.id}`
      );
      resolve("success");
    });
  }

  async changeStatus(
    contractWithPartnership: ContractWithPartnership,
    status: string
  ) {
    if (contractWithPartnership.status === status) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 계약 상태를 "${status}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        contractWithPartnershipId: contractWithPartnership.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: contractWithPartnership.status
          ? contractWithPartnership.status
          : "접수",
        newStatus: status,
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusHistories", updateStatusHistory);
      await this.db.updateAt(
        `contractWithPartnershipList/${contractWithPartnership.id}`,
        {
          status: status,
        }
      );
      contractWithPartnership.status = status;
      this.alertService.basicMessage("상태가 변경되었습니다.");
    }
  }

  getLastConsulting(contractWithPartnership) {
    let message = "";
    if (contractWithPartnership.consult.length) {
      message = contractWithPartnership.consult[0].description;
    } else {
      message = "상담내역이 없습니다.";
    }

    this.selectMessage = message;
  }

  onMouseEnter(data, content) {
    this.selectConsult = data.consult;
    if (!this.isModalOpen) {
      this.modalService.open(content, { size: "lg", centered: true });
      this.isModalOpen = true;
    }
  }

  onMouseLeave($event) {
    this.modalService.dismissAll();
    this.isModalOpen = false;
  }

  trackById(idx, contractWithPartnership) {
    return contractWithPartnership.id;
  }

  editContract(item: ContractWithPartnership) {
    this.router.navigate(["/partnership/add-partnership-contract"], {
      queryParams: { id: item.id },
    });
  }
  caculate(contractWithPartnership: ContractWithPartnership) {
    let settlement =
      contractWithPartnership?.amount * (contractWithPartnership.charge / 100);
    return settlement;
  }

  async deleteContract(item: ContractWithPartnership, index: number) {
    const confirm = await this.alertService.confirm(
      "계약 삭제",
      "계약를 삭제하면 복원 할 수 없습니다. 해당 계약를 정말로 삭제하시겠습니까?",
      "계약 삭제"
    );
    if (confirm) {
      let deleteContractWithPartnership = _.cloneDeep(item);
      deleteContractWithPartnership.deletedBy = this.dataservice.userId;
      deleteContractWithPartnership.dateDeleted = new Date().toISOString();
      await this.db.updateAt(
        `deleteContractWithPartnership/${item.id}`,
        deleteContractWithPartnership
      );
      await this.db.delete(`contractWithPartnership/${item.id}`);
      this.contractWithPartnershipList.splice(index, 1);
      this.alertService.basicMessage(`해당 계약서가 삭제되었습니다.`);
    }
  }

  goDetail(contractWithPartnership: ContractWithPartnership) {
    console.log("contractWithPartnership", contractWithPartnership);

    const modalRef = this.modalService.open(
      ContractWithPartnershipDetailComponent,
      {
        size: "xl",
        scrollable: true,
      }
    );
    modalRef.componentInstance.contractWithPartnership =
      contractWithPartnership;
  }

  passBack() {
    this.activeModal.close();
  }
}
