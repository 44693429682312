/** @format */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable, of } from "rxjs";
import { switchMap, take, map, first } from "rxjs/operators";
import { DbService } from "./db.service";
import firebase from "firebase/compat/app";
import { AlertService } from "./alert.service";
import * as auth from "firebase/app";
import { LoaderService } from "./loader.service";
declare var gapi: any;
declare var google: any;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  user$: Observable<any>;
  userVeriFy = false;

  public isTab = true;
  constructor(
    public afAuth: AngularFireAuth,
    public db: DbService,
    public router: Router,
    private alertService: AlertService,
    private loadService: LoaderService
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => (user ? db.doc$(`users/${user.uid}`) : of(null)))
    );
    this.handleRedirect();
  }

  async getUser() {
    return this.user$.pipe(first()).toPromise();
  }

  async uid() {
    return this.user$
      .pipe(
        take(1),
        map((u) => u && u.id)
      )
      .toPromise();
  }

  userId(): any {
    return this.user$.pipe(
      take(1),
      map((u) => u.id)
    );
  }

  reload(): any {
    firebase
      .auth()
      .currentUser.reload()
      .then(() => {});
  }

  info(): any {
    return this.user$.pipe(take(1)).toPromise();
  }

  async anonymousLogin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInAnonymously()
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  async emailLogin(obj): Promise<any> {
    try {
      var r = await this.afAuth.signInWithEmailAndPassword(
        obj.email,
        obj.password
      );
      if (r) {
        const user = r.user;
        localStorage.setItem("userId", r.user.uid);
        this.router.navigate(["/dashboard"]);
        this.alertService.basicMessage("로그인 되었습니다.");
      }
    } catch (error) {
      const code = error["code"];
      this.alertService.showErrorMessage(code);
    }
  }

  loginUser(value): any {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  exitLogin(obj): any {
    return new Promise<any>((resolve, reject) => {
      try {
        var r = this.afAuth.signInWithEmailAndPassword(obj.email, obj.password);

        if (r) {
        }
      } catch (error) {
        let code = error["code"];

        resolve(error);
      }
    });
  }

  loginWithToken(token): any {
    // tslint:disable-next-line:no-shadowed-variable
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(token)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  resetPassword(email): any {
    this.afAuth
      .sendPasswordResetEmail(email)
      .then(() => {})
      .catch((err) => {});
  }

  async updateUserData(user): Promise<any> {
    //사용자 데이터를 firestore에 업로드//
    const path = `users/${user.uid}`;
    const data = Object.assign({
      uid: user.uid,
      email: user.email,
      name: user.name,
      image: user.image,
    });
    await (
      await this.afAuth.currentUser
    ).updateProfile({
      displayName: user.name,
    });
    await this.db
      .updateAt(path, data)
      .then((s) => {
        this.loadService.setLoading(false);

        this.router.navigate(["/dashboard"]);
      })
      .catch((e) => {});
  }

  async signOut(): Promise<any> {
    await this.afAuth.signOut();
    return this.router.navigate(["/"]);
  }

  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  async AuthLogin(provider) {
    this.loadService.setLoading(true);
    return this.afAuth
      .signInWithPopup(provider)
      .then(async (result) => {
        let googleData: any = result.additionalUserInfo.profile;
        const checkExisted = await this.db
          .doc$(`users/${result.user.uid}`)
          .pipe(first())
          .toPromise();
        if (
          googleData.hd !== "kncuration.com" &&
          googleData.hd !== "startapp.co.kr"
        ) {
          this.alertService.error("인증되지 이메일입니다.");
          return this.afAuth.signOut();
        }
        if (!googleData.hd || !checkExisted) {
          this.alertService.error("인증되지 이메일입니다.");
          return this.afAuth.signOut();
        }

        let userData = {
          name: googleData.name ? googleData.name : "사용자",
          uid: result.user.uid,
          email: googleData.email,
          image: googleData.picture,
        };
        this.updateUserData(userData);
      })
      .catch((error) => {});
  }
  //// GOOGLE AUTH
  public async handleRedirect(): Promise<any> {
    if (Boolean(this.isRedirect())) {
      return null;
    }

    const result = await this.afAuth.getRedirectResult();

    if (result.user) {
      await this.updateUserData(result.user);
    }

    await this.setRedirect("false");
    return result;
  }

  setRedirect(val): any {
    localStorage.setItem("authRedirect", val);
  }

  isRedirect(): any {
    return localStorage.getItem("authRedirect");
  }

  changePassword(oldPassword, newPassword): any {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      return this.afAuth
        .signInWithEmailAndPassword(user.email, oldPassword)
        .then((sucess) => {
          user
            .updatePassword(newPassword)
            .then((sucess) => {
              resolve(true);
            })
            .catch((error) => {
              let code = error["code"];
              reject(code);
            });
        })
        .catch((error) => {
          let code = error["code"];
          reject(code);

          // this.alert.showErrorMessage(code);
        });
    });
  }

  exitUser(): any {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;

      this.db
        .updateAt(`users/${user.uid}`, {
          exitSwitch: true,
          pushId: "",
          alarmSwitch: false,
        })
        .then(() => {
          user
            .delete()
            .then(() => {
              // localStorage.clear();

              // this.router.navigateByUrl('/login');
              reject(false);
            })
            .catch((error) => {});
        })
        .catch((error) => {})
        .catch((error) => {});
    });
  }

  async sendEmailVerificationUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser.sendEmailVerification()
          .then((res) => {
            this.emailVerified();

            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  emailVerified() {
    return this.afAuth.authState
      .pipe(
        take(1),
        map((u) => u && u.emailVerified)
      )
      .toPromise();
  }

  emailcehck() {
    this.afAuth.authState.subscribe((data) => {
      if (data.emailVerified) {
        this.db.updateAt(`users/${data.uid}`, {
          emailVerify: true,
        });
      }
    });
  }

  userDetails(): any {
    return firebase.auth().currentUser;
  }

  logoutUser(): any {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;

      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .signOut()
          .then((res) => {
            this.db.updateAt(`users/${user.uid}`, { webPushId: "" });
            localStorage.clear();
            this.alertService.basicMessage("로그아웃 되었습니다.");
            this.router.navigate(["/account/login"]);

            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  //클로즈 이메일
  async updateEmailClosed(email, id, date) {
    return new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      let data = { dateClosed: date, email: email };
      this.db
        .updateAt(`EmailClosed/${user.uid}`, data)
        .then((success) => {
          resolve(true);
        })
        .catch((error) => {
          reject(false);
        });
    });
  }

  // 메일 발송
  sendPasswordReset(email) {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((success) => {
        this.alertService.basicMessage(
          "비밀번호 재설정 메일이 발송되었습니다."
        );
        this.router.navigate(["/account/login"]);
      })
      .catch((error) => {
        let code = error["code"];
        this.alertService.showErrorMessage(code);
      });
  }
}
