import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  //   {
  //     id: 1,
  //     label: "메뉴",
  //     isTitle: true,
  //   },
  {
    id: 1,
    label: "대시보드",
    icon: "bx-home-circle",
    link: "/schedule/meeting-management",
    // badge: {
    //   variant: "info",
    //   text: "New",
    // },
  },
  {
    id: 2,
    isLayout: true,
  },
  {
    id: 3,
    label: "관리자 기능",
    isTitle: true,
    acceptUserType: ["최고 관리자"],
  },
  {
    id: 4,
    label: "구성원",
    icon: "bx-group",
    link: "/",
    acceptUserType: ["최고 관리자"],
    subItems: [
      {
        id: 28,
        label: "항목관리",
        link: "/users/entry-management",
        parentId: 4,
      },
      // {
      //   id: 28,
      //   label: "조직관리",
      //   link: "/users/group-management",
      //   parentId: 4,
      // },
      {
        id: 16,
        label: "사용자리스트",
        link: "/users/user-list",
        parentId: 4,
      },
    ],
  },
  {
    id: 2,
    isLayout: true,
  },
  {
    id: 3,
    label: "프로젝트 관련",
    isTitle: true,
  },
  {
    id: 4,
    label: "프로젝트",
    icon: "bx-notepad",
    link: "/",
    subItems: [
      {
        id: 28,
        label: "프로젝트 검색",
        link: "/project/search-project",
        parentId: 4,
      },
      {
        id: 28,
        label: "포트폴리오 관리",
        link: "/project/portfolio-management",
        parentId: 4,
      },
      // {
      //   id: 28,
      //   label: "프로젝트 등록",
      //   link: "/project/enroll-project",
      //   parentId: 4,
      // },
      // {
      //   id: 28,
      //   label: "프로젝트 진행",
      //   link: "/project/project-management",
      //   parentId: 4,
      // },
      {
        id: 16,
        label: "입금 계획",
        link: "/project/project-financial-plan",
        parentId: 4,
      },
    ],
  },
  {
    id: 3,
    label: "내부",
    isTitle: true,
  },
  // {
  //   id: 7,
  //   label: "미팅 캘린더",
  //   icon: "bx-calendar",
  //   link: "/schedule/meeting-management",
  // },
  {
    id: 7,
    label: "등록/신청",
    icon: "bx-highlight",
    link: "/",

    subItems: [
      {
        id: 18,
        label: "도서 구매 신청",
        link: "/memberService/book",
        parentId: 7,
      },
      {
        id: 18,
        label: "교육 신청",
        link: "/memberService/education",
        parentId: 7,
      },
      {
        id: 18,
        label: "물품 신청",
        link: "/memberService/goods",
        parentId: 7,
      },
      {
        id: 18,
        label: "비용 사용등록",
        link: "/cost/cost-history",
        parentId: 7,
      },
    ],
  },
  {
    id: 7,
    label: "대여",
    icon: "bx-food-menu",
    link: "/",

    subItems: [
      {
        id: 18,
        label: "테스트폰 대여",
        link: "/memberService/testphone",
        parentId: 7,
      },
      {
        id: 18,
        label: "도서 대여",
        link: "/memberService/book",
        parentId: 7,
      },
    ],
  },
  {
    id: 7,
    label: "리더/팀장",
    icon: "bx-group",
    link: "/",
    acceptUserType: ["최고 관리자", "관리자"],
    subItems: [
      {
        id: 17,
        label: "부/팀 관리",
        link: "/department/department-management",
        parentId: 7,
      },
      {
        id: 17,
        label: "부/팀 교육신청 처리",
        link: "/department/request-education",
        parentId: 7,
      },
      {
        id: 17,
        label: "부/팀 운영비 관리",
        link: "/cost/department-cost-management",
        parentId: 7,
      },
    ],
  },
  {
    id: 5,
    label: "운영관리",
    isTitle: true,
    acceptUserType: ["최고 관리자", "관리"],
  },
  {
    id: 7,
    label: "신청처리",
    icon: "bx-run",
    link: "/",
    acceptUserType: ["최고 관리자", "관리"],
    subItems: [
      {
        id: 17,
        label: "도서 신청 처리",
        link: "/operation/request-book",
        parentId: 7,
      },
    ],
  },
  {
    id: 7,
    label: "관리",
    icon: "bx-directions",
    link: "/",
    acceptUserType: ["최고 관리자", "관리"],
    subItems: [
      {
        id: 17,
        label: "테스트폰 관리",
        link: "/operation/testphone-management",
        parentId: 7,
      },
      {
        id: 17,
        label: "도서 관리",
        link: "/operation/book-management",
        parentId: 7,
      },
      {
        id: 17,
        label: "운영비 관리",
        link: "/cost/operation-cost-management",
        parentId: 7,
      },
    ],
  },
  {
    id: 5,
    label: "비용 관련",
    isTitle: true,
    acceptUserType: ["최고 관리자"],
  },
  {
    id: 7,
    label: "회사비용",
    icon: "bx-credit-card",
    link: "/",
    acceptUserType: ["최고 관리자"],
    subItems: [
      {
        id: 18,
        label: "비용사용내역",
        link: "/cost/cost-history-management",
        parentId: 7,
      },
    ],
  },

  {
    id: 5,
    label: "영업 관련",
    isTitle: true,
    acceptUserType: ["최고 관리자", "상담"],
  },
  // {
  //   id: 6,
  //   label: "고객 관리",
  //   icon: "bxs-user-detail",
  //   link: "/",
  // },
  {
    id: 7,
    label: "영업 대시보드",
    icon: "bxs-user-detail",
    link: "/consulting/consulting-dashboard",
    acceptUserType: ["최고 관리자", "상담", "제휴"],
  },

  // {
  //   id: 7,
  //   label: "문의 관리",
  //   icon: "bx-file",
  //   link: "/",

  //   subItems: [
  //     {
  //       id: 17,
  //       label: "문의등록",
  //       link: "/inquiry/add-inquiry",
  //       parentId: 7,
  //     },
  //     {
  //       id: 18,
  //       label: "문의리스트",
  //       link: "/inquiry/inquiry-list",
  //       parentId: 7,
  //     },
  //   ],
  // },
  {
    id: 8,
    label: "문의/상담",
    icon: "bx-user-voice",
    link: "/",
    acceptUserType: ["상담", "제휴"],
    subItems: [
      // {
      //   id: 19,
      //   label: "영업 대시보드",
      //   link: "/consulting/consulting-dashboard",
      //   parentId: 8,
      // },
      {
        id: 19,
        label: "문의/상담 통계",
        link: "/consulting/consulting-statistics",
        parentId: 8,
      },
      {
        id: 19,
        label: "상담검색",
        link: "/consulting/all-consulting-management",
        parentId: 8,
      },
      {
        id: 19,
        label: "주요 고객 관리",
        link: "/consulting/consulting-management",
        parentId: 8,
      },
      {
        id: 20,
        label: "신규문의리스트",
        link: "/inquiry/new-inquiry-list",
        parentId: 8,
      },
      {
        id: 18,
        label: "문의리스트",
        link: "/inquiry/inquiry-list",
        parentId: 8,
      },
      {
        id: 17,
        label: "문의등록",
        link: "/inquiry/add-inquiry",
        parentId: 8,
      },
    ],
  },

  // {
  //   id: 28,
  //   label: "개발정의",
  //   icon: "bx bx-list-check",
  //   link: "/",
  //   acceptUserType: ["상담"],
  //   subItems: [
  //     {
  //       id: 29,
  //       label: "개발정의 항목 관리",
  //       link: "/estimate/setup-estimate",
  //       parentId: 28,
  //     },
  //     {
  //       id: 30,
  //       label: "개발범위 작성",
  //       link: "/estimate/write-estimate",
  //       parentId: 28,
  //     },
  //     {
  //       id: 31,
  //       label: "개발범위 리스트",
  //       link: "/estimate/estimate-list",
  //       parentId: 82,
  //     },
  //   ],
  // },
  {
    id: 13,
    label: "마케팅",
    icon: "bx-bar-chart-square",
    link: "/",
    subItems: [
      {
        id: 24,
        label: "기간별 통계",
        link: "/marketing/period-statistics",
        parentId: 13,
      },
      {
        id: 24,
        label: "홈페이지 접속",
        link: "/marketing/tracking-list",
        parentId: 13,
      },
      // {
      //   id: 25,
      //   label: "기타광고",
      //   link: "/inquiry/inquiry-list",
      //   parentId: 13,
      // },
      // {
      //   id: 26,
      //   label: "마케팅비용",
      //   link: "/inquiry/inquiry-list",
      //   parentId: 13,
      // },
    ],
  },

  {
    id: 8,
    label: "제휴",
    icon: "bx bx-globe",
    link: "/",
    acceptUserType: ["최고 관리자", "제휴"],
    subItems: [
      {
        id: 19,
        label: "업체 등록",
        link: "/partnership/add-company",
        parentId: 8,
      },
      {
        id: 19,
        label: "업체 리스트",
        link: "/partnership/company-management",
        parentId: 8,
      },
      {
        id: 18,
        label: "제휴 업체 리스트",
        link: "/partnership/partnership-company-management",
        parentId: 8,
      },
      {
        id: 18,
        label: "제휴 계약 등록",
        link: "/partnership/add-partnership-contract",
        parentId: 8,
      },
      {
        id: 18,
        label: "제휴 계약 리스트",
        link: "/partnership/partnership-contract-management",
        parentId: 8,
      },
    ],
  },
  {
    id: 8,
    label: "영업관련",
    icon: "bx bx-radar",
    link: "/",
    acceptUserType: ["스토어"],

    subItems: [
      {
        id: 19,
        label: "파일관리",
        link: "/startapp/sales-files-management",
        parentId: 8,
      },
    ],
  },
  {
    id: 9,
    label: "계약/매츨 관련",
    isTitle: true,
  },
  {
    id: 10,
    label: "계약",
    icon: "bx bx-layer",
    link: "/",
    subItems: [
      {
        id: 20,
        label: "계약 등록",
        link: "/contract/add-contract",
        parentId: 9,
      },
      {
        id: 21,
        label: "계약 리스트",
        link: "/contract/contract-history",
        parentId: 10,
      },
    ],
  },
  {
    id: 11,
    label: "매출",
    icon: "bx-receipt",
    link: "/",
    acceptUserType: ["상담", "제휴"],
    subItems: [
      {
        id: 22,
        label: "입금 예정 관리",
        link: "/sales/expected-deposit-management",
        parentId: 11,
      },
      {
        id: 23,
        label: "매출/입금 내역 관리",
        link: "/sales/sales-history",
        parentId: 11,
      },
      {
        id: 22,
        label: "매출 통계",
        link: "/sales/sales-statistics",
        parentId: 11,
      },
    ],
  },

  // {
  //   id: 5,
  //   label: "솔루션 서비스",
  //   isTitle: true,
  //   acceptUserType: ["스토어"],
  // },
  // {
  //   id: 8,
  //   label: "웹 상품 관리",
  //   icon: "bx-store",
  //   link: "/",
  //   acceptUserType: ["스토어"],

  //   subItems: [
  //     {
  //       id: 19,
  //       label: "웹 상품 등록",
  //       link: "/web-solution/add-product",
  //       parentId: 8,
  //     },
  //     {
  //       id: 19,
  //       label: "웹 상품 관리",
  //       link: "/web-solution/web-solution-management",
  //       parentId: 8,
  //     },

  //     {
  //       id: 19,
  //       label: "카테고리 관리",
  //       link: "/web-solution/web-category-management",
  //       parentId: 8,
  //     },
  //     {
  //       id: 19,
  //       label: "태그 관리",
  //       link: "/web-solution/web-tag-management",
  //       parentId: 8,
  //     },
  //     // {
  //     //   id: 17,
  //     //   label: "결제관리",
  //     //   link: "/store/payment-management",
  //     //   parentId: 8,

  //     // },
  //   ],
  // },

  {
    id: 8,
    label: "앱 상품 관리",
    icon: "bx-store",
    link: "/",
    acceptUserType: ["스토어"],

    subItems: [
      {
        id: 19,
        label: "상품 관리",
        link: "/app-solution/solution-app-management",
        parentId: 8,
      },
      {
        id: 19,
        label: "상품 등록",
        link: "/app-solution/add-solution-app",
        parentId: 8,
      },
      {
        id: 19,
        label: "자주묻는질문 관리",
        link: "/app-solution/faq-management",
        parentId: 8,
      },

      {
        id: 19,
        label: "리뷰 관리",
        link: "/app-solution/review-management",
        parentId: 8,
      },
      {
        id: 19,
        label: "가격 관리",
        link: "/app-solution/price-management",
        parentId: 8,
      },
      {
        id: 19,
        label: "카테고리 관리",
        link: "/app-solution/category-management",
        parentId: 8,
      },
      {
        id: 19,
        label: "태그 관리",
        link: "/app-solution/tag-management",
        parentId: 8,
      },
      // {
      //   id: 17,
      //   label: "결제관리",
      //   link: "/store/payment-management",
      //   parentId: 8,

      // },
    ],
  },
  // {
  //   id: 8,
  //   label: "주문 관리",
  //   icon: "bx-store",
  //   link: "/",
  //   acceptUserType: ["스토어"],

  //   subItems: [
  //     {
  //       id: 19,
  //       label: "주문 접수",
  //       link: "/app-solution/order-management",
  //       parentId: 8,
  //     },
  //     // {
  //     //   id: 17,
  //     //   label: "결제관리",
  //     //   link: "/store/payment-management",
  //     //   parentId: 8,

  //     // },
  //   ],
  // },
  {
    id: 9,
    label: "관리자페이지 피드백",
    isTitle: true,
  },
  {
    id: 14,
    label: "오류 및 개선사항",
    icon: "bx-sticker",
    link: "/",
    subItems: [
      {
        id: 27,
        label: "오류 및 개선사항 등록",
        link: "/cs/add-cs",
        parentId: 14,
      },
      {
        id: 27,
        label: "오류 및 개선사항 리스트",
        link: "/cs/cs-list",
        parentId: 14,
      },
    ],
  },
];
