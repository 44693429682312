<div class="modal-header">
  <h2 class="modal-title mb-0">제휴연결 계약 상세</h2>
  <button type="button"
    class="btn-close"
    aria-label="Close"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <!-- <div class="col-lg-3"> -->
    <div class="card">
      <div class="card-body">
        <ul ngbNav
          #nav="ngbNav"
          [(activeId)]="activeNav"
          (navChange)="navChanged($event)"
          class="nav-pills nav-justified">
          <li [ngbNavItem]="'수주 계약'">
            <a ngbNavLink>수주 계약</a>
          </li>
          <li [ngbNavItem]="'발주 계약'">
            <a ngbNavLink>발주 계약</a>
          </li>
        </ul>

      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h2 class="card-title">{{contactCompany.companyName}} - {{activeNav}} 리스트</h2>
          <p class="card-title-desc">{{activeNav == '수주 계약'?'케이엔큐레이션 → 제휴업체':'제휴업체 → 케이엔큐레이션'}}</p>
          <div class="row mb-2">
            <div class="col-sm-4">
              <ul ngbNav
                #justifiednav="ngbNav"
                [(activeId)]="tabs"
                class="nav-tabs nav-tabs-custom nav-justified">
                <li [ngbNavItem]="4">
                  <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                    <span class="d-none d-sm-block">전체</span>
                  </a>
                </li>
                <li [ngbNavItem]="1">
                  <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                    <span class="d-none d-sm-block">기간선택</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="mb-3 row">

                      <div class="col-md-4">
                        <div class="row">
                          <label for="example-datetime-local-input"
                            class="col-md-4 col-form-label">시작날짜</label>
                          <div class="col-md-8">
                            <input class="form-control"
                              [(ngModel)]="startDate"
                              type="date">
                          </div>
                        </div>

                      </div>
                      <div class="col-md-1">
                      </div>
                      <div class="col-md-4">
                        <div class="row">
                          <label for="example-datetime-local-input"
                            class="col-md-4 col-form-label">끝날짜</label>
                          <div class="col-md-8">
                            <input class="form-control"
                              type="date"
                              [(ngModel)]="endDate">
                          </div>
                        </div>

                      </div>
                      <div class="col-md-2">
                        <button type="button"
                          (click)="getData()"
                          class="btn btn-outline-secondary mb-2">
                          불러오기</button>
                      </div>

                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                    <span class="d-none d-sm-block">월단위</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="mb-3 row">
                      <div class="col-md-4">
                        <div class="row">
                          <label for="example-datetime-local-input"
                            class="col-md-4 col-form-label">월 선택</label>
                          <div class="col-md-8">
                            <input class="form-control"
                              [(ngModel)]="selectMonth"
                              type="month">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <button type="button"
                          (click)="getData()"
                          class="btn btn-outline-secondary mb-2">
                          불러오기</button>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>
                    <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                    <span class="d-none d-sm-block">주단위</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="mb-3 row">
                      <div class="col-md-4">
                        <div class="row">
                          <label for="example-datetime-local-input"
                            class="col-md-4 col-form-label">주선택</label>
                          <div class="col-md-8">
                            <input class="form-control"
                              type="week"
                              [(ngModel)]="selectWeek">

                          </div>
                        </div>

                      </div>
                      <div class="col-md-4">
                        <button type="button"
                          (click)="getData()"
                          class="btn btn-outline-secondary mb-2">
                          불러오기</button>
                      </div>

                    </div>
                  </ng-template>
                </li>
              </ul>
              <!-- <div class="search-box me-2 mb-2 d-inline-block">
                    <div class="position-relative">
                        <input type="text"
                            class="form-control"
                            placeholder="검색어를 입력하세요"
                            [(ngModel)]="term">
                        <i class="bx bx-search-alt search-icon"></i>
                    </div>

                </div> -->
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">

                <div class="search-box me-2 mb-2 d-inline-block">
                  <div class="position-relative">
                    <input type="text"
                      class="form-control"
                      placeholder="검색어를 입력하세요"
                      (focus)="termChange()"
                      [(ngModel)]="term">
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>

                </div>

              </div>

            </div><!-- end col-->
          </div>

          <div class="row mb-2">
            <div class="col-sm-8">
              <div [ngbNavOutlet]="justifiednav"></div>

            </div>
            <div class="col-sm-4">
              <div class="text-sm-end"
                *ngIf="(contractWithPartnershipList | filter: term) as datas">
                <button type="button"
                  (click)="goAddContract()"
                  class="btn btn-success btn-rounded mb-2 me-2"><i class="mdi mdi-plus mr-1"></i>
                  신규 계약 등록</button>
                <!-- <button type="button"
                  (click)="deleteInquiry()"
                  class="btn btn-danger btn-rounded mb-2 me-2">
                  문의 삭제</button> -->
                <!-- <button type="button"
                  (click)="selectFile()"
                  class="btn btn-secondary btn-rounded mb-2 me-2"><i class="bx bx-table mr-1"></i>
                  가져오기</button>
                <input hidden
                  type="file"
                  (change)="onFileChange($event)"
                  #fileInput
                  multiple="false"> -->
                <button type="button"
                  (click)="exportExcel(datas)"
                  class="btn btn-secondary btn-rounded mb-2 me-2"><i class="bx bx-export mr-1"></i>
                  내보내기</button>

              </div>
              <div class="text-sm-end">

              </div>
            </div>

          </div>
          <!-- <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input type="text"
                    class="form-control"
                    placeholder="검색어를 입력하세요"
                    [(ngModel)]="term">
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button type="button"
                  (click)="goAddInquiry()"
                  class="btn btn-success btn-rounded mb-2 me-2"><i class="mdi mdi-plus mr-1"></i>
                  신규 문의 등록</button>
              </div>
            </div>
          </div> -->

          <!-- Table data -->
          <div class="table-responsive mb-0">
            <table class="table table-centered  align-middle table-nowrap"
              id="inquiry-tble">
              <thead class="table-light">
                <tr>
                  <!-- <th style="width: 20px;">
                    <div class="form-check font-size-16 align-middle"
                      *ngIf="(contractWithPartnershipList | filter: term) as data">
                      <input class="form-check-input"
                        [(ngModel)]="allcheck"
                        type="checkbox"
                        (change)="checkAll(data)"
                        id="transactionCheck01">
                      <label class="form-check-label"
                        for="transactionCheck01"></label>
                    </div>
                  </th> -->
                  <th class="align-middle">계약날짜</th>
                  <th class="align-middle">계약금액</th>
                  <th class="align-middle">계약명</th>
                  <th class="align-middle">계약자명</th>
                  <th class="align-middle">제휴수수료</th>
                  <th class="align-middle">제휴정산금</th>
                  <th class="align-middle">정산여부</th>


                  <!-- <th class="align-middle">이메일</th> -->
                  <!-- <th class="align-middle">전화번호</th> -->
                  <!-- <th class="align-middle">예산</th> -->
                  <th class="align-middle">단계</th>
                  <th class="align-middle">상세보기</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of (contractWithPartnershipList | filter: term);  trackBy: trackById; let i = index">
                  <!-- <td>
                    <div class="
                  form-check
                  font-size-16">
                      <input class="form-check-input"
                        type="checkbox"
                        [(ngModel)]="data.checked"
                        id="transactionCheck{{data.index}}">
                      <label class="form-check-label"
                        for="transactionCheck{{data.index}}"></label>
                    </div>
                  </td> -->
                  <!-- <td>
                    <a href="javascript: void(0);"
                      class="text-body fw-bold">{{data.inquiryId}}</a>
                  </td> -->
                  <td>{{data.dateContract}}</td>
                  <td>{{data.amount | number}}원</td>
                  <td>{{data.title}}</td>
                  <td>{{data.contractName}}</td>
                  <td>{{data.charge}}%</td>
                  <td>{{caculate(data) | number}}원</td>
                  <td>미정산</td>
                  <td>{{data.status}}</td>

                  <!-- <td>{{data.email}}</td> -->
                  <!-- <td>{{data.phone}}</td> -->
                  <!-- <td>{{data.budget | number : '.0' }}</td> -->
                  <!-- <td class="align-items-center">
                    <div class="btn-group"
                      ngbDropdown>
                      <button type="button"
                        class="btn dropdown-toggle"
                        [ngClass]=" { 'btn-primary': data.status === '1차 전화상담 완료',
                            'btn-success': data.status === '방문/화상상담 완료', 'btn-info': data.status === '견적서 발송 완료', 'btn-warning': data.status === '계약 진행중',
                            'btn-danger': data.status === '보류', 'btn-dark': data.status === '완료', 'btn-secondary': !data.status}"
                        ngbDropdownToggle>{{data.status?data.status:'접수'}} <i class="mdi mdi-chevron-down"></i></button>
                      <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'1차 전화상담 완료')"
                          href="javascript: void(0);">1차 전화상담 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'방문/화상상담 완료')"
                          href="javascript: void(0);">방문/화상상담 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'견적서 발송 완료')"
                          href="javascript: void(0);">견적서 발송 완료</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'계약 진행중')"
                          href="javascript: void(0);">계약 진행중</a>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'보류')"
                          href="javascript: void(0);">보류</a>
                        <a class="dropdown-item"
                          (click)="changeStatus(data,'완료')"
                          href="javascript: void(0);">완료</a>
                      </div>
                    </div><!-- /btn-group -->

                  <td>
                    <!-- Button trigger modal -->
                    <button type="button"
                      style="margin-right: 0.5rem"
                      (click)="goDetail(data)"
                      class="btn btn-outline-dark">자세히보기</button>
                    <button type="button"
                      style="margin-right: 0.5rem"
                      (click)="editContract(data)"
                      class="btn btn-outline-primary">수정</button>
                    <button type="button"
                      (click)="deleteContract(data,i)"
                      class="btn btn-outline-danger">삭제</button>
                  </td>
                </tr>
                <tr *ngIf="(contractWithPartnershipList | filter: term) as contracts">
                  <th class="align-middle">총합계</th>
                  <th class="align-middle"></th>
                  <th class="align-middle">{{getTotaldatas(contracts) | number : '.0'}}원</th>
                  <th class="align-middle"></th>
                  <th class="align-middle"></th>
                  <th class="align-middle"></th>
                  <th class="align-middle">{{getTotalSettlement(contracts) | number : '.0'}}원</th>
                  <th class="align-middle"></th>
                  <th class="align-middle"></th>


                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>