<div class="modal-header">
  <h2 class="modal-title mb-0">업체 상세</h2>
  <button type="button"
    class="btn-close"
    aria-label="Close"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-6">

      <div class="card"
        *ngIf="contactCompany">
        <div class="card-body">
          <h4 class="card-title mb-4">업체 내용</h4>
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">상태 :</th>
                  <td>
                    <div class="btn-group"
                      ngbDropdown>
                      <button type="button"
                        class="btn dropdown-toggle"
                        type="button"
                        id="dropdownConfig"
                        [ngClass]=" { 'btn-primary': contactCompany.status === '전화 컨택 완료',
                                'btn-success': contactCompany.status === '미팅 예정', 'btn-info': contactCompany.status === '미팅 완료', 'btn-warning': contactCompany.status === '제휴 협의중',
                                 'btn-dark': contactCompany.status === '제휴 완료', 'btn-secondary': contactCompany.status ==='등록'}"
                        ngbDropdownToggle>{{contactCompany.status?contactCompany.status:'등록'}} <i
                          class="mdi mdi-chevron-down"></i></button>
                      <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                          (click)="changeStatus('등록')"
                          href="javascript: void(0);">등록</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('전화 컨택 완료')"
                          href="javascript: void(0);">전화 컨택 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('미팅 예정')"
                          href="javascript: void(0);">미팅 예정</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('미팅 완료')"
                          href="javascript: void(0);">미팅 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('제휴 협의중')"
                          href="javascript: void(0);">제휴 협의중</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          (click)="changeStatus('제휴 완료')"
                          href="javascript: void(0);">제휴 완료</a>
                      </div>
                    </div><!-- /btn-group -->
                  </td>
                </tr>
                <tr>
                  <th scope="row">등록일 :</th>
                  <td>{{contactCompany.dateCreated | date:'yyyy-MM-dd'}}</td>
                </tr>
                <tr>
                  <th scope="row">제휴등록일 :</th>
                  <td>{{contactCompany.datePartnerShip | date:'yyyy-MM-dd'}}</td>
                </tr>
                <tr>
                  <th scope="row">업체명 :</th>
                  <td>{{ contactCompany.companyName }} <a href="javascript: void(0);"
                      *ngIf="contactCompany.isPartnerShip"
                      class="badge badge-soft-primary font-size-13 m-1">제휴업체</a></td>
                </tr>
                <tr>
                  <th scope="row">대표자/담당자명 :</th>
                  <td>{{ contactCompany.presedent }}/{{ contactCompany.manager }}</td>
                </tr>
                <tr>
                  <th scope="row">제휴분야 :</th>
                  <td>{{ contactCompany.mainSpecialty }}</td>
                </tr>
                <tr>
                <tr>
                  <th scope="row">기타 전문 업무 :</th>
                  <td>{{ contactCompany.subSpecialty }}</td>
                </tr>
                <tr>
                  <th scope="row">수행기관 등록 여부 :</th>
                  <td>{{ contactCompany.isImplementingAgency }}</td>
                </tr>
                <tr>
                  <th scope="row">연락처 :</th>
                  <td>{{ contactCompany.phone }}</td>
                </tr>
                <tr>
                  <th scope="row">이메일 :</th>
                  <td>{{ contactCompany.email }}</td>
                </tr>
                <tr>
                  <th scope="row">대표 URL :</th>
                  <td>{{ contactCompany.url }}</td>
                </tr>
                <tr>
                  <th scope="row">주소 :</th>
                  <td>{{ contactCompany.address }}</td>
                </tr>


              </tbody>
            </table>
          </div>
          <h2 class="font-size-15 mt-4">첨부파일</h2>
          <p *ngIf="!contactCompany.files ||  contactCompany.files.length == 0">없음</p>

          <div class="table-responsive"
            *ngIf="contactCompany.files&& contactCompany.files.length>0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">파일명</th>
                  <th scope="col">파일</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of contactCompany.files">
                  <td>
                    {{file.name }}
                  </td>
                  <td>
                    {{getFileName(file.fileUrl)}}
                  </td>
                  <td>
                    <button type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="downloadFile(file.fileUrl)">다운로드</button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer bg-transparent border-top">
          <div class="text-center">
            <a href="javascript: void(0);"
              (click)="editContactCompany()"
              class="btn btn-dark"
              style="margin-right: 4px;">업체정보 수정</a>
            <a href="javascript: void(0);"
              (click)="editContactCompany()"
              class="btn btn-danger">업체정보 삭제</a>

          </div>
        </div>
      </div>


      <!-- end card -->
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start">
            <h4 class="card-title mb-4">협약서 리스트</h4>
            <button class="btn btn-success ms-auto"
              (click)="addPartnershipDocuments()">협약서 등록</button>
          </div>
          <div class="text-center"
            *ngIf="(partnershipDocuments$ | async) as partnershipDocuments">
            <div class="row justify-content-center"
              *ngIf="partnershipDocuments?.length == 0">
              <p class="mt-2 fw-semibold">등록된 협약서가 없습니다.</p>
            </div>

            <div class="table-responsive"
              *ngIf="(partnershipDocuments$ | async)?.length > 0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">등록일</th>
                    <th scope="col">등록자</th>
                    <th scope="col">협약서</th>
                    <th scope="col"></th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ( partnershipDocuments$ | async )">
                    <td>
                      {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                    </td>
                    <td>
                      {{item.createdBy.name}}
                    </td>
                    <td>
                      {{getFileName(item.file) }}
                    </td>
                    <td>
                      <button type="button"
                        class="btn btn-sm btn-outline-dark"
                        (click)="goPdfViewer(item.file)">보기</button>

                      <button type="button"
                        style="margin-left: 4px;"
                        class="btn btn-sm btn-danger"
                        (click)="deletePartnershipDocuments(item)">삭제</button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">히스토리</h4>
          <div class="text-center"
            *ngIf="(statusHistories$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">히스토리가 없습니다.</p>
            </div>
          </div>
          <div class="table-responsive"
            *ngIf="(statusHistories$ | async)?.length > 0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">처리일시</th>
                  <th scope="col">변동상태</th>
                  <th scope="col">처리자</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ( statusHistories$ | async )">
                  <td>
                    {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                  </td>
                  <td>
                    {{item.oldStatus}} -> {{item.newStatus}}
                  </td>
                  <td>
                    {{item.createdBy.name}}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card consulting">
        <div class="card-body">
          <h4 class="card-title mb-4">컨택 내역</h4>
          <div class="text-center"
            *ngIf="(contacts$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">컨택 내역이 없습니다.</p>
            </div>
          </div>
          <ngb-accordion [closeOthers]="true"
            activeIds="static-1"
            class="custom-accordion">
            <ngb-panel id="static-{{i+1}}"
              *ngFor="let item of (contacts$ | async); let i = index">
              <ng-template ngbPanelTitle>
                <div class="accordion-list">

                  <div>{{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}} - {{item.createdBy.name}}
                    <span class="badge rounded-pill badge-soft-success font-size-11">{{item.contactType}}</span>
                  </div>
                </div>

              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="item.isEditor">
                  <div class="form-group row mb-4">
                    <label for="project"
                      class="col-form-label col-2">컨택 구분</label>
                    <div class="col-10">
                      <ng-select [items]="['전화','방문','상담','협약','기타']"
                        [(ngModel)]="item.contactType"></ng-select>
                    </div>
                  </div>
                  <ckeditor [editor]="Editor"
                    [(ngModel)]="item.description"></ckeditor>
                  <div class="row text-end">
                    <button class="btn btn-primary"
                      (click)="saveEditContact(item)">수정내용 저장</button>
                  </div>
                </div>
                <div *ngIf="!item.isEditor">
                  <div class="form-group row mb-4">
                    <h4 class="col-lg-2">컨택 구분</h4>
                    <div class="col-lg-10">
                      {{item.contactType?item.contactType:'구분없음'}}
                    </div>
                  </div>
                  <h4 class="mb-4">상세 내용</h4>

                  <div [innerHTML]="item.description"></div>
                  <div class="text-center">
                    <button class="btn btn-dark mt-2"
                      (click)="editContacts(item)">
                      컨택내용
                      수정</button>
                  </div>
                </div>

              </ng-template>
            </ngb-panel>

          </ngb-accordion>
        </div>

      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start">
            <h4 class="card-title mb-4">컨택 일정</h4>
            <button class="btn btn-success ms-auto"
              (click)="addContactSchedule()">일정 등록</button>
          </div>
          <div class="text-center">
            <div class="row justify-content-center"
              *ngIf="meetingData?.length == 0">
              <p class="mt-2 fw-semibold">등록된 일정이 없습니다.</p>
            </div>

            <div class="table-responsive"
              *ngIf="meetingData?.length > 0">
              <table class="table table-nowrap mb-0"
                *ngIf="meetingData as meeting">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">미팅일자</th>
                    <th scope="col">구분</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of meeting">
                    <td>
                      {{getDate(item)}}
                    </td>
                    <td>
                      {{item.isCanceled? item.isVideoMeeting?'화상':'미팅'+'-취소':item.isVideoMeeting?'화상':'미팅'}}
                    </td>
                    <td class="text-end">
                      <button type="button"
                        (click)="editContactSchedule(item)"
                        class="btn btn-sm btn-outline-dark me-2">일정변경</button>
                      <button type="button"
                        (click)="resumeMeeting(item)"
                        *ngIf="item.isCanceled"
                        class="btn btn-sm btn-outline-danger ">취소해제</button>
                      <button type="button"
                        *ngIf="!item.isCanceled"
                        (click)="cancelMeeting(item)"
                        class="btn btn-sm btn-outline-danger ">일정취소</button>
                    </td>
                    <td>


                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">컨택 정보</h4>
          <h2 class="font-size-15 mt-4">컨택내용 입력</h2>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-2">컨택 구분</label>
            <div class="col-10">
              <ng-select [items]="['전화','방문','상담','협약','기타']"
                [(ngModel)]="contactType"></ng-select>
            </div>
          </div>
          <div class="row mb-4">
            <ckeditor [editor]="Editor"
              [(ngModel)]="description"></ckeditor>

          </div>
          <div class="text-center">

            <button (click)="createdContacts()"
              class="btn btn-primary">컨택내용 저장</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>