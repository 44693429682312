import { Injectable } from "@angular/core";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor() {}

  public importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = <XLSX.AOA2SheetOpts>(
      XLSX.utils.sheet_to_json(ws, { header: 1 })
    );

    return data;
  }

  public exportToFile(fileName: string, element_id: string) {
    if (!element_id) throw new Error("Element Id does not exists");
    const date = moment().format("YYYYMMDD");
    const name = date + "_" + fileName;
    let tbl = document.getElementById(element_id);
    let wb = XLSX.utils.table_to_book(tbl);
    XLSX.writeFile(wb, name + ".xlsx");
  }

  public arrayExportToFile(data: any[], filename: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileData: Blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(fileData, filename + ".xlsx");
  }

  public excelDateToJSDate(excelDate) {
    var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
    var converted_date = date.toISOString().split("T")[0];
    return converted_date;
  }
}
