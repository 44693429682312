import { ChangeDetectorRef, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  loading = new BehaviorSubject(false);

  constructor() {}

  setLoading(loading: boolean) {
    this.loading.next(loading);
  }

  ngAfterContentChecked() {
    // call or add here your code
  }
}
