<div class="modal-header">
  <h2 class="modal-title mb-0">문의 상세</h2>
  <button type="button"
    class="btn-close"
    aria-label="Close"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-6">
      <div class="row">
        <div class="col-6">
          <!-- end card -->
          <div class="card"
            *ngIf="inquiry$ | async  as inquiry">
            <div class="card-body">
              <div class="row m-0">
                <label for="example-text-input"
                  class="col-md-4 col-form-label">문의 상태</label>
                <div class="col-md-8">
                  <div class="btn-group"
                    ngbDropdown>
                    <button type="button"
                      class="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      [ngClass]=" { 'btn-primary': inquiry.status === '상담 진행중',
                                'btn-success': inquiry.status === '계약 완료', 'btn-info': inquiry.status === '견적서 발송 완료', 'btn-warning': inquiry.status === '접수',
                                'btn-danger': inquiry.status === '보류', 'btn-dark': inquiry.status === '상담 종료'}"
                      ngbDropdownToggle>{{inquiry.status?inquiry.status:'접수'}} <i
                        class="mdi mdi-chevron-down"></i></button>
                    <div class="dropdown-menu"
                      ngbDropdownMenu>
                      <a class="dropdown-item"
                        (click)="changeStatus(inquiry,'접수')"
                        href="javascript: void(0);">접수</a>
                      <a class="dropdown-item"
                        (click)="changeStatus(inquiry,'상담 진행중')"
                        href="javascript: void(0);">상담 진행중</a>
                      <a class="dropdown-item"
                        (click)="changeStatus(inquiry,'계약 완료')"
                        href="javascript: void(0);">계약 완료</a>
                      <a class="dropdown-item"
                        (click)="changeStatus(inquiry,'보류')"
                        href="javascript: void(0);">보류</a>
                      <a class="dropdown-item"
                        (click)="changeStatus(inquiry,'상담 종료')"
                        href="javascript: void(0);">상담 종료</a>
                    </div>
                  </div><!-- /btn-group -->
                </div>

              </div>



            </div>

          </div>
        </div>
        <div class="col-6">

          <div class="card"
            *ngIf="inquiry$ | async  as inquiry">
            <div class="card-body">
              <div class="row m-0">
                <label for="example-text-input"
                  class="col-md-4 col-form-label">주요 고객 설정</label>
                <div class="col-md-8 text-end p-0">


                  <ui-switch defaultBoColor="#dfdfdf"
                    size="small"
                    (change)="changeImportant($event,inquiry)"
                    [(ngModel)]="inquiry.importantSwitch"
                    color="#f46a6a"></ui-switch>

                </div>
              </div>



            </div>
          </div>

        </div>
      </div>



      <div class="card"
        *ngIf="inquiry$ | async  as inquiry">
        <div class="card-body"
          *ngIf="isInquiry">
          <h4 class="card-title mb-4">문의 수정</h4>
          <div class="form-group row mb-4">
            <label for="username"
              class="col-form-label col-lg-2">고객명</label>
            <div class="col-lg-10">
              <input id="username"
                name="username"
                [(ngModel)]="inquiryData.username"
                type="text"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="company"
              class="col-form-label col-lg-2">회사명</label>
            <div class="col-lg-10">
              <input id="company"
                name="company"
                [(ngModel)]="inquiryData.company"
                type="text"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">프로젝트명</label>
            <div class="col-lg-10">
              <input id="project"
                name="project"
                [(ngModel)]="inquiryData.project"
                type="text"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="budget"
              class="col-form-label col-lg-2">예산</label>
            <div class="col-lg-10">
              <input id="budget"
                name="budget"
                type="number"
                [(ngModel)]="inquiryData.budget"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">문의경로</label>
            <div class="col-lg-10">
              <ng-select [items]="['전화 문의','홈페이지 문의','숨고','크몽','케스팅 엔','위시켓','프리모아','채널톡','메타 인스턴트','기타']"
                placeholder="문의경로을 선택해주세요"
                [(ngModel)]="inquiryData.inflow"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">구분</label>
            <div class="col-lg-10">
              <ng-select [multiple]="true"
                [items]="['컨설팅','웹 개발','앱 개발','마케팅']"
                [(ngModel)]="inquiryData.service"></ng-select>
            </div>
          </div>


          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">방문경로</label>
            <div class="col-lg-10">
              <ng-select [multiple]="true"
                [items]="['네이버','구글','블로그','언론매체','유튜브','지인소개','기존고객','기타']"
                [(ngModel)]="inquiryData.visitRoute"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">문의타입</label>
            <div class="col-lg-10">
              <ng-select [items]="['미확인','아이디어 단계','단순견적문의','업체조사','급한프로젝트']"
                placeholder="문의하는 클라이언트 타입을 선택해주세요."
                [(ngModel)]="inquiryData.type"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">확인예산</label>
            <div class="col-lg-10">
              <ng-select
                [items]="['미확인','1,000만원 이하','2,000만원','3,000만원','4,000만원','5,000만원','6,000만원','7,000만원','8,000만원','9,000만원','1억원 이상']"
                placeholder="상담시 클라이언트에게 확인한 예산범위를 선택해주세요."
                [(ngModel)]="inquiryData.checkedBudget"></ng-select>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="phone"
              class="col-form-label col-lg-2">전화</label>
            <div class="col-lg-10">
              <input id="phone"
                name="phone"
                type="text"
                [(ngModel)]="inquiryData.phone"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="email"
              class="col-form-label col-lg-2">이메일</label>
            <div class="col-lg-10">
              <input id="email"
                name="email"
                type="text"
                [(ngModel)]="inquiryData.email"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="content"
              class="col-form-label col-lg-2">상세내용</label>
            <div class="col-lg-10">
              <textarea class="form-control"
                id="content"
                [(ngModel)]="inquiryData.content"
                rows="3"></textarea>
            </div>
          </div>
          <div class="row mb-4">
            <label class="col-form-label col-lg-2">파일첨부</label>
            <div class="col-lg-10">
              <ngx-dropzone (change)="onFileSelect($event)">
                <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of uploadFiles"
                  [removable]="true"
                  (removed)="onFileRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                  </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>

            <div class="table-responsive"
              *ngIf="inquiryData.updateFiles&& inquiryData.updateFiles.length>0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">파일명</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of inquiryData.updateFiles; let i = index">
                    <td>
                      {{getFileName(file) }}
                    </td>
                    <td class="text-end">
                      <button type="button"
                        class="btn btn-sm btn-outline-dark"
                        (click)="downloadFile(file)">다운로드</button>

                      <button type="button"
                        style="margin-left: 5px;"
                        class="btn btn-sm btn-danger"
                        (click)="deleteInquiryFile(file,i)">삭제</button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body"
          *ngIf="!isInquiry">
          <h4 class="card-title mb-4">문의 내용</h4>
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>

                <tr>
                  <th scope="row">문의일시 :</th>
                  <td>{{inquiry.dateCreated | date:'yyyy-MM-dd HH:mm'}}</td>
                </tr>
                <tr>
                  <th scope="row">고객명 :</th>
                  <td>{{ inquiry.username }}</td>
                </tr>
                <tr>
                  <th scope="row">회사명 :</th>
                  <td>{{ inquiry.company }}</td>
                </tr>
                <tr>
                  <th scope="row">프로젝트명 :</th>
                  <td>{{ inquiry.project }}</td>
                </tr>
                <tr>
                <tr>
                  <th scope="row">예산 :</th>
                  <td>{{ inquiry.budget }}</td>
                </tr>
                <tr>
                  <th scope="row">구분 :</th>
                  <td>{{ inquiry.service }}</td>
                </tr>
                <tr>
                  <th scope="row">문의 타입 :</th>
                  <td>
                    <div class="btn-group"
                      ngbDropdown>
                      <button type="button"
                        class="btn dropdown-toggle btn-light"
                        type="button"
                        id="dropdownMenuButton"
                        ngbDropdownToggle>{{inquiry.type?inquiry.type:'입력전'}} <i
                          class="mdi mdi-chevron-down"></i></button>
                      <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                          (click)="changeType(inquiry,'아이디어 단계')"
                          href="javascript: void(0);">아이디어 단계</a>
                        <a class="dropdown-item"
                          (click)="changeType(inquiry,'단순견적문의')"
                          href="javascript: void(0);">단순견적문의</a>
                        <a class="dropdown-item"
                          (click)="changeType(inquiry,'업체조사')"
                          href="javascript: void(0);">업체조사</a>
                        <a class="dropdown-item"
                          (click)="changeType(inquiry,'급한프로젝트')"
                          href="javascript: void(0);">급한프로젝트</a>
                        <a class="dropdown-item"
                          (click)="changeType(inquiry,'미확인')"
                          href="javascript: void(0);">미확인</a>
                      </div>
                    </div><!-- /btn-group -->
                  </td>
                </tr>
                <tr>
                  <th scope="row">확인 예산 :</th>
                  <td>
                    <div class="btn-group"
                      ngbDropdown>
                      <button type="button"
                        class="btn dropdown-toggle btn-light"
                        type="button"
                        id="dropdownMenuButton"
                        ngbDropdownToggle>{{inquiry.checkedBudget?inquiry.checkedBudget:'입력전'}} <i
                          class="mdi mdi-chevron-down"></i></button>
                      <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'1,000만원 이하')"
                          href="javascript: void(0);">1,000만원 이하</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'2,000만원')"
                          href="javascript: void(0);">2,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'3,000만원')"
                          href="javascript: void(0);">3,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'4,000만원')"
                          href="javascript: void(0);">4,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'5,000만원')"
                          href="javascript: void(0);">5,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'6,000만원')"
                          href="javascript: void(0);">6,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'7,000만원')"
                          href="javascript: void(0);">7,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'8,000만원')"
                          href="javascript: void(0);">8,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'9,000만원')"
                          href="javascript: void(0);">9,000만원</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'1억원 이상')"
                          href="javascript: void(0);">1억원 이상</a>
                        <a class="dropdown-item"
                          (click)="changeCheckBudget(inquiry,'미확인')"
                          href="javascript: void(0);">미확인</a>
                      </div>
                    </div><!-- /btn-group -->
                  </td>
                </tr>
                <tr *ngIf="inquiry.selectWeb">
                  <th scope="row">웹 상품:</th>
                  <td>{{ ('solutionWebs/' + inquiry.selectWeb | doc | async)?.title }} <button type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="goWebSolutionDetail(inquiry.selectWeb)">자세히보기</button></td>
                </tr>
                <tr *ngIf="inquiry.selectApp">
                  <th scope="row">스타터팩:</th>
                  <td>{{ ('solutionApps/' + inquiry.selectApp | doc | async)?.title }} </td>
                </tr>
                <tr>
                  <th scope="row">전화 :</th>
                  <td>{{ inquiry.phone }}</td>
                </tr>
                <tr>
                  <th scope="row">이메일 :</th>
                  <td>{{ inquiry.email }}</td>
                </tr>
                <tr>
                  <th scope="row">방문경로 :</th>
                  <td>{{ inquiry.visitRoute }}</td>
                </tr>
                <tr>
                  <th scope="row">유입정보 :</th>
                  <td>{{ inquiry.inflow }}</td>
                </tr>
                <tr *ngIf="inquiry.trackingDataId">
                  <th scope="row">접속정보 :</th>
                  <td style="width: 45px;"><button type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="openTrackDetail(inquiry.trackingDataId)">자세히보기</button></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 class="font-size-15 mt-4">첨부파일</h2>
          <p *ngIf="!inquiry.updateFiles ||  inquiry.updateFiles.length == 0">없음</p>

          <div class="table-responsive"
            *ngIf="inquiry.updateFiles&& inquiry.updateFiles.length>0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">파일명</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of inquiry.updateFiles">
                  <td>
                    {{getFileName(file) }}
                  </td>
                  <td>
                    <button type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="downloadFile(file)">다운로드</button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <h2 class="font-size-15 mt-4">상세내용</h2>
          <p style="white-space:pre-wrap ;">{{ inquiry.content }}</p>
        </div>
        <div class="card-footer bg-transparent border-top">
          <div class="text-center">
            <a href="javascript: void(0);"
              (click)="editInquiry(inquiry)"
              class="btn btn-dark"
              *ngIf="!isInquiry">문의 수정</a>
            <a href="javascript: void(0);"
              (click)="saveInquiry(inquiryData)"
              class="btn btn-primary"
              *ngIf="isInquiry">수정사항 저장</a>
          </div>
        </div>
      </div>


      <!-- end card -->
      <div class="card"
        *ngIf="inquiry$ | async  as inquiry">
        <div class="card-body"
          *ngIf="(estimates$ | async) as estimates">
          <div class="d-flex align-items-start">
            <h4 class="card-title mb-4">견적서 리스트</h4>
            <button class="btn btn-success ms-auto"
              (click)="addEstimate(inquiry)">견적서 등록</button>
            <button class="btn btn-info"
              style="margin-left: 4px;"
              (click)="sendEstimateAligo(inquiry,estimates)">알림 보내기</button>
          </div>
          <div class="text-center"
            *ngIf="(estimates$ | async) as estimates">
            <div class="row justify-content-center"
              *ngIf="estimates?.length == 0">
              <p class="mt-2 fw-semibold">등록된 견적서가 없습니다.</p>
            </div>

            <div class="table-responsive"
              *ngIf="(estimates$ | async)?.length > 0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">등록일</th>
                    <th scope="col">등록자</th>
                    <th scope="col">견적서</th>
                    <th scope="col"></th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ( estimates$ | async )">
                    <td>
                      {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                    </td>
                    <td>
                      {{item.createdBy.name}}
                    </td>
                    <td>
                      {{getFileName(item.file) }}
                    </td>
                    <td>
                      <button type="button"
                        class="btn btn-sm btn-outline-dark"
                        (click)="goPdfViewer(item.file)">보기</button>

                      <button type="button"
                        style="margin-left: 4px;"
                        class="btn btn-sm btn-danger"
                        (click)="deleteEstimate(item)">삭제</button>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">히스토리</h4>
          <div class="text-center"
            *ngIf="(statusHistories$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">히스토리가 없습니다.</p>
            </div>
          </div>
          <div class="table-responsive"
            *ngIf="(statusHistories$ | async)?.length > 0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">처리일시</th>
                  <th scope="col">변동상태</th>
                  <th scope="col">처리자</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ( statusHistories$ | async )">
                  <td>
                    {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                  </td>
                  <td>
                    {{item.oldStatus}} -> {{item.newStatus}}
                  </td>
                  <td>
                    {{item.createdBy.name}}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card"
        *ngIf="inquiry$ | async  as inquiry">
        <div class="card-body">
          <div class="row m-0">
            <label for="example-text-input"
              class="col-md-4 col-form-label">문의 처리</label>
            <div class="col-md-8 text-end p-0">
              <div class="d-flex flex-wrap gap-3">
                <button (click)="deleteInquiry(inquiry)"
                  class="btn btn-danger">삭제</button>
                <button (click)="changeStatus(inquiry,'계약 완료')"
                  class="btn btn-info">계약 완료</button>
                <button (click)="changeStatus(inquiry,'상담 종료')"
                  class="btn btn-dark">상담 종료</button>

              </div>
            </div>
          </div>



        </div>

      </div>
      <div class="card consulting"
        *ngIf="inquiry$ | async  as inquiry">
        <div class="card-body">
          <h4 class="card-title mb-4">상담 내역</h4>
          <div class="text-center"
            *ngIf="(consultings$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">상담 내역이 없습니다.</p>
            </div>
          </div>
          <ngb-accordion [closeOthers]="true"
            activeIds="static-1"
            class="custom-accordion">
            <ngb-panel id="static-{{i+1}}"
              *ngFor="let item of (consultings$ | async); let i = index">
              <ng-template ngbPanelTitle>
                <div class="accordion-list">

                  <div>{{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}} - {{item.createdBy.name}}
                    <span class="badge rounded-pill badge-soft-success font-size-11">{{item.type}}</span>
                  </div>
                </div>

              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="item.isEditor">
                  <div class="form-group row mb-4">
                    <label for="project"
                      class="col-form-label col-2">상담 구분</label>
                    <div class="col-10">
                      <ng-select [items]="['부재1차','부재2차','전화 상담','방문/화상 상담','견적서 발송','계약진행','계약 완료','상담 종료','보류','기타']"
                        [(ngModel)]="item.consultingType"></ng-select>
                    </div>
                  </div>
                  <ckeditor [editor]="Editor"
                    [(ngModel)]="item.description"></ckeditor>
                  <div class="row text-end">
                    <button class="btn btn-primary"
                      (click)="saveEditConsulting(item)">수정내용 저장</button>
                  </div>
                </div>
                <div *ngIf="!item.isEditor">
                  <div class="form-group row mb-4">
                    <h4 class="col-lg-2">상담 구분</h4>
                    <div class="col-lg-10">
                      {{item.consultingType?item.consultingType:'구분없음'}}
                    </div>
                  </div>
                  <h4 class="mb-4">상세 내용</h4>

                  <div [innerHTML]="item.description"></div>
                  <div class="text-center">
                    <div class="d-flex flex-wrap gap-2">
                      <button class="btn btn-dark mt-2"
                        (click)="editConsulting(item)">
                        상담내용
                        수정</button>
                      <button class="btn btn-info mt-2"
                        *ngIf="item.consultingType ==='부재1차' || item.consultingType ==='부재2차'"
                        (click)="sendConsultingAligo(inquiry,item)"> {{item.consultingType}}
                        메시지
                        발송</button>
                    </div>

                  </div>
                </div>

              </ng-template>
            </ngb-panel>

          </ngb-accordion>
        </div>

      </div>
      <div class="card"
        *ngIf="inquiry$ | async as inquiry">
        <div class="card-body">
          <div class="d-flex align-items-start">
            <h4 class="card-title mb-4">방문/화상 상담 일정</h4>
            <button class="btn btn-success ms-auto"
              (click)="addConsultSchedule(inquiry)">일정 등록</button>
          </div>
          <div class="text-center"
            *ngIf="meeting$ | async as meeting">
            <div class="row justify-content-center"
              *ngIf="meeting?.length == 0">
              <p class="mt-2 fw-semibold">등록된 일정이 없습니다.</p>
            </div>

            <div class="table-responsive"
              *ngIf="meeting?.length > 0">
              <table class="table table-nowrap mb-0">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">미팅일자</th>
                    <th scope="col">구분</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of meeting">
                    <td>
                      {{getDate(item)}}
                    </td>
                    <td>
                      {{item.isCanceled? item.isVideoMeeting?'화상상담':'방문상담'+'-취소':item.isVideoMeeting?'화상상담':'방문상담'}}
                    </td>
                    <td class="text-end">
                      <button type="button"
                        (click)="editConsultSchedule(item,inquiry)"
                        class="btn btn-sm btn-outline-dark me-2">일정변경</button>
                      <button type="button"
                        (click)="resumeMeeting(item)"
                        *ngIf="item.isCanceled"
                        class="btn btn-sm btn-outline-danger me-2">취소해제</button>
                      <button type="button"
                        *ngIf="!item.isCanceled"
                        (click)="cancelMeeting(item)"
                        class="btn btn-sm btn-outline-danger me-2">일정취소</button>
                      <button type="button"
                        (click)="sendAligo(inquiry,item)"
                        class="btn btn-sm btn-outline-info ">알림보내기</button>
                    </td>
                    <td>


                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex align-items-start">
            <h4 class="card-title mb-4">관련 업무 리스트</h4>
            <button class="btn btn-success ms-auto"
              (click)="addTaskList('add')">업무 생성</button>
          </div>
          <div class="text-center"
            *ngIf="(taskList$ | async) as taskList">
            <div class="row justify-content-center"
              *ngIf="taskList?.length == 0">
              <p class="mt-2 fw-semibold">등록된 관련 업무가 없습니다.</p>
            </div>

            <div class="table-responsive"
              *ngIf="taskList&& taskList.length > 0">
              <table class="table table-nowrap align-middle mb-0">
                <tbody>
                  <tr *ngFor="let list of taskList; let i = index ">
                    <!-- <td style="width: 40px;"> -->
                    <!-- <div class="form-check font-size-16">
                            <input type="checkbox"
                              class="form-check-input"
                              [checked]="list.checked">
                            <label class="form-check-label"
                              for="customCheck{{list.index}}"></label>
                          </div> -->
                    <!-- </td> -->
                    <td>
                      <h2 class="text-truncate font-size-14 m-0">{{list.dateCreated |
                        date:'yyyy-MM-dd HH:mm' }}</h2>
                    </td>
                    <td>
                      <h2 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);"
                          class="text-dark">{{list.taskType}} - {{list.name}}</a></h2>
                    </td>

                    <!-- <td>
                          <h2 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);"
                              class="text-dark">{{list.taskType}} - {{list.name}}</a></h2>
                        </td> -->

                    <!-- <td>
                          <div class="avatar-group">
                            <div class="avatar-group-item">
                              <a href="javascript: void(0);"
                                class="d-inline-block">
                                <img src="{{list.images[0]}}"
                                  class="rounded-circle avatar-xs"
                                  alt="">
                              </a>
                            </div>
                            <div class="avatar-group-item"
                              *ngIf="list.images[1]">
                              <a href="javascript: void(0);"
                                class="d-inline-block">
                                <img src="{{list.images[1]}}"
                                  class="rounded-circle avatar-xs"
                                  alt="">
                              </a>
                            </div>
                          </div>
                        </td> -->
                    <td>
                      <div class="text-center">
                        <span class="badge badge-soft-success font-size-12"
                          [ngClass]="list.status !== '대기중' ? 'badge-soft-success' : 'badge-soft-danger'">
                          {{list.status}}</span>
                      </div>
                    </td>
                    <!-- <td>
                          <h2 class="text-truncate font-size-14 m-0"><a href="javascript: void(0);"
                              class="text-dark">{{list.taskType}} - {{list.name}}</a></h2>
                        </td> -->

                    <td>
                      {{list.createdBy?.name }}
                    </td>
                    <td>
                      <div class="text-end">
                        <button type="button"
                          class="btn btn-outline-dark btn-sm btn-rounded"
                          (click)="addTaskList('detail',list,i)">상세
                          보기</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>

          </div>

        </div>
      </div>

      <div class="card"
        *ngIf="inquiry$ | async as inquiry">
        <div class="card-body">
          <h4 class="card-title mb-4">상담 정보</h4>
          <h2 class="font-size-15 mt-4">상담내용 입력</h2>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-2">상담 구분</label>
            <div class="col-10">
              <ng-select [items]="['부재1차','부재2차','전화','방문/화상','견적서 발송','계약진행','프로젝트 완료','기타']"
                [(ngModel)]="consultingType"></ng-select>
            </div>
          </div>
          <div class="row mb-4">
            <ckeditor [editor]="Editor"
              [(ngModel)]="description"></ckeditor>

          </div>
          <div class="text-center">
            <button (click)="createdConsulting(inquiry)"
              class="btn btn-primary">상담내용 저장</button>

          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">메시지 발송 내역</h4>
          <div class="text-center"
            *ngIf="(messageHistories$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">히스토리가 없습니다.</p>
            </div>
          </div>
          <div class="table-responsive"
            *ngIf="(messageHistories$ | async)?.length > 0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">발송일시</th>
                  <th scope="col">발송유형</th>
                  <th scope="col">발송자</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ( messageHistories$ | async )">
                  <td>
                    {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                  </td>
                  <td>
                    {{item.type}}
                  </td>
                  <td>
                    {{item.createdBy?.name}}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>