import { MbscModule } from "@mobiscroll/angular";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";

import { environment } from "../environments/environment";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbModule,
  NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { NgxPaginationModule } from "ngx-pagination";
import { MatCardModule } from "@angular/material/card";

import { ExtrapagesModule } from "./extrapages/extrapages.module";
// import { NgxDropzoneModule } from "ngx-dropzone";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";

import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import {
  AngularFireFunctionsModule,
  REGION,
} from "@angular/fire/compat/functions";
import { NgxSpinnerModule } from "ngx-spinner";
import { UserDetailComponent } from "./components/user-detail/user-detail.component";
import { ClientDetailComponent } from "./components/client-detail/client-detail.component";
import { ContractDetailComponent } from "./components/contract-detail/contract-detail.component";
import { InquiryDetailComponent } from "./components/inquiry-detail/inquiry-detail.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchClientComponent } from "./components/search-client/search-client.component";
import { AddScheduleComponent } from "./components/add-schedule/add-schedule.component";
import { AddTaskListComponent } from "./components/add-task-list/add-task-list.component";
import { MatSelectModule } from "@angular/material/select";
import { NgSelectModule } from "@ng-select/ng-select";
import { PipesModule } from "./core/pipes/pipe.module";
import { LoaderComponent } from "./components/loader/loader.component";
import { LoadingInterceptor } from "./loading.interceptor";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxDropzoneModule } from "ngx-dropzone";
import { AddEstimateComponent } from "./components/add-estimate/add-estimate.component";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { AddUserComponent } from "./components/add-user/add-user.component";
import { DragAndDropManagerService } from "./drag-and-drop-manager.service";
import { MatButtonModule } from "@angular/material/button";
import { CsDetailComponent } from "./components/cs-detail/cs-detail.component";
import { RequestEducationDetailComponent } from "./components/request-education-detail/request-education-detail.component";
import { RentTestphoneDetailComponent } from "./components/rent-testphone-detail/rent-testphone-detail.component";
import { RequestBookDetailComponent } from "./components/request-book-detail/request-book-detail.component";
import { MeetingDetailComponent } from "./components/meeting-detail/meeting-detail.component";
import { EditContractComponent } from "./components/edit-contract/edit-contract.component";
import { ProjectDetailComponent } from "./components/project-detail/project-detail.component";
import { EnrollPrComponent } from "./components/enroll-project/enroll-project.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { PdfViewerComponent } from "./components/pdf-viewer/pdf-viewer.component";
import { OrderDetailComponent } from "./components/order-detail/order-detail.component";
import { StoreOrderDetailComponent } from "./components/store-order-detail/store-order-detail.component";
import { SolutionOrderDetailComponent } from "./components/solution-order-detail/solution-order-detail.component";
import { AddFileComponent } from "./components/add-file/add-file.component";
import { AppDetailComponent } from "./components/app-detail/app-detail.component";
import { MaterialModule } from "./pages/material.module";
import { FaqModalComponent } from "./components/faq-modal/faq-modal.component";
import { AddProjectComponent } from "./components/add-project/add-project.component";
import { WebCategoryMangementComponent } from "./components/web-category-mangement/web-category-mangement.component";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DndModule } from "ngx-drag-drop";
import { WebSolutionDetailComponent } from "./components/web-solution-detail/web-solution-detail.component";
import { ContactCompanyDetailComponent } from "./components/contact-company-detail/contact-company-detail.component";
import { AddPartnershipDocumentsComponent } from "./components/add-partnership-documents/add-partnership-documents.component";
import { SearchContactCompanyComponent } from "./components/search-contact-company/search-contact-company.component";
import { ContractWithPartnershipDetailComponent } from "./components/contract-with-partnership-detail/contract-with-partnership-detail.component";
import { PartnershipContractListComponent } from "./components/partnership-contract-list/partnership-contract-list.component";
import { AddReceiptComponent } from "./components/add-receipt/add-receipt.component";
import { AppCategoryManagementComponent } from "./components/app-category-management/app-category-management.component";
import { AddPortfolioComponent } from "./components/add-portfolio/add-portfolio.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { TrackDataDetailComponent } from "./components/track-data-detail/track-data-detail.component";

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    UserDetailComponent,
    ClientDetailComponent,
    ProjectDetailComponent,
    ContractDetailComponent,
    InquiryDetailComponent,
    SearchClientComponent,
    AddScheduleComponent,
    AddTaskListComponent,
    LoaderComponent,
    AddEstimateComponent,
    AddUserComponent,
    CsDetailComponent,
    RequestEducationDetailComponent,
    RentTestphoneDetailComponent,
    RequestBookDetailComponent,
    MeetingDetailComponent,
    EditContractComponent,
    ProjectDetailComponent,
    EnrollPrComponent,
    PdfViewerComponent,
    OrderDetailComponent,
    StoreOrderDetailComponent,
    SolutionOrderDetailComponent,
    AddFileComponent,
    AppDetailComponent,
    FaqModalComponent,
    AddProjectComponent,
    WebCategoryMangementComponent,
    WebSolutionDetailComponent,
    ContactCompanyDetailComponent,
    AddPartnershipDocumentsComponent,
    SearchContactCompanyComponent,
    ContractWithPartnershipDetailComponent,
    PartnershipContractListComponent,
    AddReceiptComponent,
    AppCategoryManagementComponent,
    AddPortfolioComponent,
    TrackDataDetailComponent,
  ],
  imports: [
    MbscModule,
    NgxTippyModule,
    BrowserModule,
    DropzoneModule,
    DndModule,
    Ng2SearchPipeModule,
    UiSwitchModule,
    HttpClientModule,
    NgbDropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    // NgxDropzoneModule,
    CarouselModule,
    MatCardModule,
    PdfViewerModule,
    MatButtonModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgbNavModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbTooltipModule,
    AngularFireMessagingModule,
    ScrollToModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    NgxSpinnerModule,
    NgbModule,
    PipesModule,
    NgxPaginationModule,
    NgSelectModule,
    NgxDropzoneModule,
    MaterialModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    DragAndDropManagerService,
    { provide: REGION, useValue: "asia-northeast3" },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  exports: [NgxSpinnerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
