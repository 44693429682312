import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/core/services/auth.service";
import { UserService } from "src/app/core/services/user.service";
import { DbService } from "src/app/core/services/db.service";
import { Notice } from "src/app/core/models/notice.model";
import { NgbDropdown, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { InquiryDetailComponent } from "src/app/components/inquiry-detail/inquiry-detail.component";
import { FcmService } from "src/app/core/services/fcm.service";
import { CsDetailComponent } from "src/app/components/cs-detail/cs-detail.component";
import { RequestEducationDetailComponent } from "src/app/components/request-education-detail/request-education-detail.component";
import { RentTestphoneDetailComponent } from "src/app/components/rent-testphone-detail/rent-testphone-detail.component";
import { MeetingDetailComponent } from "src/app/components/meeting-detail/meeting-detail.component";
import { OrderDetailComponent } from "src/app/components/order-detail/order-detail.component";
import { SolutionOrderDetailComponent } from "src/app/components/solution-order-detail/solution-order-detail.component";
import { StoreOrderDetailComponent } from "src/app/components/store-order-detail/store-order-detail.component";
import { AddReceiptComponent } from "src/app/components/add-receipt/add-receipt.component";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  @ViewChild("myDrop", { static: false }) myDrop: NgbDropdown;

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  userNotioces$;
  types = [
    {
      type: "inquiry",
      icon: "bx bx-male",
    },
    {
      type: "orderStore",
      icon: "bx bx-male",
    },
    {
      type: "orderSolution",
      icon: "bx bx-male",
    },
    {
      type: "taskList",
      icon: "bx bx-notepad",
    },
    {
      type: "meeting",
      icon: "bx bxs-calendar",
    },
    {
      type: "asRequest",
      icon: "bx bx-first-aid",
    },
    {
      type: "asCompleted",
      icon: "bx bx-first-aid",
    },
    {
      type: "asChecked",
      icon: "bx bx-first-aid",
    },
    {
      type: "asFeedBackToCreater",
      icon: "bx bx-first-aid",
    },
    {
      type: "asFeedBackToManager",
      icon: "bx bx-first-aid",
    },
    {
      type: "educationRequest",
      icon: "bx bx-door-open",
    },
    {
      type: "educationRequestApproval",
      icon: "bx bx-door-open",
    },

    {
      type: "educationRequestStatus",
      icon: "bx bx-door-open",
    },
    {
      type: "newRequest",
      icon: "bx bx-user-pin",
    },
    {
      type: "requestApproval",
      icon: "bx bx-user-pin",
    },
    {
      type: "requestDeny",
      icon: "bx bx-user-pin",
    },
    {
      type: "requestHold",
      icon: "bx bx-user-pin",
    },
    {
      type: "requestEnd",
      icon: "bx bx-user-pin",
    },
    {
      type: "rentTestphone",
      icon: "bx bx-mobile",
    },
    {
      type: "returnTestphone",
      icon: "bx bx-mobile",
    },
    {
      type: "extensionRentTestphone",
      icon: "bx bx-mobile",
    },

    {
      type: "requestReturnTestphone",
      icon: "bx bx-mobile",
    },
  ];

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    public authService: AuthService,
    public userService: UserService,
    private db: DbService,
    private modalService: NgbModal,
    private fcm: FcmService
  ) {}

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  async ngOnInit() {
    await this.userService.init();
    this.userNotioces$ = this.db.collection$("notices", (ref) =>
      ref
        .where("userId", "==", this.userService.user.id)
        .where("checked", "==", false)
        .orderBy("dateCreated", "desc")
    );

    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
  }

  getIcon(type: string) {
    const find = this.types.find((item) => item.type == type);
    return find.icon;
  }

  updateCheckAll(userNotioces: Notice[]) {
    console.log("myDrop", this.myDrop);

    userNotioces.forEach((item) => {
      this.db.updateAt(`notices/${item.id}`, { checked: true });
    });
  }

  async goNoticeDetail(notice: Notice) {
    this.db.updateAt(`notices/${notice.id}`, { checked: true });
    if (notice.type == "inquiry") {
      const modalRef = this.modalService.open(InquiryDetailComponent, {
        fullscreen: true,
        scrollable: true,
      });
      modalRef.componentInstance.inquiryId = notice.refId;
    } else if (notice.type == "taskList") {
      this.router.navigate(["/consulting/consulting-dashboard"]);
    } else if (
      notice.type == "asRequest" ||
      notice.type == "asCompleted" ||
      notice.type == "asChecked" ||
      notice.type == "asFeedBackToCreater" ||
      notice.type == "asFeedBackToManager"
    ) {
      const modalref = this.modalService.open(CsDetailComponent, {
        size: "lg",
        centered: true,
      });
      modalref.componentInstance.csId = notice.refId;
    } else if (notice.type == "orderStore") {
      const modalref = this.modalService.open(StoreOrderDetailComponent, {
        fullscreen: true,
        scrollable: true,
      });
      modalref.componentInstance.orderId = notice.refId;
    } else if (notice.type == "orderSolution") {
      const modalref = this.modalService.open(SolutionOrderDetailComponent, {
        fullscreen: true,
        scrollable: true,
      });
      modalref.componentInstance.orderId = notice.refId;
    } else if (
      notice.type == "rentTestphone" ||
      notice.type == "returnTestphone" ||
      notice.type == "extensionRentTestphone" ||
      notice.type == "requestReturnTestphone"
    ) {
      // this.router.navigate(["/memberService/testphone"]);
      const modalref = this.modalService.open(RentTestphoneDetailComponent, {
        size: "lg",
        centered: true,
      });
      modalref.componentInstance.rentTestphoneId = notice.refId;
    } else if (notice.type == "meeting") {
      // this.router.navigate(["/memberService/testphone"]);
      const modalref = this.modalService.open(MeetingDetailComponent, {
        size: "lg",
        centered: true,
      });
      modalref.componentInstance.meetingId = notice.refId;
    } else if (
      notice.type == "newRequest" ||
      notice.type == "requestApproval" ||
      notice.type == "requestDeny" ||
      notice.type == "requestHold" ||
      notice.type == "requestComplete"
    ) {
      if (notice.refType == "교육") {
        const modalref = this.modalService.open(
          RequestEducationDetailComponent,
          {
            size: "lg",
            centered: true,
          }
        );
        modalref.componentInstance.requestEducationId = notice.refId;
      } else if (notice.refType == "도서구매") {
        const modalref = this.modalService.open(
          RequestEducationDetailComponent,
          {
            size: "lg",
            centered: true,
          }
        );
        modalref.componentInstance.requestBookId = notice.refId;
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logoutUser();
    this.router.navigate(["/account/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  addReciept() {
    const modalRef = this.modalService.open(AddReceiptComponent, {
      size: "xl",
      centered: true,
    });
  }
}
