import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { first } from "rxjs/operators";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  @Input() public user;
  @Input() public userId;
  @Input() public type;
  public loading = false;
  isEdit = false;
  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private alertService: AlertService,
    private loderService: LoaderService,
    public activeModal: NgbActiveModal,
    private db: DbService
  ) {}
  /**
   * Returns form
   */
  get form() {
    return this.userForm.controls;
  }

  userForm: FormGroup;
  positionType = [
    { value: "", name: "구분을 선택해주세요.", disabled: true },
    { value: "오류", name: "오류", disabled: false },
    { value: "개선", name: "개선", disabled: false },
  ];

  // bread crumb items
  breadCrumbItems: Array<{}>;
  // Form submition
  submit: boolean;

  config: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    maxFilesize: 50,
    acceptedFiles: "image/*",
    method: "POST",
    uploadMultiple: false,
    accept: (file) => {
      this.onAccept(file);
    },
  };
  image = "";
  file = "";
  departments$;
  ngOnInit() {
    this.breadCrumbItems = [
      { label: "사용자관리" },
      { label: "사용자등록", active: true },
    ];
    this.userForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      email: ["", [Validators.required]],
      position: ["", [Validators.required]],
      type: ["", [Validators.required]],
      level: ["", [Validators.required]],
      duty: [[], [Validators.required]],
      department: [[], [Validators.required]],
    });
    this.submit = false;
    this._fetchData();
  }

  private async _fetchData() {
    this.departments$ = this.db.collection$("departments");
    if (this.type == "edit") {
      this.userForm.get("name").setValue(this.user.name);
      this.userForm.get("email").setValue(this.user.email);
      this.userForm.get("position").setValue(this.user.position);
      this.userForm.get("type").setValue(this.user.type);
      this.userForm.get("duty").setValue(this.user.duty);
      this.userForm
        .get("department")
        .setValue(this.user.department ? this.user.department : []);
      this.userForm
        .get("level")
        .setValue(this.user.level ? this.user.level : "");
    }
    await this.dataService.inIt();
  }

  onAccept(file: any) {
    // this.image = file.name;
    this.file = file;
  }

  async validSubmit() {
    this.submit = true;
    if (this.userForm.invalid) {
      return false;
    }
    this.loderService.setLoading(true);
    const updateData = {
      dateCreated: new Date().toISOString(),
      name: this.userForm.get("name").value,
      email: this.userForm.get("email").value,
      type: this.userForm.get("type").value,
      position: this.userForm.get("position").value,
      duty: this.userForm.get("duty").value,
      department: this.userForm.get("department").value,
      level: this.userForm.get("level").value,
    };
    if (this.type == "edit") {
      delete updateData.dateCreated;
      await this.db.updateAt(`users/${this.user.id}`, updateData);
      this.loderService.setLoading(false);
      this.activeModal.close();
      return this.alertService.success("사용자가 정보가 수정되었습니다.");
    }
    const update = await this.dataService
      .createUser(updateData)
      .catch((error) => {
        this.loderService.setLoading(false);
        this.alertService.error("사용자 등록에 실패하였습니다.");
        this.activeModal.close();
      });
    if (update) {
      this.loderService.setLoading(false);
      this.alertService.success("사용자가 성공적으로 등록되었습니다.");
      this.activeModal.close();
    }
  }
  passBack() {
    this.activeModal.close();
  }

  async leaveUser() {
    const confirm = await this.alertService.confirm(
      "퇴사처리",
      `${this.user.name}님을 정말 퇴사처리 하겠습니까?`,
      "퇴사처리"
    );
    if (confirm) {
      this.loderService.setLoading(true);
      this.user.dateLeaved = new Date().toISOString();
      await this.db.updateAt(`leaveUsers/${this.user.id}`, this.user);
      await this.db.delete(`users/${this.user.id}`);
      this.loderService.setLoading(false);
      this.alertService.success("퇴사처리가 완료되었습니다.");
      this.activeModal.close();
    }
  }
}
