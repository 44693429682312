<div class="modal-header">
    <h2 class="modal-title mb-0">주문 상세</h2>
    <button type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="passBack()"></button>
</div>
<div class="modal-body"
    *ngIf="order">

    <div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">상태</label>
            <div class="col-md-10">
                <!-- <div class="form-control">{{order.status}}</div> -->
                <div class="btn-group"
                    ngbDropdown>
                    <button type="button"
                        class="btn dropdown-toggle"
                        [ngClass]=" { 'btn-primary': order.status === '주문대기', 'btn-secondary': order.status === '주문접수',
  'btn-success': order.status === '결제완료', 'btn-info': order.status === '상품준비중',
  'btn-danger': order.status === '환불', 'btn-dark': order.status === '완료'}"
                        ngbDropdownToggle>{{order.status?order.status:'주문접수'}} <i
                            class="mdi mdi-chevron-down"></i></button>
                    <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'주문대기')"
                            href="javascript: void(0);">주문대기</a>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'주문접수')"
                            href="javascript: void(0);">주문접수</a>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'결제완료')"
                            href="javascript: void(0);">결제완료</a>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'상품준비중')"
                            href="javascript: void(0);">상품준비중</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'완료')"
                            href="javascript: void(0);">완료</a>
                        <a class="dropdown-item"
                            (click)="changeStatus(order,'환불')"
                            href="javascript: void(0);">환불</a>

                    </div>
                </div><!-- /btn-group -->
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">주문일시</label>
            <div class="col-md-10">
                <div class="form-control">{{order.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">주문자명</label>
            <div class="col-md-10">
                <div class="form-control">{{order.name}}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">이메일</label>
            <div class="col-md-10">
                <div class="form-control">{{order.email?order.email:'정보없음' }}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">연락처</label>
            <div class="col-md-10">
                <div class="form-control">{{order.phone}}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">주문상품</label>
            <div class="col-md-10">
                <div class="form-control">{{order.product}}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">옵션</label>
            <div class="col-md-10">
                <div class="form-control">{{order.option?.model}}</div>
            </div>
        </div>
        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">앱등록 여부</label>
            <div class="col-md-10">
                <div class="form-control">{{order.isStore?'등록요청':'요청안함'}}</div>
            </div>
        </div>

        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">디자인템플릿 여부</label>
            <div class="col-md-10">
                <div class="form-control">{{order.isTemplate?'디자인템플릿 요청':'요청안함'}}</div>
            </div>
        </div>


        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">요청사항</label>
            <div class="col-md-10">
                <div class="form-control">{{order.memo}}</div>
            </div>
        </div>

        <div class="form-group row mb-4">
            <label for="billing-name"
                class="col-md-2 col-form-label">구매금액</label>
            <div class="col-md-10">
                <div class="form-control">{{order.totalPrice | number}}</div>
            </div>
        </div>

    </div>



</div>
<div class="modal-footer"
    *ngIf="order">
    <button class="btn btn-success"
        *ngIf="order.status !== '주문접수'"
        (click)="checkOrder()">주문접수</button>
    <button class="btn btn-info">수정</button>
    <button class="btn btn-danger"
        (click)="deleteOrder()">삭제</button>
    <button class="btn btn-secondary"
        (click)="passBack()">닫기</button>
</div>
