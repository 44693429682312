import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { StoreOrder } from "src/app/core/models/storeOrder.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"],
})
export class OrderDetailComponent implements OnInit {
  @Input() public orderId;
  order: StoreOrder;
  feedBack;
  isManager = false;
  constructor(
    private db: DbService,
    public activeModal: NgbActiveModal,
    public dataservice: DataService,
    private alertService: AlertService
  ) {}

  async ngOnInit() {
    this.order = await this.db
      .doc$(`orderStore/${this.orderId}`)
      .pipe(first())
      .toPromise();
  }

  async changeStatus(storeOrder: StoreOrder, status: string) {
    if (storeOrder.status === status) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 주문 상태를 "${status}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        storeOrderId: storeOrder.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: storeOrder.status ? storeOrder.status : "접수",
        newStatus: status,
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderStore/${storeOrder.id}`, {
        status: status,
      });
      storeOrder.status = status;
      this.alertService.basicMessage("상태가 변경되었습니다.");
    }
  }

  async checkOrder() {
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 주문을 접수하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        storeOrderId: this.order.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: this.order.status ? this.order.status : "주문접수",
        newStatus: "주문접수",
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderStore/${this.order.id}`, {
        status: "주문접수",
      });
      this.order.status = "주문접수";
      this.alertService.basicMessage("해당 주문이 접수되었습니다.");
    }
  }

  async deleteOrder() {
    const confirm = await this.alertService.confirm(
      "주문삭제",
      `해당 주문을 삭제하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const deleteOrder = {
        storeOrderId: this.order.id,
        dateDeleted: new Date().toISOString(),
        date: new Date(),
        deletedBy: this.dataservice.userId,
        ...this.order,
      };
      await this.db.updateAt("deletedStoreOrder", deleteOrder);
      await this.db.delete(`orderStore/${this.order.id}`);
      this.alertService.basicMessage("해당 주문이 삭제되었습니다.");
      this.activeModal.close("delete");
    }
  }

  passBack() {
    this.activeModal.close(this.order);
  }
}
