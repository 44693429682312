import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
import { ContractWithPartnership } from "src/app/core/models/contractWithPartnerShip.models";

@Component({
  selector: "app-contract-with-partnership-detail",
  templateUrl: "./contract-with-partnership-detail.component.html",
  styleUrls: ["./contract-with-partnership-detail.component.scss"],
})
export class ContractWithPartnershipDetailComponent implements OnInit {
  @Input() contractWithPartnership: ContractWithPartnership;
  contactCompany: ContactCompany;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.contactCompany = this.contractWithPartnership
      .contactCompanyId as ContactCompany;
  }

  passBack() {
    this.activeModal.close();
  }

  caculate(contractWithPartnership) {
    let settlement =
      contractWithPartnership?.amount *
      (this.contractWithPartnership.charge / 100);
    return settlement;
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  downloadFile(url) {
    window.open(url);
  }
}
