<!-- <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">고객 상세</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">교육 신청 정보</h4>
      <!--
          <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
              person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
      <div class="table-responsive table-nowrap">
        <table class="table  mb-0">
          <tbody>
            <tr>
              <th scope="row">상태 :</th>
              <td> <span class="badge font-size-13"
                  [ngClass]="{'bg-success': requestEducation?.status  === '승인',
            'bg-secondary': requestEducation?.status === '대기중','bg-dark': requestEducation?.status  === '보류','bg-danger': requestEducation?.status  === '반려'}">
                  {{requestEducation?.status }}</span>
              </td>
            </tr>
            <tr>
              <th scope="row">신청일 :</th>
              <td>{{requestEducation?.dateCreated | date:'yyyy-MM-dd' }}</td>
            </tr>
            <tr>
              <th scope="row">신청자 :</th>
              <td>{{('users/' +
                requestEducation?.createdBy
                | doc | async)?.name}}</td>
            </tr>
            <tr>
              <th scope="row">신청구분 :</th>
              <td>
                {{requestEducation?.type}}
              </td>
            </tr>
            <tr>
              <th scope="row">신청 교육명 :</th>
              <td>
                {{requestEducation?.title}}
              </td>
            </tr>
            <tr>
              <th scope="row">교육유형 :</th>
              <td>
                {{requestEducation?.edutionType}}
              </td>
            </tr>
            <tr>
              <th scope="row">교육기관명 :</th>
              <td>
                {{requestEducation?.educationCompany}}
              </td>
            </tr>

            <tr>
              <th scope="row">교육상세 :</th>
              <td>
                <div style="white-space:pre-wrap ;">
                  {{requestEducation?.description}}

                </div>
              </td>
            </tr>
            <tr *ngIf="requestEducation?.headComment">
              <th scope="row">조직장 코멘트 :</th>
              <td>
                {{requestEducation?.headComment}}
              </td>
            </tr>
            <tr *ngIf="requestEducation?.managementComment">
              <th scope="row">운영관리 코멘트 :</th>
              <td>
                {{requestEducation?.managementComment}}
              </td>
            </tr>
            <tr *ngIf="requestEducation?.approvedBy">
              <th scope="row">승인자 :</th>
              <td>{{('users/' +
                requestEducation.approvedBy
                | doc | async)?.name}}</td>
            </tr>
            <tr *ngIf="requestEducation?.isHeadApproved">
              <th scope="row">승인일 :</th>
              <td>{{requestEducation?.dateApproved | date:'yyyy-MM-dd' }}</td>
            </tr>
            <tr *ngIf="requestEducation?.dateCompleted">
              <th scope="row">완료일 :</th>
              <td>{{requestEducation?.dateCompleted | date:'yyyy-MM-dd' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <button type="submit"
    (click)="passBack()"
    class="btn btn-primary w-100">닫기</button>
</div>