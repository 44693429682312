/** @format */

import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import firebase from "firebase/compat/app";
import moment from "moment";
import { first, map, shareReplay, switchMap, take } from "rxjs/operators";
import { Cs, csFeedBack } from "../models/cs.model";
import { Department } from "../models/department.model";
import { Estimate } from "../models/estimate.model";
import { Inquiry } from "../models/inquiry.model";
import { Meeting } from "../models/meeting.model";
import { RentTestphone } from "../models/rentTestphone.model";
import { RequestBook } from "../models/requestBook.model";
import { RequestEducation } from "../models/requestEducation.model";
import { TaskList } from "../models/task.model";
import { Testphone } from "../models/testphone.model";
import { User } from "../models/user.model";
import { AuthService } from "./auth.service";
import {
  DbService,
  leftJoinDocument,
  leftJoinConsult,
  leftTrackDataTotal,
  oneTrackDataTotal,
} from "./db.service";
import { NoticeService } from "./notice.service";
import { SolutionWeb } from "../models/solutionWeb.models";
import { PartnershipDocument } from "../models/partnershipDocument.model";
import { AppPortFolio } from "../models/appPortFolio.model";
export interface AligoSendData {
  content: string;
  title: string;
  phone: string;
  emtitle_1: string;
  tpl_code: string;
}
@Injectable({
  providedIn: "root",
})
export class DataService {
  userId;
  users;
  user: User;
  userDepartments: Department[];
  departmentMember: User[];
  constructor(
    public db: DbService,
    public auth: AuthService,
    private fns: AngularFireFunctions,
    private noticeService: NoticeService
  ) {}

  //** 데이터 서비스 init **//
  async inIt() {
    return new Promise(async (resolve) => {
      if (this.userId) {
        resolve("success");
      } else {
        this.user = await this.auth.getUser();
        this.userId = this.user.id;
        this.departmentMember = await this.getDepartmentMember();
        resolve("success");
      }
    });
  }

  async getInquiryByTrackingId(trackingDataId: string) {
    const inquiries = await this.db
      .collection$("inquiries", (ref) =>
        ref.where("trackingDataId", "==", trackingDataId)
      )
      .pipe(first())
      .toPromise();

    return inquiries ? inquiries[0] : undefined;
  }

  getUsersByDepartmentName(departmentName: string) {
    return this.db
      .collection$("departments", (ref) =>
        ref.where("name", "==", departmentName)
      )
      .pipe(
        switchMap((departments) => {
          console.log("departments", departments[0].id);

          return this.db.collection$("users", (ref) =>
            ref.where("department", "array-contains", departments[0].id)
          );
        })
      );
  }

  getDepartmentMember(): Promise<User[]> {
    return new Promise(async (resolve) => {
      const users = await this.db
        .collection$("users", (ref) =>
          ref.where("department", "array-contains-any", this.user.department)
        )
        .pipe(first())
        .toPromise();
      resolve(users);
    });
  }

  async getAllUser(): Promise<User[]> {
    return new Promise(async (resolve) => {
      this.users = await this.db
        .collection$("users", (ref) => ref.orderBy("name", "asc"))
        .pipe(first())
        .toPromise();

      resolve(this.users);
    });
  }

  async getAllinquiryExfinish(): Promise<Inquiry[]> {
    const inquiries = await this.db
      .collection$("inquiries", (ref) => ref.where("status", "!=", "완료"))
      .pipe(take(2))
      .toPromise();

    return inquiries;
  }

  async getAllTaskByUser(): Promise<TaskList[]> {
    const taskList = await this.db
      .collection$("taskList", (ref) =>
        ref
          .where("joinUsers", "array-contains", this.userId)
          .orderBy("dateCreated", "desc")
      )
      .pipe(first())
      .toPromise();

    return taskList;
  }

  async getStatusTaskByUser(status): Promise<any> {
    const taskList = await this.db
      .collection$("taskList", (ref) =>
        ref
          .where("joinUsers", "array-contains", this.userId)
          .where("status", "==", status)
          .orderBy("dateCreated", "desc")
          .limit(10)
      )
      .pipe(
        leftJoinDocument(this.db.afs, "createdBy", "users"),
        leftJoinDocument(this.db.afs, "inquiryId", "inquiries"),
        first()
      )
      .toPromise();

    return taskList;
  }

  async addTask(task: TaskList) {
    task.createdBy = this.userId;
    const joinUsers = [...task.joinUsers, this.userId];
    let uniqueChars = [...new Set(joinUsers)];
    task.joinUsers = uniqueChars;
    task.id = this.db.createFsId();
    await this.db.updateAt(`taskList/${task.id}`, task);
    return this.noticeService.addNotice(
      this.userId,
      "taskList",
      `업무명: ${task.name}`,
      task.id
    );
  }

  async addEstimate(estimate: Estimate) {
    estimate.createdBy = this.userId;
    this.db.updateAt(`estimates`, estimate);
  }

  async addPartnershipDocument(partnershipDocument: PartnershipDocument) {
    partnershipDocument.createdBy = this.userId;
    this.db.updateAt(`partnershipDocuments`, partnershipDocument);
  }

  async addFile(fileData) {
    fileData.createdBy = this.userId;
    this.db.updateAt(`files`, fileData);
  }

  updateTask(taskListId, updateTask) {
    return this.db.updateAt(`taskList/${taskListId}`, updateTask);
  }

  async updateFeedBack(feedBack: csFeedBack, cs: Cs) {
    await this.db.updateAt(`cs/${cs.id}`, {
      feedBack: firebase.firestore.FieldValue.arrayUnion(feedBack),
    });
    if (feedBack.type == "요청자") {
      return this.noticeService.addNotice(
        cs.csManager,
        "asFeedBackToManager",
        `요청명: ${cs.title} 요청자: ${this.user.name}`,
        cs.id
      );
    } else {
      return this.noticeService.addNotice(
        cs.createdBy,
        "asFeedBackToCreater",
        `요청명: ${cs.title}, 담당자: ${this.user.name}`,
        cs.id
      );
    }
  }

  async createUser(userData) {
    return new Promise(async (resolve, reject) => {
      const createUser = await this.fns
        .httpsCallable("createUser")(userData)
        .pipe(first())
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
            console.error({ err });
          }
        );
    });
  }

  async sendAligoFcns(sendData: AligoSendData) {
    return new Promise(async (resolve, reject) => {
      this.fns
        .httpsCallable("sendAligo")(sendData)
        .pipe(first())
        .subscribe(
          (resp) => {
            resolve(resp);
          },
          (err) => {
            reject(err);
            console.error({ err });
          }
        );
    });
  }

  async createInquiry(inquiry: Inquiry) {
    inquiry.createdBy = this.userId;
    return this.db.updateAt("inquiries", inquiry);
  }

  async createProject(project) {
    project.createdBy = this.userId;
    return this.db.updateAt("projects", project);
  }

  async createCs(cs: Cs) {
    cs.createdBy = this.userId;
    cs.id = this.db.createFsId();
    await this.db.updateAt(`cs/${cs.id}`, cs);
    const csMangers = await this.db
      .collection$("users", (ref) =>
        ref.where("duty", "array-contains", "관리자개발")
      )
      .pipe(first())
      .toPromise();
    const promises = csMangers.map((user: any) => {
      return this.noticeService.addNotice(
        user.id,
        "asRequest",
        `요청명: ${cs.title}, 요청자: ${this.user.name}`,
        cs.id
      );
    });
    return Promise.all(promises);
  }

  async extensonRentTestphone(
    newRentTestphone: RentTestphone,
    testphone: Testphone
  ) {
    await this.db.updateAt(
      `rentTestphones/${newRentTestphone.id}`,
      newRentTestphone
    );
    await this.db.updateAt(`rentTestphones/${testphone.rentTestphoneId}`, {
      type: "연장",
      dateExtension: new Date().toISOString(),
    });

    const updateData = {
      expiredRentDate: newRentTestphone.expiredDate,
      rentTestphoneId: newRentTestphone.id,
    };
    await this.db.updateAt(
      `testphones/${newRentTestphone.testphoneId}`,
      updateData
    );
    const operationUser: User[] = await this.db
      .collection$("users", (ref) =>
        ref.where("duty", "array-contains", "관리")
      )
      .pipe(first())
      .toPromise();
    const promises = operationUser.map((user: User) => {
      return this.noticeService.addNotice(
        user.id,
        "extensionRentTestphone",
        `테스트폰: ${testphone.name}, 신청자: ${this.user.name}`,
        newRentTestphone.id
      );
    });
    return Promise.all(promises);
  }
  async createRentTestphone(
    rentTestphone: RentTestphone,
    testphone: Testphone
  ) {
    await this.db.updateAt(`rentTestphones/${rentTestphone.id}`, rentTestphone);
    const updateData = {
      rentedBy: rentTestphone.createdBy,
      expiredRentDate: rentTestphone.expiredDate,
      rentTestphoneId: rentTestphone.id,
      status: "대여중",
    };
    await this.db.updateAt(
      `testphones/${rentTestphone.testphoneId}`,
      updateData
    );
    const operationUser: User[] = await this.db
      .collection$("users", (ref) =>
        ref.where("duty", "array-contains", "관리")
      )
      .pipe(first())
      .toPromise();
    const promises = operationUser.map((user: User) => {
      return this.noticeService.addNotice(
        user.id,
        "rentTestphone",
        `테스트폰: ${testphone.name}, 신청자: ${this.user.name}`,
        rentTestphone.id
      );
    });
    return Promise.all(promises);
  }

  async returnTestphone(testphone: Testphone) {
    await this.db.updateAt(`rentTestphones/${testphone.rentTestphoneId}`, {
      status: "반납",
      dateReturned: new Date().toISOString(),
    });
    await this.db.updateAt(`testphones/${testphone.id}`, {
      rentedBy: "",
      expiredRentDate: "",
      rentTestphoneId: "",
      status: "대기중",
    });
    const operationUser: User[] = await this.db
      .collection$("users", (ref) =>
        ref.where("duty", "array-contains", "관리")
      )
      .pipe(first())
      .toPromise();
    const promises = operationUser.map((user: User) => {
      return this.noticeService.addNotice(
        user.id,
        "returnTestphone",
        `테스트폰: ${testphone.name}, 신청자: ${this.user.name}`,
        testphone.rentTestphoneId
      );
    });
    return Promise.all(promises);
  }

  async createRequestBook(requestBook: RequestBook) {
    await this.db.updateAt(`requestBook/${requestBook.id}`, requestBook);
    const operationUser: User[] = await this.db
      .collection$("users", (ref) =>
        ref.where("duty", "array-contains", "관리")
      )
      .pipe(first())
      .toPromise();
    const promises = operationUser.map((user: User) => {
      return this.noticeService.addRequestdNotice(
        user.id,
        "newRequest",
        `신청도서명: ${requestBook.title}, 신청자: ${this.user.name}`,
        "도서구매",
        requestBook.id
      );
    });
    return Promise.all(promises);
  }

  async createRequestEducation(requestEducation: RequestEducation) {
    requestEducation.id = this.db.createFsId();
    await this.db.updateAt(
      `requestEducation/${requestEducation.id}`,
      requestEducation
    );
    let heads: any = await this.getUserDepartmentHead(this.user);

    const promises = heads.map((user: any) => {
      return this.noticeService.addRequestdNotice(
        user,
        "newRequest",
        `교육명: ${requestEducation.title}, 신청자: ${this.user.name}`,
        "교육",
        requestEducation.id
      );
    });
    return Promise.all(promises);
  }

  async approveRequestEducation(request: RequestEducation, commment: string) {
    const approve = {
      approvedBy: this.userId,
      dateApproved: new Date().toISOString(),
      isHeadApproved: true,
      headComment: commment,
      status: "승인",
    };
    await this.db.updateAt(`requestEducation/${request.id}`, approve);
    this.noticeService.addRequestdNotice(
      (request.createdBy as User).id,
      "승인",
      `신청교육명: ${request.title}, 신청자: ${this.user.name} `,
      "교육",
      request.id
    );
  }

  async statusRequestEducation(
    request: RequestEducation,
    status: string,
    commment: string
  ) {
    const changeStatus = {
      headComment: commment,
      status: status,
    };
    await this.db.updateAt(`requestEducation/${request.id}`, changeStatus);
    this.noticeService.addRequestdNotice(
      (request.createdBy as User).id,
      status,
      `신청교육명: ${request.title}`,
      "교육",
      request.id
    );
  }

  getUserDepartmentHead(user: User) {
    1;
    return new Promise(async (resolve, reject) => {
      const promises = user.department.map(async (departmentId: any) => {
        const deparment = await this.db
          .doc$(`departments/${departmentId}`)
          .pipe(first())
          .toPromise();
        return deparment;
      });
      const userdepartments = await Promise.all(promises);
      const head = userdepartments.map((department) => department.head);
      const remove = head.filter((item) => item !== "");
      let uniqueChars = [...new Set(remove)];
      resolve(uniqueChars);
    });
  }

  checkCreatedByUser(createdBy: string) {
    return this.userId == createdBy ? true : false;
  }

  async createMeeting(tempEvent) {
    delete tempEvent._days;
    const meeting: Meeting = {
      createdBy: this.userId,
      dateCreated: new Date().toISOString(),
      ...tempEvent,
    };
    await this.db.updateAt(`meetings/${meeting.id}`, meeting);
    let noticeUsers = [];
    for await (const item of meeting.joinUsers) {
      if (item.type == "user") {
        noticeUsers.push(item.id);
      } else {
        const groupUsers = await this.db
          .collection$("users", (ref) =>
            ref.where("department", "array-contains", item.id)
          )
          .pipe(
            map((users) => {
              return users.map((user) => user.id);
            }),
            first()
          )
          .toPromise();
        noticeUsers = [...noticeUsers, ...groupUsers];
      }
    }
    let uniqueChars = [...new Set(noticeUsers)];
    uniqueChars = uniqueChars.filter((user) => user != this.userId);
    const promises = uniqueChars.map((joinUser) => {
      return this.noticeService.addNotice(
        joinUser,
        "meeting",
        `미팅명: ${meeting.title}`,
        meeting.id
      );
    });
    return Promise.all(promises);
  }

  async updateMeeting(meeting) {
    delete meeting._days;
    return this.db.updateAt(`meetings/${meeting.id}`, meeting);
  }

  getMeetings() {
    return this.db.collection$("meetings").pipe(take(1)).toPromise();
  }

  async getMeetingsByPeriod(date: Date) {
    const startOfMonth = moment(date).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(date).endOf("month").format("YYYY-MM-DD");
    console.log("endOfMonth", endOfMonth);
    console.log("startOfMonth", startOfMonth);

    const date1 = new Date(startOfMonth);
    date1.setDate(date1.getDate() - 10);
    date1.setHours(0, 0, 0, 0); // +9 hours for Korean Time
    const date2 = new Date(endOfMonth);
    const date3 = new Date();
    date2.setDate(date2.getDate() + 10);
    date2.setHours(0, 0, 0, 0); // +9 hours for Korean Time
    const start = firebase.firestore.Timestamp.fromDate(date1);
    const end = firebase.firestore.Timestamp.fromDate(date2);
    return this.db
      .collection$("meetings", (ref) =>
        ref
          .where("start", ">=", start)
          .where("start", "<", end)
          .orderBy("start", "desc")
      )
      .pipe(take(1))
      .toPromise();
  }

  async checkInquiry(inquiryId: string) {
    return this.db.updateAt(`inquiries/${inquiryId}`, {
      isCheck: true,
    });
  }

  async checkStoreOrder(orderId: string) {
    return this.db.updateAt(`storeOrder/${orderId}`, {
      isCheck: true,
    });
  }

  async checkSolutionOrder(orderId: string) {
    return this.db.updateAt(`storeOrder/${orderId}`, {
      isCheck: true,
    });
  }

  async checkCs(cs: Cs) {
    await this.db.updateAt(`cs/${cs.id}`, {
      status: "확인",
      isCheck: true,
      csManager: this.userId,
      checkedBy: this.userId,
    });
    return this.noticeService.addNotice(
      cs.createdBy,
      "asChecked",
      `요청명: ${cs.title}, 담당자: ${this.user.name}`,
      cs.id
    );
  }

  async completedCs(cs: Cs) {
    await this.db.updateAt(`cs/${cs.id}`, {
      status: "완료",
      isCheck: true,
      completedBy: this.userId,
      dateCompleted: new Date().toISOString(),
    });
    return this.noticeService.addNotice(
      cs.createdBy,
      "asCompleted",
      `요청명: ${cs.title}, 담당자: ${this.user.name}`,
      cs.id
    );
  }

  async getSalesData(start, end) {
    return this.db
      .collection$("sales", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"), first())
      .toPromise();
  }

  async getProjectFinancialPlan(start, end) {
    return this.db
      .collection$("projectFinancialPlans", (ref) =>
        ref
          .where("dueDate", ">=", start)
          .where("dueDate", "<", end)
          .orderBy("dueDate", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"), first())
      .toPromise();
  }

  async getMeetingData(start, end) {
    return this.db
      .collection$("meetings", (ref) =>
        ref
          .where("start", ">=", start)
          .where("start", "<", end)
          .orderBy("start", "desc")
      )
      .pipe(first())
      .toPromise();
  }

  getSalesFile() {
    return this.db
      .collection$("files", (ref) =>
        ref.where("type", "==", "startappSales").orderBy("dateCreated", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"), first())
      .toPromise();
  }

  async getInquiryData(start, end) {
    return this.db
      .collection$("inquiries", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            if (!item.createdDate) {
              item.createdDate = new Date(
                item.date.seconds * 1000
              ).toISOString();
            }
            return { ...item, checked: false };
          });
        }),
        leftJoinConsult(this.db.afs),
        first()
      )
      .toPromise();
  }

  async getNewInquiryData() {
    return this.db
      .collection$("inquiries", (ref) =>
        ref.where("status", "==", "접수").orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            if (!item.createdDate) {
              item.createdDate = new Date(
                item.date.seconds * 1000
              ).toISOString();
            }
            return { ...item, checked: false };
          });
        }),
        first()
      )
      .toPromise();
  }

  getTrackingDatasByPeriod(start, end) {
    return this.db
      .collection$("trackingDatas", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(
        leftTrackDataTotal(this.db.afs),
        map((arr: any) => {
          return arr.map((item) => {
            console.log(item);

            const urlType = this.getUrlType(item.referrer);
            const searchKeyword = this.getSearchKeyword(item.referrer);
            return { ...item, urlType, searchKeyword, checked: false };
          });
        }),
        first()
      )
      .toPromise();
  }

  getTrackingDataByIp(ip: string) {
    console.log("ip", ip);

    return this.db
      .collection$("trackingDatas", (ref) =>
        ref.where("ip", "==", ip).orderBy("dateCreated", "desc")
      )
      .pipe(first())
      .toPromise();
  }

  getTrackingDataByTrackDataId(trackDataId: string) {
    return this.db
      .doc$(`trackingDatas/${trackDataId}`)
      .pipe(
        map((item: any) => {
          const urlType = this.getUrlType(item.referrer);
          const searchKeyword = this.getSearchKeyword(item.referrer);
          return { ...item, urlType, searchKeyword, checked: false };
        }),
        first()
      )
      .toPromise();
  }

  getSearchKeyword(url: string) {
    const daumSearch = this.getParameterByName("q", url);
    const naverSerch = this.getParameterByName("query", url);
    return daumSearch || naverSerch;
  }

  getUrlType(url: string) {
    // 호스트 이름에 따라 유형 분류
    if (url.includes("search.daum.net")) {
      return "Daum Search";
    } else if (url.includes("blog.naver.com/PostView")) {
      return "네이버 블로그-포스트뷰";
    } else if (url.includes("blog.naver.com")) {
      return "네이버 블로그";
    } else if (url.includes("blog.naverblogwidget.com")) {
      return "네이버 블로그-위젯";
    } else if (url.includes("google.com")) {
      return "Google";
    } else if (url.includes("googleads.g.doubleclick.net")) {
      return "Google-ads-doubleclick";
    } else if (url.includes("youtube.com")) {
      return "Youtube";
    } else if (url.includes("facebook.com")) {
      return "Facebook";
    } else if (url.includes("instagram.com")) {
      return "Instagram";
    } else if (url.includes("search.naver.com")) {
      if (url.includes("fbm=1")) {
        return "Naver Search - 메인검색";
      } else if (url.includes("op_hty")) {
        return "Naver Search - 직접검색";
      } else if (url.includes("tab_jum")) {
        return "Naver Search - 우측영역 클릭";
      } else if (url.includes("nexearch")) {
        return "Naver Search - 통합검색";
      } else if (url.includes("post")) {
        return "Naver Search - 블로그";
      } else if (url.includes("cafeblog")) {
        return "Naver Search - 카페";
      }

      return "Naver Search";
    } else {
      return "기타";
    }
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    const result = results.map((item) => decodeURIComponent(item));
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  async getContractWithPartnershipPeriodData(type: string, start, end) {
    return this.db
      .collection$("contractWithPartnership", (ref) =>
        ref
          .where("type", "==", type)
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            return { ...item, checked: false };
          });
        }),
        leftJoinDocument(this.db.afs, "contactCompanyId", "contactCompany"),
        first()
      )
      .toPromise();
  }

  async getContractWithPartnershipData(type: string) {
    return this.db
      .collection$("contractWithPartnership", (ref) =>
        ref.where("type", "==", type).orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            return { ...item, checked: false };
          });
        }),
        leftJoinDocument(this.db.afs, "contactCompanyId", "contactCompany"),
        first()
      )
      .toPromise();
  }
  async getContractWithPartnershipByContactCompanybyId(
    type: string,
    contactCompanyId: string
  ) {
    return this.db
      .collection$("contractWithPartnership", (ref) =>
        ref
          .where("contactCompanyId", "==", contactCompanyId)
          .where("type", "==", type)
          .orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            return { ...item, checked: false };
          });
        }),
        leftJoinDocument(this.db.afs, "contactCompanyId", "contactCompany"),
        first()
      )
      .toPromise();
  }

  async getContractWithPartnershipPeriodDataByContactCompanybyId(
    type: string,
    contactCompanyId: string,
    start,
    end
  ) {
    return this.db
      .collection$("contractWithPartnership", (ref) =>
        ref
          .where("contactCompanyId", "==", contactCompanyId)
          .where("type", "==", type)
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(
        map((arr) => {
          return arr.map((item) => {
            return { ...item, checked: false };
          });
        }),
        leftJoinDocument(this.db.afs, "contactCompanyId", "contactCompany"),
        first()
      )
      .toPromise();
  }

  async getStoreOrderData(start, end) {
    return this.db
      .collection$("orderStore", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinConsult(this.db.afs), first())
      .toPromise();
  }

  async getSolutionOrderData(start, end) {
    return this.db
      .collection$("orderSolution", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "userId", "appSolutionUser"), first())
      .toPromise();
  }

  async getContractData(start, end) {
    return this.db
      .collection$("contracts", (ref) =>
        ref
          .where("date", ">=", start)
          .where("date", "<", end)
          .orderBy("date", "desc")
      )
      .pipe(
        leftJoinDocument(this.db.afs, "createdBy", "users"),
        leftJoinDocument(this.db.afs, "clientId", "clients"),
        leftJoinDocument(this.db.afs, "projectId", "projects"),
        first()
      )
      .toPromise();
  }

  async allGetContractData() {
    return this.db
      .collection$("contracts", (ref) => ref.orderBy("date", "desc"))
      .pipe(
        leftJoinDocument(this.db.afs, "createdBy", "users"),
        leftJoinDocument(this.db.afs, "clientId", "clients"),
        leftJoinDocument(this.db.afs, "projectId", "projects"),
        first()
      )
      .toPromise();
  }

  getInquiryStatisticData(start, end) {
    return this.db
      .collection$("inquiries", (ref) =>
        ref.where("date", ">=", start).where("date", "<", end)
      )
      .pipe(first())
      .toPromise();
  }

  getCounslutMeetingStatisticData(start, end) {
    return this.db
      .collection$("meetings", (ref) =>
        ref
          .where("start", ">=", start)
          .where("start", "<", end)
          .where("type", "==", "consultMeeting")
      )
      .pipe(first())
      .toPromise();
  }

  getContractsData(start, end) {
    return this.db
      .collection$("contracts", (ref) =>
        ref.where("date", ">=", start).where("date", "<", end)
      )
      .pipe(first())
      .toPromise();
  }

  async getInquiryAllData() {
    return this.db.collection$("inquiries").pipe(first()).toPromise();
  }

  getSalesStatisticData(start, end) {
    return this.db
      .collection$("sales", (ref) =>
        ref.where("date", ">=", start).where("date", "<", end)
      )
      .pipe(first())
      .toPromise();
  }

  async getLocalUserData(userId) {
    if (this.users) {
      const find = this.users.find((item) => item.id == userId);
      return find.name;
    } else {
      await this.getAllUser();
      const find = this.users.find((item) => item.id == userId);
      return find.name;
    }
  }

  createWebProduct(web: SolutionWeb) {
    const docRef = this.db.afs.firestore
      .collection("webSolution")
      .doc("numbering");
    let lastNumber = 0;

    return this.db.afs.firestore
      .runTransaction((transaction) => {
        return transaction.get(docRef).then(async (snapshot: any) => {
          if (!snapshot.empty) {
            lastNumber = snapshot.data().lastOrder;
          }
          const collectionRef = this.db.afs.collection("solutionWebs");
          const newDocumentRef = collectionRef.doc();
          const newNumber = lastNumber + 1;
          web.order = newNumber;
          web.createdBy = this.userId;
          web.dateCreated = new Date().toISOString();
          transaction.update(docRef, { lastOrder: newNumber });
          transaction.set(newDocumentRef.ref, web);
        });
      })
      .then(() => {})
      .catch((error) => {});
  }

  createPortFolio(portFolio: AppPortFolio) {
    const docRef = this.db.afs.firestore
      .collection("portfolios")
      .doc("numbering");
    let lastNumber = 0;

    return this.db.afs.firestore
      .runTransaction((transaction) => {
        return transaction.get(docRef).then(async (snapshot: any) => {
          console.log("snapshot", snapshot);
          console.log("snapshot.empty", snapshot.empty);
          if (!snapshot.empty && snapshot.data()) {
            lastNumber = snapshot.data().lastOrder;
          }
          const collectionRef = this.db.afs.collection("portfolios");
          const newDocumentRef = collectionRef.doc();
          const newNumber = lastNumber + 1;
          portFolio.order = newNumber;
          portFolio.createdBy = this.userId;
          portFolio.dateCreated = new Date().toISOString();
          transaction.update(docRef, { lastOrder: newNumber });
          transaction.set(newDocumentRef.ref, portFolio);
        });
      })
      .then((success) => {
        console.log("success", success);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  getType(x) {
    if (x == null) {
      return "null";
    }

    var t = typeof x;
    if (t != "object") {
      return t;
    }

    var c = Object.prototype.toString.apply(x);
    c = c.substring(8, c.length - 1); // [object ?]의 특성을 이용함

    if (c != "Object") {
      return c;
    }

    if (c.constructor == "Object") {
      return c;
    } else {
      var s = x.constructor.toString();
      var i = s.indexOf("(");
      return s.substring(9, i); // function ?( ... 의 특성을 이용함
    }

    return "unknown type";
  }

  async getSolutionPrice(): Promise<any> {
    const SolutionPrice = this.db
      .collection$(`solutionPrice`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();

    return SolutionPrice;
  }

  async getAllSolutionOptionPrice(): Promise<any> {
    const solutionOptionPrice = this.db
      .collection$(`solutionOptionPrice`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();

    return solutionOptionPrice;
  }

  async getAllsolutionApps(): Promise<any> {
    const solutionApps = this.db
      .collection$(`solutionApps`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return solutionApps;
  }

  async getsolutionAppById(appId: string): Promise<any> {
    const solutionApp = this.db
      .doc$(`solutionApps/${appId}`)
      .pipe(first())
      .toPromise();
    return solutionApp;
  }

  getContactCompanyById(contactCompanyId: string) {
    const contactCompany = this.db
      .doc$(`contactCompany/${contactCompanyId}`)
      .pipe(first())
      .toPromise();
    return contactCompany;
  }

  getContractWithPartnershipById(contractWithPartnershipId: string) {
    const contractWithPartnership = this.db
      .doc$(`contractWithPartnership/${contractWithPartnershipId}`)
      .pipe(first())
      .toPromise();
    return contractWithPartnership;
  }

  async getsolutionWebById(webId: string): Promise<any> {
    const solutionApp = this.db
      .doc$(`solutionWebs/${webId}`)
      .pipe(first())
      .toPromise();
    return solutionApp;
  }

  async getAllsolutionWebs(): Promise<any> {
    const solutionWebs = this.db
      .collection$(`solutionWebs`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return solutionWebs;
  }

  async getAllPortfolio(): Promise<any> {
    const portFolios = this.db
      .collection$(`portfolios`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return portFolios;
  }

  async getAllContactCompany(): Promise<any> {
    const contactCompany = this.db
      .collection$(`contactCompany`, (ref) =>
        ref.orderBy("dateCreated", "desc")
      )
      .pipe(first())
      .toPromise();
    return contactCompany;
  }

  contactCompanyRef$() {
    const contactCompany = this.db.collection$(`contactCompany`, (ref) =>
      ref.orderBy("dateCreated", "desc")
    );
    return contactCompany;
  }
  partnershipCompanyRef$() {
    const contactCompany = this.db.collection$(`contactCompany`, (ref) =>
      ref.where("isPartnerShip", "==", true).orderBy("datePartnerShip", "desc")
    );
    return contactCompany;
  }

  async getLastSolutionApp(): Promise<any> {
    const solutionApps = this.db
      .collection$(`solutionApps`, (ref) =>
        ref.orderBy("order", "desc").limit(1)
      )
      .pipe(first())
      .toPromise();
    return solutionApps;
  }

  async getLas(): Promise<any> {
    const solutionApps = this.db
      .collection$(`solutionApps`, (ref) =>
        ref.orderBy("order", "desc").limit(1)
      )
      .pipe(first())
      .toPromise();
    return solutionApps;
  }

  async getLastSolutionWeb(): Promise<any> {
    const getLastSolutionWebs = this.db
      .collection$(`solutionApps`, (ref) =>
        ref.orderBy("order", "desc").limit(1)
      )
      .pipe(first())
      .toPromise();
    return getLastSolutionWebs;
  }

  async getAllSolutionAppCategories() {
    const solutionAppCategories = this.db
      .collection$(`solutionAppCategories`, (ref) =>
        ref.orderBy("order", "asc")
      )
      .pipe(first())
      .toPromise();
    return solutionAppCategories;
  }

  async getAllSolutionWebCategories() {
    const webCategories = this.db
      .collection$(`webCategories`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return webCategories;
  }

  async getAllProjectAppCategories() {
    const webCategories = this.db
      .collection$(`appCategories`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return webCategories;
  }

  async getPortFolioCategories() {
    const portFolioCategories = this.db
      .collection$(`portFolioCategories`, (ref) => ref.orderBy("order", "asc"))
      .pipe(first())
      .toPromise();
    return portFolioCategories;
  }

  async getAllAppCategories() {
    const appCategories = this.db
      .doc$(`appService/categories`)
      .pipe(first())
      .toPromise();
    return appCategories;
  }

  async addAppCategories(category: string) {
    await this.db.updateAt(`appService/categories`, {
      categoryData: firebase.firestore.FieldValue.arrayUnion(category),
    });
  }

  async getAppSolutionTags() {
    return this.db.doc$(`appSolution/tags`).pipe(first()).toPromise();
  }

  async getWebSolutionTags() {
    return this.db.doc$(`webSolution/tags`).pipe(first()).toPromise();
  }

  async gePortFolioTags() {
    return this.db.doc$(`portfolios/tags`).pipe(first()).toPromise();
  }

  async getAppTags() {
    return this.db.doc$(`appService/tags`).pipe(first()).toPromise();
  }

  async getSolutionAppFunctionListByAppId(appId: string): Promise<any> {
    const SolutionReview = this.db
      .collection$(`solutionAppFunctionList`, (ref) =>
        ref.where("solutionAppId", "==", appId).orderBy("order", "asc")
      )
      .pipe(first())
      .toPromise();

    return SolutionReview;
  }
}
