<div class="modal-header">
  <div class="modal-title mt-0">{{type=='add'?'사용자생성':'사용지상세'}}</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="userForm">
    <div class="row">
      <div class="mb-3">
        <label for="name">이름</label>
        <input id="name"
          name="name"
          type="text"
          [disabled]="isEdit"
          class="form-control"
          formControlName="name"
          placeholder="이름 입력"
          [ngClass]="{'is-invalid': submit && form.name.errors}">
        <div *ngIf="submit && form.name.errors"
          class="invalid-feedback">
          <span *ngIf="form.name.errors.required">이름은 필수항목입니다.</span>
        </div>
      </div>
      <div class="mb-3">
        <label for="email">이메일</label>
        <input id="email"
          name="email"
          type="email"
          [disabled]="isEdit"
          placeholder="이메일 입력"
          class="form-control"
          formControlName="email"
          [ngClass]="{'is-invalid': submit && form.email.errors}">
        <div *ngIf="submit && form.email.errors"
          class="invalid-feedback">
          <span *ngIf="form.email.errors.required">이메일은 필수사항입니다.</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="control-label">관리 등급</label>
        <ng-select [items]="['최고 관리자','관리자','일반']"
          formControlName="type"
          placeholder="관리등급 선택"
          [ngClass]="{'is-invalid': submit && form.type.errors}"></ng-select>
        <div *ngIf="submit && form.type.errors"
          class="invalid-feedback">
          <span *ngIf="form.type.errors.required">관리등급은 필수항목입니다.</span>
        </div>
      </div>

      <div class="mb-3"
        *ngIf="departments$ | async as departments">
        <label class="control-label">조직</label>
        <ng-select formControlName="department"
          placeholder="조직 선택"
          [multiple]="true"
          [items]="departments"
          bindLabel="name"
          bindValue="id"></ng-select>
        <div *ngIf="submit && form.department.errors"
          class="invalid-feedback">
          <span *ngIf="form.department.errors.required">조직은 필수항목입니다.</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="control-label">직위</label>
        <ng-select formControlName="level"
          placeholder="직위 선택"
          [items]="['대표','선임','책임','수습']"></ng-select>
        <div *ngIf="submit && form.level.errors"
          class="invalid-feedback">
          <span *ngIf="form.level.errors.required">직위은 필수항목입니다.</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="control-label">직책</label>
        <ng-select formControlName="position"
          placeholder="직책 선택"
          [items]="['CEO','리더','팀장','파트장','팀원']"></ng-select>
        <div *ngIf="submit && form.position.errors"
          class="invalid-feedback">
          <span *ngIf="form.position.errors.required">직책은 필수항목입니다.</span>
        </div>
      </div>
      <div class="mb-3">
        <label class="control-label">업무</label>
        <ng-select [items]="['상담','마케팅','기획','개발','퍼블리싱','QA','관리','인사','디자인','스토어','제휴']"
          formControlName="duty"
          placeholder="업무 선택"
          [multiple]="true"></ng-select>
        <div *ngIf="submit && form.duty.errors"
          class="invalid-feedback">
          <span *ngIf="form.duty.errors.required">업무은 필수항목입니다.</span>
        </div>
      </div>
      <!-- <div class="mb-3">
                                <label for="productdesc">Product Description</label>
                                <textarea class="form-control"
                                    id="productdesc"
                                    rows="5"></textarea>
                            </div> -->
    </div>
    <div class="d-flex flex-wrap gap-2 mt-2">
      <button class="btn btn-danger"
        *ngIf="type != 'add'"
        (click)="leaveUser()">퇴사처리</button>
      <button type="submit"
        (click)="validSubmit()"
        class="btn btn-primary">{{type=='add'?'등록':'수정'}}</button>
      <!-- <button (click)=""
                            class="btn btn-secondary">취소</button> -->
    </div>
  </form>
</div>
