<div class="modal-header">
  <h2 class="modal-title mb-0">제휴연결 계약 상세</h2>
  <button type="button"
    class="btn-close"
    aria-label="Close"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-6">

      <div class="card"
        *ngIf="contractWithPartnership">
        <div class="card-body">
          <h4 class="card-title mb-4">제휴업체 내용</h4>
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <!-- <tr>
                  <th scope="row">상태 :</th>
                  <td>
                    <div class="btn-group"
                      ngbDropdown>
                      <button type="button"
                        class="btn dropdown-toggle"
                        type="button"
                        id="dropdownConfig"
                        [ngClass]=" { 'btn-primary': contactCompany.status === '전화 컨택 완료',
                                'btn-success': contactCompany.status === '미팅 예정', 'btn-info': contactCompany.status === '미팅 완료', 'btn-warning': contactCompany.status === '제휴 협의중',
                                 'btn-dark': contactCompany.status === '제휴 완료', 'btn-secondary': contactCompany.status ==='등록'}"
                        ngbDropdownToggle>{{contactCompany.status?contactCompany.status:'등록'}} <i
                          class="mdi mdi-chevron-down"></i></button>
                      <div class="dropdown-menu"
                        ngbDropdownMenu>
                        <a class="dropdown-item"
                          (click)="changeStatus('등록')"
                          href="javascript: void(0);">등록</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('전화 컨택 완료')"
                          href="javascript: void(0);">전화 컨택 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('미팅 예정')"
                          href="javascript: void(0);">미팅 예정</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('미팅 완료')"
                          href="javascript: void(0);">미팅 완료</a>
                        <a class="dropdown-item"
                          (click)="changeStatus('제휴 협의중')"
                          href="javascript: void(0);">제휴 협의중</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          (click)="changeStatus('제휴 완료')"
                          href="javascript: void(0);">제휴 완료</a>
                      </div>
                    </div>
                  </td>
                </tr> -->
                <tr>
                  <th scope="row">등록일 :</th>
                  <td>{{contactCompany.dateCreated | date:'yyyy-MM-dd'}}</td>
                </tr>
                <tr>
                  <th scope="row">제휴등록일 :</th>
                  <td>{{contactCompany.datePartnerShip | date:'yyyy-MM-dd'}}</td>
                </tr>
                <tr>
                  <th scope="row">업체명 :</th>
                  <td>{{ contactCompany.companyName }} <a href="javascript: void(0);"
                      *ngIf="contactCompany.isPartnerShip"
                      class="badge badge-soft-primary font-size-13 m-1">제휴업체</a></td>
                </tr>
                <tr>
                  <th scope="row">대표자/담당자명 :</th>
                  <td>{{ contactCompany.presedent }}/{{ contactCompany.manager }}</td>
                </tr>
                <tr>
                  <th scope="row">제휴분야 :</th>
                  <td>{{ contactCompany.mainSpecialty }}</td>
                </tr>
                <tr>
                <tr>
                  <th scope="row">기타 전문 업무 :</th>
                  <td>{{ contactCompany.subSpecialty }}</td>
                </tr>
                <tr>
                  <th scope="row">수행기관 등록 여부 :</th>
                  <td>{{ contactCompany.isImplementingAgency }}</td>
                </tr>
                <tr>
                  <th scope="row">연락처 :</th>
                  <td>{{ contactCompany.phone }}</td>
                </tr>
                <tr>
                  <th scope="row">이메일 :</th>
                  <td>{{ contactCompany.email }}</td>
                </tr>
                <tr>
                  <th scope="row">대표 URL :</th>
                  <td>{{ contactCompany.url }}</td>
                </tr>
                <tr>
                  <th scope="row">주소 :</th>
                  <td>{{ contactCompany.address }}</td>
                </tr>


              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">

      <div class="card"
        *ngIf="contractWithPartnership">
        <div class="card-body">
          <h4 class="card-title mb-4">계약 내용</h4>
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">계약유형 :</th>
                  <td>{{contractWithPartnership.type}}</td>
                </tr>
                <tr>
                  <th scope="row">계약일 :</th>
                  <td>{{contractWithPartnership.dateContract}}</td>
                </tr>
                <tr>
                  <th scope="row">계약명 :</th>
                  <td>{{contractWithPartnership.title}}</td>
                </tr>
                <tr>
                  <th scope="row">계약자명 :</th>
                  <td>{{contractWithPartnership.contractName}}</td>
                </tr>
                <tr>
                  <th scope="row">계약종류 :</th>
                  <td>{{contractWithPartnership.contractType}}</td>
                </tr>
                <tr>
                  <th scope="row">계약금액 :</th>
                  <td>{{contractWithPartnership.amount | number}}원</td>
                </tr>
                <tr>
                  <th scope="row">제휴 수수료 :</th>
                  <td>{{contractWithPartnership.charge | number}}%</td>
                </tr>
                <tr>
                  <th scope="row">제휴 정산 금액:</th>
                  <td>{{caculate(contractWithPartnership) | number}}원</td>
                </tr>
                <tr>
                  <th scope="row">제휴 정산 여부:</th>
                  <td>미정산</td>
                </tr>
              </tbody>
            </table>
          </div>


          <div class="table-responsive"
            *ngIf="contractWithPartnership.files&& contractWithPartnership.files.length>0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">파일명</th>
                  <th scope="col">파일</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of contractWithPartnership.files">
                  <td>
                    {{file.name }}
                  </td>
                  <td>
                    {{getFileName(file.fileUrl)}}
                  </td>
                  <td>
                    <button type="button"
                      class="btn btn-sm btn-outline-dark"
                      (click)="downloadFile(file.fileUrl)">다운로드</button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>