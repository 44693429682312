import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { StoreConsulting } from "src/app/core/models/storeConsult.models";
import { StoreOrder } from "src/app/core/models/storeOrder.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService, leftJoinDocument } from "src/app/core/services/db.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import { storeData } from "src/app/pages/store/storeData";

@Component({
  selector: "app-order-detail",
  templateUrl: "./store-order-detail.component.html",
  styleUrls: ["./store-order-detail.component.scss"],
})
export class StoreOrderDetailComponent implements OnInit {
  @Input() public orderId;
  public Editor = ClassicEditor;
  private _prevOnSameUrlNavigation;

  order: StoreOrder;
  feedBack;
  isManager = false;
  isOrder = false;
  consultings$: Observable<any>;
  statusHistories$: Observable<any>;
  consultingType = "구매문의";
  description = "";
  totalPrice = 0;
  selectOption;
  templateSwitch;
  storeSwitch;
  selectProduct;
  products;
  options;
  isStore;
  isTemplate;
  constructor(
    private db: DbService,
    public activeModal: NgbActiveModal,
    public dataservice: DataService,
    private alertService: AlertService,
    private dataService: DataService,
    private _router: Router,
    private _location: LocationStrategy
  ) {}

  async ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    this.products = storeData;
    this._location.onPopState(() => {
      // example for a simple check if modal is opened
      if (this.activeModal !== undefined) {
        ("modal is opened - cancel default browser event and close modal");
        // TODO: cancel the default event
        // close modal
        this.activeModal.close();
      } else {
      }
    });
    this.order = await this.db
      .doc$(`orderStore/${this.orderId}`)
      .pipe(first())
      .toPromise();
    this.consultings$ = this.db
      .collection$(`storeConsultings`, (ref) =>
        ref.where("storeOrderId", "==", this.order.id).orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.statusHistories$ = this.db
      .collection$(`statusStoreOrderHistories`, (ref) =>
        ref.where("storeOrderId", "==", this.order.id).orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
  }

  async changeStatus(storeOrder: StoreOrder, status: string) {
    if (storeOrder.status === status) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 주문 상태를 "${status}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        storeOrderId: storeOrder.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: storeOrder.status ? storeOrder.status : "접수",
        newStatus: status,
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderStore/${storeOrder.id}`, {
        status: status,
      });
      storeOrder.status = status;
      this.alertService.basicMessage("상태가 변경되었습니다.");
    }
  }

  async checkOrder() {
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 주문을 접수하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        storeOrderId: this.order.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: this.order.status ? this.order.status : "주문접수",
        newStatus: "주문접수",
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderStore/${this.order.id}`, {
        status: "주문접수",
      });
      this.order.status = "주문접수";
      this.alertService.basicMessage("해당 주문이 접수되었습니다.");
    }
  }

  async deleteOrder() {
    const confirm = await this.alertService.confirm(
      "주문삭제",
      `해당 주문을 삭제하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const deleteOrder = {
        storeOrderId: this.order.id,
        dateDeleted: new Date().toISOString(),
        date: new Date(),
        deletedBy: this.dataservice.userId,
        ...this.order,
      };
      await this.db.updateAt("deletedStoreOrder", deleteOrder);
      await this.db.delete(`orderStore/${this.order.id}`);
      this.alertService.basicMessage("해당 주문이 삭제되었습니다.");
      this.activeModal.close("delete");
    }
  }

  passBack() {
    this.activeModal.close(this.order);
  }

  editOrder() {
    this.isOrder = !this.isOrder;
    this.selectProduct = this.products.find(
      (item) => item.title == this.order.product
    );
    this.selectOption = this.selectProduct.options.find(
      (option) => option.model == this.order.option.model
    );
    this.isStore = this.order.isStore ? "등록요청" : "요청안함";
    this.isTemplate = this.order.isTemplate ? "디자인요청" : "요청안함";
  }

  async saveOrder() {
    this.isOrder = !this.isOrder;
    this.order.product = this.selectProduct.title;
    this.order.option = this.selectOption;
    this.order.isStore = this.isStore == "등록요청" ? true : false;
    this.order.isTemplate = this.isTemplate == "디자인요청" ? true : false;
    this.order.totalPrice = this.getPrice();
    await this.db.updateAt(`orderStore/${this.order.id}`, this.order);
    this.alertService.basicMessage("변경사항이 저장되었습니다.");
  }

  async saveEditConsulting(item) {
    item.isEditor = false;
    const updateData = { ...item };
    delete updateData.isEditor;
    updateData.createdBy = this.dataService.userId;
    await this.db.updateAt(`storeConsultings/${item.id}`, updateData);
    this.alertService.success("수정 내용이 저장되었습니다.");
  }

  editConsulting(item) {
    item.isEditor = true;
  }

  async createdConsulting() {
    const confirm = await this.alertService.confirm(
      "상담등록",
      "상담 내용을 저장하시겠습니까?",
      "확인"
    );
    if (confirm) {
      const newConsulting: StoreConsulting = {
        storeOrderId: this.order.id,
        date: new Date(),
        dateCreated: new Date().toISOString(),
        type: this.order.status ? this.order.status : "주문접수",
        updateFiles: [],
        consultingType: this.consultingType,
        createdBy: this.dataService.userId,
        description: this.description,
      };
      await this.db.updateAt("storeConsultings", newConsulting);
      this.description = "";
      this.alertService.basicMessage("상담내용이 등록되었습니다.");
    }
  }

  getPrice() {
    const price = this.selectOption.isSaled
      ? this.selectOption.salePrice
      : this.selectOption.price;
    const storePrice = this.selectOption.storeRegistrationPrice;
    const templatePrice = this.isTemplate == "디자인요청" ? 2200000 : 0;
    return this.storeSwitch
      ? price + storePrice + templatePrice
      : price + templatePrice;
  }
}
