import { LocationStrategy } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Estimate } from "src/app/core/models/estimate.model";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { UserService } from "src/app/core/services/user.service";
@Component({
  selector: "app-add-file",
  templateUrl: "./add-file.component.html",
  styleUrls: ["./add-file.component.scss"],
})
export class AddFileComponent implements OnInit {
  @Input() public inquiry;
  @HostListener("window:popstate", ["$event"])
  private _prevOnSameUrlNavigation;

  submitted = false;
  userList;
  inquiryList;
  inquiryList$;
  myFile;
  isEdit = false;
  isLoading = true;
  userList$;
  constructor(
    private _location: LocationStrategy,
    public modalService: NgbModal,
    public dataService: DataService,
    public fileUploaderService: FileUploaderSevice,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    public loader: LoaderService,
    private db: DbService,
    private _router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
  }

  async updateFile() {
    this.loader.setLoading(true);
    const file = await this.uploadFile(this.myFile);
    const fileData = {
      dateCreated: new Date().toISOString(),
      file: file,
      fileName: this.myFile.name,
      createdBy: this.userService.user.id,
      type: "startappSales",
    };
    await this.dataService.addFile(fileData);
    this.loader.setLoading(false);
    await this.alertService.success("파일이가 등록되었습니다.");
    this.activeModal.close("update");
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFile = event.target.files[i];
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFileMerge(
          file.name,
          val
        );
        resolve(url);
      };
    });
  }

  passBack() {
    this.activeModal.close();
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }
}
