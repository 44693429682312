// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  client_Id:
    "914086897978-mkrr8tt90ef4ftqrsk4cccb40flppj3h.apps.googleusercontent.com",
  firebaseConfig: {
    apiKey: "AIzaSyD4QgK6irF8zEjvdjRdOvDDVOu8Eg6g8bA",
    authDomain: "startapp-new.firebaseapp.com",
    projectId: "startapp-new",
    storageBucket: "startapp-new.appspot.com",
    messagingSenderId: "914086897978",
    appId: "1:914086897978:web:f0474157a28cc344258803",
    vapidKey:
      "AAAA1NPTKTo:APA91bH7-67JVpEdpjIm1DuC_esZ37zWrMVBvY-Sc3OZ7RmB4xVTBxpfoKC-0EHXhuQd5LdJukgNfBRPSyQX1vXU3vADwWI40em3KL6EMTGOj12qqegGWIeI7taAaoQbP-xIDGENHotP",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
