// export layout related constants
export const LAYOUT_VERTICAL = "vertical";

export const LAYOUT_HORIZONTAL = "horizontal";

export const LAYOUT_WIDTH = "fluid";

export const SIDEBAR_TYPE = "dark";

export const TOPBAR = "dark";
