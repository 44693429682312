<div class="modal-header">
  <div class="modal-title mt-0">{{type=='add'?'업무생성':'업무상세'}}</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div *ngIf="isEdit || type=='add'">
    <form (ngSubmit)="listData()"
      [formGroup]="formData">
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="tasktype">업무타입</label>
            <select class="form-select"
              formControlName="taskType">
              <option value=""
                disabled>업무타입선택</option>
              <option value="미팅상담준비">미팅상담준비</option>
              <option value="견적서발송">견적서발송</option>
              <option value="계약서준비">계약서준비</option>
              <option value="계약서발송">계약서발송</option>
              <option value="계약전달">계약전달</option>
              <option value="기타">기타</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="control-label">업무명</label>
            <input class="form-control"
              placeholder="업무명을 입력해주세요."
              type="text"
              name="title"
              formControlName="name"
              [ngClass]="{'is-invalid': submitted && form.name.errors}" />
            <div *ngIf="submitted && form.name.errors"
              class="invalid-feedback">
              <span *ngIf="form.name.errors.required">업무명은 필수항목입니다.</span>
            </div>
          </div>
          <div class="mb-3">
            <label class="control-label">업무상세</label>
            <textarea class="form-control"
              placeholder="업무상세 내용을 입력해주세요."
              type="text"
              name="description"
              formControlName="description"></textarea>
          </div>
          <div class="mb-3">
            <label for="file">첨부파일</label>
            <input formControlName="file"
              id="file"
              type="file"
              multiple
              class="form-control"
              (change)="onFileChange($event)">
            <h2 class="font-size-15 mt-4">첨부파일</h2>
            <p *ngIf="!isEdit && myFiles.length == 0 ">없음</p>
            <p *ngIf="isEdit&& myFiles.length == 0 && taskList.files.length ==0 ">없음</p>
            <div class="table-responsive"
              *ngIf="!isEdit&&myFiles&& myFiles.length>0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">파일명</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of myFiles">
                    <td>
                      {{file.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="table-responsive"
                *ngIf="isEdit && myFiles.length + taskList.files.length > 0 ">
                <table class="table table-centered mb-0 table-nowrap"
                  *ngIf="myFiles.length > 0">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">업로드 파일명</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of myFiles">
                      <td>
                        {{file.name }}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-centered mb-0 table-nowrap"
                  *ngIf="taskList.files.length > 0">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">파일명</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of  taskList.files">
                      <td>
                        {{getFileName(file) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          <div class="mb-3"
            *ngIf="(inquiryList$ | async) as inquiry">
            <label for="status">해당문의</label>
            <ng-select [items]="inquiry"
              placeholder="업무에 해당하는 문의를 선택해주세요."
              bindLabel="username"
              [searchFn]="customSearchFn"
              bindValue="id"
              formControlName="inquiryId">
              <ng-template ng-option-tmp
                let-item="item">
                {{item.username}} <br />
                <small>{{item.company}}</small>
              </ng-template>
            </ng-select>
          </div>
          <div class="mb-3"
            *ngIf="(userList$ | async) as users">
            <label for="status">업무공유멤버</label>
            <ng-select [items]="users"
              placeholder="업무를 공유할 멤버를 선택해주세요."
              bindLabel="name"
              [searchable]
              [multiple]="true"
              bindValue="id"
              formControlName="joinUsers"></ng-select>
          </div>
        </div>
      </div>
      <button class="btn btn-success">{{isEdit?'수정저장':'업무생성'}}</button>
    </form>
  </div>
  <div class="card"
    *ngIf="taskList&&!isEdit">

    <div class="card-body">
      <h2 class="font-size-15">업무기본</h2>
      <div class="table-responsive m-0">
        <table class="table table-nowrap m-0">
          <tbody>
            <tr>
              <th scope="row text-right">생성일시 :</th>
              <td>{{taskList.dateCreated | date:'yyyy-MM-dd HH:mm'}}</td>
            </tr>
            <tr>
              <th scope="row text-right">생성자 :</th>
              <td>{{('users/' + taskList.createdBy | doc | async)?.name}}</td>
            </tr>
            <tr>
              <th scope="row text-right">참여자 :</th>
              <td>
                <div *ngFor="let item of taskList.joinUsers">
                  {{( 'users/' + item | doc | async)?.name}}
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row  text-right">업무명 :</th>
              <td>{{ taskList.name }}</td>
            </tr>
            <tr *ngIf="taskList.inquiryId">
              <th scope="row  text-right">문의명 :</th>
              <td>{{ ('inquiries/' + taskList.inquiryId | doc | async)?.username }}
                <a href="javascript: void(0);"
                  class="btn btn-outline-dark btn-sm btn-rounded ml-2"
                  (click)="goInquiryDetail()">문의상세</a>
              </td>
            </tr>
            <tr>
              <th scope="row  text-right">업무구분 :</th>
              <td>{{ taskList.taskType }}</td>
            </tr>
            <tr>
            <tr>
              <th scope="row  text-right">상태 :</th>
              <td>{{ taskList.status }}</td>
            </tr>
            <tr>
              <!-- <tr>
                              <th>상세 내용 :</th>
                              <td style="width: 45px;">{{ inquiry.content }}</td>
                          </tr> -->
          </tbody>
        </table>
      </div>

      <h2 class="font-size-15 mt-4">상세내용</h2>
      <p>{{ taskList.description }}</p>

      <h2 class="font-size-15 mt-4">첨부파일</h2>
      <p *ngIf="!taskList.files ||  taskList.files.length == 0">없음</p>

      <div class="table-responsive"
        *ngIf="taskList.files&& taskList.files.length>0">
        <table class="table table-centered mb-0 table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">파일명</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of taskList.files">
              <td>
                {{getFileName(file) }}
              </td>
              <td>
                <button type="button"
                  class="btn btn-sm btn-outline-dark"
                  (click)="downloadFile(file)">다운로드</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer bg-transparent border-top"
      *ngIf="taskList.status !=='완료'">
      <div class="text-center">
        <a href="javascript: void(0);"
          *ngIf="taskList.createdBy == dataService.userId"
          (click)="editTaskList()"
          class="btn btn-dark m-2">업무수정</a>
        <a href="javascript: void(0);"
          (click)="endTaskList()"
          class="btn btn-primary">완료처리</a>
      </div>
    </div>
  </div>
</div>