/** @format */

import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { DbService } from "./db.service";

@Injectable({
  providedIn: "root",
})
export class NoticeService {
  typeData = [
    {
      type: "inquiry",
      title: "새로운 문의가 등록되었습니다.",
    },
    {
      type: "educationRequest",
      title: "새로운 교육신청이 등록되었습니다.",
    },
    {
      type: "meeting",
      title: "새로운 미팅이 등록되었습니다.",
    },
    {
      type: "asRequest",
      title: "새로운 수정요청이 등록되었습니다.",
    },
    {
      type: "asCompleted",
      title: "수정요청이 완료되었습니다.",
    },
    {
      type: "asChecked",
      title: "수정요청을 담당자가 확인하였습니다.",
    },
    {
      type: "asFeedBackToCreater",
      title: "담당자가 수정요청에 피드백을 달았습니다.",
    },
    {
      type: "asFeedBackToManager",
      title: "요청자가 수정요청에 피드백을 달았습니다.",
    },
    {
      type: "taskList",
      title: "새로운 업무가 등록되었습니다.",
    },
    {
      type: "rentTestphone",
      title: "새로운 테스트폰 대여가 등록되었습니다.",
    },
    {
      type: "returnTestphone",
      title: "테스트폰이 반납되었습니다.",
    },
    {
      type: "extensionRentTestphone",
      title: "테스트폰이 대여 연장되었습니다.",
    },
  ];

  requestType = [
    {
      type: "newRequest",
      status: "신청",
      title: "신청이 등록되었습니다.",
    },
    {
      type: "requestApproval",
      status: "승인",
      title: "신청이 승인되었습니다.",
    },
    {
      type: "requestDeny",
      status: "반려",
      title: "신청이 반려되었습니다.",
    },
    {
      type: "requestHold",
      status: "보류",
      title: "신청이 보류되었습니다.",
    },
  ];

  constructor(public db: DbService) {}

  addNotice(userId: string, type: string, description: string, refId?: string) {
    const findType = this.typeData.find((item) => item.type === type);
    const notice = {
      dateCreated: new Date().toISOString(),
      type: type,
      title: findType.title,
      description: description,
      userId: userId,
      checked: false,
      refId: refId ? refId : "",
    };
    return this.db.updateAt("notices", notice);
  }

  addRequestdNotice(
    userId: string,
    type: string,
    description: string,
    refType: string,
    refId?: string
  ) {
    console.log("userId", userId, type, description, refType, refId);

    const findType = this.requestType.find((item) => item.type === type);
    const notice = {
      dateCreated: new Date().toISOString(),
      type: findType.type,
      title: `${refType} ${findType.title}`,
      description: description,
      userId: userId,
      checked: false,
      refType: refType,
      refId: refId ? refId : "",
    };
    return this.db.updateAt("notices", notice);
  }
}
