import { Component, Input, OnInit } from "@angular/core";
import {
  MbscCalendarEvent,
  MbscDatepickerOptions,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  Notifications,
} from "@mobiscroll/angular";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import firebase from "firebase/compat/app";
import moment from "moment";
import { combineLatest, Observable } from "rxjs";
import { map, first } from "rxjs/operators";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
import { Department } from "src/app/core/models/department.model";
import { Meeting } from "src/app/core/models/meeting.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import {
  DbService,
  leftJoinOneDocument,
} from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StibeeService } from "src/app/core/services/stibee.service";
import { Inquiry } from "src/app/core/models/inquiry.model";

@Component({
  selector: "app-add-schedule",
  templateUrl: "./add-schedule.component.html",
  styleUrls: ["./add-schedule.component.scss"],
})
export class AddScheduleComponent implements OnInit {
  @Input() public inquiry;
  @Input() public contactCompany: ContactCompany;
  @Input() public meeting: Meeting;
  @Input() public type;
  @Input() public startDate;
  colorPicker: any;
  selectedHour = 30;
  popupEventTitle: string | undefined;
  popupEventDescription = "";
  popupEventAllDay = false;
  popupEventStatus = "busy";
  isVideoMeeting = false;
  meetingBackupData = [];
  popupButtons: any = [];
  userData$: Observable<any>;
  contactCompanyList$: Observable<any[]>;
  calendarSelectedDate: any = new Date();
  switchLabel: any = "하루종일";
  tempColor = "";
  isPeriod = false;
  selectedColor = "";
  selectedType = "";
  selectedRoom = "";
  selectInquiryId: string;
  selectInquiryData;
  selectContactCompany;
  inquiryData$;
  selectEndDate;
  combineList$;
  combineList;
  minDate = new Date();
  datePickerOptions: MbscDatepickerOptions = {
    stepMinute: 30,
    touchUi: true,
  };
  datePickerControls = ["date"];
  datetimePickerControls = ["calendar", "timegrid"];
  datetimePickerResponsive = {
    medium: {
      controls: ["calendar", "timegrid"],
      touchUi: false,
    },
  };

  datePickerResponsive: any = {
    medium: {
      controls: ["calendar"],
      touchUi: false,
    },
  };

  meetingType = [
    { color: "#ffeb3c", text: "내부 미팅", value: "memberMeeting" },
    { color: "#ff9900", text: "클라이언트 미팅", value: "clientMeeting" },
    { color: "#f44437", text: "상담 미팅", value: "consultMeeting" },
    { color: "#3c6dff", text: "일반 미팅", value: "normalMeeting" },
    { color: "#763cff", text: "제휴 미팅", value: "partnerShipMeeting" },
  ];

  meetingMinute = [
    { text: "30분", value: 30 },
    { text: "1시간", value: 60 },
    { text: "1시간 30분", value: 90 },
    { text: "2시간", value: 120 },
    { text: "2시간 30분", value: 150 },
    { text: "3시간", value: 180 },
  ];

  meetingRoom = [
    { text: "외부", value: "outside", disabled: false, shortText: "(외부)" },
    { text: "사용안함", value: "noRoom", disabled: false, shortText: "" },
    {
      text: "대회의실",
      value: "conferenceRoom",
      disabled: true,
      shortText: "(대)",
    },
    {
      text: "1회의실",
      value: "meetingRoom1",
      disabled: true,
      shortText: "(1)",
    },
    {
      text: "2회의실",
      value: "meetingRoom2",
      disabled: true,
      shortText: "(2)",
    },
    {
      text: "3회의실",
      value: "meetingRoom3",
      disabled: true,
      shortText: "(3)",
    },
    {
      text: "4회의실",
      value: "meetingRoom4",
      disabled: true,
      shortText: "(4)",
    },
    {
      text: "5회의실",
      value: "meetingRoom5",
      disabled: true,
      shortText: "(5)",
    },
    {
      text: "6회의실",
      value: "meetingRoom6",
      disabled: true,
      shortText: "(6)",
    },
    {
      text: "7회의실",
      value: "meetingRoom7",
      disabled: true,
      shortText: "(7)",
    },
  ];

  colorAnchor: HTMLElement | undefined;
  clients = [];
  userData = [];
  isEdit = false;
  colors = [
    "#ffeb3c",
    "#ff9900",
    "#f44437",
    "#ea1e63",
    "#9c26b0",
    "#3f51b5",
    "#009788",
    "#4baf4f",
    "#7e5d4e",
  ];

  isCreater = true;
  videoMeetingUrl = "";
  selectedClient;
  selectStartDate;
  selectedUser = [];
  submit = false;
  meetingData: MbscCalendarEvent[] = [];
  popupHeaderText!: string;
  popupAnchor: HTMLElement | undefined;
  popupAddButtons = [
    "cancel",
    {
      handler: () => {
        this.saveEvent();
      },
      keyCode: "enter",
      text: "등록",
      disabled: true,
      cssClass: "mbsc-popup-button-primary",
    },
  ];
  eventSettings: MbscEventcalendarOptions = {
    theme: "ios",
    themeVariant: "light",
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false,
    eventDelete: false,
  };
  view: MbscEventcalendarView = {
    calendar: { type: "month", labels: "all" },
    // agenda: { type: "month" },
  };
  constructor(
    public dataService: DataService,
    public activeModal: NgbActiveModal,
    public alertService: AlertService,
    public db: DbService,
    private notify: Notifications,
    private loaderSevice: LoaderService,
    private stibeeService: StibeeService
  ) {}

  async ngOnInit(): Promise<void> {
    this._fetchData();
  }
  async _fetchData() {
    await this.dataService.inIt();
    if (this.meeting && !this.inquiry) {
      if (this.meeting.refType == "inquiry") {
        this.inquiry = await this.db
          .doc$(`inquiries/${this.meeting.refId}`)
          .pipe(first())
          .toPromise();
      }
      console.log("this.inquiry", this.inquiry);
    }
    if (this.inquiry) {
      this.selectedType = "consultMeeting";
      this.selectInquiryData = this.inquiry;
      this.popupEventTitle = `상담미팅-${
        this.inquiry.uesername ? this.inquiry.usernam : "삭제문의"
      }`;
      const meetings = await this.dataService.getMeetings();

      this.meetingData = meetings.map((item) => {
        const time = moment(new Date(item.start.seconds * 1000)).format(
          "HH:mm"
        );
        const room = this.meetingRoom.find(
          (room) => room.value == item.meetingRoom
        );
        const meeting = {
          id: item.id,
          start: new Date(item.start.seconds * 1000),
          end: new Date(item.end.seconds * 1000),
          title: `${room.shortText}${time}  ${item.title}`,
          eventTitle: item.title,
          description: item.description,
          allDay: item.allDay,
          free: false,
          color: item.color,
          meetingRoom: item.meetingRoom,
          type: item.type,
          joinUsers: item.joinUsers.map((users) => users.id),
          dateCreated: item.dateCreated,
          createdBy: item.createdBy,
        };
        return meeting;
      });
      this.meetingBackupData = [...this.meetingData];
    }
    if (this.contactCompany) {
      this.selectedType = "partnerShipMeeting";
      this.popupEventTitle = `제휴미팅-${
        this.contactCompany.companyName
          ? this.contactCompany.companyName
          : this.contactCompany.presedent
      }`;

      const meetings = await this.dataService.getMeetings();

      this.meetingData = meetings.map((item) => {
        const time = moment(new Date(item.start.seconds * 1000)).format(
          "HH:mm"
        );
        const room = this.meetingRoom.find(
          (room) => room.value == item.meetingRoom
        );
        const meeting = {
          id: item.id,
          start: new Date(item.start.seconds * 1000),
          end: new Date(item.end.seconds * 1000),
          title: `${room.shortText}${time}  ${item.title}`,
          eventTitle: item.title,
          description: item.description,
          allDay: item.allDay,
          free: false,
          color: item.color,
          meetingRoom: item.meetingRoom,
          type: item.type,
          joinUsers: item.joinUsers.map((users) => users.id),
          dateCreated: item.dateCreated,
          createdBy: item.createdBy,
        };
        return meeting;
      });

      this.meetingBackupData = [...this.meetingData];
    }

    const userData$ = this.db.collection$("users").pipe(
      map((users) => {
        return users.map((user: User) => {
          return {
            text: user.name,
            value: user.id,
            avatar: user.image,
            type: "user",
          };
        });
      })
    );

    const departments$ = this.db.collection$("departments").pipe(
      map((departments) => {
        return departments.map((department: Department) => {
          return {
            text: department.name,
            value: department.id,
            avatar: "",
            type: "department",
          };
        });
      })
    );

    this.combineList$ = combineLatest(departments$, userData$).pipe(
      map((data) => {
        const newArray = [...data[0], ...data[1]];
        this.combineList = newArray;
        return newArray;
      })
    );

    this.inquiryData$ = this.db
      .collection$("inquiries", (ref) => ref.where("status", "!=", "완료"))
      .pipe(
        map((inquiryData) => {
          return inquiryData.map((item) => {
            return {
              email: item.email,
              text:
                item.username && item.company
                  ? `${item.company}-${item.username}`
                  : item.username
                  ? item.username
                  : item.company
                  ? item.company
                  : "입력안함",
              value: item.id,
              avatar: "m1",
            };
          });
        })
      );

    this.contactCompanyList$ = this.db.collection$("contactCompany").pipe(
      map((contactCompanyList) => {
        return contactCompanyList.map((item) => {
          return {
            text: item.companyName
              ? `${item.companyName}-${item.presedent}`
              : item.presedent,
            value: item.id,
            avatar: "m1",
          };
        });
      })
    );

    this.setMeeting();
  }

  setMeeting() {
    if (this.type == "edit") {
      const startDate = this.meeting.start.seconds
        ? new Date(this.meeting.start.seconds * 1000)
        : this.meeting.start;
      const endDate = this.meeting.end.seconds
        ? new Date(this.meeting.end.seconds * 1000)
        : this.meeting.end;
      this.selectStartDate = startDate;
      this.selectEndDate = endDate;
      if (!this.inquiry && this.meeting.type == "consultMeeting") {
        this.selectInquiryId = this.meeting.refId as string;
      }

      if (!this.contactCompany && this.meeting.type == "partnerShipMeeting") {
        this.selectContactCompany = this.meeting.refId;
      }

      this.popupEventTitle = this.meeting.eventTitle
        ? this.meeting.eventTitle
        : this.meeting.title;
      this.popupEventDescription = this.meeting.description;
      this.popupEventAllDay = this.meeting.allDay;
      this.isVideoMeeting = this.meeting.isVideoMeeting;
      this.videoMeetingUrl = this.meeting.videoMeetingUrl;

      this.selectedRoom = this.meeting.meetingRoom;
      this.selectedType = this.meeting.type;
      this.selectedUser = this.meeting.joinUsers.map((users) => users.id);
    } else {
      const startDate = this.startDate ? this.startDate : new Date();

      startDate.setHours(12);
      const endDate = new Date(startDate);
      endDate.setMinutes(endDate.getMinutes() + this.selectedHour);
      this.selectStartDate = startDate;
      this.selectEndDate = endDate;
      if (this.inquiry) {
        this.selectedType = "consultMeeting";
        this.popupEventTitle = `상담미팅-${
          this.inquiry.username ? this.inquiry.username : "삭제문의"
        }`;
      }

      if (this.contactCompany) {
        this.selectedType = "partnerShipMeeting";
        this.popupEventTitle = `제휴미팅-${
          this.contactCompany.companyName
            ? this.contactCompany.companyName
            : this.contactCompany.presedent
        }`;
      }
    }
  }

  passBack() {
    this.activeModal.close();
  }

  checkStatus() {
    if (this.popupButtons.length > 1) {
      if (this.popupEventTitle && this.selectedType) {
        this.popupButtons[1].disabled = false;
      } else {
        this.popupButtons[1].disabled = true;
      }
    }
  }

  selectedInquiry(event, inquiries) {
    console.log("inquiries", inquiries);

    this.selectInquiryData = inquiries.find(
      (item) => item.value == this.selectInquiryId
    );
    console.log(" this.selectInquiryData", this.selectInquiryData);
  }

  getMeetingRoomText(value: string) {
    const selectMeetingRoom = this.meetingRoom.find(
      (item) => item.value == value
    );
    return selectMeetingRoom.text;
  }

  async saveEvent() {
    this.loaderSevice.setLoading(true);
    this.submit = true;
    if (
      !this.popupEventTitle ||
      !this.selectedRoom ||
      !this.selectStartDate ||
      this.selectedUser.length == 0
    ) {
      this.loaderSevice.setLoading(false);

      return false;
    }
    const joinUsers = this.selectedUser.map((user) => {
      const find = this.combineList.find((item) => item.value == user);
      const newUserData = {
        id: find.value,
        type: find.type,
      };
      return newUserData;
    });
    const id = await this.db.createFsId();
    const tempEvent: Meeting = {
      id,
      title: this.popupEventTitle,
      description: this.popupEventDescription ? this.popupEventDescription : "",
      start: this.selectStartDate,
      end: this.selectEndDate,
      allDay: this.popupEventAllDay,
      isVideoMeeting: this.isVideoMeeting,
      videoMeetingUrl: this.videoMeetingUrl ? this.videoMeetingUrl : "",
      meetingRoom: this.selectedRoom,
      type: this.selectedType,
      durationTime: this.selectedHour,
      refType:
        this.selectedType == "consultMeeting"
          ? "inquiry"
          : this.selectedType == "partnerShipMeeting"
          ? "partnerShip"
          : "",
      refId: this.inquiry
        ? this.inquiry.id
        : this.selectInquiryId
        ? this.selectInquiryId
        : this.contactCompany
        ? this.contactCompany.id
        : this.selectContactCompany
        ? this.selectContactCompany
        : "",
      color: this.getColor(this.selectedType),
      joinUsers: joinUsers,
      dateCreated: new Date().toISOString(),
      createdBy: this.dataService.userId,
    };

    if (this.type == "edit") {
      tempEvent.id = this.meeting.id;
      console.log("tempEvent", tempEvent);

      await this.dataService.updateMeeting(tempEvent);
      this.alertService.success("미팅이 수정되었습니다.");
    } else {
      await this.dataService.createMeeting(tempEvent);
      console.log("this.selectInquiryData", this.selectInquiryData);

      if (tempEvent.refType == "inquiry" && this.selectInquiryData.email) {
        const email = this.selectInquiryData.email;
        const name = this.selectInquiryData.text
          ? this.selectInquiryData.text
          : "고객";
        const dateTime = moment(this.selectStartDate).format(
          "YYYY년 M월 D일 h:mm"
        );
        const date = moment(this.selectStartDate).format("YYYY년 M월 D일");
        if (tempEvent.isVideoMeeting) {
          // this.stibeeService.sendScheduleVideoMeeting(
          //   email,
          //   name,
          //   dateTime,
          //   date
          // );
        } else {
          const place =
            this.selectedRoom == "outside"
              ? "협의 장소"
              : `서울특별시 성동구 아차산로 17길 48<br>(SKV1 센터 2층 ${this.getMeetingRoomText(
                  this.selectedRoom
                )})`;
          // this.stibeeService.sendScheduleMeeting(
          //   email,
          //   name,
          //   dateTime,
          //   date,
          //   place
          // );
        }
        this.alertService.success("미팅이 등록되었습니다..");
      } else {
        this.alertService.success("미팅이 등록되었습니다.");
      }
    }
    this.calendarSelectedDate = this.selectStartDate;
    this.loaderSevice.setLoading(false);
    this.activeModal.close(tempEvent);
  }

  getColor(type) {
    const find = this.meetingType.find((item) => item.value == type);
    return find.color;
  }

  checkStartDates() {
    if (this.selectStartDate) {
      const endDate = new Date(this.selectStartDate);
      endDate.setMinutes(endDate.getMinutes() + this.selectedHour);
      this.selectEndDate = endDate;
      const start = moment(this.selectStartDate).format("YYYY-MM-DD");
      const end = moment(this.selectStartDate).format("YYYY-MM-DD");
      this.getMeetingData(start, end);
    }
  }

  async getMeetingData(start, end) {
    if (start) {
      this.isPeriod = false;
      const date1 = new Date(start);
      date1.setHours(9, 0, 0, 0); // +9 hours for Korean Time
      const date2 = new Date(end);
      date2.setDate(date2.getDate() + 1);
      date2.setHours(9, 0, 0, 0); // +9 hours for Korean Time
      const startDate = firebase.firestore.Timestamp.fromDate(date1);
      const endDate = firebase.firestore.Timestamp.fromDate(date2);
      const data = await this.dataService.getMeetingData(startDate, endDate);
      const checkData = this.checkTime(data);
      const id = this.type == "edit" ? this.meeting.id : "";
      this.meetingRoom = this.meetingRoom.map((room) => {
        const find = checkData.find(
          (data) => data.meetingRoom == room.value && data.id != id
        );
        if (room.text == "외부") {
          return room;
        }
        room.disabled = false;
        if (find) {
          room.disabled = true;
        }
        return room;
      });
    }
  }

  changeTitle() {
    console.log("changeTitle");

    if (this.inquiry) {
      if (this.isVideoMeeting) {
        this.popupEventTitle = `상담(화상)-${
          this.inquiry.username ? this.inquiry.username : "삭제문의"
        }`;
      } else {
        this.popupEventTitle = `상담-${
          this.inquiry.username ? this.inquiry.username : "삭제문의"
        }`;
      }
    } else if (this.contactCompany) {
      if (this.isVideoMeeting) {
        this.popupEventTitle = `제휴(화상)-${
          this.contactCompany.companyName
            ? this.contactCompany.companyName
            : this.contactCompany.presedent
        }`;
      } else {
        this.popupEventTitle = `제휴-${
          this.contactCompany.companyName
            ? this.contactCompany.companyName
            : this.contactCompany.presedent
        }`;
      }
    }
  }

  checkTime(data) {
    let start = this.selectStartDate;
    let end = this.selectEndDate;
    const checkData = data.filter((meeting) => {
      const date1 = new Date(meeting.start.seconds * 1000 + 12000);
      const date2 = new Date(meeting.end.seconds * 1000 - 12000);
      const isBeetweenStart = moment(date1).isBetween(start, end);
      const isBeetweenEnd = moment(date2).isBetween(start, end);
      if (isBeetweenStart || isBeetweenEnd) {
        return true;
      }

      return false;
    });
    return checkData;
  }

  onDeleteClick(): void {
    if (this.isCreater) {
    }
  }

  async deleteEvent(event: MbscCalendarEvent): Promise<void> {
    await this.db.delete(`meetings/${event.id}`);
    this.meetingData = this.meetingData.filter((item) => item.id !== event.id);
    this.notify.snackbar({
      button: {
        action: async () => {
          const tempEvent = { ...event };
          delete tempEvent._days;
          await this.db.updateAt(`meetings/${tempEvent.id}`, tempEvent);
          this.meetingData = [...this.meetingData, event];
        },
        text: "취소",
      },
      message: "미팅이 삭제되었습니다.",
    });
  }
}
