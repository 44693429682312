<!-- <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">고객 상세</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">테스트폰 대여 정보</h4>
            <!--
            <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
                person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
            <div class="table-responsive table-nowrap">
                <table class="table  mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">상태 :</th>
                            <td> <span class="badge font-size-13"
                                    [ngClass]="{'bg-success': rentTestphone?.status  === '대여중',
              'bg-secondary': rentTestphone?.status === '대기중','bg-info': rentTestphone?.status === '반납'}">
                                    {{rentTestphone?.status }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">구분 :</th>
                            <td>{{rentTestphone?.type }}</td>
                        </tr>
                        <tr>
                            <th scope="row">신청일 :</th>
                            <td>{{rentTestphone?.dateCreated | date:'yyyy년 MM월 dd일' }}</td>
                        </tr>
                        <tr>
                            <th scope="row">신청자 :</th>
                            <td>{{('users/' +
                                rentTestphone?.createdBy
                                | doc | async)?.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">대여 테스트폰 :</th>
                            <td>{{('testphones/' +
                                rentTestphone?.testphoneId
                                | doc | async)?.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">대여목적 :</th>
                            <td>
                                {{rentTestphone?.purpose}}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">대여기간 :</th>
                            <td>
                                {{rentTestphone?.period}} 일
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">반납기한 :</th>
                            <td>
                                {{getDate(rentTestphone?.expiredDate)}}
                            </td>
                        </tr>
                        <tr *ngIf="rentTestphone?.dateReturned">
                            <th scope="row">반납일 :</th>
                            <td>
                                {{rentTestphone?.dateReturned | date:'yyyy년 MM월 dd일' }}
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <button type="submit"
        (click)="passBack()"
        class="btn btn-primary w-100">닫기</button>
</div>