<div class="modal-header">
    <div class="modal-title mt-0">프로젝트 {{type =='edit'?'수정':'등록'}}</div>
    <button type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="passBack()"></button>
</div>
<div class="modal-body">
    <div>
        <form (ngSubmit)="enroll()"
            [formGroup]="formData">
            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <label for="status">시작 예정일</label>
                        <input class="form-control"
                            name="dateStarted"
                            formControlName="dateStarted"
                            [ngClass]="{'is-invalid': submitForm && form.dateStarted.errors}"
                            type="date">
                        <div *ngIf="submitForm && form.dateStarted.errors"
                            class="invalid-feedback">
                            <span *ngIf="form.dateStarted.errors.required">시작 예정일은 필수사항입니다.</span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="status">프로젝트 매니저 배정</label>
                        <ng-select [items]="users"
                            *ngIf="(userList$ | async) as users"
                            placeholder="프로젝트를 담당할 PM을 선택해주세요."
                            bindLabel="name"
                            [searchable]
                            [multiple]="false"
                            bindValue="id"
                            [ngClass]="{'is-invalid': submitForm && form.pm.errors}"
                            formControlName="pm"></ng-select>
                        <div *ngIf="submitForm && form.pm.errors"
                            class="invalid-feedback">
                            <span *ngIf="form.pm.errors.required">시작 예정일은 필수사항입니다.</span>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-success">{{type =='edit'?'수정':'등록'}}</button>
        </form>
    </div>
</div>