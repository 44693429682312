<div class="modal-header">
  <h2 class="modal-title mt-0">포트폴리오 {{porfolioId?'수정':'등록'}}</h2>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">

  <div class="card">
    <div class="card-body">

      <h2 class="card-title">기본 정보</h2>
      <p class="card-title-desc">하단의 모든 항목을 입력해주세요.</p>
      <div class="row">
        <div class="mb-3">
          <label for="productname">프로젝트명</label>
          <input id="productname"
            name="productname"
            type="text"
            class="form-control"
            [(ngModel)]="newPortFolio.title"
            placeholder="프로젝트명을 입력해주세요.">
        </div>
        <div class="mb-3">
          <label for="productname">노출여부</label>
          <ui-switch defaultBoColor="#dfdfdf"
            size="small"
            class="form-control"
            [(ngModel)]="newPortFolio.displaySwitch"
            color="#f46a6a"></ui-switch>
        </div>


        <div class="mb-3">
          <label class="control-label">카테고리</label>
          <ng-select [items]="appCategories"
            bindLabel="title"
            bindValue="id"
            [(ngModel)]="newPortFolio.categoryId"
            placeholder="카테고리를 선택해주세요."></ng-select>

        </div>
        <div class="mb-3">
          <label class="control-label">주요기능</label>
          <ng-select
            [items]="['회원관리','쇼핑몰','커뮤니티','배송','피드','측정','방명록','챗봇','공유','결제','다국어','매칭','실시간채팅','정산','업체관리','정보제공','위치기반','PDF저장','교육','게임','음악','알고리즘','상담','후기','동영상','지도','신청','문의','컨텐츠 제공','상담','QR코드','예약','직원관리','오픈마켓','멤버십','포인트','쿠폰','스트리밍','유튜부','광고','기획전','컨텐츠판매','설문','상품소개','기록','블루투스연결','IoT','진단']"
            placeholder="주요기능을 선택해주세요.(다중선택 가능)"
            [(ngModel)]="newPortFolio.functions"
            [multiple]="true"></ng-select>
        </div>
        <div class="mb-3">
          <label class="control-label">태그</label>
          <mat-chip-list #chipList
            aria-label="Tag selection">
            <mat-chip *ngFor="let tag of newPortFolio.tag"
              (removed)="remove(tag)">
              {{tag}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input placeholder="신규 태그"
              #tagInput
              [formControl]="tagCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete"
            (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of filteredTags | async"
              [value]="tag">
              {{tag}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div class="mb-3">
          <label for="productname">안드로이드 스토어 URL</label>
          <input id="productname"
            name="productname"
            type="text"
            class="form-control"
            [(ngModel)]="newPortFolio.androidStoreUrl"
            placeholder="안드로이드 스토어 URL을 입력해주세요.">

        </div>
        <div class="mb-3">
          <label for="productname">iOS 스토어 URL</label>
          <input id="productname"
            name="productname"
            type="text"
            class="form-control"
            [(ngModel)]="newPortFolio.iosStoreUrl"
            placeholder="iOS 스토어 URL을 입력해주세요.">
        </div>
        <div class="mb-3">
          <label for="productname">웹 URL</label>
          <input id="productname"
            name="productname"
            type="text"
            class="form-control"
            [(ngModel)]="newPortFolio.webUrl"
            placeholder="웹 URL을 입력해주세요.">
        </div>
        <div class="mb-3">

          <label for="product-image">메인 이미지</label>
          <input class="form-control"
            type="file"
            (change)="onImageChange($event,'mainImage')"
            id="formFile">

          <div class="image-wrap mt-3 mb-3"
            *ngIf="newPortFolio.mainImage">
            <div class="image-view">
              <img src="{{ newPortFolio.mainImage }}"
                alt=""
                class="img-fluid mx-auto d-block rounded" />
            </div>
            <button class="image-close-btn"
              (click)="deleteImage('mainImage')">
              <i class="mdi mdi-close"> </i>
            </button>
          </div>
        </div>
        <div class="mb-3">

          <label for="product-image">상세 이미지</label>
          <input class="form-control"
            type="file"
            (change)="onImageChange($event,'detailImage')"
            id="formFile">

          <div class="image-wrap mt-3 mb-3"
            *ngIf="newPortFolio.detailImage">
            <div class="image-view">
              <img src="{{ newPortFolio.detailImage }}"
                alt=""
                class="img-fluid mx-auto d-block rounded" />
            </div>
            <button class="image-close-btn"
              (click)="deleteImage('detailImage')">
              <i class="mdi mdi-close"> </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button class="btn btn-primary"
    (click)="save()">{{porfolioId?'저장':'등록'}}</button>
</div>