import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { Observable } from "rxjs";
import { finalize, first, take } from "rxjs/operators";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { Meeting } from "src/app/core/models/meeting.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import {
  DbService,
  getMeetingRef,
  leftArrayJoin,
  leftJoinOneDocument,
  leftUsersJoin,
} from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { InquiryDetailComponent } from "../inquiry-detail/inquiry-detail.component";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
import { ContactCompanyDetailComponent } from "../contact-company-detail/contact-company-detail.component";
@Component({
  selector: "app-meeting-detail",
  templateUrl: "./meeting-detail.component.html",
  styleUrls: ["./meeting-detail.component.scss"],
})
export class MeetingDetailComponent implements OnInit {
  @Input() public meetingId;
  meeting$: Observable<Meeting>;
  meeting;
  meetingType = [
    { color: "#ffeb3c", text: "내부 미팅", value: "memberMeeting" },
    { color: "#ff9900", text: "클라이언트 미팅", value: "clientMeeting" },
    { color: "#f44437", text: "상담 미팅", value: "consultMeeting" },
    { color: "#3c6dff", text: "일반 미팅", value: "normalMeeting" },
    { color: "#763cff", text: "제휴 미팅", value: "partnerShipMeeting" },
  ];

  meetingRoom = [
    { text: "외부", value: "outside", disabled: true, shortText: "(외부)" },

    { text: "사용안함", value: "noRoom", disabled: true, shortText: "" },
    {
      text: "대회의실",
      value: "conferenceRoom",
      disabled: true,
      shortText: "(대)",
    },
    {
      text: "1회의실",
      value: "meetingRoom1",
      disabled: true,
      shortText: "(1)",
    },
    {
      text: "2회의실",
      value: "meetingRoom2",
      disabled: true,
      shortText: "(2)",
    },
    {
      text: "3회의실",
      value: "meetingRoom3",
      disabled: true,
      shortText: "(3)",
    },
    {
      text: "4회의실",
      value: "meetingRoom4",
      disabled: true,
      shortText: "(4)",
    },
    {
      text: "5회의실",
      value: "meetingRoom5",
      disabled: true,
      shortText: "(5)",
    },
    {
      text: "6회의실",
      value: "meetingRoom6",
      disabled: true,
      shortText: "(6)",
    },
    {
      text: "7회의실",
      value: "meetingRoom7",
      disabled: true,
      shortText: "(7)",
    },
  ];
  constructor(
    public activeModal: NgbActiveModal,
    public db: DbService,
    private modalService: NgbModal,
    private loaderSerivce: LoaderService,
    private alertService: AlertService,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    this.loaderSerivce.setLoading(true);
  }

  async ngAfterViewInit() {
    this.meeting = await this.db
      .doc$(`meetings/${this.meetingId}`)
      .pipe(
        getMeetingRef(this.db.afs),
        leftUsersJoin(this.db.afs, "joinUsers"),
        take(1),
        finalize(() => this.loaderSerivce.setLoading(false))
      )
      .toPromise();
    console.log("this.meeting", this.meeting);
  }

  passBack() {
    this.activeModal.close();
  }

  getMeetingTypeText(color) {
    if (color) {
      const find = this.meetingType.find((item) => item.color == color);
      if (find) {
        return find.text;
      }
    }
  }
  getMeetingRoom(meetingRoom) {
    if (meetingRoom) {
      const find = this.meetingRoom.find((item) => item.value == meetingRoom);
      if (find) {
        return find.text;
      }
    }
  }

  getDate(start, end) {
    if (start) {
      const startDate1 = new Date(start.seconds * 1000);
      const endtDate1 = new Date(end.seconds * 1000);
      const startDate2 = moment(startDate1).format("YYYY-MM-DD");
      const endtDate2 = moment(endtDate1).format("YYYY-MM-DD");
      if (moment(startDate2).isSame(endtDate2)) {
        const startDate = moment(startDate1).format("M월 DD일 (dd) LT");
        const endDate = moment(endtDate1).format("LT");
        return `${startDate} ~ ${endDate}`;
      }
      const startDate = moment(startDate1).format("M월 DD일 (dd) LT");
      const endDate = moment(endtDate1).format("MM월 DD일 (dd) LT");
      return `${startDate} ~ ${endDate}`;
    }
  }

  goInquiryDetail(inquiry: Inquiry) {
    const modalRef = this.modalService.open(InquiryDetailComponent, {
      fullscreen: true,
      scrollable: true,
    });
    modalRef.componentInstance.inquiryId = inquiry.id;
  }

  editMeeting() {
    this.activeModal.close("edit");
  }

  goContactCompanyDetail(contactCompany: ContactCompany) {
    const modalRef = this.modalService.open(ContactCompanyDetailComponent, {
      fullscreen: true,
      scrollable: true,
    });
    modalRef.componentInstance.contactCompanyId = contactCompany.id;
  }

  async resumeMeeting() {
    const confirm = await this.alertService.confirm(
      "알림",
      "해당 취소된 미팅을 다시 되돌리시겠습니까?",
      "확인"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${this.meetingId}`, {
        isCanceled: false,
        dateCanceld: "",
        canceledBy: "",
      });
      this.meeting.isCanceled = false;
      this.alertService.success("미팅이 다시 예약되었습니다.");
    }
  }

  async cancelMeeting() {
    const confirm = await this.alertService.confirm(
      "미팅 취소 확인",
      "해당 미팅을 정말로 취소하시겠습니까?",
      "미팅 취소"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${this.meetingId}`, {
        isCanceled: true,
        dateCanceld: new Date().toISOString(),
        canceledBy: this.dataService.userId,
      });
      this.meeting.isCanceled = true;
      this.alertService.success("미팅이 취소되었습니다.");
    }
  }

  async deletMeeting() {
    const confirm = await this.alertService.confirm(
      "미팅 삭제 확인",
      "해당 미팅을 정말로 삭제하시겠습니까?",
      "미팅 삭제"
    );
    if (confirm) {
      await this.db.updateAt(`deledteMeetings/${this.meetingId}`, {
        ...this.meeting,
        dateDeleted: new Date().toISOString(),
        deletedBy: this.dataService.userId,
      });
      await this.db.delete(`meetings/${this.meetingId}`);
      this.alertService.success("미팅이 삭제되었습니다.");
      this.activeModal.close("delete");
    }
  }
}
