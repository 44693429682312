import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class StibeeService {
  apiKey =
    "28e392bebce9387437fefe3c4cb130a7b967f8ea2cad95a022f7db7cd67a22e757116f28673a84f201d6c13bc96082fea32503526b533869174b7df77f6add89";

  constructor(private http: HttpClient) {}

  sendScheduleMeeting(
    email: string,
    name: string,
    dateTime: string,
    date: string,
    place: string
  ) {
    const apiUrl =
      "https://stibee.com/api/v1.0/auto/MTllNmFhNjktNzE2NC00ZjBiLWI4NTEtNGViZGIxYzk1NjAw";
    const headers = new HttpHeaders({
      AccessToken: this.apiKey,
      ["Content-Type"]: "application/json",
    });

    //당일미팅알림 API - https://stibee.com/api/v1.0/auto/OTYxMzY2NGEtYzcwMi00ODZiLWJhYjUtYjYxMDAwYTAzMDQw//
    //화상미팅 API - 'https://stibee.com/api/v1.0/auto/YThiNTkyNDAtMjMwNC00NjQ3LTk0OTItZGMyYTQyOTg3M2Zm'
    // 요청 바디
    const requestBody = {
      subscriber: email,
      name,
      dateTime,
      date,
      place,
    };

    // POST 요청 보내기
    this.http.post(apiUrl, requestBody, { headers }).subscribe(
      (response) => {
        // 요청 성공 시 처리할 로직
        console.log(response);
      },
      (error) => {
        // 요청 실패 시 처리할 로직
        console.error(error);
      }
    );
  }

  sendScheduleVideoMeeting(
    email: string,
    name: string,
    dateTime: string,
    date: string
  ) {
    const apiUrl =
      "https://stibee.com/api/v1.0/auto/YThiNTkyNDAtMjMwNC00NjQ3LTk0OTItZGMyYTQyOTg3M2Zm";
    const headers = new HttpHeaders({
      AccessToken: this.apiKey,
      ["Content-Type"]: "application/json",
    });

    //당일미팅알림 API - https://stibee.com/api/v1.0/auto/OTYxMzY2NGEtYzcwMi00ODZiLWJhYjUtYjYxMDAwYTAzMDQw//
    //화상미팅 API - 'https://stibee.com/api/v1.0/auto/YThiNTkyNDAtMjMwNC00NjQ3LTk0OTItZGMyYTQyOTg3M2Zm'
    // 요청 바디
    const requestBody = {
      subscriber: email,
      name,
      dateTime,
      date,
    };

    // POST 요청 보내기
    this.http.post(apiUrl, requestBody, { headers }).subscribe(
      (response) => {
        // 요청 성공 시 처리할 로직
        console.log(response);
      },
      (error) => {
        // 요청 실패 시 처리할 로직
        console.error(error);
      }
    );
  }
}
