<div class="modal-body">

  <div class="card"
    *ngIf="inquiry || contactCompany">

    <div class="card-body">
      <h4 class="card-title mb-4">미팅 일정</h4>

      <mbsc-eventcalendar [data]="meetingData"
        [view]="view"
        [options]="eventSettings"></mbsc-eventcalendar>
    </div>
  </div>

  <div class="card">

    <div class="card-body">
      <h4 class="card-title mb-4">미팅 {{meeting?'수정':'등록'}}</h4>
      <div *ngIf="!inquiry && !contactCompany">
        <mbsc-input label="제목"
          [(ngModel)]="popupEventTitle"
          required
          #title="ngModel"
          [error]="submit&& title.invalid"
          errorMessage="제목은 필수사항입니다."
          (keyup)="checkStatus()"
          placeholder="미팅 제목을 입력해주세요."></mbsc-input>

        <mbsc-textarea label="설명"
          [(ngModel)]="popupEventDescription"
          placeholder="설명을 입력해주세요."></mbsc-textarea>

        <mbsc-datepicker [(ngModel)]="selectStartDate"
          display="anchored"
          returnFormat="jsdate"
          required
          label="시작"
          [touchUi]="true"
          #dates="ngModel"
          (change)="checkStartDates()"
          [error]="submit && dates.invalid && selectStartDate"
          errorMessage="날짜를 선택해주세요."
          [options]="datePickerOptions"
          [controls]="popupEventAllDay ? datePickerControls : datetimePickerControls"
          [responsive]="popupEventAllDay ? datePickerResponsive : datetimePickerResponsive"></mbsc-datepicker>
        <mbsc-select [data]="meetingMinute"
          display="anchored"
          [(ngModel)]="selectedHour"
          [itemHeight]="40"
          *ngIf="!popupEventAllDay"
          label="진행시간"
          labelStyle="stacked"
          inputStyle="box"
          (change)="checkStartDates()"
          placeholder="진행시간을 선택해주세요"></mbsc-select>
        <mbsc-select [data]="meetingType "
          display="anchored"
          [(ngModel)]="selectedType"
          [itemHeight]="40"
          label="미팅유형"
          labelStyle="stacked"
          inputStyle="box"
          (change)="checkStatus()"
          placeholder="미팅유형을 선택해주세요"></mbsc-select>
        <mbsc-switch label="화상미팅"
          (change)="changeTitle()"
          [(ngModel)]="isVideoMeeting"></mbsc-switch>
        <mbsc-input label="화상미팅 URL"
          *ngIf="isVideoMeeting"
          [(ngModel)]="videoMeetingUrl"
          placeholder="화상 미팅 URL를 입력해주세요."></mbsc-input>
        <mbsc-select [(ngModel)]="selectInquiryId"
          *ngIf="selectedType =='consultMeeting' &&  inquiryData$ | async as inquiryData "
          [data]="inquiryData"
          display="anchored"
          [itemHeight]="50"
          label="문의"
          [filter]="true"
          labelStyle="stacked"
          required
          (change)="selectedInquiry($event,inquiryData)"
          inputStyle="box"
          #inquiry="ngModel"
          [error]="submit && inquiry.invalid"
          errorMessage="상담 미팅일 경우 문의를 선택해주세요."
          placeholder="문의를 선택해주세요."></mbsc-select>
        <mbsc-select [(ngModel)]="selectContactCompany"
          *ngIf="selectedType =='partnerShipMeeting' &&  contactCompanyList$ | async as contactCompanyList "
          [data]="contactCompanyList"
          display="anchored"
          [itemHeight]="50"
          label="업체"
          [filter]="true"
          labelStyle="stacked"
          required
          inputStyle="box"
          #contactCompany="ngModel"
          [error]="submit && contactCompany.invalid"
          errorMessage="제휴 미팅일 경우 업체를 선택해주세요."
          placeholder="업체를 선택해주세요."></mbsc-select>

        <mbsc-select [data]="meetingRoom"
          display="anchored"
          [(ngModel)]="selectedRoom"
          [itemHeight]="40"
          label="회의실"
          [disabled]="isPeriod || popupEventAllDay "
          labelStyle="stacked"
          inputStyle="box"
          (change)="checkStatus()"
          required
          #room="ngModel"
          [error]="submit && room.invalid"
          errorMessage="미팅할 회의실은 필수사항입니다."
          placeholder="미팅할 회의실을 선택해주세요"></mbsc-select>


        <mbsc-select [(ngModel)]="selectedUser"
          *ngIf="combineList$ | async as users"
          [data]="users"
          display="anchored"
          [itemHeight]="50"
          [selectMultiple]="true"
          label="참여자"
          [filter]="true"
          labelStyle="stacked"
          required
          inputStyle="box"
          #users="ngModel"
          [error]="submit && users.invalid && selectedUser.length ==0"
          errorMessage="참여자를 최소 1명이상 선택해주세요."
          placeholder="참여자를 선택해주세요."></mbsc-select>
        <ng-template #users
          let-item>
          <div class="md-image-text-item">
            <img class="md-image-text-avatar"
              [src]="item.avatar" />
            <div class="md-image-text-name">{{item.display}}</div>
          </div>
        </ng-template>
        <div class="row m-3">
          <button type="submit"
            (click)="saveEvent()"
            class="btn btn-primary">{{ type == 'add'?'미팅등록':'변경사항 저장'}}</button>
        </div>
        <!-- class="mbsc-button-group">
            <mbsc-button class="mbsc-button-block"
              color="danger"
              variant="outline"
              [disabled]="!isCreater"
              (click)="onDeleteClick()">미팅 등록
            </mbsc-button> -->
      </div>
      <div *ngIf="inquiry">
        <div class="row">
          <mbsc-input label="제목"
            [(ngModel)]="popupEventTitle"
            [disabled]="!isCreater"
            required
            #title="ngModel"
            [error]="submit&& title.invalid"
            errorMessage="제목은 필수사항입니다."
            (keyup)="checkStatus()"
            placeholder="미팅 제목을 입력해주세요."></mbsc-input>
        </div>

        <div class="row">
          <mbsc-textarea label="설명"
            [disabled]="!isCreater"
            [(ngModel)]="popupEventDescription"
            placeholder="설명을 입력해주세요."></mbsc-textarea>
        </div>
        <div class="row">
          <mbsc-switch label="화상미팅"
            [disabled]="!isCreater"
            (change)="changeTitle()"
            [(ngModel)]="isVideoMeeting"></mbsc-switch>
        </div>
        <div class="row"
          *ngIf="isVideoMeeting">
          <mbsc-input label="화상미팅 URL"
            [(ngModel)]="videoMeetingUrl"
            placeholder="화상 미팅 URL를 입력해주세요."></mbsc-input>
        </div>
        <div class="row">
          <mbsc-datepicker [(ngModel)]="selectStartDate"
            [disabled]="!isCreater"
            returnFormat="jsdate"
            (change)="checkStartDates()"
            required
            [touchUi]="true"
            #dates="ngModel"
            [error]="submit && dates.invalid && selectStartDate"
            errorMessage="날짜를 선택해주세요."
            [options]="datePickerOptions"
            [controls]="popupEventAllDay ? datePickerControls : datetimePickerControls"
            [responsive]="popupEventAllDay ? datePickerResponsive : datetimePickerResponsive"></mbsc-datepicker>
        </div>
        <div class="row">

          <mbsc-select [data]="meetingMinute"
            display="anchored"
            [(ngModel)]="selectedHour"
            [itemHeight]="40"
            *ngIf="!popupEventAllDay"
            label="진행시간"
            labelStyle="stacked"
            inputStyle="box"
            (change)="checkStartDates()"
            placeholder="미팅유형을 선택해주세요"></mbsc-select>
        </div>

        <div class="row">

          <div class="col-6 m-0">
            <mbsc-select [data]="meetingType"
              display="anchored"
              [(ngModel)]="selectedType"
              [itemHeight]="40"
              label="미팅유형"
              disabled
              labelStyle="stacked"
              inputStyle="box"
              (change)="checkStatus()"
              placeholder="미팅유형을 선택해주세요"></mbsc-select>
          </div>
          <div class="col-6 m-0">
            <mbsc-select [data]="meetingRoom"
              display="anchored"
              [(ngModel)]="selectedRoom"
              [itemHeight]="40"
              label="회의실"
              [disabled]="!isCreater || isPeriod || popupEventAllDay "
              labelStyle="stacked"
              inputStyle="box"
              required
              #room="ngModel"
              [error]="submit && room.invalid"
              errorMessage="미팅할 회의실은 필수사항입니다."
              placeholder="미팅할 회의실을 선택해주세요"></mbsc-select>
          </div>
        </div>
        <div class="row">

          <div class="col-6 m-0">

            <mbsc-input label="고객명"
              [(ngModel)]="inquiry.username"
              disabled
              (keyup)="checkStatus()"
              placeholder="고객명을 입력해주세요."></mbsc-input>
          </div>
          <div class="col-6 m-0"
            *ngIf="(combineList$ | async) as users">
            <mbsc-select [(ngModel)]="selectedUser"
              [data]="users"
              display="anchored"
              [itemHeight]="50"
              [selectMultiple]="true"
              label="참여자"
              [disabled]="!isCreater"
              [filter]="true"
              labelStyle="stacked"
              required
              inputStyle="box"
              #users="ngModel"
              [error]="submit && users.invalid && selectedUser.length ==0"
              errorMessage="참여자를 최소 1명이상 선택해주세요."
              placeholder="참여자를 선택해주세요."></mbsc-select>
            <ng-template #users
              let-item>
              <div class="md-image-text-item">
                <img class="md-image-text-avatar"
                  [src]="item.avatar" />
                <div class="md-image-text-name">{{item.display}}</div>
              </div>
            </ng-template>
          </div>

          <div class="row m-3">
            <button type="submit"
              (click)="saveEvent()"
              class="btn btn-primary">{{ type == 'add'?'미팅등록':'변경사항 저장'}}</button>
          </div>
          <!-- class="mbsc-button-group">
            <mbsc-button class="mbsc-button-block"
              color="danger"
              variant="outline"
              [disabled]="!isCreater"
              (click)="onDeleteClick()">미팅 등록
            </mbsc-button> -->
        </div>


        <!-- class="mbsc-button-group">
            <mbsc-button class="mbsc-button-block"
              color="danger"
              variant="outline"
              [disabled]="!isCreater"
              (click)="onDeleteClick()">미팅 등록
            </mbsc-button> -->
      </div>
      <div *ngIf="contactCompany">
        <div class="row">
          <mbsc-input label="제목"
            [(ngModel)]="popupEventTitle"
            [disabled]="!isCreater"
            required
            #title="ngModel"
            [error]="submit&& title.invalid"
            errorMessage="제목은 필수사항입니다."
            (keyup)="checkStatus()"
            placeholder="미팅 제목을 입력해주세요."></mbsc-input>
        </div>

        <div class="row">
          <mbsc-textarea label="설명"
            [disabled]="!isCreater"
            [(ngModel)]="popupEventDescription"
            placeholder="설명을 입력해주세요."></mbsc-textarea>
        </div>
        <div class="row">
          <mbsc-switch label="화상미팅"
            [disabled]="!isCreater"
            (change)="changeTitle()"
            [(ngModel)]="isVideoMeeting"></mbsc-switch>
        </div>
        <div class="row"
          *ngIf="isVideoMeeting">
          <mbsc-input label="화상미팅 URL"
            [(ngModel)]="videoMeetingUrl"
            placeholder="화상 미팅 URL를 입력해주세요."></mbsc-input>
        </div>
        <div class="row">
          <mbsc-datepicker [(ngModel)]="selectStartDate"
            [disabled]="!isCreater"
            returnFormat="jsdate"
            (change)="checkStartDates()"
            required
            [touchUi]="true"
            #dates="ngModel"
            [error]="submit && dates.invalid && selectStartDate"
            errorMessage="날짜를 선택해주세요."
            [options]="datePickerOptions"
            [controls]="popupEventAllDay ? datePickerControls : datetimePickerControls"
            [responsive]="popupEventAllDay ? datePickerResponsive : datetimePickerResponsive"></mbsc-datepicker>
        </div>
        <div class="row">

          <mbsc-select [data]="meetingMinute"
            display="anchored"
            [(ngModel)]="selectedHour"
            [itemHeight]="40"
            *ngIf="!popupEventAllDay"
            label="진행시간"
            labelStyle="stacked"
            inputStyle="box"
            (change)="checkStartDates()"
            placeholder="미팅유형을 선택해주세요"></mbsc-select>
        </div>

        <div class="row">

          <div class="col-6 m-0">
            <mbsc-select [data]="meetingType"
              display="anchored"
              [(ngModel)]="selectedType"
              [itemHeight]="40"
              label="미팅유형"
              disabled
              labelStyle="stacked"
              inputStyle="box"
              (change)="checkStatus()"
              placeholder="미팅유형을 선택해주세요"></mbsc-select>
          </div>
          <div class="col-6 m-0">
            <mbsc-select [data]="meetingRoom"
              display="anchored"
              [(ngModel)]="selectedRoom"
              [itemHeight]="40"
              label="회의실"
              [disabled]="!isCreater || isPeriod || popupEventAllDay "
              labelStyle="stacked"
              inputStyle="box"
              required
              #room="ngModel"
              [error]="submit && room.invalid"
              errorMessage="미팅할 회의실은 필수사항입니다."
              placeholder="미팅할 회의실을 선택해주세요"></mbsc-select>
          </div>
        </div>
        <div class="row">

          <div class="col-6 m-0">

            <mbsc-input label="업체명"
              [(ngModel)]="contactCompany.companyName"
              disabled
              (keyup)="checkStatus()"
              placeholder="업체명을 입력해주세요."></mbsc-input>
          </div>
          <div class="col-6 m-0"
            *ngIf="(combineList$ | async) as users">
            <mbsc-select [(ngModel)]="selectedUser"
              [data]="users"
              display="anchored"
              [itemHeight]="50"
              [selectMultiple]="true"
              label="참여자"
              [disabled]="!isCreater"
              [filter]="true"
              labelStyle="stacked"
              required
              inputStyle="box"
              #users="ngModel"
              [error]="submit && users.invalid && selectedUser.length ==0"
              errorMessage="참여자를 최소 1명이상 선택해주세요."
              placeholder="참여자를 선택해주세요."></mbsc-select>
            <ng-template #users
              let-item>
              <div class="md-image-text-item">
                <img class="md-image-text-avatar"
                  [src]="item.avatar" />
                <div class="md-image-text-name">{{item.display}}</div>
              </div>
            </ng-template>
          </div>

          <div class="row m-3">
            <button type="submit"
              (click)="saveEvent()"
              class="btn btn-primary">{{ type == 'add'?'미팅등록':'변경사항 저장'}}</button>
          </div>
          <!-- class="mbsc-button-group">
            <mbsc-button class="mbsc-button-block"
              color="danger"
              variant="outline"
              [disabled]="!isCreater"
              (click)="onDeleteClick()">미팅 등록
            </mbsc-button> -->
        </div>


        <!-- class="mbsc-button-group">
            <mbsc-button class="mbsc-button-block"
              color="danger"
              variant="outline"
              [disabled]="!isCreater"
              (click)="onDeleteClick()">미팅 등록
            </mbsc-button> -->
      </div>
    </div>
  </div>


</div>
<!--  -->