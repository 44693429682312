import { Component, Input, OnInit } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { Cs, csFeedBack } from "src/app/core/models/cs.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
@Component({
  selector: "app-cs-detail",
  templateUrl: "./cs-detail.component.html",
  styleUrls: ["./cs-detail.component.scss"],
})
export class CsDetailComponent implements OnInit {
  @Input() public csId;
  cs: Cs;
  feedBack;
  isManager = false;
  constructor(
    private db: DbService,
    public activeModal: NgbActiveModal,
    public dataservice: DataService,
    private alertService: AlertService
  ) {}

  async ngOnInit() {
    this.cs = await this.db.doc$(`cs/${this.csId}`).pipe(first()).toPromise();
    console.log(" this.cs", this.cs);

    await this.dataservice.inIt();
    this.isManager = this.checkCsManger();
  }

  checkCsManger() {
    const user = this.dataservice.user;
    const find = user.duty.find((item) => item === "관리자개발");
    if (find) {
      return true;
    }
    return false;
  }

  passBack() {
    this.activeModal.close();
  }

  async saveFeedback(csId) {
    if (this.cs.createdBy != this.dataservice.userId && !this.isManager) {
      this.alertService.error("요청자 또는 개발 담당자만 작성가능합니다.");
      return;
    }
    let feedBack: csFeedBack = {
      type: this.isManager ? "담당자" : "요청자",
      dateCreated: new Date().toISOString(),
      text: this.feedBack,
      createdBy: this.dataservice.userId,
    };

    await this.dataservice.updateFeedBack(feedBack, this.cs);
    this.cs.feedBack && this.cs.feedBack.length > 0
      ? this.cs.feedBack.push(feedBack)
      : (this.cs.feedBack = [feedBack]);
    this.feedBack = "";
    this.alertService.basicMessage("피드백이 등록되었습니다.");
  }

  trackById(idx, cs) {
    return cs.id;
  }

  downloadFile(url) {
    window.open(url);
  }

  async checkCs() {
    await this.dataservice.checkCs(this.cs);
    this.alertService.basicMessage("확인처리 되었습니다.");
    this.activeModal.close();
  }

  async deleteCs() {
    const confirm = await this.alertService.confirm(
      "요청 삭제",
      "요청을 삭제하면 복원 할 수 없습니다. 해당 요청 정말로 삭제하시겠습니까?",
      "요청 삭제"
    );
    if (confirm) {
      await this.db.delete(`cs/${this.csId}`);
      this.alertService.basicMessage(`해당 요청이 삭제되었습니다.`);
      this.activeModal.close();
    }
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  getShortUrl(url) {
    const shorturl = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = shorturl.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  async complateCs() {
    const confirm = await this.alertService.confirm(
      "",
      "해당 요청를 정말로 완료처리하시겠습니까?",
      "요청완료"
    );
    if (confirm) {
      await this.dataservice.completedCs(this.cs);
      this.alertService.basicMessage(`해당 요청이 완료되었습니다.`);
      this.activeModal.close();
    }
  }
}
