import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { finalize, first } from "rxjs/operators";
import {
  DbService,
  leftJoinOneDocument,
} from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";
@Component({
  selector: "app-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent implements OnInit {
  @Input() public contractId;
  contract;
  private modalContent: TemplateRef<ProjectDetailComponent>;
  private modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private loaderSerivce: LoaderService,
    private db: DbService
  ) {}

  async ngOnInit(): Promise<void> {
    this.loaderSerivce.setLoading(true);
    this.contract = await this.db
      .doc$(`contracts/${this.contractId}`)
      .pipe(
        leftJoinOneDocument(this.db.afs, "clientId", "clients"),
        leftJoinOneDocument(this.db.afs, "createdBy", "users"),
        leftJoinOneDocument(this.db.afs, "projectId", "projects"),
        first(),
        finalize(() => this.loaderSerivce.setLoading(false))
      )
      .toPromise();
    console.log("this.contract", this.contract);
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  downloadFile(url) {
    window.open(url);
  }

  passBack() {
    this.activeModal.close(this.contract);
  }

  goPdfViewer(url: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      scrollable: true,
      size: "xl",
    });
    modalRef.componentInstance.url = url;
  }
}
