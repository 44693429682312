<div class="modal-header">
  <h3 class="modal-title">요청 상세</h3>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body"
  *ngIf="cs">

  <div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">상태</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.status}}</div>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">요청일시</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}</div>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">구분</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.type}}</div>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">요청자</label>
      <div class="col-md-10">
        <div class="form-control">{{( 'users/' + cs.createdBy | doc | async)?.name }}</div>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">제목</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.title}}</div>
      </div>
    </div>
    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">관련페이지</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.pageUrl == "" ?'없음':cs.pageUrl}}</div>
      </div>
    </div>

    <div class="form-group row mb-4">
      <label for="billing-name"
        class="col-md-2 col-form-label">상세내용</label>
      <div class="col-md-10">
        <div class="form-control">{{cs.description}}</div>
      </div>
    </div>

  </div>
  <div class="form-group row mb-4">
    <label for="example-textarea"
      class="col-md-2 col-form-label">첨부파일</label>
    <div class="col-md-10">
      <div class="form-control"
        *ngIf="!cs.updateFiles ||  cs.updateFiles.length == 0">없음</div>

      <div class="table-responsive"
        *ngIf="cs.updateFiles&& cs.updateFiles.length>0">
        <table class="table table-centered mb-0 table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">파일명</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of cs.updateFiles">
              <td>
                {{getShortUrl(file) }}
              </td>
              <td>
                <button type="button"
                  class="btn btn-sm btn-outline-dark"
                  (click)="downloadFile(file)">다운로드</button>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

      <!-- <textarea class="form-control"
                      id="example-textarea"
                      rows="3"
                      placeholder="Write some note.."></textarea> -->
    </div>

  </div>

  <div class="form-group row mb-4">
    <label for="example-textarea"
      class="col-md-2 col-form-label">피드백 내역</label>
    <div class="col-md-10">
      <div class="form-control"
        *ngIf="!cs.feedBack ||  cs.feedBack.length == 0">피드백없음</div>

      <div class="table-responsive"
        *ngIf="cs.feedBack&& cs.feedBack.length>0">
        <table class="table table-centered mb-0 table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">구분</th>
              <th scope="col">날짜</th>
              <th scope="col">작성자</th>
              <th scope="col">피드백내용</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let feedBack of cs.feedBack ; let i=index;  trackBy: trackById">
              <td>
                {{feedBack.type == '요청자'?'요청자':'담당자'}}
              </td>
              <td>
                {{feedBack.dateCreated | date:'yyyy-MM-dd HH:mm:SS'}}
              </td>
              <td>{{('users/' + feedBack.createdBy | doc | async)?.name}}</td>
              <td>{{feedBack.text}}</td>
            </tr>

          </tbody>
        </table>
      </div>

      <!-- <textarea class="form-control"
                      id="example-textarea"
                      rows="3"
                      placeholder="Write some note.."></textarea> -->
    </div>

  </div>
  <div class="form-group row mb-4">
    <label for="billing-name"
      class="col-md-2 col-form-label">피드백작성</label>
    <div class="col-md-10">
      <textarea class="form-control"
        [(ngModel)]="feedBack"></textarea>
    </div>
    <div style="text-align: right;"
      class="mt-3">
      <button type="submit"
        class="btn btn-primary w-md"
        (click)="saveFeedback(cs.id)">저장</button>
    </div>
  </div>
  <div class="form-group row mb-4">
    <label for="example-textarea"
      class="col-md-2 col-form-label">확인여부</label>
    <div class="col-md-10">
      <div class="form-control">{{cs.isCheck?'확인':'미확인'}}</div>

      <!-- <textarea class="form-control"
                      id="example-textarea"
                      rows="3"
                      placeholder="Write some note.."></textarea> -->
    </div>
  </div>
</div>
<div class="modal-footer"
  *ngIf="cs">
  <button class="btn btn-success"
    *ngIf="cs.status !=='완료' && isManager"
    (click)="complateCs()">완료처리</button>
  <button class="btn btn-info"
    *ngIf="!cs.isCheck && isManager"
    (click)="checkCs()">확인처리</button>

  <button class="btn btn-danger"
    *ngIf="cs.createdBy == dataservice.userId"
    (click)="deleteCs()">삭제</button>

  <button class="btn btn-secondary"
    (click)="passBack()">닫기</button>
</div>