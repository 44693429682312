<div class="modal-header">
  <div class="modal-title mt-0">{{isEdit?'고객정보 수정':'고객 상세'}}</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div *ngIf="!isEdit">
    <div class="table-responsive">
      <table class="table table-nowrap mb-0">
        <tbody>
          <tr>
            <th scope="row">고객타입 :</th>
            <td>{{client?.clientType}}</td>
          </tr>
          <tr>
            <th scope="row">고객명 :</th>
            <td>{{client?.clientName}}</td>
          </tr>
          <tr *ngIf="client?.clientType != '개인'">
            <th scope="row">회사명 :</th>
            <td>{{client?.companyName}}</td>
          </tr>
          <tr *ngIf="client?.clientType != '개인'">
            <th scope="row">대표명 :</th>
            <td>{{client?.presedent}}</td>
          </tr>
          <tr *ngIf="client?.clientType != '개인'">
            <th scope="row">사업자등록번호 :</th>
            <td>{{client?.businessRegistration}}</td>
          </tr>
          <tr>
            <th scope="row">주소 :</th>
            <td>{{client?.address}}</td>
          </tr>
          <tr>
            <th scope="row">전화 :</th>
            <td>{{client?.phone}}</td>
          </tr>
          <tr>
            <th scope="row">이메일 :</th>
            <td>{{client?.email}}</td>
          </tr>
          <tr>
            <th scope="row">등록일 :</th>
            <td>{{client?.dateCreated | date:'yyyy-MM-dd'}}</td>
          </tr>
          <tr>
            <th scope="row">등록자 :</th>
            <td>{{client?.createdBy.name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row mt-3">
      <h2 class="font-size-15 mt-4">첨부파일</h2>
      <p *ngIf="!client?.clientFiles ||  client?.clientFiles.length == 0">없음</p>
      <div class="table-responsive"
        *ngIf="client?.clientFiles&& client?.clientFiles.length>0">
        <table class="table table-centered mb-0 table-nowrap">
          <thead class="thead-light">
            <tr>
              <th scope="col">파일명</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of client.clientFiles; let i = index"
              style="vertical-align: middle;">
              <td>
                {{getFileName(file) }}
              </td>
              <td>
                <button type="button"
                  class="btn btn-sm btn-outline-danger"
                  (click)="downloadFile(file)">다운로드</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <div *ngIf="isEdit">

    <form [formGroup]="clientForm"
      (ngSubmit)="validSubmit()">
      <div class="row m3">
        <div class="col-12">
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">고객 유형</label>
            <div class="col-md-9">
              <select id="type"
                name="clientType"
                class="form-control"
                (change)="checkClientType()"
                formControlName="clientType">
                <option value=""
                  disabled>--고객유형을 선택해주세요.--</option>
                <option value="상담고객">상담고객</option>
                <option value="기존고객">기존고객</option>
                <option value="직접입력">직접고객정보입력</option>
              </select>
              <div *ngIf="submit && clForm.clientType.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.clientType.errors.required">고객 유형은
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.clientType.value == '기존고객'">
            <label class="col-md-3 col-form-label">기존고객 선택</label>
            <div class="col-md-9">
              <div class="inner col-lg-12 ml-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-10">
                    <input class="form-control"
                      name="existingClient"
                      type="text"
                      disabled
                      formControlName="existingClient"
                      [ngClass]="{'is-invalid': submit && clForm.existingClient.errors}"
                      id="existingClient">
                  </div>
                  <div class="col-md-2">
                    <div class="mt-4 mt-md-0">
                      <button type="button"
                        (click)="searchClient()"
                        class="btn btn-dark">
                        검색</button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="submit && clForm.existingClient.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.existingClient.errors.required">기존고객을 선택해주세요.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.clientType.value == '상담고객'">
            <label class="col-md-3 col-form-label">상담고객 선택</label>
            <div class="col-md-9">
              <div class="inner col-lg-12 ml-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-10">
                    <input class="form-control"
                      name="consultingClient"
                      type="text"
                      disabled
                      formControlName="consultingClient"
                      [ngClass]="{'is-invalid': submit && clForm.consultingClient.errors}"
                      id="clientName">
                  </div>
                  <div class="col-md-2">
                    <div class="mt-4 mt-md-0">
                      <button type="button"
                        (click)="searchInquiry()"
                        class="btn btn-dark">
                        검색</button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="submit && clForm.consultingClient.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.consultingClient.errors.required">상담고객을 선택해주세요.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">사업 유형</label>
            <div class="col-md-9">
              <select id="type"
                name="businessType"
                formControlName="businessType"
                class="form-control"
                (change)="checkType()"
                [ngClass]="{'is-invalid': submit && clForm.businessType.errors}">
                <option value=""
                  disabled>--사업유형을 선택해주세요.--</option>
                <option value="개인">개인</option>
                <option value="사업자">사업자</option>
              </select>
              <div *ngIf="submit && clForm.businessType.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.businessType.errors.required">사업 유형은
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.businessType.value=='개인'">
            <label class="col-md-3 col-form-label">고객명</label>
            <div class="col-md-9">
              <input class="form-control"
                name="clientName"
                type="text"
                formControlName="clientName"
                [ngClass]="{'is-invalid': submit && clForm.clientName.errors}"
                id="clientName">
              <div *ngIf="submit && clForm.clientName.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.clientName.errors.required">고객명은
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.businessType.value!=='개인'">
            <label class="col-md-3 col-form-label">회사명</label>
            <div class="col-md-9">
              <input class="form-control"
                name="companyName"
                type="text"
                [readonly]="clForm.clientType.value ==='기존고객'"
                formControlName="companyName"
                [ngClass]="{'is-invalid': submit && clForm.companyName.errors}"
                id="companyName">
              <div *ngIf="submit && clForm.companyName.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.companyName.errors.required">회사명은
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.clientType.value!=='개인'">
            <label class="col-md-3 col-form-label">대표명</label>
            <div class="col-md-9">
              <input class="form-control"
                name="presedent"
                [readonly]="clForm.clientType.value ==='기존고객'"
                type="text"
                formControlName="presedent"
                [ngClass]="{'is-invalid': submit && clForm.presedent.errors}"
                id="presedent">
              <div *ngIf="submit && clForm.presedent.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.presedent.errors.required">회사명은 필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.clientType.value!=='개인'">
            <label class="col-md-3 col-form-label">사업자등록번호</label>
            <div class="col-md-9">
              <input class="form-control"
                formControlName="businessRegistration"
                name="businessRegistration"
                [readonly]="clForm.clientType.value ==='기존고객'"
                type="text"
                [ngClass]="{'is-invalid': submit && clForm.businessRegistration.errors}"
                id="businessRegistration">
              <div *ngIf="submit && clForm.businessRegistration.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.businessRegistration.errors.required">사업자등록번호는
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">주소</label>
            <div class="col-md-9">
              <input class="form-control"
                formControlName="address"
                name="address"
                type="text"
                [readonly]="clForm.clientType.value =='기존고객'"
                [ngClass]="{'is-invalid': submit && clForm.address.errors}"
                id="address">
              <div *ngIf="submit && clForm.address.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.address.errors.required">주소는 필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">이메일</label>
            <div class="col-md-9">
              <input class="form-control"
                formControlName="email"
                name="email"
                type="email"
                [readonly]="clForm.clientType.value =='기존고객'"
                [ngClass]="{'is-invalid': submit && clForm.email.errors}"
                id="email">
              <div *ngIf="submit && clForm.email.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.email.errors.required">이메일은 필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">전화번호</label>
            <div class="col-md-9">
              <input class="form-control"
                formControlName="phone"
                name="phone"
                type="text"
                [readonly]="clForm.clientType.value =='기존고객'"
                [ngClass]="{'is-invalid': submit && clForm.phone.errors}"
                id="phone">
              <div *ngIf="submit && clForm.phone.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.phone.errors.required">전화번호는 필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-md-3 col-form-label">담당자</label>
            <div class="col-md-9">
              <input class="form-control"
                name="contactPerson"
                formControlName="contactPerson"
                type="text"
                [readonly]="clForm.clientType.value =='기존고객'"
                [ngClass]="{'is-invalid': submit && clForm.contactPerson.errors}"
                id="contactPerson">
              <div *ngIf="submit && clForm.contactPerson.errors"
                class="invalid-feedback">
                <span *ngIf="clForm.contactPerson.errors.required">담당자명은
                  필수사항입니다.</span>
              </div>
            </div>
          </div>
          <div class="row mb-3"
            *ngIf="clForm.clientType.value !=='기존고객'">
            <label for="product-image"
              class="col-md-3 col-form-label">고객등록문서</label>
            <div class="col-md-9">
              <ngx-dropzone (change)="onSelect($event)">
                <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
                <ngx-dropzone-preview *ngFor="let f of clientFiles"
                  [removable]="true"
                  (removed)="onRemove(f)">
                  <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                  </ngx-dropzone-label>
                </ngx-dropzone-preview>
              </ngx-dropzone>
            </div>
          </div>
          <div class="row mt-3">
            <h2 class="font-size-15 mt-4">첨부파일</h2>
            <p *ngIf="!client?.clientFiles ||  client?.clientFiles.length == 0">없음</p>
            <div class="table-responsive"
              *ngIf="client?.clientFiles&& client?.clientFiles.length>0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">파일명</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of client.clientFiles; let i = index"
                    style="vertical-align: middle;">
                    <td>
                      {{getFileName(file) }}
                    </td>
                    <td>
                      <button type="button"
                        class="btn btn-sm btn-outline-danger"
                        (click)="deleteFile(file,i)">삭제</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>

<div class="modal-footer"
  *ngIf="!isEdit">
  <button class="btn btn-danger"
    (click)="editClientData()">정보수정</button>

  <button type="submit"
    (click)="passBack()"
    class="btn btn-primary">닫기</button>
</div>

<div class="modal-footer"
  *ngIf="isEdit">
  <button class="btn btn-danger"
    (click)="validSubmit()">저장</button>

  <button type="submit"
    (click)="cancel()"
    class="btn btn-primary">취소</button>
</div>