import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
// import 'moment/locale/ko';
moment.locale("ko");

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  transform(date: any, type?: any): any {
    if (type == "chat") {
      return moment(date).format();
    } else {
      return moment(date).fromNow();
    }
  }
}
