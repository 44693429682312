<div class="modal-header">
  <h4 class="modal-title"
    id="modal-basic-title">고객 검색</h4>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="search-box">
          <div class="position-relative">
            <input type="text"
              class="form-control"
              [(ngModel)]="term"
              placeholder="회사명 또는 이름을 입력해주세요.">
            <i class="bx bx-search-alt search-icon"></i>
          </div>
        </div>
        <div class="table-responsive"
          *ngIf="contactCompanyList">
          <div class="card"
            *ngIf="(contactCompanyList | filter: term).length == 0">
            <div class="card-body">
              <div class="text-center">
                <div class="row justify-content-center">
                  <h4 class="mt-2 fw-semibold">검색 결과가 없습니다.</h4>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-centered table-nowrap table-hover"
            *ngIf="(contactCompanyList | filter: term).length > 0">
            <thead class="thead-light">
              <tr>
                <th scope="col">업체명</th>
                <th scope="col">대표명/담당자명</th>
                <th scope="col">전화</th>
                <!-- <th scope="col">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of contactCompanyList | filter: term | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index"
                (click)="select(item)">
                <td>
                  <h2 class="font-size-14 mb-1"><a href="javascript: void(0);"
                      class="text-dark">{{item.clients}}</a>
                  </h2>
                  <p class="text-muted mb-0">{{item.companyName }}</p>
                </td>
                <td>{{item.presedent}}/{{item.manager}}</td>
                <td>{{item.phone}}</td>

                <!-- <div>
                    <a href="javascript: void(0);"
                      class="badge badge-soft-primary font-size-11 m-1">Photoshop</a>
                    <a href="javascript: void(0);"
                      class="badge badge-soft-primary font-size-11 m-1">illustrator</a>
                  </div> -->
                <!-- <td>
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2">
                      <a href=""
                        ngbTooltip="Message"><i class="bx bx-message-square-dots"></i></a>
                    </li>
                    <li class="list-inline-item px-2">
                      <a href=""
                        ngbTooltip="Profile"><i class="bx bx-user-circle"></i></a>
                    </li>
                  </ul>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive"
          *ngIf="clients">
          <div class="card"
            *ngIf="(clients | filter: term).length == 0">
            <div class="card-body">
              <div class="text-center">
                <div class="row justify-content-center">
                  <h4 class="mt-2 fw-semibold">검색 결과가 없습니다.</h4>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-centered table-nowrap table-hover"
            *ngIf="(clients | filter: term).length > 0">
            <thead class="thead-light">
              <tr>
                <th scope="col">고객명/회사명</th>
                <th scope="col">대표자명</th>
                <th scope="col">이메일</th>
                <th scope="col">전화</th>
                <!-- <th scope="col">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of clients | filter: term | paginate
                : {
                    itemsPerPage: tableSize,
                    currentPage: page,
                    totalItems: count
                  };
            let i = index"
                (click)="select(item)">
                <td>
                  <h2 class="font-size-14 mb-1"><a href="javascript: void(0);"
                      class="text-dark">{{item.clientName}}</a>
                  </h2>
                  <p class="text-muted mb-0">{{item.companyName | slice:0:10}}</p>
                </td>
                <td>{{item.presedent}}</td>
                <td>{{item.email}}</td>
                <td>{{item.phone}}</td>

                <!-- <div>
                    <a href="javascript: void(0);"
                      class="badge badge-soft-primary font-size-11 m-1">Photoshop</a>
                    <a href="javascript: void(0);"
                      class="badge badge-soft-primary font-size-11 m-1">illustrator</a>
                  </div> -->
                <!-- <td>
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2">
                      <a href=""
                        ngbTooltip="Message"><i class="bx bx-message-square-dots"></i></a>
                    </li>
                    <li class="list-inline-item px-2">
                      <a href=""
                        ngbTooltip="Profile"><i class="bx bx-user-circle"></i></a>
                    </li>
                  </ul>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          <pagination-controls previousLabel="이전"
            nextLabel="다음"
            (pageChange)="onTableDataChange($event)">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
