import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
import { DataService } from "src/app/core/services/data.service";
import { AlertService } from "src/app/core/services/alert.service";
import { DbService, leftJoinDocument } from "src/app/core/services/db.service";
import { Observable } from "rxjs";
import { first, shareReplay } from "rxjs/operators";

import { ActivatedRoute, Navigation, Route, Router } from "@angular/router";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { AddScheduleComponent } from "src/app/components/add-schedule/add-schedule.component";
import moment from "moment";
import { Meeting } from "src/app/core/models/meeting.model";
import { TaskList } from "src/app/core/models/task.model";
import { AddTaskListComponent } from "src/app/components/add-task-list/add-task-list.component";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LocationStrategy } from "@angular/common";
import { LoaderService } from "src/app/core/services/loader.service";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";
import { WebSolutionDetailComponent } from "../web-solution-detail/web-solution-detail.component";
import { Contacts } from "src/app/core/models/contacts.models";
import { AddPartnershipDocumentsComponent } from "../add-partnership-documents/add-partnership-documents.component";
@Component({
  selector: "app-contact-company-detail",
  templateUrl: "./contact-company-detail.component.html",
  styleUrls: ["./contact-company-detail.component.scss"],
})
export class ContactCompanyDetailComponent implements OnInit {
  @Input() contactCompany: ContactCompany;
  @Input() contactCompanyId: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  private _prevOnSameUrlNavigation;
  iscontactCompany = false;
  public Editor = ClassicEditor;
  contacts$: Observable<any>;
  statusHistories$: Observable<any>;
  meeting$: Observable<any>;
  partnershipDocuments$: Observable<any>;
  description = "";
  contactType = "전화";
  uploadFiles = [];
  meetingData: any;
  taskList$;
  deleteFiles = [];
  constructor(
    private _location: LocationStrategy,
    public dataService: DataService,
    private alertService: AlertService,
    private db: DbService,
    private _router: Router,
    private modalService: NgbModal,
    public fileUploaderService: FileUploaderSevice,
    public activeModal: NgbActiveModal,
    private loadService: LoaderService,
    private router: Router
  ) {}

  async ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    this._location.onPopState(() => {
      // example for a simple check if modal is opened
      if (this.activeModal !== undefined) {
        ("modal is opened - cancel default browser event and close modal");
        // TODO: cancel the default event
        // close modal
        this.activeModal.close();
      } else {
      }
    });
    this.getData(this.contactCompanyId);

    await this.dataService.inIt();
  }

  async cancelMeeting(meeting) {
    const confirm = await this.alertService.confirm(
      "미팅 취소 확인",
      "정말 미팅을 취소하시겠습니까?",
      "미팅취소"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${meeting.id}`, {
        isCanceled: true,
        dateCanceld: new Date().toISOString(),
        canceledBy: this.dataService.userId,
      });
      let i = this.meetingData.findIndex((item) => item.id === meeting.id);
      this.meetingData[i].isCanceled = true;
      this.alertService.success("미팅이 취소되었습니다.");
    }
  }

  async resumeMeeting(meeting) {
    const confirm = await this.alertService.confirm(
      "알림",
      "해당 취소된 미팅을 다시 되돌리시겠습니까?",
      "확인"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${meeting.id}`, {
        isCanceled: false,
        dateCanceld: "",
        canceledBy: "",
      });
      let i = this.meetingData.findIndex((item) => item.id === meeting.id);
      this.meetingData[i].isCanceled = false;
      this.alertService.success("미팅이 다시 예약되었습니다.");
    }
  }

  addPartnershipDocuments() {
    const modalRef = this.modalService.open(AddPartnershipDocumentsComponent, {
      size: "lg",
    });
    modalRef.componentInstance.contactCompany = this.contactCompany;
  }

  async getData(contactCompanyId) {
    this.contactCompany = await this.db
      .doc$(`contactCompany/${contactCompanyId}`)
      .pipe(first())
      .toPromise();

    this.contacts$ = this.db
      .collection$(`contacts`, (ref) =>
        ref
          .where("contactCompanyId", "==", this.contactCompany.id)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));

    this.statusHistories$ = this.db
      .collection$(`contactStatusHistories`, (ref) =>
        ref
          .where("contactCompanyId", "==", this.contactCompany.id)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.partnershipDocuments$ = this.db
      .collection$(`partnershipDocuments`, (ref) =>
        ref
          .where("contactCompanyId", "==", this.contactCompany.id)
          .orderBy("dateCreated", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));

    this.meetingData = await this.db
      .collection$(`meetings`, (ref) =>
        ref
          .where("refId", "==", this.contactCompany.id)
          .orderBy("start", "desc")
      )
      .pipe(first())
      .toPromise();
  }

  downloadFile(url) {
    window.open(url);
  }

  passBack() {
    this.activeModal.close(this.contactCompany);
  }

  getDate(item) {
    const date = moment(new Date(item.start.seconds * 1000)).format(
      "YYYY년 MM월 DD일 HH:mm"
    );
    return date;
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  async createdContacts() {
    const confirm = await this.alertService.confirm(
      "상담등록",
      "상담 내용을 저장하시겠습니까?",
      "확인"
    );
    if (confirm) {
      const newContacts: Contacts = {
        contactCompanyId: this.contactCompany.id,
        date: new Date(),
        dateCreated: new Date().toISOString(),
        type: this.contactCompany.status ? this.contactCompany.status : "컨택",
        updateFiles: [],
        contactType: this.contactType,
        createdBy: this.dataService.userId,
        description: this.description,
      };
      await this.db.updateAt("contacts", newContacts);
      this.description = "";
      this.alertService.basicMessage("컨택 내용이 등록되었습니다.");
    }
  }

  async changeStatus(status: string) {
    if (this.contactCompany.status === status) {
      return;
    }
    if (status == "제휴 완료") {
      const confirm = await this.alertService.confirm(
        "상태변경",
        `해당 업체를 제휴업체로 등록하시겠습니까?`,
        "확인"
      );
      if (confirm) {
        const updateStatusHistory = {
          contactCompanyId: this.contactCompany.id,
          dateCreated: new Date().toISOString(),
          date: new Date(),
          oldStatus: this.contactCompany.status
            ? this.contactCompany.status
            : "접수",
          newStatus: status,
          createdBy: this.dataService.userId,
        };
        await this.db.updateAt("contactStatusHistories", updateStatusHistory);
        const updateContactCompany: Partial<ContactCompany> = {
          status: status,
          isPartnerShip: true,
          datePartnerShip: new Date().toISOString(),
        };
        await this.db.updateAt(
          `contactCompany/${this.contactCompany.id}`,
          updateContactCompany
        );
        this.contactCompany.status = status;
        this.contactCompany.isPartnerShip = true;
        this.contactCompany.datePartnerShip =
          updateContactCompany.datePartnerShip;
        this.alertService.basicMessage("제휴업체로 등록하였습니다.");
      }
    } else {
      if (this.contactCompany.isPartnerShip) {
        const confirm = await this.alertService.confirm(
          "상태변경",
          `이미 제휴 업체로 등록되어 있습니다. 해당 업체를 제휴업체에서 취소하고 상태를 "${status}"로 변경하시겠습니까?`,
          "확인"
        );
        if (confirm) {
          const updateStatusHistory = {
            contactCompanyId: this.contactCompany.id,
            dateCreated: new Date().toISOString(),
            date: new Date(),
            oldStatus: this.contactCompany.status
              ? this.contactCompany.status
              : "접수",
            newStatus: status,
            createdBy: this.dataService.userId,
          };
          await this.db.updateAt("contactStatusHistories", updateStatusHistory);
          const updateContactCompany: Partial<ContactCompany> = {
            status: status,
            isPartnerShip: false,
            datePartnerShip: "",
          };
          await this.db.updateAt(
            `contactCompany/${this.contactCompany.id}`,
            updateContactCompany
          );
          this.contactCompany.status = status;
          this.contactCompany.isPartnerShip = false;
          this.contactCompany.datePartnerShip = "";
          this.alertService.basicMessage("상태가 변경되었습니다.");
        }
      } else {
        const confirm = await this.alertService.confirm(
          "상태변경",
          `해당 업체 상태를 "${status}"로 변경하시겠습니까?`,
          "확인"
        );
        if (confirm) {
          const updateStatusHistory = {
            contactCompanyId: this.contactCompany.id,
            dateCreated: new Date().toISOString(),
            date: new Date(),
            oldStatus: this.contactCompany.status
              ? this.contactCompany.status
              : "접수",
            newStatus: status,
            createdBy: this.dataService.userId,
          };
          await this.db.updateAt("contactStatusHistories", updateStatusHistory);
          await this.db.updateAt(`contactCompany/${this.contactCompany.id}`, {
            status: status,
          });
          this.contactCompany.status = status;
          this.alertService.basicMessage("상태가 변경되었습니다.");
        }
      }
    }
  }

  async deleteContactCompany() {
    const confirm = await this.alertService.confirm(
      "업체 삭제",
      "업체를 삭제하면 복원 할 수 없습니다. 해당 업체를 정말로 삭제하시겠습니까?",
      "문의 삭제"
    );
    if (confirm) {
      await this.db.updateAt(
        `deletedContactCompany/${this.contactCompany.id}`,
        this.contactCompany
      );
      await this.db.delete(`contactCompany/${this.contactCompany.id}`);
      this.alertService.basicMessage("업체가 삭제되었습니다.");
      this.activeModal.close("deleted");
    }
  }

  editContacts(item) {
    item.isEditor = true;
  }

  editContactCompany() {
    this.activeModal.close();
    this.router.navigate(["/partnership/add-company"], {
      queryParams: { id: this.contactCompany.id },
    });
  }

  async saveEditContact(item) {
    item.isEditor = false;
    const updateData = { ...item };
    delete updateData.isEditor;
    updateData.createdBy = this.dataService.userId;
    await this.db.updateAt(`contacts/${item.id}`, updateData);
    this.alertService.success("수정 내용이 저장되었습니다.");
  }

  async deleteContacts(item) {
    const confirm = await this.alertService.confirm(
      "삭제 확인",
      "해당 컨택내역을 정말 삭제 하시겠습니까?",
      "삭제"
    );
    if (confirm) {
      await this.db.updateAt(`deletedContacts/${item.id}`, item);
      await this.db.delete(`contacts/${item.id}`);
      this.alertService.success("삭제 되었습니다.");
    }
  }

  editontactCompany() {
    this.iscontactCompany = true;
    this.deleteFiles = [];
    this.uploadFiles = [];
  }

  // async savecontactCompany() {
  //   this.loadService.setLoading(true);
  //   const promises = this.uploadFiles.map((file) => this.uploadFile(file));
  //   const files: string[] = await Promise.all(promises);
  //   const deletedpromises = this.deleteFiles.map((file) =>
  //     this.fileUploaderService.deleteFile(file)
  //   );
  //   await Promise.all(deletedpromises);
  //   this.contactCompany.updateFiles = [
  //     ...this.contactCompany.updateFiles,
  //     ...files,
  //   ];
  //   const updateData = {
  //     updateFiles: this.contactCompany.updateFiles,
  //     username: this.contactCompany.username,
  //     company: this.contactCompany.company,
  //     project: this.contactCompany.project,
  //     service: this.contactCompany.service,
  //     phone: this.contactCompany.phone,
  //     email: this.contactCompany.email,
  //     content: this.contactCompany.content,
  //   };
  //   console.log(
  //     "this.contactCompany.updateFiles",
  //     this.contactCompany.updateFiles
  //   );

  //   await this.db.updateAt(`inquiries/${this.contactCompany.id}`, updateData);
  //   this.loadService.setLoading(false);
  //   this.alertService.success("수정사항이 저장되었습니다.");
  //   this.iscontactCompany = false;
  // }

  onFileSelect(event) {
    this.uploadFiles.push(...event.addedFiles);
  }

  onFileRemove(event) {
    this.uploadFiles.splice(this.uploadFiles.indexOf(event), 1);
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  addContactSchedule() {
    const modalRef = this.modalService.open(AddScheduleComponent, {
      size: "xl",
      centered: true,
    });
    modalRef.componentInstance.contactCompany = this.contactCompany;
    modalRef.componentInstance.type = "add";
    modalRef.result.then(async (result) => {
      if (result) {
        this.meetingData = await this.db
          .collection$(`meetings`, (ref) =>
            ref
              .where("refId", "==", this.contactCompany.id)
              .orderBy("start", "desc")
          )
          .pipe(first())
          .toPromise();
      }
    });
  }

  editContactSchedule(item) {
    const modalRef = this.modalService.open(AddScheduleComponent, {
      size: "xl",

      centered: true,
    });
    modalRef.componentInstance.contactCompany = this.contactCompany;
    modalRef.componentInstance.meeting = item;
    modalRef.componentInstance.type = "edit";
    modalRef.result.then(async (result) => {
      if (result) {
        this.meetingData = await this.db
          .collection$(`meetings`, (ref) =>
            ref
              .where("refId", "==", this.contactCompany.id)
              .orderBy("start", "desc")
          )
          .pipe(first())
          .toPromise();
      }
    });
  }

  // deletecontactCompanyFile(file, i) {
  //   this.contactCompany.updateFiles.splice(i, 1);
  //   this.deleteFiles.push(file);
  // }

  async deletePartnershipDocuments(partnershipDocuments) {
    const confirm = await this.alertService.confirm(
      "삭제 확인",
      "해당 제휴 문서를 정말 삭제 하시겠습니까?",
      "삭제"
    );
    if (confirm) {
      const deleteData = {
        ...partnershipDocuments,
        deletedBy: this.dataService.userId,
      };
      await this.db.updateAt(
        `deletedPartnershipDocuments/${partnershipDocuments.id}`,
        deleteData
      );
      await this.db.delete(`partnershipDocuments/${partnershipDocuments.id}`);
      this.alertService.success("삭제 되었습니다.");
    }
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }

  goPdfViewer(url: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      scrollable: true,
      size: "xl",
    });
    modalRef.componentInstance.url = url;
  }
}
