<!-- <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">고객 상세</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">사용자 정보</h4>
            <!-- 
            <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
                person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
            <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">이름 :</th>
                            <td>{{user.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">관리타입 :</th>
                            <td>{{user.type}}</td>
                        </tr>
                        <tr>
                            <th scope="row">조직 :</th>
                            <td>
                                <div>
                                    <a class="badge badge-soft-primary font-size-12 m-1"
                                        *ngFor="let department of user.department">{{('departments/' +
                                        department
                                        | doc | async)?.name}}</a>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">직위 :</th>
                            <td>{{user.level}}</td>
                        </tr>
                        <tr>
                            <th scope="row">직책 :</th>
                            <td>{{user.position}}</td>
                        </tr>
                        <tr>
                            <th scope="row">업무 :</th>
                            <td>{{getString(user.duty)}}</td>
                        </tr>
                        <tr>
                            <th scope="row">이메일 :</th>
                            <td>{{user.email}}</td>
                        </tr>
                        <tr>
                            <th scope="row">등록일 :</th>
                            <td>{{user.dateCreated | date:'yyyy-MM-dd'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <button type="submit"
        (click)="passBack()"
        class="btn btn-primary w-100">닫기</button>
</div>