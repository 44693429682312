import { LocationStrategy } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Cost } from "src/app/core/models/cost.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { AuthService } from "src/app/core/services/auth.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { ExcelService } from "src/app/core/services/excel.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
import heic2any from "heic2any";

@Component({
  selector: "app-add-receipt",
  templateUrl: "./add-receipt.component.html",
  styleUrls: ["./add-receipt.component.scss"],
})
export class AddReceiptComponent implements OnInit {
  @Input() public reciept;
  @HostListener("window:popstate", ["$event"])
  private _prevOnSameUrlNavigation;
  myFiles = [];
  submitted = false;
  users$: Observable<any[]>;
  formData: FormGroup;
  isEdit = false;
  selectCost: Cost;
  deleteingdFilles = [];
  constructor(
    private db: DbService,
    private modalService: NgbModal,
    private dataservice: DataService,
    private alertService: AlertService,
    public formBuilder: FormBuilder,
    private router: Router,
    public activeModal: NgbActiveModal,
    private auth: AuthService,
    private fileUploaderService: FileUploaderSevice,
    private loaderService: LoaderService,
    private excelSrv: ExcelService
  ) {}
  ngOnInit() {
    this.users$ = this.db.collection$("users");

    this.formData = this.formBuilder.group({
      dateUsed: ["", [Validators.required]],
      title: ["", [Validators.required]],
      department: ["", [Validators.required]],
      method: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      type: ["", [Validators.required]],
      costType: ["", [Validators.required]],
      users: [[], [Validators.required]],
      files: [""],
    });
  }
  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  selecType() {
    if (this.formData.get("type").value !== "부/팀 운영비") {
      this.formData.get("department").setValue("해당없음");
      if (
        this.formData.get("type").value == "점심식사" ||
        this.formData.get("type").value == "저녁식사"
      ) {
        this.formData.get("costType").setValue("식비");
        this.formData.get("title").setValue("식비");
      }
    } else {
      this.formData.get("department").setValue("");
    }
  }

  updateTextView() {
    const value = this.formData.get("amount").value;
    let num = this.getNumber(value);
    if (num == 0) {
      this.formData.get("amount").setValue("");
    } else {
      this.formData.get("amount").setValue(num.toLocaleString());
    }
  }

  getNumber(text) {
    const arr = text.split("");
    const out = new Array();
    for (let cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) == false) {
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(""));
  }

  getDateFormat(dueDate) {
    const date = new Date(dueDate.seconds * 1000);
    return date;
  }

  deleteFile(file, index) {
    this.selectCost.files.splice(index, 1);
    this.deleteingdFilles.push(file);
  }

  deleteMyFiles(file, index) {
    this.myFiles.splice(index, 1);
  }

  async save() {
    this.submitted = true;
    if (!this.formData.valid) {
      this.submitted = true;
      return;
    }
    const confirm = await this.alertService.confirm(
      "등록 확인",
      "사용 내역을 등록하시겠습니까?",
      "등록"
    );
    if (confirm) {
      this.loaderService.setLoading(true);
      const userId = await this.auth.uid();
      const promises = this.myFiles.map((file) => this.uploadFile(file));
      const files = await Promise.all(promises);
      const updateData: Cost = {
        dateCreated: new Date().toISOString(),
        dateUsed: new Date(this.formData.get("dateUsed").value),
        title: this.formData.get("title").value,
        amount: this.setNumber(this.formData.get("amount").value),
        type: this.formData.get("type").value,
        costType: this.formData.get("costType").value,
        department: this.formData.get("department").value,
        method: this.formData.get("method").value,
        files: files,
        users: this.formData.get("users").value,
        createdBy: userId,
      };

      if (this.isEdit) {
        updateData.files = [...this.selectCost.files, ...files];
        await this.db.updateAt(`costs/${this.selectCost.id}`, updateData);
        this.loaderService.setLoading(false);
        this.alertService.basicMessage("사용내역이 수정되었습니다.");
        this.passBack();
      } else {
        await this.db.updateAt("costs", updateData);
        this.loaderService.setLoading(false);
        this.alertService.basicMessage("사용내역이 등록되었습니다.");
        this.passBack();
      }
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve, reject) => {
      // HEIC 파일을 JPG로 변환합니다.
      if (file.type === "image/heic" || file.type === "image/heif") {
        try {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
            quality: 0.8, // JPG 품질 설정
          });
          // 변환된 이미지 파일명을 재설정합니다.
          const newFileName = `${new Date().getTime()}_${
            file.name.split(".")[0]
          }.jpg`;
          this.fileUploaderService
            .uploadBlobToStorage(newFileName, convertedBlob as Blob)
            .then((url) => resolve(url));
        } catch (error) {
          console.error("Error converting HEIC to JPEG", error);
          reject(error);
        }
      } else {
        // HEIC 파일이 아닐 경우 원본 파일을 업로드합니다.
        this.readFileAsDataURL(file).then((dataUrl) => {
          this.fileUploaderService
            .uploadFile(file.name, dataUrl)
            .then((url) => resolve(url));
        });
      }
    });
  }

  private readFileAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  setNumber(str) {
    const withoutCommas = str.replaceAll(",", "");
    return Number(withoutCommas);
  }

  onFileUpload(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    event.target.value = "";
  }

  get form() {
    return this.formData.controls;
  }

  passBack() {
    this.activeModal.close();
  }
}
