import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { TaskList } from "src/app/core/models/task.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { InquiryDetailComponent } from "../inquiry-detail/inquiry-detail.component";

@Component({
  selector: "app-add-task-list",
  templateUrl: "./add-task-list.component.html",
  styleUrls: ["./add-task-list.component.scss"],
})
export class AddTaskListComponent implements OnInit {
  @Input() public inquiry;
  @Input() public taskList;
  @Input() public type;

  formData: FormGroup;
  submitted = false;
  userList;
  inquiryList;
  inquiryList$;
  myFiles = [];
  isEdit = false;
  isLoading = true;
  userList$;
  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public fileUploaderService: FileUploaderSevice,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    private db: DbService,
    private router: Router
  ) {}

  ngOnInit() {
    this.formData = this.formBuilder.group({
      name: ["", [Validators.required]],
      file: new FormControl(""),
      taskType: ["", [Validators.required]],
      inquiryId: [null],
      joinUsers: [[], [Validators.required]],
      description: [""],
    });
    this._fetchData();
  }

  private async _fetchData() {
    // this.upcomingTasks = tasks.filter((t) => t.taskType === "upcoming");

    if (this.inquiry) {
      this.formData.get("inquiryId").setValue(this.inquiry.id);
    }
    await this.dataService.inIt();
    // const firbaseData = await Promise.all([
    //   this.dataService.getAllUser()
    // ]);
    this.userList$ = this.db.collection$("users");
    this.inquiryList$ = this.db.collection$("inquiries", (ref) =>
      ref.where("status", "!=", "완료")
    );
  }

  get form() {
    return this.formData.controls;
  }

  async listData() {
    if (this.formData.valid) {
      const name = this.formData.get("name").value;
      const taskType = this.formData.get("taskType").value;
      const inquiryId = this.formData.get("inquiryId").value
        ? this.formData.get("inquiryId").value
        : "";
      const joinUsers = this.formData.get("joinUsers").value;
      const promises = this.myFiles.map((file) => this.uploadFile(file));
      const files = await Promise.all(promises);
      if (!this.isEdit) {
        const createdTask: TaskList = {
          group: "상담",
          dateCreated: new Date().toISOString(),
          name,
          status: "대기중",
          taskType,
          joinUsers,
          inquiryId,
          files: files,
          checked: false,
          description: this.formData.get("description").value,
          createdBy: "",
        };

        await this.dataService.addTask(createdTask);
      } else {
        let uniqueChars = [...new Set(joinUsers)];
        const removeJoinUsers = uniqueChars;
        const updateTask = {
          name,
          taskType,
          removeJoinUsers,
          inquiryId,
          files: [...this.taskList.files, ...files],
          description: this.formData.get("description").value,
        };
        await this.dataService.updateTask(this.taskList.id, updateTask);
      }
    }
    this.activeModal.close("update");
    this.formData.reset();
    // this._fetchData();
    this.submitted = false;
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  customSearchFn(term: string, inquiry: Inquiry) {
    term = term ? term.toLowerCase() : "";
    return (
      inquiry.username?.toLowerCase().indexOf(term) > -1 ||
      inquiry.company?.toLowerCase().indexOf(term) > -1
    );
  }

  passBack() {
    this.activeModal.close();
  }

  editTaskList() {
    this.formData.get("name").setValue(this.taskList.name);
    this.formData.get("description").setValue(this.taskList.description);
    this.formData.get("taskType").setValue(this.taskList.taskType);
    this.formData.get("joinUsers").setValue(this.taskList.joinUsers);
    this.formData.get("inquiryId").setValue(this.taskList.inquiryId);
    this.isEdit = true;
  }

  getInquiryName(inquiryId) {
    if (this.inquiryList) {
      const inquiry = this.inquiryList.find((item) => item.id == inquiryId);
      return inquiry.username;
    }
  }

  async endTaskList() {
    const confirm = await this.alertService.confirm(
      "완료처리 확인",
      "해당 업무를 완료처리하시겠습니까?",
      "완료처리"
    );
    if (confirm) {
      await this.db.updateAt(`taskList/${this.taskList.id}`, {
        status: "완료",
      });
      this.alertService.success("완료처리되었습니다.");
      this.activeModal.close("완료");
    }
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  downloadFile(url) {
    window.open(url);
  }

  goInquiryDetail() {
    this.activeModal.close();
    const modalRef = this.modalService.open(InquiryDetailComponent, {
      fullscreen: true,
      scrollable: true,
    });
    modalRef.componentInstance.inquiryId = this.taskList.inquiryId;
    // this.router.navigate(["/consulting-detail"], {
    //   state: { inquiryId: this.taskList.inquiryId },
    // });
  }

  getArrayToString(arr: User[]) {
    const stringArray = arr.map((item) => item.name);
    return stringArray.toString();
  }
}
