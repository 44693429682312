import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { BehaviorSubject } from "rxjs";
import { mergeMapTo, take } from "rxjs/operators";
import Swal from "sweetalert2";
import { DbService } from "./db.service";
const broadcast = new BroadcastChannel("myAppChannel");

@Injectable({
  providedIn: "root",
})
export class FcmService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private fireMessaging: AngularFireMessaging,
    private afAuth: AngularFireAuth,
    private db: DbService
  ) {
    this.getPermission();
    broadcast.onmessage = (event) => {
      // event.data - do whatever you want
    };
    this.currentMessage.subscribe((data) => {
      if (data) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: data.notification.body,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  }

  requestToken(): void {
    this.fireMessaging.requestToken.subscribe({
      next: (token) => {
        this.updateToken(token);
        // Upload the user FCM token to your server.
      },
      error: (err) => {
        console.error("Fetching FCM token failed: ", +err);
      },
    });
  }

  updateToken(token) {
    this.afAuth.authState.pipe(take(1)).subscribe(async (user) => {
      if (!user) return;
      const data = {
        userId: user.uid,
        token,
      };
      await this.db.updateAt(`fcmTokens/${user.uid}`, data);
      this.reciveMessage();
    });
  }

  getPermission() {
    this.fireMessaging.requestPermission
      // .pipe(mergeMapTo(this.fireMessaging.tokenChanges))
      .subscribe(
        () => {
          this.requestToken();
        },
        (error) => {
          console.error(error);
        }
      );
  }

  reciveMessage() {
    this.fireMessaging.messages.subscribe((messages: any) => {
      this.currentMessage.next(messages);
    });
  }
}
