import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot } from "@angular/router";
import { User } from "../models/user.model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  user: User;
  constructor(public auth: AuthService, private router: Router) {}

  init() {
    return new Promise(async (resolve) => {
      if (!this.user) {
        this.user = await this.auth.getUser();

        resolve("init");
      }
      resolve("allready init");
    });
  }

  reInit() {
    return new Promise(async (resolve) => {
      this.user = await this.auth.getUser();
      resolve("init");
    });
  }
}
