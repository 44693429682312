import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { finalize, first } from "rxjs/operators";
import { Client } from "src/app/core/models/client.model";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { AlertService } from "src/app/core/services/alert.service";
import {
  DbService,
  leftJoinDocument,
  leftJoinOneDocument,
} from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { SearchClientComponent } from "../search-client/search-client.component";
@Component({
  selector: "app-client-detail",
  templateUrl: "./client-detail.component.html",
  styleUrls: ["./client-detail.component.scss"],
})
export class ClientDetailComponent implements OnInit {
  @Input() public clientId;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  client$;
  client;
  isEdit = false;
  clientForm: FormGroup;
  clientFiles: File[] = [];
  submit = false;
  private modalContent: TemplateRef<ClientDetailComponent>;
  private modalRef: NgbModalRef;
  clientType = "";
  selectClientId = "";
  deletingFiles = [];
  selectedInquiry: Inquiry;
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private db: DbService,
    public fileUploaderService: FileUploaderSevice,
    private loaderSerivce: LoaderService,
    private alertService: AlertService
  ) {}

  get form() {
    return this.clientForm.controls;
  }

  checkType() {
    this.clientType = this.clientForm.get("businessType").value;
    if (this.clientType == "개인") {
      this.clientForm.get("clientName").setValue("");
      this.clientForm.get("companyName").setValue("해당없음");
      this.clientForm.get("presedent").setValue("해당없음");
      this.clientForm.get("businessRegistration").setValue("해당없음");
      return false;
    }
    this.clientForm.get("companyName").setValue("");
    this.clientForm.get("clientName").setValue("해당없음");
    this.clientForm.get("presedent").setValue("");
    this.clientForm.get("businessRegistration").setValue("");
  }

  onSelect(event) {
    this.clientFiles.push(...event.addedFiles);
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  async ngOnInit(): Promise<void> {
    this.clientForm = this.formBuilder.group({
      consultingClient: ["", [Validators.required]],
      existingClient: ["", [Validators.required]],
      businessType: ["", [Validators.required]],
      clientName: ["", [Validators.required]],
      companyName: ["", [Validators.required]],
      clientType: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      address: ["", [Validators.required]],
      contactPerson: [""],
      presedent: ["", [Validators.required]],
      businessRegistration: ["", [Validators.required]],
    });
    this.loaderSerivce.setLoading(true);

    this.client = await this.db
      .doc$(`clients/${this.clientId}`)
      .pipe(
        leftJoinOneDocument(this.db.afs, "createdBy", "users"),
        leftJoinOneDocument(this.db.afs, "inquiryId", "inquiries"),
        first(),
        finalize(() => this.loaderSerivce.setLoading(false))
      )
      .toPromise();
  }

  onRemove(event) {
    this.clientFiles.splice(this.clientFiles.indexOf(event), 1);
  }

  get clForm() {
    return this.clientForm.controls;
  }

  passBack() {
    this.activeModal.close();
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  downloadFile(url) {
    window.open(url);
  }

  checkClientType() {
    if (this.clForm.clientType.value == "직접입력") {
      this.clForm.consultingClient.setValue("해당없음");
      this.clForm.existingClient.setValue("해당없음");
      this.selectedInquiry = null;
    } else if (this.clForm.clientType.value == "기존고객") {
      this.clForm.consultingClient.setValue("해당없음");
      this.selectedInquiry = null;
    } else {
      this.clForm.existingClient.setValue("해당없음");
    }
  }

  editClientData() {
    this.clForm.clientType.setValue(this.client.clientType);

    this.clForm.consultingClient.setValue(this.client.consultingClient);
    this.clForm.existingClient.setValue(this.client.existingClient);
    this.clForm.businessType.setValue(this.client.businessType);
    this.clForm.clientName.setValue(this.client.clientName);
    this.clForm.companyName.setValue(this.client.companyName);
    this.clForm.phone.setValue(this.client.phone);
    this.clForm.email.setValue(this.client.email);
    this.clForm.address.setValue(this.client.address);
    this.clForm.contactPerson.setValue(this.client.clientName);
    this.clForm.presedent.setValue(this.client.presedent);
    this.clForm.businessRegistration.setValue(this.client.businessRegistration);
    this.isEdit = true;
    this.checkClientType();
  }

  validSubmit() {
    this.submit = true;
    const type = this.clientForm.get("businessType").value;
    if (type !== "개인") {
      const clientName = this.clientForm.get("companyName").value;
      this.clientForm.get("clientName").setValue(clientName);
    }
    if (this.clientForm.invalid) {
      console.log("this.clientForm.invalid", this.clientForm.invalid);

      return false;
    }
    this.createdClientData();
  }

  async createdClientData() {
    this.loaderSerivce.setLoading(true);
    const uploadpromises = this.clientFiles.map((file) =>
      this.uploadFile(file)
    );
    const deleetepromises = this.deletingFiles.map((file) =>
      this.fileUploaderService.deleteFile(file)
    );
    await Promise.all(deleetepromises);
    const files = await Promise.all(uploadpromises);
    this.clientType = this.clientForm.get("clientType").value;
    if (this.clientType == "개인") {
    }
    const uploadClientData = {
      companyName: this.clientForm.get("companyName").value,
      clientType: this.clientForm.get("clientType").value, // 고객타입
      businessType: this.clientForm.get("businessType").value, // 고객타입
      clientName:
        this.clientForm.get("businessType").value === "개인"
          ? this.clientForm.get("clientName").value
          : this.clientForm.get("companyName").value, // 고객타입
      phone: this.clientForm.get("phone").value, // 연락처
      email: this.clientForm.get("email").value, // 이메일
      address: this.clientForm.get("address").value, // 주소;
      presedent: this.clientForm.get("presedent").value, //대표명;
      inquiryId: this.selectedInquiry ? this.selectedInquiry.id : "",
      contactPerson: this.clientForm.get("contactPerson").value,
      businessRegistration: this.clientForm.get("businessRegistration").value, //사업자등록증;
      clientFiles: [...files, ...this.client.clientFiles],
    };
    console.log("uploadClientData", uploadClientData);

    await this.db.updateAt(`clients/${this.clientId}`, uploadClientData);
    this.loaderSerivce.setLoading(false);
    this.submit = false;
    this.alertService.success("고객정보가 수정되었습니다.");
    this.client = await this.db
      .doc$(`clients/${this.clientId}`)
      .pipe(
        leftJoinOneDocument(this.db.afs, "createdBy", "users"),
        leftJoinOneDocument(this.db.afs, "inquiryId", "inquiries"),
        first(),
        finalize(() => this.loaderSerivce.setLoading(false))
      )
      .toPromise();
    this.isEdit = false;
  }

  searchClient() {
    const modalRef = this.modalService.open(SearchClientComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.type = "searchClient";
    modalRef.result.then((result: Client) => {
      if (result) {
        this.selectClientId = result.id;
        this.clientForm.get("existingClient").setValue(result.clientName);
        this.clientForm.get("phone").setValue(result.phone);
        this.clientForm.get("email").setValue(result.email);
        this.clientForm.get("address").setValue(result.address);
        this.clientForm.get("contactPerson").setValue(result.contactPerson);
        this.clientForm.get("businessType").setValue(result.businessType);

        if (result.businessType == "개인") {
          this.clientForm.get("clientName").setValue(result.clientName);
          this.clientForm.get("companyName").setValue("해당없음");
          this.clientForm.get("presedent").setValue("해당없음");
          this.clientForm.get("businessRegistration").setValue("해당없음");
        } else {
          this.clientForm.get("companyName").setValue(result.companyName);
          this.clientForm.get("clientName").setValue(result.clientName);
          this.clientForm.get("presedent").setValue(result.presedent);
          this.clientForm
            .get("businessRegistration")
            .setValue(result.businessRegistration);
        }
      }
    });
  }

  searchInquiry() {
    const modalRef = this.modalService.open(SearchClientComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.type = "searchInquiry";
    modalRef.result.then((result) => {
      if (result) {
        this.selectedInquiry = result;
        this.clientForm.get("consultingClient").setValue(result.username);
        this.clientForm.get("companyName").setValue(result.company);
        this.clientForm.get("phone").setValue(result.phone);
        this.clientForm.get("email").setValue(result.email);
        this.clientForm.get("contactPerson").setValue(result.username);
      }
    });
  }
  deleteFile(file, i) {
    this.deletingFiles.push(file);
    this.client.clientFiles.splice(i, 1);
  }

  cancel() {
    this.isEdit = false;
  }
}
