import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { first } from "rxjs/operators";
import { DbService } from "src/app/core/services/db.service";
@Component({
  selector: "app-rent-testphone-detail",
  templateUrl: "./rent-testphone-detail.component.html",
  styleUrls: ["./rent-testphone-detail.component.scss"],
})
export class RentTestphoneDetailComponent implements OnInit {
  @Input() public rentTestphoneId;
  rentTestphone;
  constructor(public activeModal: NgbActiveModal, public db: DbService) {}

  async ngOnInit() {
    this.rentTestphone = await this.db
      .doc$(`rentTestphones/${this.rentTestphoneId}`)
      .pipe(first())
      .toPromise();
  }

  passBack() {
    this.activeModal.close();
  }

  getDate(expiredDate) {
    if (expiredDate) {
      const date = moment(new Date(expiredDate.seconds * 1000)).format(
        "YYYY년 MM월 DD일"
      );
      return date;
    }
  }
}
