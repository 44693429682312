<div class="modal-header">
  <div class="modal-title mt-0">법인카드 사용 등록</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="row">
      <form (ngSubmit)="save()"
        [formGroup]="formData">
        <div class="row">

          <div class="mb-3">
            <label class="control-label">비용사용일</label>
            <input class="form-control"
              type="date"
              name="dateUsed"
              placeholder="비용 사용일을 선택해주세요."
              formControlName="dateUsed"
              [ngClass]="{'is-invalid': submitted && form.dateUsed.errors}" />
            <div *ngIf="submitted && form.dateUsed.errors"
              class="invalid-feedback">
              <span *ngIf="form.dateUsed.errors.required">비용사용일은 필수사항입니다.</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="type">시용유형</label>
            <select class="form-select"
              formControlName="type"
              (change)="selecType()"
              placeholder="사용유형을 선택해주세요."
              [ngClass]="{'is-invalid': submitted && form.type.errors}">
              <option value=""
                disabled>--사용유형 선택--</option>
              <option value="일반">일반</option>
              <option value="부/팀 운영비">부/팀 운영비</option>
              <option value="운영비">운영비</option>
              <option value="점심식사">점심식사</option>
              <option value="저녁식사">저녁식사</option>
            </select>
            <div *ngIf="submitted && form.type.errors"
              class="invalid-feedback">
              <span *ngIf="form.type.errors.required">비용항목은 필수사항입니다.</span>
            </div>
          </div>
          <div class="mb-3"
            *ngIf="form.type.value == '부/팀 운영비'">
            <label for="department">부/팀선택</label>
            <select class="form-select"
              formControlName="department"
              placeholder="부/팀을 선택해주세요."
              [ngClass]="{'is-invalid': submitted && form.department.errors}">
              <option value=""
                disabled>--부/팀 선택--</option>
              <option value="기획부">기획부</option>
              <option value="개발부">개발부</option>
              <option value="디자인팀">디자인팀</option>
              <option value="전략기획팀">전략기획팀</option>
              <option value="운영관리">운영관리</option>
              <option value="신사업팀">신사업팀</option>
            </select>
            <div *ngIf="submitted && form.department.errors"
              class="invalid-feedback">
              <span *ngIf="form.department.errors.required">부/팀은 필수사항입니다.</span>
            </div>
          </div>
          <div class="mb-3"
            *ngIf="form.type.value && form.type.value !=='점심식사' && form.type.value !=='저녁식사'">
            <label for="type">비용항목</label>
            <select class="form-select"
              formControlName="costType"
              (change)="selecType()"
              placeholder="비용항목을 선택해주세요."
              [ngClass]="{'is-invalid': submitted && form.costType.errors}">
              <option value=""
                disabled>--비용항목 선택--</option>
              <option value="식비">식비</option>
              <option value="소모품비">소모품비</option>
              <option value="사무용품비">사무용품비</option>
              <option value="간식비">간식비</option>
              <option value="회식비">회식비</option>
              <option value="여비/교통비">여비/교통비</option>
              <option value="숙박비">숙박비</option>
              <option value="공연관람비">공연관람비</option>
              <option value="교육비">교육비</option>
              <option value="소프트웨어 사용료">소프트웨어 사용료</option>
              <option value="전자기기">전자기기</option>
              <option value="우편발송비">우편발송비</option>
              <option value="기타">기타</option>
            </select>
            <div *ngIf="submitted && form.costType.errors"
              class="invalid-feedback">
              <span *ngIf="form.costType.errors.required">비용항목은 필수사항입니다.</span>
            </div>
          </div>



          <div class="mb-3">
            <label for="method">사용수단</label>
            <select class="form-select"
              formControlName="method"
              placeholder="사용수단을 선택해주세요."
              [ngClass]="{'is-invalid': submitted && form.method.errors}">
              <option value=""
                disabled>--사용수단 선택--</option>
              <option value="농협카드">농협법인카드</option>
              <option value="하나카드">하나법인카드</option>
              <option value="현금">현금</option>
              <option value="개인카드">개인카드</option>
              <option value="기타">기타</option>
            </select>
            <div *ngIf="submitted && form.method.errors"
              class="invalid-feedback">
              <span *ngIf="form.method.errors.required">사용수단은 필수사항입니다.</span>
            </div>
          </div>
          <div class="mb-3"
            *ngIf="form.type.value && form.type.value !=='점심식사' && form.type.value !=='저녁식사'">
            <label class="control-label">사용내역명</label>
            <input class="form-control"
              placeholder="사용내역명을 입력해주세요."
              type="text"
              name="title"
              formControlName="title"
              [ngClass]="{'is-invalid': submitted && form.title.errors}" />
            <div *ngIf="submitted && form.title.errors"
              class="invalid-feedback">
              <span *ngIf="form.title.errors.required">사용내역명은 필수사항입니다.</span>
            </div>
          </div>
          <div class="mb-3">
            <label class="control-label">사용금액</label>
            <input class="form-control"
              placeholder="사용금액을 입력해주세요."
              type="text"
              name="amount"
              formControlName="amount"
              (keyup)="updateTextView()"
              [ngClass]="{'is-invalid': submitted && form.amount.errors}" />
            <div *ngIf="submitted && form.amount.errors"
              class="invalid-feedback">
              <span *ngIf="form.amount.errors.required">사용금액은 필수사항입니다.</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="method">사용직원</label>
            <ng-select *ngIf="(users$ | async) as users"
              [items]="users"
              [searchable]="true"
              bindLabel="name"
              bindValue="id"
              formControlName="users"
              placeholder="사용직원을 선택해주세요."
              [multiple]="true"
              [ngClass]="{'is-invalid': submitted && form.users.errors}"></ng-select>

            <div *ngIf="submitted && (form.users.errors || !form.users.value.length)"
              class="invalid-feedback">
              <span *ngIf="form.users.errors.required">사용자를 최소 1명이상 선택해주세요.</span>
            </div>
          </div>

          <div class="mb-3">
            <label for="file">첨부파일</label>
            <input formControlName="files"
              id="file"
              type="file"
              class="form-control"
              multiple
              (change)="onFileUpload($event)">
            <h2 class="font-size-15 mt-4">첨부파일</h2>
            <p *ngIf="!isEdit && myFiles.length == 0 ">없음</p>
            <p *ngIf="isEdit&& myFiles.length == 0 && selectCost && selectCost.files.length ==0 ">없음</p>
            <div class="table-responsive"
              *ngIf="!isEdit&&myFiles&& myFiles.length>0">
              <table class="table table-centered mb-0 table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th scope="col">파일명</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let file of myFiles; let i = index">
                    <td>
                      {{file.name }}
                    </td>
                    <td>
                      <button type="button"
                        class="btn btn-sm btn-outline-danger"
                        (click)="deleteMyFiles(file,i)">삭제</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div class="table-responsive"
                *ngIf="isEdit && myFiles?.length + selectCost?.files.length > 0 ">
                <table class="table table-centered mb-0 table-nowrap"
                  *ngIf="myFiles.length > 0">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">업로드 파일명</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of myFiles; let i =index">
                      <td>
                        {{file.name }}
                      </td>
                      <td>
                        <button type="button"
                          class="btn btn-sm btn-danger"
                          (click)="deleteMyFiles(file,i)">삭제</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table table-centered mb-0 table-nowrap"
                  *ngIf="selectCost.files.length > 0">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col">파일명</th>
                      <th scope="col"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let file of selectCost.files; let i = index">
                      <td>
                        {{getFileName(file) }}
                      </td>
                      <td>
                        <button type="button"
                          class="btn btn-sm btn-danger"
                          (click)="deleteFile(file,i)">삭제</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex flex-wrap text-end">
          <button type="submit"
            class="btn btn-success">{{isEdit?'저장':'등록'}}</button>
        </div>
      </form>
    </div>
  </div>

</div>