import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/core/services/db.service";
import { first } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Inquiry } from "src/app/core/models/inquiry.model";
@Component({
  selector: "app-search-client",
  templateUrl: "./search-client.component.html",
  styleUrls: ["./search-client.component.scss"],
})
export class SearchClientComponent implements OnInit {
  @Input() public type: string;
  clients;
  term: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  inquiries: Inquiry[];
  constructor(private db: DbService, public activeModal: NgbActiveModal) {}

  async ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    if (this.type == ("searchInquiry" || "searchAll")) {
      this.inquiries = await this.db
        .collection$("inquiries")
        .pipe(first())
        .toPromise();
    }
    if (this.type == ("searchClient" || "searchAll")) {
      this.clients = await this.db
        .collection$("clients")
        .pipe(first())
        .toPromise();
    }
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.fetchData();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchData();
  }

  select(item) {
    this.activeModal.close(item);
  }
}
