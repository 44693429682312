import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { first } from "rxjs/operators";
import { Contract } from "src/app/core/models/contract.model";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
@Component({
  selector: "app-edit-contract",
  templateUrl: "./edit-contract.component.html",
  styleUrls: ["./edit-contract.component.scss"],
})
export class EditContractComponent implements OnInit {
  @Input() public contractId;
  public loading = false;
  contractForm: FormGroup;
  contract: Contract;
  breadCrumbItems: Array<{}>;
  deletingFiles = [];
  submit: boolean;
  submitContract = false;
  image = "";
  file = "";

  contractFiles: File[] = [];
  selectedInquiry: Inquiry;

  constructor(
    public formBuilder: FormBuilder,
    private dataService: DataService,
    private alertService: AlertService,
    private loderService: LoaderService,
    public activeModal: NgbActiveModal,
    private db: DbService,
    private fileUploaderService: FileUploaderSevice
  ) {}
  /**
   * Returns form
   */
  get form() {
    return this.contractForm.controls;
  }

  async ngOnInit() {
    this.contractForm = this.formBuilder.group(
      {
        contractTitle: ["", [Validators.required]],
        dateSigned: ["", [Validators.required]],
        periodStart: ["", [Validators.required]],
        periodEnd: ["", [Validators.required]],
        contractType: ["", [Validators.required]],
        advancePayment: ["", [Validators.required]],
        interimPayment: ["", [Validators.required]],
        balance: ["", [Validators.required]],
        information: [""],
        contractAmount: [0, [Validators.required]],
      },
      {
        validator: this.checkCondition(),
      }
    );
    this.submit = false;
    this.contract = await this.db
      .doc$(`contracts/${this.contractId}`)
      .pipe(first())
      .toPromise();
    console.log("this.contract", this.contract);

    this._fetchData();
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  checkCondition() {
    return (group: FormGroup): { [key: string]: any } => {
      let advancePayment = Number(group.controls["advancePayment"].value);
      let interimPayment = Number(group.controls["interimPayment"].value);
      let balance = Number(group.controls["balance"].value);
      let totalConditioan = advancePayment + interimPayment + balance;

      if (totalConditioan !== 100) {
        return {
          wrongCondition: true,
        };
      }
    };
  }

  onContractFileSelect(event) {
    this.contractFiles.push(...event.addedFiles);
  }

  private async _fetchData() {
    this.contractForm
      .get("contractTitle")
      .setValue(this.contract.contractTitle);
    this.contractForm.get("dateSigned").setValue(this.contract.dateSigned);
    this.contractForm.get("periodStart").setValue(this.contract.periodStart);
    this.contractForm.get("periodEnd").setValue(this.contract.periodEnd);
    this.contractForm.get("contractType").setValue(this.contract.contractType);
    this.contractForm
      .get("advancePayment")
      .setValue(this.contract.advancePayment);
    this.contractForm
      .get("interimPayment")
      .setValue(this.contract.interimPayment);
    this.contractForm.get("balance").setValue(this.contract.balance);
    this.contractForm.get("information").setValue(this.contract.information);
    this.contractForm
      .get("contractAmount")
      .setValue(this.contract.contractAmount);
  }

  onAccept(file: any) {
    // this.image = file.name;
    this.file = file;
  }

  get ctForm() {
    return this.contractForm.controls;
  }

  getNumber(text) {
    const arr = text.split("");
    const out = new Array();
    for (let cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) == false) {
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(""));
  }

  updateTextView() {
    const value = this.contractForm.get("contractAmount").value;
    let num = this.getNumber(value);
    if (num == 0) {
      this.contractForm.get("contractAmount").setValue("");
    } else {
      this.contractForm.get("contractAmount").setValue(num.toLocaleString());
    }
  }

  async validSubmit() {
    this.submit = true;
    if (this.contractForm.invalid) {
      return false;
    }
    this.loderService.setLoading(true);
    const updateData = {
      dateCreated: new Date().toISOString(),
      name: this.contractForm.get("name").value,
      email: this.contractForm.get("email").value,
      type: this.contractForm.get("type").value,
      position: this.contractForm.get("position").value,
      duty: this.contractForm.get("duty").value,
      department: this.contractForm.get("department").value,
      level: this.contractForm.get("level").value,
    };

    this.loderService.setLoading(false);
    this.alertService.success("사용자가 성공적으로 등록되었습니다.");
    this.activeModal.close();
  }

  onContractFileRemove(event) {
    this.contractFiles.splice(this.contractFiles.indexOf(event), 1);
  }
  passBack() {
    this.activeModal.close();
  }

  async validContract() {
    this.submitContract = true;
    if (this.contractForm.invalid) {
      return false;
    }

    this.loderService.setLoading(true);

    const uploadpromises = this.contractFiles.map((file) =>
      this.uploadFile(file)
    );
    const deleetepromises = this.deletingFiles.map((file) =>
      this.fileUploaderService.deleteFile(file)
    );
    const contractAmount = this.contractForm.get("contractAmount").value;
    await Promise.all(deleetepromises);
    const files = await Promise.all(uploadpromises);
    const updateData = {
      contractTitle: this.contractForm.get("contractTitle").value,
      dateSigned: this.contractForm.get("dateSigned").value,
      periodStart: this.contractForm.get("periodStart").value,
      periodEnd: this.contractForm.get("periodEnd").value,
      contractType: this.contractForm.get("contractType").value,
      advancePayment: this.contractForm.get("advancePayment").value,
      interimPayment: this.contractForm.get("interimPayment").value,
      balance: this.contractForm.get("balance").value,
      information: this.contractForm.get("information").value
        ? this.contractForm.get("information").value
        : "",
      contractAmount:
        typeof contractAmount == "string"
          ? this.setNumber(this.contractForm.get("contractAmount").value)
          : contractAmount,
      contractFiles: [...this.contract.contractFiles, ...files],
    };
    await this.db.updateAt(`contracts/${this.contractId}`, updateData);
    this.loderService.setLoading(false);

    this.alertService.success("계약이 수정되었습니다.");
    this.activeModal.close();
  }

  setNumber(str) {
    const withoutCommas = str.replaceAll(",", "");
    return Number(withoutCommas);
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  deleteFile(file, i) {
    this.deletingFiles.push(file);
    this.contract.contractFiles.splice(i, 1);
  }
}
