/** @format */

import { Injectable } from "@angular/core";
import { DbService } from "./db.service";
import { take, map } from "rxjs/operators";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/compat/storage";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class FileUploaderSevice {
  task: AngularFireUploadTask;

  percentage: Observable<number>;
  snapshot: Observable<any>;
  downloadURL: string;

  constructor(private storage: AngularFireStorage) {}

  startUpload(file) {
    // The storage path
    const path = `test/${Date.now()}_${file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();

        // this.db
        //   .collection("files")
        //   .add({ downloadURL: this.downloadURL, path });
      })
    );
  }

  isActive(snapshot) {
    return (
      snapshot.state === "running" &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  storageUpload(filedata, filehandle, DropzoneHandle, cb) {
    const path = `uploadFile/${Date.now()}_${filedata.name}`;
    const ref = this.storage.ref(path);

    // Getting Handle of the progressbar element of current file //
    const progressBar = filehandle.previewElement.children[2];

    // Firestore storage task
    // uuid for file being uploaded

    // Because I am uploading file in base64 data_url //
    this.task = this.storage.upload(path, filedata);

    // Making progress bar of current file preview element visible
    progressBar.opacity = 1;

    this.percentage = this.task.percentageChanges();
    this.percentage.pipe(
      map((per) => {
        progressBar.children[0].style.width = per + "%";
        return per;
      })
    );

    this.snapshot = this.task.snapshotChanges().pipe(
      finalize(async () => {
        const downloadURL = await ref.getDownloadURL().toPromise();
        const mataData = await ref.getMetadata().toPromise();

        // storing meta data and download url in object and returning
        // it to callign function ...
        var response = {
          object_info: {
            publicURL: downloadURL,
            metainfo: mataData,
          },
          type_of_object: "type",
        };
        filehandle.fullPath = mataData.fullPath;
        progressBar.style.opacity = 0;

        return cb(response);
      })

      // Hiding progress bar for current file
    );
  }

  uploadFile(fileName: string, data: string) {
    return new Promise<any>((resolve) => {
      const fileName2 = `${new Date().getTime()}_${fileName}`;
      this.storage
        .ref(`/files/` + fileName2)
        .putString(data, "data_url")
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;

          resolve(url);
        })
        .catch((error) => {});
    });
  }

  uploadBlobToStorage(fileName: string, blob: Blob) {
    const fileNameWithTimestamp = `${new Date().getTime()}_${fileName}`;
    return new Promise<any>((resolve, reject) => {
      this.storage
        .ref(`/files/${fileNameWithTimestamp}`)
        .put(blob)
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;
          resolve(url);
        })
        .catch((error) => {
          console.error("Error uploading file", error);
          reject(error);
        });
    });
  }

  uploadFileMerge(fileName, dataurl) {
    return new Promise<any>((resolve) => {
      this.storage
        .ref(`/files/` + fileName)
        .putString(dataurl, "data_url")
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;

          resolve(url);
        })
        .catch((error) => {});
    });
  }

  uploadImageMerge(fileName, dataurl) {
    return new Promise<any>((resolve) => {
      const fileName = this.generateFilename() + ".png";
      this.storage
        .ref(`/image/` + fileName)
        .putString(dataurl, "data_url")
        .then((v) => {
          let url = `https://storage.googleapis.com/${v.metadata.bucket}/${v.metadata.fullPath}`;

          resolve(url);
        })
        .catch((error) => {});
    });
  }

  generateFilename() {
    var length = 20;
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  deleteFile(downloadUrl) {
    return this.storage.storage.refFromURL(downloadUrl).delete();
  }
}
