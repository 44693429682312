import { LocationStrategy } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DndDropEvent } from "ngx-drag-drop";
import { AppCategory } from "src/app/core/models/appCategory.model";

import { UserService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { Observable } from "rxjs";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { tagData } from "src/app/core/models/tagData.model";
import { map, startWith, take } from "rxjs/operators";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import * as XLSX from "xlsx";
import { SolutionWeb } from "src/app/core/models/solutionWeb.models";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { A, COMMA, ENTER } from "@angular/cdk/keycodes";
import { AppPortFolio } from "src/app/core/models/appPortFolio.model";
@Component({
  selector: "app-add-portfolio",
  templateUrl: "./add-portfolio.component.html",
  styleUrls: ["./add-portfolio.component.scss"],
})
export class AddPortfolioComponent implements OnInit {
  @HostListener("window:popstate", ["$event"])
  @ViewChild("tagInput")
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("fileInput") fileInput: ElementRef;
  @Input() porfolioId;

  porfolioForm: FormGroup;

  // bread crumb items
  breadCrumbItems: Array<{}>;
  // Form submition
  submit: boolean;
  accept = "*";
  componentcolor: string;
  config: DropzoneConfigInterface = {
    // Change this to your upload POST address:
    maxFilesize: 50,
    acceptedFiles: "image/*",
    method: "POST",
    uploadMultiple: false,
    accept: (file) => {
      this.onAccept(file);
    },
  };
  mianImage = "";
  detailImage = "";
  selectType = "";
  file = "";
  uploadFiles = [];
  newPortFolio: AppPortFolio = {
    mainImage: "",
    detailImage: "",
    listImage: "",
    functions: [],
    dateCreated: "",
    createdBy: "",
    displaySwitch: true,
    order: 1,
    categoryId: null,
    tag: [],
    androidStoreUrl: "",
    iosStoreUrl: "",
    webUrl: "",
    platforms: [],
    sampleUrl: "",
    title: "",
  };
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredTags: Observable<string[]>;
  tags: string[] = [];
  allTags: string[];
  tagCtrl = new FormControl("");
  deleteFeatures = [];
  private _prevOnSameUrlNavigation;
  appCategories: AppCategory[];
  constructor(
    private _location: LocationStrategy,
    public modalService: NgbModal,
    public dataService: DataService,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    public loading: LoaderService,
    private db: DbService,
    private _router: Router,
    private userService: UserService,
    public formBuilder: FormBuilder,
    public fileUploaderService: FileUploaderSevice
  ) {}

  async ngOnInit() {
    this.loading.setLoading(true);
    await this.dataService.inIt();
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    if (this.porfolioId) {
      await this.setData();
    }
    this.appCategories = await this.dataService.getAllProjectAppCategories();
    this.componentcolor = "#3bafda";
    this.submit = false;
    const tagData: tagData = await this.dataService.gePortFolioTags();
    this.allTags = tagData.tags;
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags?.slice()
      )
    );
    this.loading.setLoading(false);
  }

  async setData() {
    const portFolio = await this.db
      .doc$(`portfolios/${this.porfolioId}`)
      .pipe(take(1))
      .toPromise();
    this.newPortFolio = { ...portFolio };
  }

  passBack() {
    this.activeModal.close();
  }

  /**
   * on dragging task
   * @param item item dragged
   * @param list list from item dragged
   */
  onDragged(item: any, list: any[]) {
    const index = list.indexOf(item);
    list.splice(index, 1);

    list.map((item, i) => {
      if (item.id) {
        return this.db.updateAt(`appCategories/${item.id}`, { order: i + 1 });
      }
    });
    this.appCategories = list.map((item, i) => {
      item.order = i + 1;
      return item;
    });
  }

  onDrop(event: DndDropEvent, filteredList?: any[], targetStatus?: string) {
    if (filteredList && event.dropEffect === "move") {
      let index = event.index;

      if (typeof index === "undefined") {
        index = filteredList.length;
      }
      filteredList.splice(index, 0, event.data);
    }
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }

  /**
   * Onclick delete member from form
   */
  deleteImage(type) {
    this.newPortFolio[type] = "";
  }

  onAccept(file: any) {
    if (this.selectType == "mainImage") {
      this.mianImage = file.name;
    } else {
      this.detailImage = file.name;
    }
    this.file = file;
  }

  onRemove(event) {
    // this.uploadFiles.splice(this.uploadFiles.indexOf(event), 1);
  }

  onSelect(event) {
    this.uploadFiles.push(...event.addedFiles);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.newPortFolio.tag && this.newPortFolio.tag.length
        ? this.newPortFolio.tag.push(value)
        : (this.newPortFolio.tag = [value]);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.newPortFolio.tag.indexOf(fruit);

    if (index >= 0) {
      this.newPortFolio.tag.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.newPortFolio.tag
      ? this.newPortFolio.tag.push(event.option.viewValue)
      : (this.newPortFolio.tag = [event.option.viewValue]);
    this.tagInput.nativeElement.value = "";
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allTags.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }

  changeDisplaySwitch() {
    setTimeout(() => {
      const isEmpty = Object.values(this.newPortFolio).every(
        (x) => x === null || x === ""
      );
      console.log("isEmpty", isEmpty);
    }, 500);
  }

  async save() {
    if (
      !this.newPortFolio.mainImage ||
      !this.newPortFolio.categoryId ||
      !this.newPortFolio.title
    ) {
      this.alertService.errormsg(
        "알림",
        "메인이미지, 제목, 카테고리는 필수항목입니다."
      );
      return;
    }
    if (!this.porfolioId) {
      const confirm = await this.alertService.confirm(
        "포트폴리오 등록",
        "작성하신 포트폴리오를 등록하시겠습니까?",
        "등록"
      );
      if (!confirm) {
        return false;
      }
      this.loading.setLoading(true);
      this.porfolioId = await this.db.createFsId();
      await this.dataService.createPortFolio(this.newPortFolio);
      this.loading.setLoading(false);
      this.alertService.success("성공적으로 등록되었습니다.");
      this.activeModal.close("add");
    } else {
      const confirm = await this.alertService.confirm(
        "포트폴리오 수정",
        "수정하신 포트폴리오를 저장하시겠습니까?",
        "저장"
      );
      if (!confirm) {
        return false;
      }
      await this.db.updateAt(
        `portfolios/${this.porfolioId}`,
        this.newPortFolio
      );
      this.loading.setLoading(false);
      this.alertService.success("성공적으로 저장되었습니다.");
      this.activeModal.close(this.newPortFolio);
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadImageMerge(
          file.name,
          val
        );
        resolve(url);
      };
    });
  }

  async onImageChange(event, type) {
    this.loading.setLoading(true);
    const file = event.target.files[0];
    const uploadedFile = await this.uploadFile(file);
    this.newPortFolio[type] = uploadedFile;
    this.loading.setLoading(false);
  }

  delImage(type) {
    this.newPortFolio[type] = "";
  }
}
