import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  productList,
  productModel,
} from "src/app/pages/app-solution/solution-app-manegement/product.model";

@Component({
  selector: "app-app-detail",
  templateUrl: "./app-detail.component.html",
  styleUrls: ["./app-detail.component.scss"],
})
export class AppDetailComponent implements OnInit {
  @Input() appId: string;
  public productDetail: productModel[];

  constructor(public activeModal: NgbActiveModal) {
    this.productDetail = productList.filter(function (product) {
      return product.id == 1;
    });
  }

  ngOnInit(): void {}

  passBack() {
    this.activeModal.close();
  }
}
