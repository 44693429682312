<!-- <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">고객 상세</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">웹 솔루션 상품정보</h4>
      <div class="row"
        *ngIf="websoltion">
        <!-- Controls carousel -->
        <ngb-carousel [showNavigationIndicators]="showNavigationIndicators">
          <ng-template ngbSlide
            *ngFor="let item of websoltion.images">
            <img src="{{item}}"
              alt="
            first
            slide"
              class="d-block img-fluid">
          </ng-template>

        </ngb-carousel>
      </div>
      <!-- End controls carousel -->
      <!--
            <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
                person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
      <div class="table-responsive">
        <table class="table table-nowrap mb-0">
          <tbody>

            <tr>
              <th scope="row">상품명 :</th>
              <td>{{websoltion?.title}}</td>
            </tr>
            <tr>
              <th scope="row">상품코드 :</th>
              <td>{{websoltion?.productNumber}}</td>
            </tr>
            <tr>
              <th scope="row">카테고리 :</th>
              <td>{{websoltion?.categoryId.title}}</td>
            </tr>
            <tr>
              <th scope="row">테그 :</th>
              <td *ngIf="websoltion"><span class="badge badge-soft-success font-size-12"
                  *ngFor="let item of websoltion.tag">{{item}}</span>
              </td>
            </tr>
            <tr>
              <th scope="row">노출유무 :</th>
              <td>{{websoltion?.displaySwitch?'노출중':'미노출' }}</td>
            </tr>
            <tr>
              <th scope="row">샘플URL :</th>
              <td>{{websoltion?.sampleUrl}} <button type="button"
                  class="btn btn-sm btn-outline-dark"
                  (click)="openUrl(websoltion.sampleUrl)">미리보기</button></td>
            </tr>
            <tr>
              <th scope="row">구매URL :</th>
              <td>{{websoltion?.puchaseUrl}} <button type="button"
                  (click)="openUrl(websoltion.puchaseUrl)"
                  class="btn btn-sm btn-outline-dark">바로가기</button></td>
            </tr>
            <tr>
              <th scope="row">등록일 :</th>
              <td>{{websoltion?.dateCreated | date:'yyyy-MM-dd'}}</td>
            </tr>
            <tr>
              <th scope="row">등록자 :</th>
              <td>{{websoltion?.createdBy.name}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <button type="submit"
    (click)="passBack()"
    class="btn btn-primary w-100">닫기</button>
</div>
