import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Consulting } from "src/app/core/models/consult.models";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { AligoSendData, DataService } from "src/app/core/services/data.service";
import { AlertService } from "src/app/core/services/alert.service";
import {
  DbService,
  leftJoinDocument,
  leftJoinOneDocument,
} from "src/app/core/services/db.service";
import { Observable } from "rxjs";
import { first, shareReplay, take } from "rxjs/operators";

import { ActivatedRoute, Navigation, Route, Router } from "@angular/router";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { AddScheduleComponent } from "src/app/components/add-schedule/add-schedule.component";
import moment from "moment";
import { Meeting } from "src/app/core/models/meeting.model";
import { TaskList } from "src/app/core/models/task.model";
import { AddTaskListComponent } from "src/app/components/add-task-list/add-task-list.component";

import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddEstimateComponent } from "../add-estimate/add-estimate.component";
import { Estimate } from "src/app/core/models/estimate.model";
import { LocationStrategy } from "@angular/common";
import { LoaderService } from "src/app/core/services/loader.service";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";
import { WebSolutionDetailComponent } from "../web-solution-detail/web-solution-detail.component";
import { Timestamp } from "firebase/firestore";
import { AuthService } from "src/app/core/services/auth.service";
import { TrackingData } from "src/app/core/models/trackingData.model";
import { TrackDataDetailComponent } from "../track-data-detail/track-data-detail.component";

@Component({
  selector: "app-inquiry-detail",
  templateUrl: "./inquiry-detail.component.html",
  styleUrls: ["./inquiry-detail.component.scss"],
})
export class InquiryDetailComponent implements OnInit {
  @Input() inquiryId: string;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  private _prevOnSameUrlNavigation;
  isInquiry = false;
  public Editor = ClassicEditor;
  consultings$: Observable<any>;
  inquiry$: Observable<any>;
  statusHistories$: Observable<any>;
  messageHistories$: Observable<any>;
  meeting$: Observable<any>;
  estimates$: Observable<any>;
  description = "";
  consultingType = "전화";
  uploadFiles = [];
  meetingData: any;
  taskList$;
  deleteFiles = [];
  inquiryData: Inquiry;

  constructor(
    private _location: LocationStrategy,
    public dataService: DataService,
    private alertService: AlertService,
    private db: DbService,
    private _router: Router,
    private modalService: NgbModal,
    public fileUploaderService: FileUploaderSevice,
    public activeModal: NgbActiveModal,
    private loadService: LoaderService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    this._location.onPopState(() => {
      // example for a simple check if modal is opened
      if (this.activeModal !== undefined) {
        ("modal is opened - cancel default browser event and close modal");
        // TODO: cancel the default event
        // close modal
        this.activeModal.close();
      } else {
      }
    });
    this.getData(this.inquiryId);

    await this.dataService.inIt();
  }

  async cancelMeeting(meeting) {
    const confirm = await this.alertService.confirm(
      "미팅 취소 확인",
      "정말 미팅을 취소하시겠습니까?",
      "미팅취소"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${meeting.id}`, {
        isCanceled: true,
        dateCanceld: new Date().toISOString(),
        canceledBy: this.dataService.userId,
      });
      // let i = this.meetingData?.findIndex((item) => item.id === meeting.id);
      // this.meetingData[i].isCanceled = true;
      this.alertService.success("미팅이 취소되었습니다.");
    }
  }

  async resumeMeeting(meeting) {
    const confirm = await this.alertService.confirm(
      "알림",
      "해당 취소된 미팅을 다시 되돌리시겠습니까?",
      "확인"
    );
    if (confirm) {
      await this.db.updateAt(`meetings/${meeting.id}`, {
        isCanceled: false,
        dateCanceld: "",
        canceledBy: "",
      });

      this.alertService.success("미팅이 다시 예약되었습니다.");
    }
  }

  addTaskList(type: string, taskList?: TaskList, i?: number) {
    const modalRef = this.modalService.open(AddTaskListComponent, {
      size: "lg",
    });
    if (taskList) {
      modalRef.componentInstance.taskList = taskList;
    }
    modalRef.componentInstance.type = type;
    modalRef.result.then(async (result) => {});
  }

  addEstimate(inquiry) {
    const modalRef = this.modalService.open(AddEstimateComponent, {
      size: "lg",
    });
    modalRef.componentInstance.inquiry = inquiry;
  }

  async getData(inquiryId) {
    this.inquiry$ = await this.db.doc$(`inquiries/${inquiryId}`);

    this.inquiry$.pipe(take(1)).subscribe((data) => {
      console.log("data", data);

      if (!data.dateCreated) {
        this.alertService.error("삭제된 문의 입니다.");
      }
    });
    this.consultings$ = this.db
      .collection$(`consultings`, (ref) =>
        ref.where("inquiryId", "==", inquiryId).orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.statusHistories$ = this.db
      .collection$(`statusHistories`, (ref) =>
        ref.where("inquiryId", "==", inquiryId).orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.messageHistories$ = this.db
      .collection$(`messageHistories`, (ref) =>
        ref.where("inquiryId", "==", inquiryId).orderBy("dateCreated", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));

    this.estimates$ = this.db
      .collection$(`estimates`, (ref) =>
        ref.where("inquiryId", "==", inquiryId).orderBy("dateCreated", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.taskList$ = this.db.collection$(`taskList`, (ref) =>
      ref.where("inquiryId", "==", inquiryId).orderBy("dateCreated", "desc")
    );
    this.meeting$ = await this.db.collection$(`meetings`, (ref) =>
      ref.where("refId", "==", inquiryId).orderBy("start", "desc")
    );
  }

  openTrackDetail(trackingDataId: string) {
    const modalRef = this.modalService.open(TrackDataDetailComponent, {
      fullscreen: true,
      scrollable: true,
    });
    if (trackingDataId) {
      modalRef.componentInstance.trackingDataId = trackingDataId;
    }
  }

  downloadFile(url) {
    window.open(url);
  }

  passBack() {
    this.activeModal.close();
  }

  getDate(item) {
    const date = moment(new Date(item.start.seconds * 1000)).format(
      "YYYY년 MM월 DD일 HH:mm"
    );
    return date;
  }

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }

  async createdConsulting(inquiry: Inquiry) {
    if (this.consultingType === "부재2차") {
      if (!inquiry.noResponse1Date) {
        this.alertService.error("부재1차가 아직 등록되지 않았습니다.");
        return;
      }
    }

    const confirm = await this.alertService.confirm(
      "상담등록",
      "상담 내용을 저장하시겠습니까?",
      "확인"
    );
    if (confirm) {
      const newConsulting: Consulting = {
        inquiryId: this.inquiryId,
        date: new Date(),
        dateCreated: new Date().toISOString(),
        type: inquiry.status ? inquiry.status : "접수",
        updateFiles: [],
        consultingType: this.consultingType,
        createdBy: this.dataService.userId,
        description: this.description,
      };
      await this.db.updateAt("consultings", newConsulting);
      if (this.consultingType === "부재1차") {
        await this.db.updateAt(`inquiries/${inquiry.id}`, {
          noResponse1Date: new Date(),
        });
      }
      if (this.consultingType === "부재2차") {
        await this.db.updateAt(`inquiries/${inquiry.id}`, {
          noResponse2Date: new Date(),
        });
      }
      this.description = "";
      this.alertService.basicMessage("상담내용이 등록되었습니다.");
    }
  }

  async changeStatus(inquiry, status: string) {
    if (inquiry.status === status) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 문의 상태를 "${status}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        inquiryId: inquiry.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: inquiry.status ? inquiry.status : "접수",
        newStatus: status,
        createdBy: this.dataService.userId,
      };
      await this.db.updateAt("statusHistories", updateStatusHistory);
      await this.db.updateAt(`inquiries/${inquiry.id}`, {
        status: status,
      });
      this.alertService.basicMessage("상태가 변경되었습니다.");
    }
  }

  async changeType(inquiry, type: string) {
    if (inquiry.type === type) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "문의타입변경",
      `해당 문의 타입을 "${type}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      await this.db.updateAt(`inquiries/${inquiry.id}`, {
        type: type,
      });
      this.alertService.basicMessage("문의 타입이 변경되었습니다.");
    }
  }

  async changeCheckBudget(inquiry, checkedBudget: string) {
    if (inquiry.checkedBudget === checkedBudget) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "확인예산변경",
      `해당 문의 확인예산을 "${checkedBudget}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      await this.db.updateAt(`inquiries/${inquiry.id}`, {
        checkedBudget: checkedBudget,
      });
      this.alertService.basicMessage("문의 확인예산이 변경되었습니다.");
    }
  }

  async deleteInquiry(inquiry) {
    const confirm = await this.alertService.confirm(
      "문의 삭제",
      "문의를 삭제하면 복원 할 수 없습니다. 해당 문의를 정말로 삭제하시겠습니까?",
      "문의 삭제"
    );
    if (confirm) {
      await this.db.updateAt(`deletedInquiries/${inquiry.id}`, inquiry);
      await this.db.delete(`inquiries/${inquiry.id}`);
      this.alertService.basicMessage("문의가 삭제되었습니다.");
      this.activeModal.close("deleted");
    }
  }

  editConsulting(item) {
    item.isEditor = true;
  }

  async saveEditConsulting(item) {
    // 'consultings'
    console.log("budget", item);

    item.isEditor = false;
    const updateData = { ...item };

    delete updateData.isEditor;
    updateData.createdBy = this.dataService.userId;
    await this.db.updateAt(`consultings/${item.id}`, updateData);
    this.alertService.success("수정 내용이 저장되었습니다.");
  }

  async deleteConsulting(item) {
    const confirm = await this.alertService.confirm(
      "삭제 확인",
      "해당 상담내역을 정말 삭제 하시겠습니까?",
      "삭제"
    );
    if (confirm) {
      await this.db.delete(`consultings/${item.id}`);
      this.alertService.success("삭제 되었습니다.");
    }
  }

  editInquiry(inquiry) {
    this.isInquiry = true;
    this.inquiryData = inquiry;
    this.deleteFiles = [];
    this.uploadFiles = [];
  }

  async saveInquiry(inquiry) {
    this.loadService.setLoading(true);
    const promises = this.uploadFiles.map((file) => this.uploadFile(file));
    const files: string[] = await Promise.all(promises);
    const deletedpromises = this.deleteFiles.map((file) =>
      this.fileUploaderService.deleteFile(file)
    );
    await Promise.all(deletedpromises);
    inquiry.updateFiles =
      inquiry.updateFiles && inquiry.updateFiles.length
        ? [...inquiry.updateFiles, ...files]
        : [...files];
    const updateData: Partial<Inquiry> = {
      updateFiles: inquiry.updateFiles,
      username: inquiry.username,
      company: inquiry.company,
      project: inquiry.project,
      budget: inquiry.budget,
      service: inquiry.service,
      phone: inquiry.phone,
      email: inquiry.email,
      content: inquiry.content,
    };

    await this.db.updateAt(`inquiries/${inquiry.id}`, updateData);
    this.loadService.setLoading(false);
    this.alertService.success("수정사항이 저장되었습니다.");
    this.isInquiry = false;
  }

  onFileSelect(event) {
    this.uploadFiles.push(...event.addedFiles);
  }

  onFileRemove(event) {
    this.uploadFiles.splice(this.uploadFiles.indexOf(event), 1);
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  addConsultSchedule(inquiry) {
    const modalRef = this.modalService.open(AddScheduleComponent, {
      size: "xl",
      centered: true,
    });
    modalRef.componentInstance.inquiry = inquiry;
    modalRef.componentInstance.type = "add";
    modalRef.result.then(async (result) => {
      if (result) {
        this.meetingData = await this.db
          .collection$(`meetings`, (ref) =>
            ref.where("refId", "==", inquiry.id).orderBy("start", "desc")
          )
          .pipe(first())
          .toPromise();
      }
    });
  }

  editConsultSchedule(meeting, inquiry) {
    const modalRef = this.modalService.open(AddScheduleComponent, {
      size: "xl",

      centered: true,
    });
    modalRef.componentInstance.inquiry = inquiry;
    modalRef.componentInstance.meeting = meeting;
    modalRef.componentInstance.type = "edit";
    modalRef.result.then(async (result) => {
      if (result) {
        this.meetingData = await this.db
          .collection$(`meetings`, (ref) =>
            ref.where("refId", "==", inquiry.id).orderBy("start", "desc")
          )
          .pipe(first())
          .toPromise();
      }
    });
  }

  deleteInquiryFile(file, i) {
    this.inquiryData.updateFiles.splice(i, 1);
    this.deleteFiles.push(file);
  }

  async deleteEstimate(estimate: Estimate) {
    const confirm = await this.alertService.confirm(
      "삭제 확인",
      "해당 견적서를 정말 삭제 하시겠습니까?",
      "삭제"
    );
    if (confirm) {
      const deleteData = { ...estimate, deletedBy: this.dataService.userId };
      await this.db.updateAt(`deletedEstimate/${estimate.id}`, deleteData);
      await this.db.delete(`estimates/${estimate.id}`);
      this.alertService.success("삭제 되었습니다.");
    }
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }

  goPdfViewer(url: string) {
    const modalRef = this.modalService.open(PdfViewerComponent, {
      scrollable: true,
      size: "xl",
    });
    modalRef.componentInstance.url = url;
  }

  goWebSolutionDetail(websoltionId: string) {
    const modalRef = this.modalService.open(WebSolutionDetailComponent, {
      size: "lg",
    });
    if (websoltionId) {
      modalRef.componentInstance.websoltionId = websoltionId;
    }
    modalRef.result.then(async (result) => {});
  }

  async sendAligo(inquiry: Inquiry, meeting: Meeting) {
    const phone1 = inquiry.phone.replace("-", "");
    const phone = phone1.replace("+82", "1");
    const meetingDate = new Date(meeting.start.seconds * 1000);
    const now = moment();

    let sendData: AligoSendData = {
      title: "",
      phone: phone,
      content: "",
      emtitle_1: "",
      tpl_code: "",
    };

    if (meeting.isCanceled) {
      this.alertService.error("취소된 미팅입니다.");
      return;
    }

    if (!moment(meetingDate).isAfter(now)) {
      this.alertService.error("이미 지난 미팅입니다.");
      return;
    }
    const confirm = await this.alertService.confirm(
      "발송 확인",
      `${
        meeting.isVideoMeeting ? "화상상담 안내" : "방문상담 안내"
      } 알림을 발송하시겠습니까?`,
      "발송"
    );
    if (!confirm) {
      return;
    }
    try {
      if (meeting.isVideoMeeting) {
        const month = moment(meeting.start.seconds * 1000).format("M");
        const day = moment(meeting.start.seconds * 1000).format("D");
        const time = moment(meeting.start.seconds * 1000).format("HH:mm");
        const videoMeetingUrl =
          "https://zoom.us/j/6746035085?pwd=ZVNIdkhOOTI3R2xpSWJkd1ppbW83QT09";
        sendData.tpl_code = "TS_0255";
        sendData.title = "카카오톡 알림톡_화상 미팅 안내";
        sendData.emtitle_1 = "화상 미팅 일정 안내";
        sendData.content = `
안녕하세요 ${inquiry.username} 님, 스타트앱입니다.

${month} 월 ${day} 일 ${time} 미팅 안내 드립니다.

미팅 내용 : ${inquiry.service} 관련 화상 미팅

화상 미팅 URL : ${videoMeetingUrl}

감사합니다.`;
      } else {
        const month = moment(meeting.start.seconds * 1000).format("M");
        const day = moment(meeting.start.seconds * 1000).format("D");
        const time = moment(meeting.start.seconds * 1000).format("HH:mm");
        const meetingRoomNumber = meeting.meetingRoom.replace(
          "meetingRoom",
          ""
        );
        sendData.tpl_code = "TS_0254";
        sendData.title = "카카오톡 알림톡_방문 미팅 안내";
        sendData.emtitle_1 = "미팅 일정 안내";
        sendData.content = `
안녕하세요 ${inquiry.username} 님, 스타트앱입니다.

${month} 월 ${day} 일 ${time} 미팅 안내 드립니다.

미팅 내용 : ${inquiry.service} 관련 미팅

미팅 장소 : 서울특별시 성동구 아차산로17길 48 SK V1센터 1동 2층 ${meetingRoomNumber} 회의실

URL : https://naver.me/FSQGbxV7
T Map : https://surl.tmobiapi.com/602d14ac (위 URL을 클릭하시면 T Map으로 자동 연결 됩니다.)

감사합니다.`;
      }
      this.loadService.setLoading(true);
      await this.dataService.sendAligoFcns(sendData);
      await this.updateMessageHistory(
        meeting.isVideoMeeting ? "화상상담 안내" : "방문상담 안내",
        inquiry.id
      );

      this.loadService.setLoading(false);
      this.alertService.success(
        "해당 클라이언트한테 알림을 발송완료하였습니다."
      );
    } catch (error) {}
  }

  async sendConsultingAligo(inquiry: Inquiry, consulting: Consulting) {
    const confirm = await this.alertService.confirm(
      "발송 확인",
      `${consulting.consultingType} 알림을 발송하시겠습니까?`,
      "발송"
    );
    if (!confirm) {
      return;
    }
    const phone1 = inquiry.phone.replace("-", "");
    const phone = phone1.replace("+82", "1");
    let sendData: AligoSendData = {
      title: "",
      phone: phone,
      content: "",
      emtitle_1: "",
      tpl_code: "",
    };

    try {
      switch (consulting.consultingType) {
        case "부재1차":
          const month = moment().format("M");
          const day = moment().format("D");
          const time = moment().format("HH:mm");
          sendData.tpl_code = "TS_0252";
          sendData.title = "카카오톡 알림톡_연락 부재";
          sendData.emtitle_1 = "앱 개발 무료 견적 관련 통화 부재 안내";
          sendData.content = `
안녕하세요 ${inquiry.username} 님, 스타트앱입니다.

저희 스타트앱에 대한 관심과 신뢰에 깊이 감사드립니다.

${
  inquiry.username
} 님께서 입력해주신 연락처로 전문 담당자가 연락 드렸으나 부재셔서 부재 메세지 드립니다.

1차 연락 : ${month} 월 ${day} 일 ${time}

전문 담당자가 재 연락 드릴 예정이오니 상담을 원치 않으시거나,
통화가 가능한 시간이 별도로 있는 경우
본 카카오톡 채널을 통해 상담취소 또는
통화가능한 시간을 알려주시기 바랍니다.

감사합니다.

-----------------------
${inquiry.username} 님께서 문의 주신 내용은 아래와 같습니다.

회사명 : ${inquiry.company ? inquiry.company : "-"}
프로젝트명 : ${inquiry.project ? inquiry.project : "-"}
연락처 : ${inquiry.phone ? inquiry.phone : "-"}
이메일 : ${inquiry.email ? inquiry.email : "-"}
문의서비스 : ${inquiry.service ? inquiry.service : "-"}
문의경로 : ${inquiry.visitRoute ? inquiry.visitRoute : "-"}

입력해 주신 연락처로 전문 담당자가 고객님께 연락드릴 예정이오니 중요한 정보 및 상세한 상담을 위해 전화를 꼭 받아주시기 바랍니다.

스타트앱은 고객님의 비즈니스 성공을 위한 파트너로써 끊임없이 노력하겠습니다.

감사합니다.`;

          break;

        case "부재2차":
          const noResponse1Date = new Date(
            (inquiry.noResponse1Date as Timestamp).seconds * 1000
          );
          const month1 = moment(noResponse1Date).format("M");
          const day1 = moment(noResponse1Date).format("D");
          const time1 = moment(noResponse1Date).format("HH:mm");
          const month2 = moment().format("M");
          const day2 = moment().format("D");
          const time2 = moment().format("HH:mm");
          sendData.tpl_code = "TS_0251";
          sendData.title = "카카오톡 알림톡_연락 부재_2차";
          sendData.emtitle_1 = "앱 개발 무료 견적 관련 통화 부재 안내";
          sendData.content = `
안녕하세요 ${inquiry.username} 님, 스타트앱입니다.

저희 스타트앱에 대한 관심과 신뢰에 깊이 감사드립니다.

${
  inquiry.username
} 님께서 입력해주신 연락처로 전문 담당자가 연락 드렸으나 부재셔서 부재 메세지 드립니다.

1차 연락 : ${month1} 월 ${day1} 일 ${time1}
2차 연락 : ${month2} 월 ${day2} 일 ${time2}


전문 담당자가 재 연락 드릴 예정이오니 상담을 원치 않으시거나,
통화가 가능한 시간이 별도로 있는 경우
본 카카오톡 채널을 통해 상담취소 또는
통화가능한 시간을 알려주시기 바랍니다.

감사합니다.

-----------------------

${inquiry.username} 님께서 문의 주신 내용은 아래와 같습니다.

회사명 : ${inquiry.company ? inquiry.company : "-"}
프로젝트명 : ${inquiry.project ? inquiry.project : "-"}
연락처 : ${inquiry.phone ? inquiry.phone : "-"}
이메일 : ${inquiry.email ? inquiry.email : "-"}
문의서비스 : ${inquiry.service ? inquiry.service : "-"}
문의경로 : ${inquiry.visitRoute ? inquiry.visitRoute : "-"}

입력해 주신 연락처로 전문 담당자가 고객님께 연락드릴 예정이오니 중요한 정보 및 상세한 상담을 위해 전화를 꼭 받아주시기 바랍니다.

스타트앱은 고객님의 비즈니스 성공을 위한 파트너로써 끊임없이 노력하겠습니다.

감사합니다.`;

          break;
        default:
          break;
      }

      this.loadService.setLoading(true);
      await this.dataService.sendAligoFcns(sendData);
      await this.updateMessageHistory(consulting.consultingType, inquiry.id);

      this.loadService.setLoading(false);
      this.alertService.success(
        "해당 클라이언트한테 알림을 발송완료하였습니다."
      );
    } catch (error) {}
  }

  async sendEstimateAligo(inquiry: Inquiry, estimates) {
    if (!estimates.length) {
      this.alertService.error("견적서를 등록하고 알림을 보내주세요.");
      return;
    }
    const confirm = await this.alertService.confirm(
      "발송 확인",
      "견적서 발송 알림을 발송하시겠습니까?",
      "발송"
    );
    if (!confirm) {
      return;
    }
    const phone1 = inquiry.phone.replace("-", "");
    const phone = phone1.replace("+82", "1");
    let sendData: AligoSendData = {
      title: "",
      phone: phone,
      content: "",
      emtitle_1: "",
      tpl_code: "",
    };

    try {
      sendData.tpl_code = "TS_0250";
      sendData.title = "카카오톡 알림톡_견적서 발송";
      sendData.emtitle_1 = "앱 개발 견적 발송 안내";
      sendData.content = `
안녕하세요 ${inquiry.username} 님, 스타트앱입니다.

저희 스타트앱에 대한 관심과 신뢰에 깊이 감사드립니다.

${inquiry.username} 님께서 요청 주신 ${
        inquiry.project ? inquiry.project : "-"
      } 관련 견적서가 발송되었습니다.

아래 주소의 이메일로 발송되었으니 확인 부탁드립니다.

수신 이메일 주소 : ${inquiry.email ? inquiry.email : "-"}

감사합니다.`;

      this.loadService.setLoading(true);
      await this.dataService.sendAligoFcns(sendData);
      await this.updateMessageHistory("견적서 발송", inquiry.id);
      this.loadService.setLoading(false);
      this.alertService.success(
        "해당 클라이언트한테 알림을 발송완료하였습니다."
      );
    } catch (error) {}
  }

  async changeImportant($event, inquiry: Inquiry) {
    if ($event) {
      const confirm = await this.alertService.confirm(
        "알림",
        "주요 고객으로 설정하시겠습니까?",
        "설정"
      );
      console.log("confirm", confirm);

      if (confirm) {
        await this.db.updateAt(`inquiries/${inquiry.id}`, {
          importantSwitch: $event,
          importantStatus: "기능 정의중",
        });
        this.alertService.success("주요 고객으로 설정되었습니다.");
      } else {
        inquiry.importantSwitch = false;
      }
    } else {
      const confirm = await this.alertService.confirm(
        "알림",
        "주요 고객에서 해제하시겠습니까?",
        "해제"
      );
      if (confirm) {
        await this.db.updateAt(`inquiries/${inquiry.id}`, {
          importantSwitch: $event,
        });
        this.alertService.success("주요 고객에서 해제되었습니다.");
      } else {
        inquiry.importantSwitch = true;
      }
    }
  }

  async updateMessageHistory(type, inquiryId) {
    const uid = await this.authService.uid();
    const addData = {
      dateCreated: new Date().toISOString(),
      createdBy: uid,
      type,
      inquiryId,
    };
    console.log("addData", addData);

    return this.db.updateAt(`messageHistories`, addData);
  }
}
