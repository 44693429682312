import { Component, Input, OnInit } from "@angular/core";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { RequestEducation } from "src/app/core/models/requestEducation.model";
import {
  DbService,
  docJoin,
  leftJoinDocument,
} from "src/app/core/services/db.service";
@Component({
  selector: "app-request-education-detail",
  templateUrl: "./request-education-detail.component.html",
  styleUrls: ["./request-education-detail.component.scss"],
})
export class RequestEducationDetailComponent implements OnInit {
  @Input() public requestEducationId;
  requestEducation;
  constructor(public activeModal: NgbActiveModal, public db: DbService) {}

  async ngOnInit() {
    this.requestEducation = await this.db
      .doc$(`requestEducation/${this.requestEducationId}`)
      .pipe(first())
      .toPromise();
  }

  passBack() {
    this.activeModal.close();
  }
}
