import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { Contract } from "src/app/core/models/contract.model";
import { Project } from "src/app/core/models/project.model";
import { User } from "src/app/core/models/user.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";

@Component({
  selector: "app-enroll-project",
  templateUrl: "./enroll-project.component.html",
  styleUrls: ["./enroll-project.component.scss"],
})
export class EnrollPrComponent implements OnInit {
  @Input() public contract: Contract;
  @Input() public type: string;

  formData: FormGroup;
  userList;
  inquiryList;
  inquiryList$;
  myFiles = [];
  isEdit = false;
  isLoading = true;
  userList$;
  submitForm = false;
  constructor(
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    public dataService: DataService,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    private db: DbService,
    private loadService: LoaderService
  ) {}

  ngOnInit() {
    this.formData = this.formBuilder.group({
      pm: [null, [Validators.required]],
      dateStarted: ["", [Validators.required]],
    });
    this._fetchData();
  }

  private async _fetchData() {
    await this.dataService.inIt();
    if (this.type == "edit") {
      const startDate = moment(this.contract.projectId.dateStarted).format(
        "YYYY-MM-DD"
      );
      this.formData.get("dateStarted").setValue(startDate);
      this.formData.get("pm").setValue(this.contract.projectId.pmId);
    }
    this.userList$ = this.db.collection$("users");
  }

  get form() {
    return this.formData.controls;
  }

  async enroll() {
    this.submitForm = true;
    if (!this.formData.valid) {
      // this.alertService.errormsg("알림", "프로젝트 매니저를 배정해 주세요.");
      return false;
    }
    this.loadService.setLoading(true);
    if (this.type == "edit") {
      const updateData = {
        dateStarted: new Date(
          this.formData.get("dateStarted").value
        ).toISOString(),
        pmId: this.formData.get("pm").value,
      };
      await this.db.updateAt(
        `projects/${this.contract.projectId.id}`,
        updateData
      );
      this.loadService.setLoading(false);
      this.alertService.success("프로젝트가 수정되었습니다.");
    } else {
      const id = await this.db.createFsId();
      const createdData: Project = {
        id,
        dateCreated: new Date().toISOString(),
        dateStarted: new Date(
          this.formData.get("dateStarted").value
        ).toISOString(),
        date: new Date(this.formData.get("dateStarted").value),
        status: "대기중",
        contractId: this.contract.id,
        pmId: this.formData.get("pm").value,
        clientId: this.contract.clientId,
        charges: [],
      };
      await this.db.updateAt(`projects/${id}`, createdData);
      await this.db.updateAt(`contracts/${this.contract.id}`, {
        projectId: id,
      });
      this.loadService.setLoading(false);
      this.alertService.success("프로젝트가 등록되었습니다.");
    }

    this.activeModal.close("update");
    this.submitForm = false;
  }

  passBack() {
    this.activeModal.close();
  }

  getArrayToString(arr: User[]) {
    const stringArray = arr.map((item) => item.name);
    return stringArray.toString();
  }
}
