import { Component, Input, OnInit } from "@angular/core";
import { DbService } from "src/app/core/services/db.service";
import { first } from "rxjs/operators";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Inquiry } from "src/app/core/models/inquiry.model";
import { ContactCompany } from "src/app/core/models/contactCompany.model";
@Component({
  selector: "app-search-contact-company",
  templateUrl: "./search-contact-company.component.html",
  styleUrls: ["./search-contact-company.component.scss"],
})
export class SearchContactCompanyComponent implements OnInit {
  @Input() public type: string;
  clients;
  term: string;
  page: number = 1;
  count: number = 0;
  tableSize: number = 7;
  tableSizes: any = [3, 6, 9, 12];
  contactCompanyList: ContactCompany[];
  constructor(private db: DbService, public activeModal: NgbActiveModal) {}

  async ngOnInit() {
    this.fetchData();
  }

  async fetchData() {
    this.contactCompanyList = await this.db
      .collection$("contactCompany", (ref) =>
        ref.where("isPartnerShip", "==", true)
      )
      .pipe(first())
      .toPromise();
  }

  onTableDataChange(event: any) {
    this.page = event;
    this.fetchData();
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.fetchData();
  }

  select(item) {
    this.activeModal.close(item);
  }
}
