<div class="modal-header">
  <div class="modal-title mt-0">계약 수정</div>
  <button type="button"
    class="btn-close"
    aria-hidden="true"
    (click)="passBack()"></button>
</div>
<div class="modal-body"
  *ngIf="contract">
  <form [formGroup]="contractForm"
    (ngSubmit)="validContract()">
    <div class="row mt-3">
      <div class="col-12">
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약명</label>
          <div class="col-md-9">
            <input class="form-control"
              name="contractTitle"
              formControlName="contractTitle"
              [ngClass]="{'is-invalid': submitContract && ctForm.contractTitle.errors}"
              type="text">
            <div *ngIf="submitContract && ctForm.contractTitle.errors"
              class="invalid-feedback">
              <span *ngIf="ctForm.contractTitle.errors.required">계약명은
                필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약금액(부가세포함)</label>
          <div class="col-md-9">
            <input class="form-control"
              name="contractAmount"
              (keyup)="updateTextView()"
              formControlName="contractAmount"
              [ngClass]="{'is-invalid': submitContract && ctForm.contractAmount.errors}"
              type="text">
            <div *ngIf="submit && ctForm.contractAmount.errors"
              class="submitContract-feedback">
              <span *ngIf="ctForm.contractAmount.errors.required">계약금액은
                필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약일</label>
          <div class="col-md-9">
            <input class="form-control"
              name="dateSigned"
              formControlName="dateSigned"
              [ngClass]="{'is-invalid': submitContract && ctForm.dateSigned.errors}"
              type="date">
            <div *ngIf="submitContract && ctForm.dateSigned.errors"
              class="invalid-feedback">
              <span *ngIf="ctForm.dateSigned.errors.required">계약일은 필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">프로젝트 시작일</label>
          <div class="col-md-9">
            <input class="form-control"
              name="periodStart"
              formControlName="periodStart"
              [ngClass]="{'is-invalid': submitContract && ctForm.periodStart.errors}"
              type="date">
            <div *ngIf="submitContract && ctForm.periodStart.errors"
              class="invalid-feedback">
              <span *ngIf="ctForm.periodStart.errors.required">프로젝트 시작일은
                필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">프로젝트 종료일</label>
          <div class="col-md-9">
            <input class="form-control"
              name="periodEnd"
              formControlName="periodEnd"
              [ngClass]="{'is-invalid': submitContract && ctForm.periodEnd.errors}"
              type="date">
            <div *ngIf="submitContract && ctForm.periodEnd.errors"
              class="invalid-feedback">
              <span *ngIf="ctForm.periodEnd.errors.required">프로젝트 종료일은
                필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약종류</label>
          <div class="col-md-9">
            <select id="contractType"
              name="contractType"
              formControlName="contractType"
              [ngClass]="{'is-invalid': submitContract && ctForm.contractType.errors} "
              class="form-control">
              <option value=""
                disabled>--계약종류을 선택해주세요.--</option>
              <option value="신규프로젝트">신규프로젝트</option>
              <option value="추가개발">추가개발</option>
              <option value="기타">기타</option>
            </select>
            <div *ngIf="submitContract && ctForm.contractType.errors "
              class="invalid-feedback">
              <span *ngIf="ctForm.contractType.errors.required">계약종류는
                필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약조건</label>
          <div class="col-md-3">
            <select id="advancePayment"
              name="advancePayment"
              formControlName="advancePayment"
              [ngClass]="{'is-invalid': (submitContract && ctForm.advancePayment.errors) ||  (submitContract &&contractForm.hasError('wrongCondition'))}"
              class="form-control">
              <option value="">--선금--</option>
              <option value="0">0</option>
              <option value="10">10%</option>
              <option value="20">20%</option>
              <option value="30">30%</option>
              <option value="40">40%</option>
              <option value="50">50%</option>
              <option value="60">60%</option>
              <option value="70">70%</option>
              <option value="80">80%</option>
              <option value="90">90%</option>
              <option value="100">100%</option>
            </select>
            <div
              *ngIf="(submitContract && ctForm.advancePayment.errors) || (submitContract && contractForm.hasError('wrongCondition'))"
              class="invalid-feedback">
              <span *ngIf="ctForm.advancePayment.errors?.required">선금은
                필수사항입니다.</span>
              <span *ngIf="!ctForm.advancePayment.errors&&contractForm.hasError('wrongCondition')">선금,중도금,잔금의
                합계는
                100%로 맞춰야합니다.
              </span>
            </div>
          </div>
          <div class="col-md-3">
            <select id="interimPayment"
              name="interimPayment"
              formControlName="interimPayment"
              [ngClass]="{'is-invalid': (submitContract && ctForm.interimPayment.errors) ||  (submitContract &&contractForm.hasError('wrongCondition'))}"
              class="form-control">
              <option value="">--중도금--</option>
              <option value="0">0</option>
              <option value="10">10%</option>
              <option value="20">20%</option>
              <option value="30">30%</option>
              <option value="40">40%</option>
              <option value="50">50%</option>
              <option value="60">60%</option>
              <option value="70">70%</option>
              <option value="80">80%</option>
              <option value="90">90%</option>
              <option value="100">100%</option>
            </select>
            <div
              *ngIf="(submitContract && ctForm.interimPayment.errors) ||  (submitContract &&contractForm.hasError('wrongCondition'))"
              class="invalid-feedback">
              <span *ngIf="ctForm.interimPayment.errors?.required">중도금
                필수사항입니다.</span>
            </div>
          </div>
          <div class="col-md-3">
            <select id="balance"
              name="balance"
              formControlName="balance"
              [ngClass]="{'is-invalid': (submitContract && ctForm.balance.errors) ||  (submitContract &&contractForm.hasError('wrongCondition'))}"
              class="form-control">
              <option value="">--잔금--</option>
              <option value="0">0</option>
              <option value="10">10%</option>
              <option value="20">20%</option>
              <option value="30">30%</option>
              <option value="40">40%</option>
              <option value="50">50%</option>
              <option value="60">60%</option>
              <option value="70">70%</option>
              <option value="80">80%</option>
              <option value="90">90%</option>
              <option value="100">100%</option>
            </select>
            <div
              *ngIf="(submitContract && ctForm.balance.errors) ||  (submitContract &&contractForm.hasError('wrongCondition'))"
              class="invalid-feedback">
              <span *ngIf="ctForm.balance.errors?.required">잔금은 필수사항입니다.</span>
            </div>
          </div>

        </div>
        <div class="row mb-3">
          <label class="col-md-3 col-form-label">계약추가정보</label>
          <div class="col-md-9">
            <textarea formControlName="information"
              class="form-control"
              rows="5"
              [ngClass]="{'is-invalid': submitContract && ctForm.information.errors}"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <label for="product-image"
            class="col-md-3 col-form-label">계약문서</label>
          <div class="col-md-9">

            <ngx-dropzone (change)="onContractFileSelect($event)">
              <ngx-dropzone-label>파일을 Drag&Drop하거나 직접 선택해주세요!</ngx-dropzone-label>
              <ngx-dropzone-preview *ngFor="let f of contractFiles"
                [removable]="true"
                (removed)="onContractFileRemove(f)">
                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})
                </ngx-dropzone-label>
              </ngx-dropzone-preview>
            </ngx-dropzone>
          </div>
        </div>
        <div class="row mb-3">
          <div class="table-responsive"
            *ngIf="contract.contractFiles&& contract.contractFiles.length>0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">파일명</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of contract.contractFiles; let i = index">
                  <td>
                    {{getFileName(file) }}
                  </td>
                  <td>
                    <button type="button"
                      class="btn btn-sm btn-outline-danger"
                      (click)="deleteFile(file,i)">삭제</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap gap-2 mt-2">
      <button type="submit"
        class="btn btn-primary">저장</button>
      <button (click)="passBack()"
        class="btn btn-secondary">취소</button>
    </div>
  </form>

</div>