<div class="modal-header">
  <h2 class="modal-title mb-0">주문 상세</h2>
  <button type="button"
    class="btn-close"
    aria-label="Close"
    (click)="passBack()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-xl-6">
      <!-- end card -->
      <div class="card"
        *ngIf="order">
        <div class="card-body">
          <div class="row m-0">
            <label for="example-text-input"
              class="col-md-4 col-form-label">주문 상태</label>
            <div class="col-md-4">
              <div class="btn-group"
                ngbDropdown>
                <button type="button"
                  class="btn dropdown-toggle"
                  [ngClass]=" { 'btn-primary': order.status === '주문대기', 'btn-secondary': order.status === '주문접수',
'btn-success': order.status === '결제완료', 'btn-info': order.status === '상품준비중',
'btn-danger': order.status === '환불', 'btn-dark': order.status === '완료'}"
                  ngbDropdownToggle>{{order.status?order.status:'주문접수'}} <i class="mdi mdi-chevron-down"></i></button>
                <div class="dropdown-menu"
                  ngbDropdownMenu>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'주문대기')"
                    href="javascript: void(0);">주문대기</a>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'주문접수')"
                    href="javascript: void(0);">주문접수</a>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'결제완료')"
                    href="javascript: void(0);">결제완료</a>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'상품준비중')"
                    href="javascript: void(0);">상품준비중</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'완료')"
                    href="javascript: void(0);">완료</a>
                  <a class="dropdown-item"
                    (click)="changeStatus(order,'환불')"
                    href="javascript: void(0);">환불</a>

                </div>
              </div><!-- /btn-group -->
            </div>
            <div class="col-md-4 text-end p-0">
              <div class="d-flex flex-wrap gap-2">
                <button (click)="deleteOrder()"
                  class="btn btn-danger">삭제</button>
                <button (click)="checkOrder()"
                  *ngIf="order.status !=='주문접수'"
                  class="btn btn-dark">접수처리</button>

              </div>
            </div>
          </div>



        </div>

      </div>
      <div class="card"
        *ngIf="order">
        <div class="card-body"
          *ngIf="isOrder">
          <h4 class="card-title mb-4">주문 수정</h4>
          <div class="form-group row mb-4">
            <label for="name"
              class="col-form-label col-lg-2">주문자명</label>
            <div class="col-lg-10">
              <input id="name"
                name="name"
                [(ngModel)]="order.name"
                type="text"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="email"
              class="col-form-label col-lg-2">이메일</label>
            <div class="col-lg-10">
              <input id="email"
                name="email"
                [(ngModel)]="order.email"
                type="email"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="phone"
              class="col-form-label col-lg-2">연락처</label>
            <div class="col-lg-10">
              <input id="phone"
                name="phone"
                [(ngModel)]="order.phone"
                type="text"
                class="form-control">
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">주문상품</label>
            <div class="col-lg-10">
              <ng-select [items]="products"
                bindLabel="title"
                [(ngModel)]="selectProduct"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">옵션</label>
            <div class="col-lg-10">
              <ng-select [items]="selectProduct.options"
                bindLabel="optionTitle"
                [(ngModel)]="selectOption"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">앱등록 여부</label>
            <div class="col-lg-10">
              <ng-select [items]="['등록요청','요청안함']"
                [(ngModel)]="isStore"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-lg-2">템플릿디자인 여부</label>
            <div class="col-lg-10">
              <ng-select [items]="['디자인요청','요청안함']"
                [(ngModel)]="isTemplate"></ng-select>
            </div>
          </div>
          <div class="form-group row mb-4">
            <label for="content"
              class="col-form-label col-lg-2">요청사항</label>
            <div class="col-lg-10">
              <textarea class="form-control"
                id="content"
                [(ngModel)]="order.memo"
                rows="3"></textarea>
            </div>
          </div>

        </div>
        <div class="card-body"
          *ngIf="!isOrder">
          <h4 class="card-title mb-4">주문 내용</h4>
          <div class="table-responsive">
            <table class="table table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">주문일시 :</th>
                  <td>{{order.dateCreated | date:'yyyy-MM-dd HH:mm'}}</td>
                </tr>
                <tr>
                  <th scope="row">주문자명 :</th>
                  <td>{{ order.name }}</td>
                </tr>
                <tr>
                  <th scope="row">이메일 :</th>
                  <td>{{order.email?order.email:'정보없음' }}</td>
                </tr>
                <tr>
                  <th scope="row">연락처 :</th>
                  <td>{{order.phone}}</td>
                </tr>
                <tr>
                <tr>
                  <th scope="row">주문상품 :</th>
                  <td>{{order.product}}</td>
                </tr>
                <tr>
                  <th scope="row">옵션 :</th>
                  <td>{{order.option?.model}}</td>
                </tr>
                <tr>
                  <th scope="row">앱등록 여부 :</th>
                  <td>{{order.isStore?'등록요청':'요청안함'}}</td>
                </tr>
                <tr>
                  <th scope="row">템플릿디자인 여부 :</th>
                  <td>{{order.isTemplate?'디자인요청':'요청안함'}}</td>
                </tr>

                <tr>
                  <th scope="row">구매금액 :</th>
                  <td>{{order.totalPrice | number}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 class="font-size-15 mt-4">요청사항</h2>
          <p style="white-space:pre-wrap ;">{{ order.memo }}</p>
        </div>
        <div class="card-footer bg-transparent border-top">
          <div class="text-center">
            <a href="javascript: void(0);"
              (click)="editOrder()"
              class="btn btn-dark"
              *ngIf="!isOrder">주문 수정</a>
            <a href="javascript: void(0);"
              (click)="saveOrder()"
              class="btn btn-primary"
              *ngIf="isOrder">수정사항 저장</a>
          </div>
        </div>
      </div>


      <!-- end card -->

      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">히스토리</h4>
          <div class="text-center"
            *ngIf="(statusHistories$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">히스토리가 없습니다.</p>
            </div>
          </div>
          <div class="table-responsive"
            *ngIf="(statusHistories$ | async)?.length > 0">
            <table class="table table-centered mb-0 table-nowrap">
              <thead class="thead-light">
                <tr>
                  <th scope="col">처리일시</th>
                  <th scope="col">변동상태</th>
                  <th scope="col">처리자</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ( statusHistories$ | async )">
                  <td>
                    {{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}
                  </td>
                  <td>
                    {{item.oldStatus}} -> {{item.newStatus}}
                  </td>
                  <td>
                    {{item.createdBy.name}}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="card consulting">
        <div class="card-body">
          <h4 class="card-title mb-4">상담 내역</h4>
          <div class="text-center"
            *ngIf="(consultings$ | async)?.length == 0">
            <div class="row justify-content-center">
              <p class="mt-2 fw-semibold">상담 내역이 없습니다.</p>
            </div>
          </div>
          <ngb-accordion [closeOthers]="true"
            activeIds="static-1"
            class="custom-accordion">
            <ngb-panel id="static-{{i+1}}"
              *ngFor="let item of (consultings$ | async); let i = index">
              <ng-template ngbPanelTitle>
                <div class="accordion-list">

                  <div>{{item.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}} - {{item.createdBy.name}}
                    <span class="badge rounded-pill badge-soft-success font-size-11">{{item.type}}</span>
                  </div>
                </div>

              </ng-template>
              <ng-template ngbPanelContent>
                <div *ngIf="item.isEditor">
                  <div class="form-group row mb-4">
                    <label for="project"
                      class="col-form-label col-2">상담 구분</label>
                    <div class="col-10">
                      <ng-select [items]="['구매문의','결제문의','과정문의','A/S문의','기타']"
                        [(ngModel)]="item.consultingType"></ng-select>
                    </div>
                  </div>
                  <ckeditor [editor]="Editor"
                    [(ngModel)]="item.description"></ckeditor>
                  <div class="row text-end">
                    <button class="btn btn-primary"
                      (click)="saveEditConsulting(item)">수정내용 저장</button>
                  </div>
                </div>
                <div *ngIf="!item.isEditor">
                  <div class="form-group row mb-4">
                    <h4 class="col-lg-2">상담 구분</h4>
                    <div class="col-lg-10">
                      {{item.consultingType?item.consultingType:'구분없음'}}
                    </div>
                  </div>
                  <h4 class="mb-4">상세 내용</h4>

                  <div [innerHTML]="item.description"></div>
                  <div class="text-center">
                    <button class="btn btn-dark mt-2"
                      (click)="editConsulting(item)">
                      상담내용
                      수정</button>
                  </div>
                </div>

              </ng-template>
            </ngb-panel>

          </ngb-accordion>
        </div>

      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">상담 정보</h4>
          <h2 class="font-size-15 mt-4">상담내용 입력</h2>
          <div class="form-group row mb-4">
            <label for="project"
              class="col-form-label col-2">상담 구분</label>
            <div class="col-10">
              <ng-select [items]="['구매문의','결제문의','과정문의','A/S문의','기타']"
                [(ngModel)]="consultingType"></ng-select>
            </div>
          </div>
          <div class="row mb-4">
            <ckeditor [editor]="Editor"
              [(ngModel)]="description"></ckeditor>

          </div>
          <div class="text-center">

            <button (click)="createdConsulting()"
              class="btn btn-primary">상담내용 저장</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>