<div class="modal-header">
    <h2 class="modal-title m-0">PDF 상세</h2>
    <button type="button"
        class="btn-close"
        aria-label="Close"
        (click)="passBack()"></button>
</div>
<div class="modal-body">
    <div id="outerContainer">
        <div class="pdf-container">
            <pdf-viewer [src]="pdfUrl"
                [rotation]="0"
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="true"
                [zoom]="1"
                [zoom-scale]="'page-width'"
                [stick-to-page]="false"
                [render-text]="false"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                style="width: 100%; height: 800px;"></pdf-viewer>
        </div>
    </div>
</div>