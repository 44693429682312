// geolocation.service.ts
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class GeolocationService {
  private functionUrl =
    "https://asia-northeast3-startapp-new.cloudfunctions.net/getAddressByIp"; // Firebase Function URL

  constructor(private http: HttpClient) {}

  getGeoLocation(ip: string) {
    const url = `${this.functionUrl}?ip=${ip}`;
    return this.http.get<any>(url);
  }
}
