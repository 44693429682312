import { LocationStrategy } from "@angular/common";
import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DndDropEvent } from "ngx-drag-drop";
import { AppCategory } from "src/app/core/models/appCategory.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import { DbService } from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { UserService } from "src/app/core/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-app-category-management",
  templateUrl: "./app-category-management.component.html",
  styleUrls: ["./app-category-management.component.scss"],
})
export class AppCategoryManagementComponent implements OnInit {
  @HostListener("window:popstate", ["$event"])
  private _prevOnSameUrlNavigation;
  appCategories: AppCategory[];
  submitted = false;
  userList;
  isEdit = false;
  isLoading = true;
  userList$;
  activeId: string;
  constructor(
    private _location: LocationStrategy,
    public modalService: NgbModal,
    public dataService: DataService,
    public alertService: AlertService,
    public activeModal: NgbActiveModal,
    public loader: LoaderService,
    private db: DbService,
    private _router: Router,
    private userService: UserService
  ) {}

  async ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    this.appCategories = await this.dataService.getAllProjectAppCategories();
  }

  passBack() {
    this.activeModal.close();
  }

  async deleteCategory(category) {
    let i = this.appCategories.findIndex((item) => item.id === category.id);

    const selectCategory = this.appCategories[i];
    const confirm = await this.alertService.confirm(
      "삭제 확인",
      `카테고리 ${selectCategory.title}를 삭제하시겠습니까?`,
      "삭제"
    );
    if (confirm) {
      this.appCategories.splice(i, 1);
      await this.db.delete(`appCategories/${category.id}`);
      this.alertService.success(`${selectCategory.title}가 삭제되었습니다.`);
      this.activeId = this.appCategories[i - 1]
        ? this.appCategories[i - 1].id
        : "";
    }
  }

  editCategory(category) {
    Swal.fire({
      title: "카테고리 수정",
      html: `<input type="text" id="title" class="swal2-input" placeholder="카테고리명" value="${category.title}">`,
      confirmButtonText: "저장",
      focusConfirm: false,
      backdrop: false,
      preConfirm: () => {
        const title = (
          Swal.getPopup().querySelector("#title") as HTMLInputElement
        ).value;

        if (!title) {
          Swal.showValidationMessage(`카테고리명은 필수사항입니다.`);
        }
        return { title: title };
      },
    }).then(async (result) => {
      if (result.value) {
        let i = this.appCategories.findIndex((item) => item.id == category.id);
        category.title = result.value.title;
        this.appCategories[i] = category;
        await this.db.updateAt(`appCategories/${category.id}`, category);
        this.alertService.success(category.title + "가 수정되었습니다.");
      }
    });
  }

  addCategory() {
    Swal.fire({
      title: "카테고리 추가",
      html: `<input type="text" id="title" class="swal2-input" placeholder="카테고리명">`,
      confirmButtonText: "추가",
      focusConfirm: false,
      preConfirm: () => {
        const title = (
          Swal.getPopup().querySelector("#title") as HTMLInputElement
        ).value;

        if (!title) {
          Swal.showValidationMessage(`카테고리명은 필수사항입니다.`);
        }
        return { title: title };
      },
    }).then(async (result) => {
      console.log("result", result);

      if (result.value) {
        this.loader.setLoading(true);
        let lastOrder = this.appCategories.length
          ? this.appCategories[this.appCategories.length - 1]?.order
          : 0;
        const category = {
          title: result.value.title,
          order: lastOrder + 1,
          id: this.db.createFsId(),
        };
        this.appCategories.push(category);
        this.activeId = category.id;
        await this.db.updateAt("appCategories", category);
        this.loader.setLoading(false);
        this.alertService.success(category.title + "가 추가되었습니다.");
      }
    });
  }

  /**
   * on dragging task
   * @param item item dragged
   * @param list list from item dragged
   */
  onDragged(item: any, list: any[]) {
    const index = list.indexOf(item);
    list.splice(index, 1);

    list.map((item, i) => {
      if (item.id) {
        return this.db.updateAt(`appCategories/${item.id}`, { order: i + 1 });
      }
    });
    this.appCategories = list.map((item, i) => {
      item.order = i + 1;
      return item;
    });
  }

  onDrop(event: DndDropEvent, filteredList?: any[], targetStatus?: string) {
    if (filteredList && event.dropEffect === "move") {
      let index = event.index;

      if (typeof index === "undefined") {
        index = filteredList.length;
      }
      filteredList.splice(index, 0, event.data);
    }
  }

  public ngOnDestroy() {
    this._router.onSameUrlNavigation = this._prevOnSameUrlNavigation;
  }
}
