<!-- <div class="modal-header">
    <h4 class="modal-title"
        id="modal-basic-title">고객 상세</h4>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <div class="card">
        <div class="card-body">
            <h4 class="card-title mb-4">계약 상세정보</h4>
            <!--
              <p class="text-muted mb-4">Hi I'm Cynthia Price,has been the industry's standard dummy text To an English
                  person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
            <div class="table-responsive">
                <table class="table table-nowrap mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">계약명 :</th>
                            <td>{{contract?.contractTitle}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약일 :</th>
                            <td>{{contract?.dateSigned | date:'yyyy-MM-dd'}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약자 :</th>
                            <td>{{contract?.clientId.clientName}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약금액 :</th>
                            <td>{{contract?.contractAmount | number : '.0'}}원</td>
                        </tr>
                        <tr>
                            <th scope="row">계약상태 :</th>
                            <td>{{contract?.status}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약종류 :</th>
                            <td>{{contract?.contractType}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약조건 :</th>
                            <td>선:{{contract?.advancePayment}} 중:{{contract?.interimPayment}} 잔:{{contract?.balance}}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">프로젝트기간 :</th>
                            <td>{{contract?.periodStart}} ~ {{contract?.periodEnd}}</td>
                        </tr>
                        <tr>
                            <th scope="row">계약등록일 :</th>
                            <td>{{contract?.dateCreated | date:'yyyy-MM-dd'}}</td>
                        </tr>
                        <tr>
                            <th scope="row">등록자 :</th>
                            <td>{{contract?.createdBy.name}}</td>
                        </tr>
                    </tbody>
                </table>
                <h2 class="font-size-15 mt-4">상세내용</h2>
                <p style="white-space:pre-wrap ;">{{ contract.information }}</p>
                <div class="row mt-3">
                    <h2 class="font-size-15 mt-4">첨부파일</h2>
                    <p *ngIf="!contract?.contractFiles ||  contract?.contractFiles.length == 0">없음</p>
                    <div class="table-responsive"
                        *ngIf="contract?.contractFiles&& contract?.contractFiles.length>0">
                        <table class="table table-centered mb-0 table-nowrap">
                            <thead class="thead-light">
                                <tr>
                                    <th scope="col">파일명</th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let file of contract?.contractFiles; let i = index"
                                    style="vertical-align: middle;">
                                    <td>
                                        {{getFileName(file) }}
                                    </td>
                                    <td>
                                        <button type="button"
                                            class="btn btn-sm btn-outline-danger"
                                            (click)="goPdfViewer(file)">보기</button>
                                    </td>
                                    <td>
                                        <button type="button"
                                            class="btn btn-sm btn-outline-primary"
                                            (click)="downloadFile(file)">다운로드</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button type="submit"
        (click)="passBack()"
        class="btn btn-primary w-100">닫기</button>
</div>