import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DbService } from "src/app/core/services/db.service";
import { LoaderService } from "src/app/core/services/loader.service";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DataService } from "src/app/core/services/data.service";
import { AlertService } from "src/app/core/services/alert.service";
import { FileUploaderSevice } from "src/app/core/services/fileUploader.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent, MatChipList } from "@angular/material/chips";
import { Observable } from "rxjs";
import { tagData } from "src/app/core/models/tagData.model";
import { map, startWith } from "rxjs/operators";
import { SolutionWebCategory } from "src/app/core/models/solutionAppCategory.models";

@Component({
  selector: "app-add-project",
  templateUrl: "./add-project.component.html",
  styleUrls: ["./add-project.component.scss"],
})
export class AddProjectComponent implements OnInit {
  @ViewChild("chipList") chipList: MatChipList;
  @Input() public contract;
  @Input() public contractId;
  @ViewChild("fileInput") fileInput: ElementRef;

  file;
  isEdit = false;
  projectForm: FormGroup;
  submit: boolean;
  uxfiles = [];
  designFiles = [];
  publishingFiles = [];
  developedFiles = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new FormControl("");
  filteredFruits: Observable<string[]>;
  sampleUrl = "";
  tagList = [];
  project;
  fileType;
  sampleUrls = [{ name: "", url: "" }];
  appCategories = [];
  allTags: string[];
  filteredTags: Observable<string[]>;
  tagCtrl = new FormControl("");
  categories = [];
  CreateNew;
  webCategories: SolutionWebCategory[];

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private loaderSerivce: LoaderService,
    private db: DbService,
    private dataService: DataService,
    public loderService: LoaderService,
    public alertService: AlertService,
    public fileUploaderService: FileUploaderSevice
  ) {}

  async ngOnInit(): Promise<void> {
    this.dataService.inIt();
    const appCategories = await this.dataService.getAllAppCategories();

    this.appCategories = appCategories.categoryData;
    const tagData: tagData = await this.dataService.getAppTags();
    this.allTags = tagData.tags;
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
    this.CreateNew = (category) => {
      this.alertService
        .confirm("카테고리 추가", `${category}를 추가 하시겠습니까?`, "추가")
        .then(async (ok) => {
          if (ok) {
            await this.dataService.addAppCategories(category);
            this.categories.push(category);
            this.alertService.basicMessage("추가되었습니다.");
          }
        });
    };
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter((item) =>
      item.toLowerCase().includes(filterValue)
    );
  }

  addItem() {
    this.sampleUrls.push({ name: "", url: "" });
  }

  deleteItem(index: number) {
    this.sampleUrls.splice(index, 1);
  }
  async save() {
    this.loderService.setLoading(true);
    const promiseUxfiles = this.uxfiles.map((file) => this.uploadFile(file));
    const promiseDesignFiles = this.designFiles.map((file) =>
      this.uploadFile(file)
    );
    const promisePublishingFiles = this.publishingFiles.map((file) =>
      this.uploadFile(file)
    );
    const promiseDevelopedFiles = this.developedFiles.map((file) =>
      this.uploadFile(file)
    );

    const updateProject = {
      dateCreated: new Date().toISOString(),
      uxfiles: await Promise.all(promiseUxfiles),
      designFiles: await Promise.all(promiseDesignFiles),
      publishingFiles: await Promise.all(promisePublishingFiles),
      developedFiles: await Promise.all(promiseDevelopedFiles),
      sampleUrls: this.sampleUrls,
      date: new Date(),
      createdBy: "",
      contractId: this.contract.id,
      categories: this.categories,
      tags: this.tagList,
    };

    this.dataService
      .createProject(updateProject)
      .then(() => {
        this.loderService.setLoading(false);
        this.alertService.basicMessage("프로젝트가 등록되었습니다.");
        this.activeModal.close(this.contract);
      })
      .catch(() => {
        this.loderService.setLoading(false);
        this.alertService.basicMessage(
          "등록이 실패하였습니다. 잠시후에 다시 시도하시길 바랍니다."
        );
      });
  }

  onSelect(event, type) {
    switch (type) {
      case "uxfiles":
        this.uxfiles.push(...event.addedFiles);
        this.file = null;
        break;
      case "designFiles":
        this.designFiles.push(...event.addedFiles);
        this.file = null;

        break;
      case "publishingFiles":
        this.publishingFiles.push(...event.addedFiles);
        this.file = null;

        break;
      case "developedFiles":
        this.developedFiles.push(...event.addedFiles);
        this.file = null;

        break;
      default:
        break;
    }
  }

  selectFile(type) {
    this.fileType = type;
    this.fileInput.nativeElement.click();
  }

  onFileChange(event) {
    switch (this.fileType) {
      case "uxfiles":
        for (var i = 0; i < event.target.files.length; i++) {
          this.uxfiles.push(event.target.files[i]);
        }
        event.target.value = "";

        break;
      case "designFiles":
        for (var i = 0; i < event.target.files.length; i++) {
          this.designFiles.push(event.target.files[i]);
        }
        event.target.value = "";

        break;
      case "publishingFiles":
        for (var i = 0; i < event.target.files.length; i++) {
          this.publishingFiles.push(event.target.files[i]);
        }
        event.target.value = "";

        break;
      case "developedFiles":
        for (var i = 0; i < event.target.files.length; i++) {
          this.developedFiles.push(event.target.files[i]);
        }
        event.target.value = "";

        break;
      default:
        break;
    }
  }

  deleteUploadFile(i, type) {
    switch (type) {
      case "uxfiles":
        this.uxfiles.splice(i, 1);

        break;
      case "designFiles":
        this.designFiles.splice(i, 1);

        break;
      case "publishingFiles":
        this.publishingFiles.splice(i, 1);

        break;
      case "developedFiles":
        this.developedFiles.splice(i, 1);
        break;
      default:
        break;
    }
  }

  uploadFile(file) {
    return new Promise<any>(async (resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const val = reader.result as string;
        const fileData = { name: file.name, data: val };
        const url = await this.fileUploaderService.uploadFile(file.name, val);
        resolve(url);
      };
    });
  }

  onRemove(event, type) {
    switch (type) {
      case "uxfiles":
        this.uxfiles.splice(this.uxfiles.indexOf(event), 1);

        break;
      case "designFiles":
        this.designFiles.splice(this.designFiles.indexOf(event), 1);

        break;
      case "publishingFiles":
        this.publishingFiles.splice(this.publishingFiles.indexOf(event), 1);

        break;
      case "developedFiles":
        this.developedFiles.splice(this.developedFiles.indexOf(event), 1);

        break;
      default:
        break;
    }
  }

  passBack() {
    this.activeModal.close(this.contract);
  }

  remove(ctrl): void {
    const index = this.tagList.indexOf(ctrl);

    if (index >= 0) {
      this.tagList.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our fruit
    if (value) {
      this.tagList.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }

  checkList(item) {}

  getFileName(url) {
    let filename = url.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/fileStorage/",
      ""
    );

    filename = filename.replace(
      "https://storage.googleapis.com/startapp-new.appspot.com/files/",
      ""
    );
    const words = filename.split("_");
    words.splice(0, 1);
    const newFilewName = words.join("_");
    return newFilewName;
  }
}
