import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { first } from "rxjs/operators";
import { SolutionConsulting } from "src/app/core/models/solutionConsult.models";
import { SolutionOrder } from "src/app/core/models/solutionOrder.model";
import { AlertService } from "src/app/core/services/alert.service";
import { DataService } from "src/app/core/services/data.service";
import {
  DbService,
  leftJoinDocument,
  leftJoinOneDocument,
} from "src/app/core/services/db.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import { log } from "console";

@Component({
  selector: "app-order-detail",
  templateUrl: "./solution-order-detail.component.html",
  styleUrls: ["./solution-order-detail.component.scss"],
})
export class SolutionOrderDetailComponent implements OnInit {
  @Input() public orderId;
  public Editor = ClassicEditor;
  private _prevOnSameUrlNavigation;

  order;
  feedBack;
  isManager = false;
  isOrder = false;
  consultings$: Observable<any>;
  statusHistories$: Observable<any>;
  consultingType = "구매문의";
  description = "";
  totalPrice = 0;
  selectOption;
  templateSwitch;
  storeSwitch;
  selectProduct;
  products;
  isStore;
  isTemplate;
  selectOption2;
  options = [
    {
      title: "3개월",
      value: 3,
      androidPrice: 270000,
      allPlatformPrice: 510000,
    },
    {
      title: "6개월",
      value: 6,
      androidPrice: 390000,
      allPlatformPrice: 720000,
    },
    {
      title: "12개월",
      value: 12,
      androidPrice: 540000,
      allPlatformPrice: 960000,
    },
  ];
  constructor(
    private db: DbService,
    public activeModal: NgbActiveModal,
    public dataservice: DataService,
    private alertService: AlertService,
    private dataService: DataService,
    private _router: Router,
    private _location: LocationStrategy
  ) {}

  async ngOnInit() {
    this._prevOnSameUrlNavigation = this._router.onSameUrlNavigation;
    this._router.onSameUrlNavigation = "ignore";
    this._location.pushState(
      "Modal state",
      "Modal title",
      this._router.url,
      ""
    );
    this._location.onPopState(() => {
      // example for a simple check if modal is opened
      if (this.activeModal !== undefined) {
        ("modal is opened - cancel default browser event and close modal");
        // TODO: cancel the default event
        // close modal
        this.activeModal.close();
      } else {
      }
    });
    this.order = await this.db
      .doc$(`orderSolution/${this.orderId}`)
      .pipe(
        leftJoinOneDocument(this.db.afs, "userId", "appSolutionUser"),
        first()
      )
      .toPromise();
    console.log("this.order", this.order);

    this.consultings$ = this.db
      .collection$(`solutionConsultings`, (ref) =>
        ref
          .where("solutionOrderId", "==", this.order.id)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
    this.statusHistories$ = this.db
      .collection$(`statusSolutionOrderHistories`, (ref) =>
        ref
          .where("solutionOrderId", "==", this.order.id)
          .orderBy("date", "desc")
      )
      .pipe(leftJoinDocument(this.db.afs, "createdBy", "users"));
  }

  async changeStatus(solutionOrder: SolutionOrder, status: string) {
    if (solutionOrder.status === status) {
      return;
    }
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 신청 상태를 "${status}"로 변경하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        solutionOrderId: solutionOrder.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: solutionOrder.status ? solutionOrder.status : "접수",
        newStatus: status,
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderSolution/${solutionOrder.id}`, {
        status: status,
      });
      solutionOrder.status = status;
      this.alertService.basicMessage("상태가 변경되었습니다.");
    }
  }

  async checkOrder() {
    const confirm = await this.alertService.confirm(
      "상태변경",
      `해당 신청을 접수하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const updateStatusHistory = {
        solutionOrderId: this.order.id,
        dateCreated: new Date().toISOString(),
        date: new Date(),
        oldStatus: this.order.status ? this.order.status : "신청접수",
        newStatus: "신청접수",
        createdBy: this.dataservice.userId,
      };
      await this.db.updateAt("statusStoreOrderHistories", updateStatusHistory);
      await this.db.updateAt(`orderSolution/${this.order.id}`, {
        status: "신청접수",
      });
      this.order.status = "신청접수";
      this.alertService.basicMessage("해당 신청이 접수되었습니다.");
    }
  }

  async deleteOrder() {
    const confirm = await this.alertService.confirm(
      "신청삭제",
      `해당 신청을 삭제하시겠습니까?`,
      "확인"
    );
    if (confirm) {
      const deleteOrder = {
        solutionOrderId: this.order.id,
        dateDeleted: new Date().toISOString(),
        date: new Date(),
        deletedBy: this.dataservice.userId,
        ...this.order,
      };
      await this.db.updateAt("deletedSolutionOrder", deleteOrder);
      await this.db.delete(`orderSolution/${this.order.id}`);
      this.alertService.basicMessage("해당 신청이 삭제되었습니다.");
      this.activeModal.close("delete");
    }
  }

  passBack() {
    this.activeModal.close(this.order);
  }

  editOrder() {
    this.isOrder = !this.isOrder;
  }

  async saveOrder() {
    this.isOrder = !this.isOrder;
    this.order.totalPrice = this.getPrice();
    await this.db.updateAt(`orderSolution/${this.order.id}`, this.order);
    this.alertService.basicMessage("변경사항이 저장되었습니다.");
  }

  async saveEditConsulting(item) {
    item.isEditor = false;
    const updateData = { ...item };
    delete updateData.isEditor;
    updateData.createdBy = this.dataService.userId;
    await this.db.updateAt(`solutionConsultings/${item.id}`, updateData);
    this.alertService.success("수정 내용이 저장되었습니다.");
  }

  editConsulting(item) {
    item.isEditor = true;
  }

  async createdConsulting() {
    const confirm = await this.alertService.confirm(
      "상담등록",
      "상담 내용을 저장하시겠습니까?",
      "확인"
    );
    if (confirm) {
      const newConsulting: SolutionConsulting = {
        solutionOrderId: this.order.id,
        date: new Date(),
        dateCreated: new Date().toISOString(),
        type: this.order.status ? this.order.status : "신청접수",
        updateFiles: [],
        consultingType: this.consultingType,
        createdBy: this.dataService.userId,
        description: this.description,
      };
      await this.db.updateAt("solutionConsultings", newConsulting);
      this.description = "";
      this.alertService.basicMessage("상담내용이 등록되었습니다.");
    }
  }

  getPrice() {
    const find = this.options.find(
      (option) => option.value == this.order.period
    );
    if (this.order.option == "안드로이드") {
      return find.androidPrice;
    } else {
      return find.allPlatformPrice;
    }
  }
}
