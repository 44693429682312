<div class="modal-header">
    <div class="modal-title mt-0">IP 상세</div>
    <button type="button"
        class="btn-close"
        aria-hidden="true"
        (click)="passBack()"></button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xl-4">
            <div class="table-responsive"
                *ngIf="trackingData">
                <table class="table table-nowrap mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">IP :</th>
                            <td>{{trackingData?.ip}}</td>
                        </tr>
                        <!-- <tr>
                            <th scope="row">지역 :</th>
                            <td>서울시 성동구 아차산로</td>
                        </tr> -->
                        <tr>
                            <th scope="row">방문횟수 :</th>
                            <td>{{(trackingDataList$ | async)?.length}}회</td>
                        </tr>
                        <tr>
                            <th scope="row">첫방문 :</th>
                            <td>{{firstVisit | date:'yyyy-MM-dd HH:mm'}}</td>
                        </tr>
                        <tr>
                            <th scope="row">문의여부 :</th>
                            <td>{{isInquiry}} <button type="button"
                                    *ngIf="isInquiry ==='문의등록'"
                                    (click)="goInquiryDetail()"
                                    style="margin-left: 2px;"
                                    class="btn btn-outline-dark btn-sm btn-rounded m-0">상세
                                    보기</button></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
        <div class="col-xl-8">
            <div class="table-responsive mb-0"
                *ngIf="trackingData">
                <table class="table table-centered  align-middle table-nowrap"
                    id="trackingData-tble">
                    <thead class="table-light">
                        <tr>

                            <th class="align-middle">날짜</th>
                            <th class="align-middle">타입</th>
                            <th class="align-middle">Device Type</th>
                            <th class="align-middle">Browser</th>
                            <th class="align-middle">접속유입 URL</th>
                            <th class="align-middle">접속유형</th>
                            <th class="align-middle">검색키워드</th>
                            <th class="align-middle">utmCampaign</th>
                            <th class="align-middle">utmContent</th>
                            <th class="align-middle">utmMedium</th>
                            <th class="align-middle">utmSource</th>
                            <th class="align-middle">utmTerm</th>


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of (trackingDataList$ | async);">

                            <td>{{data.dateCreated | date:'yyyy-MM-dd HH:mm:ss'}}</td>
                            <td>{{getTypeName(data.type)}}</td>
                            <td>{{data.deviceInfo?.deviceType}}</td>
                            <td>{{data.deviceInfo?.browser}}</td>
                            <td>{{data.referrer?(data.referrer | slice:0:35):'-'}}</td>
                            <td>{{data.urlType}}</td>
                            <td>{{data.searchKeyword}}</td>
                            <td>{{data.utmCampaign}}</td>
                            <td>{{data.utmContent}}</td>
                            <td>{{data.utmMedium}}</td>
                            <td>{{data.utmSource}}</td>
                            <td>{{data.utmTerm}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>



</div>


<div class="modal-footer">
    <button type="submit"
        (click)="passBack()"
        class="btn btn-primary">닫기</button>
</div>